import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid, InputAdornment, InputLabel } from '@material-ui/core';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import SelectField from 'components/core/SelectField';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    flex: {
        flex: 1
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        display: 'block',
        paddingLeft: theme.spacing(1/4)
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    },
    button: {
        width: theme.spacing(19.5),
        marginLeft: theme.spacing(1)/2,
        marginRight: theme.spacing(1)/2,
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

export default function PackageInfoForm({ title = 'Package Information', record, isSubmitting, onSubmit, onError, onDirty }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = { 
        reasonId: ['required'],
        carrierId: ['required'],
        packageTypeId: ['required']
    }
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validations, record)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <SelectField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                autoFocus
                margin="normal"
                id="reasonId"
                label="Reason"
                defaultValue={state ? (state.reasonId || "") : ""}
                required={true}
                error={!!errors['reasonId']}
                helperText={!!errors['reasonId'] && errors['reasonId']}
                resource={{ 
                    path: '/device-order-reason',
                    method: 'get'
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
            />
            <SelectField
                className={clsx(classes.flex, classes.marginRight)}
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="carrierId"
                label="Carrier"
                defaultValue={state ? (state.carrierId || "") : ""}
                required={true}
                error={!!errors['carrierId']}
                helperText={!!errors['carrierId'] && errors['carrierId']}
                resource={{ 
                    path: '/shipping-carriers',
                    method: 'get'
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                textName="text"
                valueName="id"
                extraValues={[{
                    "valueName": "text",
                    "submitName": "carrierName"
                }]}
            />
            <div className={classes.hbox}>
                <SelectField
                    className={clsx(classes.flex, classes.marginRight)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal"
                    id="packageTypeId"
                    label="Package Type"
                    defaultValue={state ? (state.packageTypeId || "") : ""}
                    required={true}
                    error={!!errors['packageTypeId']}
                    helperText={!!errors['packageTypeId'] && errors['packageTypeId']}
                    resource={{ 
                        path: '/package-type',
                        method: 'get'
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    textName="text"
                    valueName="value"
                />
                <TextField
                    className={clsx(classes.flex, classes.marginLeft)}
                    type="number"
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal" 
                    id="packageWeight" 
                    label="Weight"
                    defaultValue={state && state.packageWeight} 
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors['packageWeight']}
                    helperText={!!errors['packageWeight'] && errors['packageWeight']}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{'lbs'}</InputAdornment>
                    }}
                />
            </div>
            <div style={{width: '100%', marginTop: '16px'}}>
                <InputLabel style={{ transform: 'translate(0, 1.5px) scale(0.75)', transformOrigin: 'top left' }}> Package Size </InputLabel> 
            </div>
            <div className={classes.hbox}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item className={classes.flex}>
                        <TextField
                            type="number"
                            className={classes.flex}
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            margin="normal" 
                            id="packageLength" 
                            label="Length"
                            defaultValue={state && state.packageLength} 
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors['packageLength']}
                            helperText={!!errors['packageLength'] && errors['packageLength']}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{'in'}</InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item style={{width: 10}}></Grid>
                    <Grid item className={classes.flex}>
                        <TextField
                            type="number"
                            className={classes.flex}
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            margin="normal" 
                            id="packageWidth" 
                            label="Width"
                            defaultValue={state && state.packageWidth} 
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors['packageWidth']}
                            helperText={!!errors['packageWidth'] && errors['packageWidth']}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{'in'}</InputAdornment>
                            }}
                        />
                    </Grid>
                    <Grid item style={{width: 10}}></Grid>
                    <Grid item className={classes.flex}>
                        <TextField
                            type="number"
                            className={classes.flex}
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            margin="normal" 
                            id="packageHeight" 
                            label="Height"
                            defaultValue={state && state.packageHeight} 
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors['packageHeight']}
                            helperText={!!errors['packageHeight'] && errors['packageHeight']}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{'in'}</InputAdornment>
                            }}
                        />
                    </Grid>                    
                </Grid>
            </div>
        </form>
    )
}