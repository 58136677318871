import React, { useRef, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import Filter from 'components/core/Filter';
import { TextField } from '@material-ui/core';

const inputComponent = ({ decimalScale }) => ({ name, inputRef, onChange, ...others }) => {
    return (
        <NumberFormat
            {...others}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name,
                        value: values.value,
                    }
                })
            }}
            decimalScale={decimalScale}
            thousandSeparator
            isNumericString
            allowNegative={false}
            isAllowed={({ floatValue }) => (floatValue != undefined ? floatValue > 0 : true)}
        />
    )
}

const NumberFilter = ({ showOperator, operator, dataType, ...others }) => {
    const operators = useRef([
        { label: 'equal', value: 'eq' },
        { label: 'not equal', value: 'ne' },
        { label: 'greater than', value: 'gt' },
        { label: 'less than', value: 'lt' },
        { label: 'greater than or equal to', value: 'gte' },
        { label: 'less than or equal to', value: 'lte' },
        // { label: 'between', value: 'between' }    
    ])

    return (
        <Filter
            InputProps={{
                inputComponent: useMemo(() => inputComponent({
                    decimalScale: (dataType == 'integer') ? 0 : 2
                }), [dataType])  
            }}
            component={TextField}
            operator={(showOperator && operator) ? operator : "gt"}
            operators={showOperator && operators.current}
            showOperator={showOperator}
            dataType={dataType}
            {...others}
        />
    )
}

export default NumberFilter