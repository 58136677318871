import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import PhoneField from 'components/core/PhoneField';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    flex: {
        flex: 1
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

export default function AuthContactForm({ title = 'Billing Information', record, isSubmitting, onSubmit, onError }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = { 
        memberPhone: ['required'], 
        authPhone: ['required'],
        authFax: ['required']
    }
    const [state, errors, handleChange, handleBlur, handleSubmit] = useForm(callback, validations, record)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    // console.log(state)

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <PhoneField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="memberPhone" 
                label="Member Phone"
                defaultValue={state && state.memberPhone} 
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors['memberPhone']}
                helperText={!!errors['memberPhone'] && errors['memberPhone']}
            />
            <PhoneField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="authPhone" 
                label="Authorization Phone"
                defaultValue={state && state.authPhone} 
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors['authPhone']}
                helperText={!!errors['authPhone'] && errors['authPhone']}
            />
            <PhoneField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="authFax" 
                label="Authorization Fax"
                defaultValue={state && state.authFax} 
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors['authFax']}
                helperText={!!errors['authFax'] && errors['authFax']}
            />
        </form>
    )
}