import React, { Fragment, forwardRef, useRef, useState, useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, CircularProgress, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide  } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import { useDispatch } from 'react-redux';
import { AuthManagerContext } from 'contexts/AuthManager'

import { getDefaultHCP } from 'actions';

import { I18nManagerContext } from 'contexts/I18nManager';
import PatientPathwayForm from 'components/PatientPathwayForm'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 400
    },
    title: {
        display: 'block',
        padding: 0
    },
    subtitle: {
        display: 'block',
        paddingLeft: theme.spacing(1/4)
    },
    content: {
    },
    buttons: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    buttonsCancel: {
        marginTop: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: theme.spacing(19.5),
        marginLeft: theme.spacing(1)/2,
        marginRight: theme.spacing(1)/2,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    },
    message: {
        paddingTop: 6,
        paddingBottom: 13,
        textAlign: 'center',
        fontSize: '.9rem',
        fontStyle: 'italic'
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function PatientPathwayDialog({ open, title, subtitle, record, onSubmit, onCancel }) {
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const dispatch = useDispatch()
    const authManager = useContext(AuthManagerContext)
    const [isSubmitting, setSubmitting] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const [isCancelling, setIsCancelling] = useState(false)
    const [state, setState] = useState(record || {})
    const params = useParams()

    const patientId = params.patientId
    const customerId =  params.customerId || authManager.customerId 

    const handleCancel = () => {
        if (isDirty) {
            setIsCancelling(true)
        } else {
            onCancel()
        }
    }

    const handleError = () => {
        setSubmitting(false)   
    }

    const handleDone = () => {
        setSubmitting(true)
    }

    const handleSubmit = newState => {
        onSubmit(newState)
    }

    const handleDirty = isDirty => {
        setIsDirty(isDirty)
    }

    const handleConfirm = () => {
        onCancel()
    }

    const handleDenied = () => {
        setIsCancelling(false)
    }

    useEffect(() => {
        async function fetchData() {
          const { data: { result }, error } = await dispatch(getDefaultHCP({ patientId: patientId, customerId: customerId }))
            
          if (!error && result) {
            setState(result)
          }
        }

        record && (typeof record === 'object') || fetchData();
      }, [patientId, customerId]);
      

    return (
        <Dialog 
            classes={{
                paper: classes.dialogPaper
            }}
            open={open} 
            onClose={onCancel} 
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            keepMounted
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="form-dialog-title">
                {/* <Typography className={classes.title} variant="h6" color="secondary" noWrap> */}
                    {i18nManager.t(title)}
                {/* </Typography> */}
                <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                    {i18nManager.t(subtitle)}
                </Typography>  
            </DialogTitle>
            <DialogContent className={classes.content}>
                <PatientPathwayForm 
                    record={state}
                    params={params}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    onError={handleError}
                    onDirty={handleDirty}
                />
            </DialogContent>
            {isCancelling ? (
                <Fragment> 
                    <Typography className={classes.message} variant="caption" color="error" noWrap>
                        Are you sure you want to cancel? All changes will be lost.
                    </Typography> 
                    <DialogActions className={classes.buttonsCancel}>
                        <Button variant="contained" className={classes.button} onClick={handleDenied}>No</Button>
                        <Button variant="contained" className={classes.button} onClick={handleConfirm} color="secondary">Yes</Button>
                    </DialogActions> 
                </Fragment>
            ) : (
                <DialogActions className={classes.buttons}>
                    <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" className={classes.button} onClick={handleDone} color="secondary">Submit</Button>
                </DialogActions>
            )}
        </Dialog>
    )
}

PatientPathwayDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default PatientPathwayDialog