import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatFloatHours } from 'helpers';

import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    chip: {
        color: 'white',
        padding: '0px 4px',
        width: 'fit-content',
        alignSelf: 'center',
        backgroundColor: 'gray',
    },
    goal: {
        color: 'white',
        paddingLeft: 8,
        backgroundColor: '#4CB091'
    },
    baseline: {
        color: 'white',
        paddingLeft: 8,
        backgroundColor: '#FCA861'
    },
    critical: {
        color: 'white',
        paddingLeft: 8,
        backgroundColor: '#EF7373'
    },
    noIcon: {
        padding: 4
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 'fit-content'
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: 'fit-content',
        alignSelf: 'center'
    }
}))

export default function MeasureChipField({ value, record, ...others }) {
    const classes = useStyles()
    const floatValue = parseFloat(value)
    const measure = (floatValue && record.ruleName.includes('Sleep')) ? formatFloatHours(floatValue) : floatValue
    const cls = (value > record.criticalZoneHigh || value < record.criticalZoneLow) ? 'critical' : (value > record.baselineHigh || value < record.baselineLow ? 'baseline' : 'goal')

    return (
        <div className={classes.vbox}>
            <div className={classes.hbox}>
                <Chip label={measure}
                    className={clsx(classes.chip, classes[cls], classes.noIcon)}
                    {...others}
                />
            </div>
        </div>
    )
}