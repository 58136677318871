import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// function inputComponent(props) {
//     const { id, inputRef, onChange, ...other } = props;
  
//     return (
//       <NumberFormat
//         {...other}
//         getInputRef={inputRef}
//         onValueChange={values => {
//             console.log(props)
//           onChange({
//             target: {
//                 id,
//                 value: new Date(values.formattedValue),
//             },
//           });
//         }}
//         format="##/##/####" 
//         placeholder="MM/DD/YYYY"
//        // mask=""
//         isNumericString
//         mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
//       />
//     );
// }
  
// inputComponent.propTypes = {
//     inputRef: PropTypes.func.isRequired,
//     onChange: PropTypes.func.isRequired,
//   };

// export default function DateField(props) {
//     return (
//         <TextField
//             InputProps={{
//                 inputComponent,

//             }}
//             {...props}
//         />
//     )
// }

export default function DateField({...others}) {
  console.log(others)

  // const handleChange = ({ value, ...others }) => {
  //   debugger
  
  // //   {value => handleChange({
  // //     target: { id: "dob", value }
  // // })}
  
  //   onChange && onChange({
  //       // text,
  //       value,
  //       ...others
  //   })
  // }

  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
              clearable={true}
              //variant="inline"
              format="MM/dd/yyyy"
              //margin="normal"
              //id="dob"
              //label="Birthday"
              //fullWidth
              disableFuture
              //error={!!errors['dob']}
              //helperText={!!errors['dob'] && errors['dob']}
              //value={state && state.dob ? state.dob : null}
              initialFocusedDate={new Date()}
              //onChange={handleChange}
              {...others}
          />
      </MuiPickersUtilsProvider>
  )
}