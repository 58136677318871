import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { AuthManagerContext } from 'contexts/AuthManager';
import { useDispatch } from 'react-redux';

import * as Types from 'constants/ActionTypes';
import uuid from 'uuid/v4';
import { actionSuccessCreator } from 'actions/index';

import { EventEmitter } from 'events'

class WebSocketEmitter extends EventEmitter {
	constructor({ token }) {
		super()
		this.ws = new WebSocket(`${process.env.REACT_APP_WS_URL}?accessToken=${token}`)
	}
	addEventListener(event, listener) {
		return this.ws.addEventListener(event, listener)
	}	
}

export const WebSocketContext = createContext(null)

export const WebSocketProvider = ({ children }) => {
    const authManager = useContext(AuthManagerContext)
    // const ws = useMemo(() => new WebSocket(`${process.env.REACT_APP_WS_URL}?accessToken=${authManager.token}`), [authManager.token])
	const ws = useMemo(() => new WebSocketEmitter({ token: authManager.token }), [authManager.token])
    const dispatch = useDispatch()

    useEffect(() => {
        ws.addEventListener('open', () => {
            console.log('WebSocket connection opened')
        }, false)

        ws.addEventListener('close', () => {
            console.log('disconnected');
        })
        
        ws.addEventListener('message', message => {
            try {
                const body = message.data && JSON.parse(message.data)
                const { data, ...others } = body.payload
				

				if (data.event) {
					const { event, params } = data

					ws.emit(event, params)
				}
				else {
					const notification = { foreground: true, internal: true, message: others, data }

					dispatch(actionSuccessCreator(Types.PUSH_NOTIFICATION, uuid(), { result: notification }))
				}
            }
            catch(error) {
                console.log(error)
            }
        }, false)
    }, [])

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}