import { filterByProp, mergeByProp, ascending } from 'utils';
import DataBrowserContainer from 'containers/core/DataBrowser';

import StackNavigation from 'components/StackNavigation';

export const asRoutes = views => views.map(({ title, path, ...others }) => ({
    title,
    text: title,
    to: path,
    component: DataBrowserContainer, //TODO:
    ...others
}))

export const getRoutes = (application, routesOverride) => mergeByProp(
    asRoutes(
        filterByProp(
            application.views || [], 
            routesOverride.map(route => route['name']), 
        )('name')
    ), 
    routesOverride
)('name')
.sort(ascending('sortOrder'))

const asRoute = ({ title, path, ...others }) => ({
    title,
    text: title,
    to: path,
    ...others
})

const routeWith = (route, application) => {
    if (route.routes && route.routes.length > 0) {
        const stackRoutes = getRoutesWith(route.routes, application)
        const { title, text, to, icon } = stackRoutes[0]

        route = Object.assign(route, {
            routes: stackRoutes,
            component: StackNavigation,
            title, text, icon, to
        })
    }
    else {
        const view = (application.views || []).find(view => view.name === route.name)
        if (view) {
            route = Object.assign({...view}, route)   
        }
    }
    return asRoute(route)
}
export const getRoutesWith = (routes, application) => {
    return routes.map(route => routeWith(route, application))
}

export { default as Login } 	                    from './Login';
export { default as ForgotPassword } 	            from './ForgotPassword';
export { default as EmailVerify } 	                from './EmailVerify';
export { default as ResetPassword } 	            from './ResetPassword';

export { default as Devices } 	                    from './Devices';
export { default as Patients } 	                    from './Patients';
export { default as Users } 	                    from './Users';
export { default as EducationalMaterials }          from './EducationalMaterials';

export { default as CustomerProfile } 	            from './CustomerProfile';
export { default as Customer } 	                    from './Customer';

export { default as PatientPathways }               from './PatientPathways';
export { default as PatientGoals }                  from './PatientGoals';
export { default as PatientDevices }                from './PatientDevices';
export { default as PatientEducationalMaterials }   from './PatientEducationalMaterials';
export { default as PatientInsurances }             from './PatientInsurances';
export { default as PatientProfile }                from './PatientProfile';
export { default as PatientHCProviders }            from './PatientHCProviders';
export { default as PatientInteractionsWidget }     from './PatientInteractionsWidget';
export { default as PatientInteractions }           from './PatientInteractions';
export { default as PatientDetails }                from './PatientDetails';
export { default as PatientAlerts }                 from './PatientAlerts';
export { default as PatientAlertMeasures }          from './PatientAlertMeasures';
export { default as PatientAlertHistory }           from './PatientAlertHistory';
export { default as PatientAlertDetails }           from './PatientAlertDetails';
export { default as PatientOrders }                from './PatientOrders';
export { default as PatientOrderContent }           from './PatientOrderContent';
export { default as PatientOrderHistory }           from './PatientOrderHistory';
export { default as PatientOrderDetails }           from './PatientOrderDetails';
export { default as Patient } 	                    from './Patient';

export { default as Customers } 	                from './Customers';

export { default as Reports } 	                    from './Reports';

export { default as Main } 	                        from './Main';