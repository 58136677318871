import React, { createContext, useState } from 'react';
import { defaultLanguage } from 'utils'

export const I18nManagerContext = createContext(null)

export const I18nManagerProvider = ({ value, children }) => {
    const lang = defaultLanguage()

    return (
        <I18nManagerContext.Provider value={Object.assign({
            defaultLanguage: lang
        }, value, {
            t: (key, interpolations = {}) => {
                const locales = value.locales || []
                const locale = locales.find(locale => locale.key === key)
                const template = locale && locale.values[lang]

                return template ? 
                    template.replace(/\{\{\s*([^}\s]+)\s*\}\}/g, (_, token) => interpolations[token]) :
                    key
            }
        })}>
            {children}
        </I18nManagerContext.Provider>
    )
}