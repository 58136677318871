import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem, Chip, FormControl, InputLabel } from '@material-ui/core';

import I18nField from 'components/core/I18nField'
import IconField from 'components/core/IconField'

const useStyles = makeStyles(theme => ({
    flex: {
        flex: 1
    },
    dialog: {
        margin: 'auto',
        minWidth: 275,
    },
    dialogContent: {
        paddingBottom: theme.spacing(5)
    },
    dialogActions: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1)
    }
}))

const supportedLanguages = [{
    code: 'en',
    name: 'English',
    primary: true
}, {
    code: 'es',
    name: 'Español',
    primary: false
}]

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

function DataBrowserForm({ open, resources, onOpen, onClose, onSubmit }) {
    const classes = useStyles()
    const [formState, setFormState] = useState({ resource: { path: "" } })

    useEffect(() => {
        if (onOpen) onOpen({
            resources: { params: { method: 'get' } }
        })
    }, [])

    function handleSubmit(event) {
        event.preventDefault()
        if (onClose) onClose()

        onSubmit(formState)
    }

    function handleChange(event) {
        const { id, value } = event.target

        setFormState(prevState => Object.assign({...prevState}, { [id]: value }))
    }

    function handleAutoSelectChange(selection) {
        const { id, value } = selection 

        setFormState(prevState => Object.assign({...prevState}, { [id]: value }))
    }

    const handleSelectChange = select => event => {
        const { id, options, valueName } = select
        let { value } = event.target

        value = options.find(option => option[valueName] === value)
        setFormState(prevState => Object.assign({...prevState}, { [id]: {...value} }))
    }

    return (
        <Dialog
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title">
            <form style={{ width: 400 }} autoComplete="off" onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">
                    New Data Table
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <TextField
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        autoFocus
                        margin="dense" 
                        id="name" 
                        label="Name"
                        defaultValue={formState.name} 
                        fullWidth
                        onChange={handleChange}
                        required={true}
                    />
                    <I18nField
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="dense" 
                        id="title" 
                        label="Title"
                        defaultValue={formState.title} 
                        fullWidth
                        onChange={handleAutoSelectChange}
                        required={true}
                        languages={supportedLanguages}
                    />
                    <I18nField
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="dense" 
                        id="subtitle" 
                        label="Subtitle"
                        defaultValue={formState.subtitle} 
                        fullWidth
                        onChange={handleAutoSelectChange}
                        required={false}
                        languages={supportedLanguages}
                    />
                    <IconField
                        InputLabelProps={InputLabelProps}
                        // variant="outlined"
                        autoComplete="off"
                        margin="dense" 
                        id="icon" 
                        label="Icon"
                        defaultValue={formState.icon} 
                        fullWidth
                        onChange={handleAutoSelectChange}
                        required={false}
                    />
                    {/* <TextField
                        InputLabelProps={InputLabelProps}
                        // variant="outlined"
                        autoComplete="off"
                        margin="dense" 
                        id="icon" 
                        label="Icon"
                        defaultValue={formState.icon} 
                        fullWidth
                        onChange={handleChange}
                        required={false}
                    /> */}
                    <TextField
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="dense" 
                        id="path" 
                        label="Application Path"
                        defaultValue={formState.path} 
                        fullWidth
                        onChange={handleChange}
                        required={true}
                    />
                    <TextField
                        select
                        InputLabelProps={InputLabelProps}
                        SelectProps={{
                            id: "resource",
                            value: formState.resource.path
                        }}
                        autoComplete="off"
                        margin="dense"
                        label="API Resource"
                        // id="resource"
                        // value={formState.resource.path}
                        fullWidth
                        onChange={handleSelectChange({
                            id: 'resource',
                            valueName: 'path',
                            options: resources
                        })}
                        required={true}
                    >
                        {resources ? resources.map(resource => (
                            <MenuItem key={resource.path} value={resource.path}>
                                <Chip label={resource.method.toUpperCase()} color="secondary"/> 
                                <Chip style={{ marginLeft: 4 }} label={resource.path}/>
                            </MenuItem>
                        )) : [""]}
                    </TextField>
                    <TextField
                        type="number"
                        InputLabelProps={Object.assign(InputLabelProps, {
                            shrink: true
                        })}
                        autoComplete="off"
                        margin="dense"
                        id="sortOrder"
                        label="Order"
                        defaultValue={formState.sortOrder}
                        fullWidth
                        onChange={handleChange}
                        required={true}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

DataBrowserForm.propTypes = {
    onSubmit: PropTypes.func.isRequired    
}

export default DataBrowserForm