import React, { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {  Switch, Link  } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Badge from '@material-ui/core/Badge'
import Popover from '@material-ui/core/Popover'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { Button, Fab, Tooltip, Divider, CircularProgress } from '@material-ui/core'
import { Icon } from 'styles';
import logo from 'assets/image/logo_white.svg';

import AuthRoute from 'components/AuthRoute'
import AdvancedMode from 'components/core/AdvancedMode'
import DataBrowserForm from 'components/core/DataBrowserForm'
import CustomAvatar from 'components/core/CustomAvatar'
import ChangePasswordDialog from 'components/ChangePasswordDialog'
import { DialogProvider } from 'contexts/Dialog'
import { AuthManagerContext, AuthManagerProvider } from 'contexts/AuthManager'
import { WebSocketProvider } from 'contexts/WebSocket'
import { I18nManagerContext, I18nManagerProvider } from 'contexts/I18nManager'
import { AdvancedModeProvider } from 'contexts/AdvancedMode'

import { Customers, Devices, Patients, Users, EducationalMaterials, Customer, Patient, Reports } from 'screens'
import { getRoutesWith} from 'screens/index'
import { Base } from 'components/themes/main'

import { fetchResources, fetchDataSource, createApplicationTableView, createApplicationLocale, deleteApplicationLocale } from 'actions'
import { createRequestId } from 'utils'
import { createRecord } from 'actions/index';

const drawerWidth = 260

const useStyles = makeStyles(theme => ({
    logo: {
        width: 150
    },
    root: {
        display: 'flex',
        height: '100vh'
    },
    appBar: {
        ...Base.appBar
    },
    // appBarShift: {
    //     marginLeft: drawerWidth,
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // },
    apptoolbar: {
        height: 70, 
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -6,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 84,
        // width: theme.spacing(7) + 1,
        // [theme.breakpoints.up('sm')]: {
        //     width: theme.spacing(7) + 1,
        // },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        overflowY: 'hidden',
        overflowX: 'auto'
        // padding: theme.spacing(5) ,
        // paddingTop: theme.spacing(4),
        // paddingBottom: theme.spacing(5),
    },
    widgetContentText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        marginLeft: theme.spacing(2),
        // alignSelf: 'flex-start'
    },
    icon: {
        marginLeft: 14
    },
    listItem: {
        height: 53
    },
    badge: {
        ...Base.badge,
        transform: 'scale(.8) translate(50%, -50%)'
    },
    largeBadge: {
        ...Base.badge,
        transform: 'translate(0%, -50%)'
    },
    avatar: {
        width: 40,
        height: 40,
    },
    bigAvatar: {
        width: 55,
        height: 55,
        margin: '0 auto',
        marginBottom: 10
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        width: 350,
        // height: 280,
        textAlign: 'center',
        height: 'fit-content'
    },
    cardContent: {
        flexGrow: 1,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(),
        justifyContent: 'center'
    },
    cardContentList: {
        flexGrow: 1,
        paddingTop: 16,
        paddingBottom: '16px !important',
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: 'center'
    },
    buttonSwitch: {
        width: theme.spacing(8),
    },
    cardActions: {
        justifyContent: 'center',
        paddingBottom: theme.spacing(4),
    },
    cardListItem: {
        width: '100%',
        maxWidth: '36ch'
    },
    inline: {
    display: 'inline',
    },
    activity: {
        color: '#4CB091',
        position: 'relative',
        top: 65,
        left: '48%',
        marginTop: -24, //-theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4,
        zIndex: 9999
    },
    divider: {
        marginLeft: 32
    },
    listItemText: {
        maxWidth: 280,
        paddingRight: 20
    },
    secondaryAction: {
        // right: 0
    },
    rootPopover: {
        width: '100%',
        overflow: 'auto',
        position: 'relative',
    },
}))

const changePasswordResource = { 
    path: '/change-password', 
    method: 'post' 
}

const UserPopover = (props) => {
    const authManager = useContext(AuthManagerContext)
    const classes = useStyles()
    // const [mode, setMode] = useContext(AdvancedModeContext)
	const [openDialog, setOpenDialog] = useState(false)
	const dispatch = useDispatch()

    // function handleModeChange(event) {
    //     const { checked } = event.target
    //     setMode(checked ? "true" : "false")
    // }

	function handlePasswordChange (event) {
        setOpenDialog(true)
    }

	const handleCancel = () => {
        setOpenDialog(false)
    }

	const handleSubmit = async (newState) => {
		const { currentPassword, newPassword } = newState
        const { error } = await dispatch(createRecord(changePasswordResource, {  email: authManager.email, currentPassword, newPassword }))

		if (!error) {
			setOpenDialog(false)
		} else {
			
		}
    }

    return (
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        >
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <CustomAvatar image={authManager.avatar} name={authManager.lastName} className={classes.bigAvatar} />
                    <Typography variant="subtitle1">
                        {authManager.lastName}, {authManager.firstName}
                    </Typography>
                    <Typography color="textSecondary">
                        {authManager.email}
                    </Typography>
                    {/* <FormControlLabel
                        control={
                            <ButtonSwitch className={classes.buttonSwitch} color="primary" onChange={handleModeChange} checked={mode === "true"}/>
                         }
                        label="Advanced"
                    /> */}
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Button /* onClick={this.handleLogout} */ color="secondary" variant="contained">Logout</Button>
					<Button onClick={handlePasswordChange} color="primary" variant="contained">Change Password</Button>
                </CardActions>
            </Card> 
			{(openDialog) && (
				<ChangePasswordDialog
					open={openDialog}
					record={null}
					onCancel={handleCancel}
					onSubmit={handleSubmit}
				/>
			)}
        </Popover>
    )    
}

const mapStateData = ({ resource }) => state => {
    let isLoading = false
    let data = []

    if (resource) {
        const id = createRequestId(resource)

        isLoading = (state.datasources[id]) ? state.datasources[id].isLoading : false
        data = (state.datasources[id]) ? state.datasources[id].data : []
    }
    return { isLoading, data } 
}

const NotificationPopover = (props) => {
    const authManager = useContext(AuthManagerContext)
    const classes = useStyles()

    const views = useSelector(state => state.application.data.views)
    const alertView = views.find(view => view.name === 'UserAlerts')
    const resource = { ...alertView.resources['list'] }
    const params = useParams() 
    const dispatch = useDispatch()
    const { isLoading, data } = useSelector(mapStateData({
        resource: Object.assign(resource, {
            params: Object.assign({ customerId: authManager.customerId, userId: authManager.id }, params)
        })
    }))
    // const deps = useMemo(() => JSON.stringify([resource, entityId]), [resource, entityId])

    useEffect(() => {
        if (resource) {
            dispatch(fetchDataSource(resource))
        }
    }, [/*deps*/])

    const ActivityMask = () => {
        const classes = useStyles()
    
        return (
            <CircularProgress size={24} className={classes.activity} />
        )
    }

    const handleNotificationClick = event => {
        debugger
    }

    return (
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        >
            <Card className={classes.card}>
                <CardContent className={classes.cardContentList}>
                    {isLoading && (<ActivityMask />)}
                    <List className={classes.rootPopover}>
                        {data && data.map((item, index) => (
                            item.total > 0 && (
                            <React.Fragment key={index}>
                            {(index != 0) && (
                                <Divider variant="inset" component="li" className={classes.divider} />
                            )}
                            <ListItem button key={`item-${index}`} className={classes.listItem}>
                                <ListItemText
                                    className={classes.widgetContentText}
                                    primary={
                                        <Typography variant="subtitle1" color="secondary" noWrap>{item.category}</Typography>
                                    }
                                    onClick={handleNotificationClick}
                                />
                                <Badge badgeContent={item.total} classes={{
                                    badge: classes.largeBadge
                                }}></Badge>
                            </ListItem>
                            </React.Fragment>
                        )))}
                    </List>
                </CardContent>
                {/* <CardActions className={classes.cardActions}></CardActions> */}
            </Card> 
        </Popover>
    )    
}

const AppMenu = ({ open, routes, onChange }) => {
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const theme = useTheme()
    const [selectedIndex, setSelectedIndex] = useState(2)

    function handleItem(index) {
        setSelectedIndex(index)
    }

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            open={open}
        >
            <div className={classes.toolbar}>
            </div>
            <List>
                {routes && routes.map((item, index) => (
                    <Tooltip key={index} title={!open ? i18nManager.t(item.text) : '' } placement='right' >
                        <ListItem 
                            className={classes.listItem} 
                            key={index} 
                            component={Link}
                            to={item.to}
                            button 
                            onClick={() => handleItem(index)}
                            selected={(selectedIndex === index) ? true : false} 
                        >
                            <ListItemIcon className={classes.icon}>
                                <Badge badgeContent={ item.name == 'Alerts' ? 2 : 0 } classes={{
                                    badge: classes.badge
                                }}>
                                <Icon icon={item.icon} size={24} color={(selectedIndex === index) ? theme.palette.primary.light : theme.palette.primary.dark}/>
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary={i18nManager.t(item.text)} />
                            {/* <Badge badgeContent={2} classes={{
                                badge: classes.badge
                            }}/> */}
                        </ListItem>
                    </Tooltip>
                ))}
            </List>
        </Drawer>
    )
}

const UserAvatar = () => {
    const authManager = useContext(AuthManagerContext)
    const classes = useStyles()

    return (
         <CustomAvatar logo={authManager.avatar} name={authManager.lastName} className={classes.avatar} />
    )
}

let customerStackRoutes = [{
    name: 'Customers',
    component: Customers,
    to: '/customers'
}, {
    name: 'Customer',
    component: Customer,
    to: '/customers/:customerId/:prop',
    title: record => record.name
}, {
    name: 'Patient',
    component: Patient,
    to: '/customers/:customerId/patients/:patientId/:prop',
    from: '/customers/:customerId/patients/:patientId',
    title: record => record.fullName
}]

let patientStackRoutes = [{
    name: 'Patients',
    component: Patients,
    to: '/patients'
}, {
    name: 'Patient',
    component: Patient,
    to: '/patients/:patientId/:prop',
    from: '/patients/:patientId',
    title: record => record.fullName
}]

let routesOverride = [{
    name: 'CustomerStack',
    routes: customerStackRoutes
}, {
    name: 'Devices',
    component: Devices,
    to: '/devices'
}, {
    name: 'PatientStack',
    routes: patientStackRoutes
}, {
    name: 'Users',
    component: Users,
    to: '/users'
}, {
    name: 'EducationalMaterials',
    component: EducationalMaterials,
    to: '/educational-materials'
}, {
    name: 'Reports',
    component: Reports,
    to: '/reports'
}]

const mapState = state => {
    const auth = state.auth && state.auth.data
    const routes = getRoutesWith(routesOverride, state.application.data)

    return {
        auth,
        routes,
        application: state.application.data,
        locales: state.locales.data,
        inProgress: state.locales.inProgress,
        resources: state.resources.data
    }
}

const Main = ({ history }) => {
    const dispatch = useDispatch()
    const { inProgress, auth, application, locales, routes, resources } = useSelector(mapState)
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [openNotificationMenu, setOpenNotificationMenu] = useState(null)
    const openProfile = Boolean(anchorEl)
    const openNotification = Boolean(openNotificationMenu)

    useEffect(() => {
        if (routes && routes.length > 0) {
            history.push(routes[2].to)
        }
    }, [])

    function handleDrawer() {
        setOpen(!open)
    }  

    function handleNewPage() {
        setOpenForm(true) 
    }

    function handleDataBrowserFormOpen({ resources }) {
        fetchResources(resources.params)
    }

    function handleDataBrowserFormClose() {
        setOpenForm(false)    
    }

    function handleDataBrowserFormSubmit(formData) {
        dispatch(createApplicationTableView(process.env.REACT_APP_NAME, formData))
    }

    function handleI18nLocaleCreate(values, key) {
        dispatch(createApplicationLocale(process.env.REACT_APP_NAME, { key, values }))
    }

    function handleI18nLocaleDelete(key) {
        dispatch(deleteApplicationLocale(process.env.REACT_APP_NAME, key))
    }

    function handleNotificationMenuOpen(event) {
        setOpenNotificationMenu(event.currentTarget)
    }

    function handleNotificationMenuClose() {
        setOpenNotificationMenu(null)
    }

    function handleProfileMenuOpen(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleProfileMenuClose() {
        setAnchorEl(null)
    }

    return (
        <AuthManagerProvider value={auth}>
            <WebSocketProvider>
                <AdvancedModeProvider>
                    <I18nManagerProvider value={{
                        inProgress,
                        locales,
                        onCreate: handleI18nLocaleCreate,
                        onDelete: handleI18nLocaleDelete
                    }}>
						<DialogProvider>
							<div className={classes.root}>
								<CssBaseline />
								<AppBar
									position="fixed"
									className={classes.appBar}
								>
									<Toolbar className={classes.apptoolbar}>
										<IconButton
											color="inherit"
											aria-label="open drawer"
											onClick={handleDrawer}
											edge="start"
											className={classes.menuButton}
										>
											<Icon icon="menu" size={24}/>
										</IconButton>
										<img className={classes.logo} src={logo} />
										{/* <Typography variant="caption" noWrap className={classes.title}>
											{application && application.title}
										</Typography> */}
										<div className={classes.grow} />
										<AdvancedMode>
											<Fab variant="extended" size="medium" color="secondary" onClick={handleNewPage}>
												Create View
											</Fab>
										</AdvancedMode>
										<div className={classes.sectionDesktop}>
											<IconButton aria-label="messages" color="inherit">
												<Badge /*badgeContent={2}*/ classes={{
													badge: classes.badge
												}}>
													<Icon icon="message-square" size={20}/>
												</Badge>
											</IconButton>
											<IconButton aria-label="notifications" color="inherit" >
												<Badge /*badgeContent={8}*/ classes={{
													badge: classes.badge
												}}>
													<Icon icon="bell" size={20}/>
												</Badge>
											</IconButton>
											{/* {openNotification && (
												<NotificationPopover
													id="notification-popper"
													open={openNotification}
													anchorEl={openNotificationMenu}
													onClose={handleNotificationMenuClose}
												/>
											)} */}
											<IconButton
												edge="end"
												aria-label="account of current user"
												aria-owns={openProfile ? "user-popper" : null}
											// aria-controls={menuId}
												aria-haspopup="true"
												onClick={handleProfileMenuOpen} 
												color="inherit"
												size="small"
											>
												<UserAvatar />
											</IconButton>
											{openProfile && (
												<UserPopover
													id="user-popper"
													open={openProfile}
													anchorEl={anchorEl}
													onClose={handleProfileMenuClose}
												/>
											)}
										</div>
									</Toolbar>
								</AppBar>
								<AppMenu
									routes={routes}
									open={open}
								/>
								<main className={classes.content}>
									<div className={classes.toolbar} />
									<Switch>
										{routes && routes.map(({ to, component, ...others }, index) => {
											return (component) ? 
												<AuthRoute
													key={index}
													path={to}
													component={component}
													{...others}
												/> : null
										})}
									</Switch>
								</main>
								{openForm && (
									<DataBrowserForm
										resources={resources}
										open={openForm}
										onOpen={handleDataBrowserFormOpen}
										onClose={handleDataBrowserFormClose}
										onSubmit={handleDataBrowserFormSubmit}
									/>
								)}
							</div>
						</DialogProvider>
                    </I18nManagerProvider>
                </AdvancedModeProvider>
            </WebSocketProvider>
        </AuthManagerProvider>
    )
}

Main.propTypes = {
}

export default Main