import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';

const inputComponent = ({ decimalScale }) => ({ name, inputRef, onChange, ...others }) => {
    return (
        <NumberFormat
            {...others}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name,
                        value: values.value,
                    }
                })
            }}
            decimalScale={decimalScale}
            thousandSeparator
            isNumericString
            allowNegative={false}
            isAllowed={({ floatValue }) => (floatValue != undefined ? floatValue > 0 : true)}
        />
    )
}

const NumberField = ({ dataType, ...others }) => {
    return (
        <TextField
            InputProps={{
                inputComponent: useMemo(() => inputComponent({
                    decimalScale: (dataType == 'integer') ? 0 : 2
                }), [dataType])
            }}
            {...others}
        />
    )
}

export default NumberField