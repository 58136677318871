import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ButtonGroup, Button, Slider, FormControlLabel } from "@material-ui/core";
import clsx from 'clsx';

import { ft2cm, cm2ft } from 'utils';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    label: {
        color: theme.palette.text.secondary,
        padding: 0,
        fontSize: '0.75rem',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
        lineHeight: 1,
    },
    slider: {
        flex: 1
    },
    group: {
        marginLeft: theme.spacing(2)
    },
    button: {
        border: 'none !important',
        borderTopRightRadius: `${theme.spacing(1/2)}px !important`,
        borderBottomRightRadius: `${theme.spacing(1/2)}px !important`,
        borderTopLeftRadius: `${theme.spacing(1/2)}px !important`,
        borderBottomLeftRadius: `${theme.spacing(1/2)}px !important`,
        // borderRadius: theme.spacing(1/2),
        textTransform: 'none !important',
        marginLeft: theme.spacing(1/4),
        marginRight: theme.spacing(1/4)
    },
    selected: {
        color: 'white',
        backgroundColor: `${theme.palette.text.secondary} !important`
    },
    unselected: {
        color: theme.palette.text.secondary,
        backgroundColor: 'transparent !important'
    },
    margin: {
        height: theme.spacing(3)
    }
}))

const defs = {
    ft: {
        min: 3, max: 9,
        step: 0.05,
        convert: {
            cm: ft => ft2cm(ft),
            m:  ft => ft2cm(ft) / 100
        },
        precision: value => +value.toFixed(2),
        formatted: value => {
            const feets = Math.trunc(value)
            const inches = Math.floor(((value % 1) / 0.08333))
            let formatted = `${feets}'`
    
            if (inches > 0) formatted += `${inches}"`
            return formatted
        }
    },
    cm: {
        min: 30, max: 275,  
        step: 1,//0.01,
        convert: {
            ft: cm => cm2ft(cm),
            m:  cm => cm / 100
        },
        precision: value => Math.round(value),
        formatted: value => Math.round(value)
    },
    m: {
        min: 0.30, max: 2.75,
        step: 0.01,  
        convert: {
            ft: m => cm2ft(m * 100),
            cm: m => m * 100
        },
        precision: value => +value.toFixed(2),
        formatted: value => +value.toFixed(2)
    }
}

const valueText = value => {
    return `${value}`;
}

const HeightField = ({
    id,
    label,
    defaultValue,
    unit,
    units, //Array text, value objects
    values,
    onValueChange,
    ...others 
}) => {
    const classes = useStyles()
    const initialIndex = useMemo(() => {
        const index = units.findIndex(u => u.value === unit)
        return (index !== -1) ? index : 0
    }, [unit])
    const [selectedIndex, setSelectedIndex] = useState(initialIndex)
    const [valueState, setValueState] = useState(defaultValue)

    const doValueChange = (value, index) => {
        if (value && onValueChange) {
            const unit = units[index].value
            onValueChange((values && typeof values === 'function') ? values(value, unit) : value, unit)
        }
    }

    const handleChange = (event, newValue) => {
        setValueState(
            defs[units[selectedIndex].text]
                .precision(newValue)
        )
    }

    const handleChangeComitted = (event, newValue) => doValueChange(newValue, selectedIndex)

    const handleButtonGroupClick = index => () => {
        let newValue = defs[units[selectedIndex].text]
            .convert[units[index].text](valueState)

        newValue = defs[units[index].text]
            .precision(newValue)
        setValueState(newValue)
        setSelectedIndex(index)
        doValueChange(newValue, index)
    }

    return (
        <div className={classes.root}>
            <Typography className={classes.label} id={id} gutterBottom>{label}</Typography>
            <div className={classes.container}>
                <Slider
                    className={classes.slider}
                    name={id}
                    // defaultValue={defaultValue}
                    value={valueState}
                    getAriaValueText={valueText}
                    aria-labelledby={id}
                    valueLabelDisplay="on"
                    valueLabelFormat={defs[units[selectedIndex].text].formatted}
                    min={defs[units[selectedIndex].text].min}
                    max={defs[units[selectedIndex].text].max}
                    step={defs[units[selectedIndex].text].step}
                    onChange={handleChange}
                    onChangeCommitted={handleChangeComitted}
                />
                <ButtonGroup 
                    className={classes.group}
                    disableElevation 
                    size="small"
                    variant="contained"
                >
                    {units && units.map((unit, index) => (
                        <Button 
                            key={index}
                            className={clsx([classes.button, (selectedIndex === index) ? classes.selected : classes.unselected])}
                            onClick={handleButtonGroupClick(index)}
                        >
                            {unit.text}
                        </Button>
                    ))}
                </ButtonGroup>
            </div>
        </div>
    )
}

export default HeightField

// import React from 'react';
// import PropTypes from 'prop-types';
// import { TextField } from '@material-ui/core';
// import NumberFormat from 'react-number-format';

// function inputComponent(props) {
//     const { id, inputRef, onChange, ...other } = props;
  
//     return (
//       <NumberFormat
//         {...other}
//         getInputRef={inputRef}
//         onValueChange={values => {
//             console.log(props)
//           onChange({
//             target: {
//                 id,
//                 value: values.value,
//             },
//           });
//         }}
//         format="#'#''" 
//         placeholder="#'#''"
//         mask={['#', '#']}
//         isNumericString
//       />
//     );
// }
  
// inputComponent.propTypes = {
//     inputRef: PropTypes.func.isRequired,
//     onChange: PropTypes.func.isRequired,
//   };

// export default function HeightField(props) {
//     return (
//         <TextField
//             InputProps={{
//                 inputComponent,

//             }}
//             {...props}
//         />
//     )
// }