import React, { useRef, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import { AuthManagerContext } from 'contexts/AuthManager'
import SelectField from 'components/core/SelectField';

const useStyles = makeStyles(theme => ({
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function PatientActivateForm({ title = 'Primary Care Provider', record, params= {}, isSubmitting, onSubmit, onError }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = { ownerId: ['required'] }
    const [state, errors, handleChange, handleBlur, handleSubmit] = useForm(callback, validations, record)

    const authManager = useContext(AuthManagerContext)
    
    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    // console.log(state)

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            {/* <Typography className={classes.formTitle} variant="h5" color="textSecondary" noWrap>
                {title}
            </Typography> */}
            <SelectField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="ownerId"
                label="Account Owner"
                defaultValue={state ? (state.ownerId || "") : ""}
                required={true}
                error={!!errors['ownerId']}
                helperText={!!errors['ownerId'] && errors['ownerId']}
                resource={{
                    path: '/active-owners',
                    method: 'get'
                }}
                params={authManager.customerId ? { customerId: params.customerId || authManager.customerId } : {} }
                onChange={handleChange}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
            />
        </form>
    )
}