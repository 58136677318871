import React, { useRef, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper, Button } from '@material-ui/core';

import useForm from 'hooks/useForm';

import { AuthManagerContext } from 'contexts/AuthManager'
import SelectField from 'components/core/SelectField';
import DataTable from 'components/core/DataTable'
import clsx from 'clsx';

import { getOrderItems } from 'actions';
import { createRecord } from 'actions/index';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    flex: {
        flex: 1
    },
    fixedWidth: {
        width: 100
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
        display: 'block',
        paddingLeft: theme.spacing(1 / 4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    },
    tableContainer: {
        display: 'flex',
        // padding: 20,
        flexGrow: 1,
        alignItems: 'stretch',
        paddingTop: 32,
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    tableRoot: {
        flexGrow: 1,
        overflowY: 'scroll',
        boxShadow: 'none',
        //border: '1px solid #E2E2E3',
        borderRadius: 2,
    },
    tableContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        flexGrow: 1,
        paddingTop: 0
    },
    tableListContainer: {
        display: 'flex',
        // padding: 20,
        flexGrow: 1,
        alignItems: 'stretch',
        paddingTop: 32,
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    tableListRoot: {
        flexGrow: 1,
        overflowY: 'scroll',
        boxShadow: 'none',
        border: '1px solid #E2E2E3',
        borderRadius: 2,
    },
    tableListContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        flexGrow: 1,
        // height: 1
    },
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

const mapState = state => {
    return {
        orderItems: state.orderItems.data,
        inProgress: state.orderItems.inProgress
    }
}

const assignKitResource = { 
    path: '/customers/:customerId/patients/:patientId/devices/assign-kit', 
    method: 'post'
}

const assignDeviceResource = { 
    path: '/customers/:customerId/patients/:patientId/devices/scan', 
    method: 'post'
}

export default function OrderItemsForm({ title = 'Order Items', record, isSubmitting, onSubmit, onError, onDirty }) {
    const authManager = useContext(AuthManagerContext)
    const classes = useStyles()
    const params = useParams()
    const ref = useRef()
    const dispatch = useDispatch()
    const { orderItems, inProgress } = useSelector(mapState)
    const [orderItemsState, setOrderItemsState] = useState([])
    const validations = {}
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validations, record)
    const entityId = record && record.patientId

    const onAssignKit = () => {
        async function fetchData() {
            const { customerId, patientId, deviceKitId } = state
    
            const { payload, error } = await dispatch(createRecord(Object.assign(assignKitResource, { params: {customerId, patientId}}), { deviceKitId }))
         
            if (!error && payload) {
                dispatch(getOrderItems(Object.assign({ customerId: customerId, patientId: patientId }, params))) 
            }
        }
    
        record && (typeof record === 'object') && fetchData();
    }

    const onAssignDevice = () => {
        async function fetchData() {
            const { customerId, patientId, serialNumber } = state
    
            const { payload, error } = await dispatch(createRecord(Object.assign(assignDeviceResource, { params: {customerId, patientId}}), { serialNumber }))
         
            if (!error && payload) {
                dispatch(getOrderItems(Object.assign({ customerId: customerId, patientId: patientId }, params))) 
            }
        }
    
        record && (typeof record === 'object') && fetchData();
    }

    const onAssignSupply = () => {
        const { supplyId, supplyName, supplyQty } = state

        setOrderItemsState(prevState => {
            const newState = [...prevState]
            newState.push({ deviceSerialNumberId: null, deviceId: null, deviceName: supplyName, deviceType: "Supply", serialNumber: null, supplyId: supplyId, qty: supplyQty})
            return newState
        })
    }

    function callback() {
        Object.assign(state, {orderDetails: orderItemsState}) 
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])

    useEffect(() => {
        dispatch(getOrderItems(Object.assign({ customerId: authManager.customerId, patientId: entityId }, params))) 
    }, [entityId])

    useEffect(() => {
        setOrderItemsState(orderItems)
    }, [orderItems])

    return (
        <Paper className={classes.tableRoot}>
            <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
                <div className={classes.hbox}>
                    <SelectField
                        className={clsx(classes.flex, classes.marginRight)}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="deviceKitId"
                        label="Device Kit"
                        defaultValue={state ? (state.deviceKitId || "") : ""}
                        autoFocus
                        error={!!errors['deviceKitId']}
                        helperText={!!errors['deviceKitId'] && errors['deviceKitId']}
                        resource={{
                            path: '/device-kits',
                            method: 'get'
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="id"
                    />
                    <Button
                        variant="contained"
                        color='primary'
                        className={classes.smallButton}
                        disabled={state && !state.deviceKitId || !state}
                        onClick={onAssignKit}
                    >
                        Add
                        </Button>
                </div>
                <div className={classes.hbox}>
                    <SelectField
                        className={clsx(classes.flex, classes.marginRight)}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="deviceId"
                        label="Device Type"     // TODO: 
                        defaultValue={state ? (state.deviceId || "") : ""}
                        fullWidth
                        error={!!errors['deviceId']}
                        helperText={!!errors['deviceId'] && errors['deviceId']}
                        resource={{ path: '/customers/:customerId/devices', params: { customerId: authManager.customerId } }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="name"
                        valueName="id"
                        extraValues={[{
                            "valueName": "name",
                            "submitName": "deviceName"
                        }]}
                    />
                    <TextField
                        InputLabelProps={InputLabelProps}
                        className={clsx(classes.flex, classes.marginLeft, classes.marginRight)}
                        autoComplete="off"
                        margin="normal"
                        id="serialNumber"
                        label="Serial Number"
                        defaultValue={state && state.serialNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors['serialNumber']}
                        helperText={!!errors['serialNumber'] && errors['serialNumber']}
                    />
                    <SelectField
                        className={clsx(classes.fixedWidth, classes.marginLeft, classes.marginRight)}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="deviceQty"
                        label="Qty."
                        defaultValue={state ? (state.deviceQty || "") : ""}
                        error={!!errors['deviceQty']}
                        helperText={!!errors['deviceQty'] && errors['deviceQty']}
                        resource={{
                            path: '/device-quantity',
                            method: 'get'
                        }}
                        params={state && state.deviceId ? { deviceId: state.deviceId } : {}} // TODO: DeviceId
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="id"
                        disabled={state && !state.deviceId || !state}
                    />
                    <Button
                        variant="contained"
                        color='primary'
                        className={classes.smallButton}
                        disabled={state && !(state.deviceId && state.serialNumber && state.deviceQty) || !state}
                        onClick={onAssignDevice}
                    >
                        Add
                        </Button>
                </div>
                <div className={classes.hbox}>
                    <SelectField
                        className={clsx(classes.flex, classes.marginRight)}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="supplyId"
                        label="Supply"
                        defaultValue={state ? (state.supplyId || "") : ""}
                        error={!!errors['supplyId']}
                        helperText={!!errors['supplyId'] && errors['supplyId']}
                        resource={{
                            path: '/supply',
                            method: 'get'
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="value"
                        extraValues={[{
                            "valueName": "text",
                            "submitName": "supplyName"
                        }]}
                    />
                    <SelectField
                        className={clsx(classes.fixedWidth, classes.marginLeft, classes.marginRight)}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="supplyQty"
                        label="Qty."
                        defaultValue={state ? (state.supplyQty || "") : ""}
                        error={!!errors['supplyQty']}
                        helperText={!!errors['supplyQty'] && errors['supplyQty']}
                        resource={{
                            path: '/supply-quantity',
                            method: 'get'
                        }}
                        params={state && state.supplyId ? { deviceId: state.supplyId } : {}}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="id"
                        disabled={state && !state.supplyId || !state}
                    />
                    <Button
                        variant="contained"
                        color='primary'
                        className={classes.smallButton}
                        disabled={state && !(state.supplyId && state.supplyQty) || !state}
                        onClick={onAssignSupply}
                    >
                        Add
                        </Button>
                </div>
            </form>
            <div className={classes.tableListContainer}>
                <div className={classes.tableListContent}>
                    <Paper className={classes.tableListRoot}>
                        <DataTable
                            isOpen={false}
                            inProgress={inProgress}
                            rowCount={orderItemsState.length}
                            pageSize={25}
                            rowGetter={({ index }) => {}}
                            columns={[{
                                label: 'Type',
                                dataKey: 'deviceType'
                            }, {
                                label: 'Serial Number',
                                dataKey: 'serialNumber'
                            }, {
                                label: 'Name',
                                dataKey: 'deviceName'
                            }, {
                                label: 'Qty.',
                                dataKey: 'qty'
                            }, {
                                label: 'Actions',
                                dataKey: 'trash',
                                type: 'ActionColumn',
								handleClick: (event, row) => {
									setOrderItemsState(prevState => {
										const newState = [...prevState]
										newState.pop(row)
										return newState
									})

								}
                            }]}
                            rows={orderItemsState}
                            allowSelection={false}
                        />
                    </Paper>
                </div>
            </div>
        </Paper>
    )
}