import React, { Component } from 'react';

import PropTypes    from 'prop-types';
import classNames   from 'classnames';
import {  withStyles  } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};
  
const styles = theme => ({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
});

const SnackbarBody = (props) => {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];
  
    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

SnackbarBody.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
};
  
const SnackbarBodyWrapper = withStyles(styles)(SnackbarBody);

class ToastMessage extends Component {

    state = {
        open: false
    }

    componentDidMount() {
        this.setState({ open: true })
    }
    
    handleClose = () => {
        const { onClose } = this.props;

        this.setState({ open: false })
        onClose()
    }

    render() {
        const { classes, variant, message, autoHideDuration, ...other } = this.props;
    
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.state.open}
                autoHideDuration={autoHideDuration}
                onClose={this.handleClose}
            >
                <SnackbarBodyWrapper
                    onClose={this.handleClose}
                    variant={variant}
                    message={message}
                />
            </Snackbar>
        );
    }
}

ToastMessage.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    autoHideDuration: PropTypes.number,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    onClose: PropTypes.func
};

export default withStyles(styles)(ToastMessage);