import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from 'components/core/Filter';
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Select, Chip } from "@material-ui/core";

import { createRequestId } from 'utils';
import { fetchDataSource } from 'actions/index';

const useStyles = makeStyles(theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2
    }
}))

const SelectFilter = ({ showOperator, operator, resource, params = {}, onChange, multiple, ...others }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const dataSourceId = useMemo(() => (resource && !resource.data) ? createRequestId(resource) : null, [resource])
    const optionsState = useSelector(state => state.datasources[dataSourceId] ? state.datasources[dataSourceId].data : (resource && resource.data) ? resource.data : [])

    useEffect(() => {
        let dispatchPromise = null

        if (resource && !resource.data) {
            dispatchPromise = dispatch(fetchDataSource(resource, params))
        }
        return () => {
            if (dispatchPromise && dispatchPromise.cancel) {
                dispatchPromise.cancel()
            }
        }
    }, [resource])

    const handleChange = ({ value, ...others }) => {
        const text = Array.isArray(value)
            ? optionsState.filter(option => value.find(value => value === option.value)).map(option => option.text).join(', ')
            : optionsState.find(option => option.value === value).text

        onChange && onChange({
            text,
            value,
            ...others
        })
    }

    return (
        <Filter
            component={Select}
            operator={(showOperator && operator) ? operator : (multiple ? "in" : "eq")}
            onChange={handleChange}

            options={optionsState}
            multiple={multiple}
            {...others}
            renderValue={value => {
                const isMultipleSelection = Array.isArray(value)
                const text = isMultipleSelection
                    ? optionsState.length > 0 && optionsState.filter(option => value.find(value => value === option.value)) //.map(option => option.text).join(', ')
                    : optionsState.length > 0 && optionsState.find(option => option.value === value).text

                return isMultipleSelection ? (
                    <div className={classes.chips}>
                        {text && text.map(item => (
                            <Chip key={item['value']} label={item['text']} className={classes.chip} size='small' /> //TODO: onDelete={handleDelete(measures)}
                        ))}
                    </div>
                ) : text
            }}
        >
            {optionsState ? optionsState.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.text}
                </MenuItem>
            )) : (
                    <MenuItem value={''}>
                        No options
                </MenuItem>
                )}
        </Filter>
    )
}

export default SelectFilter