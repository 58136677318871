import React, { useState, useEffect, useRef, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getReportFormParams } from 'actions';
import { I18nManagerContext } from 'contexts/I18nManager';
import DynamicDialog from 'components/DynamicDialog';
import { AuthManagerContext } from 'contexts/AuthManager'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 350
    },
    preview: {
        marginTop: theme.spacing(2),
        height: 361,
        width: '100%',
        paddingLeft: 15,
        marginTop: 29,
        minWidth: 556
    },
    card: {
        marginTop: theme.spacing(1),
        minWidth: 275,
        minHeight: 100,
        height: 'inherit',
        overflow: 'scroll'
    },
    contentText: {
        paddingTop: theme.spacing(1 / 2),
        paddingBottom: theme.spacing(1 / 2)
    }
}))

function ReportDynamicDialog({ record, title, subtitle, onSubmit, ...props }) {
    const authManager = useContext(AuthManagerContext)
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const dispatch = useDispatch()
    const [state, setState] = useState((typeof record === 'object') ? record : {})
    const params = useParams()
    const [isLoading, setLoading] = useState(true)

    const customerId = params.customerId || authManager.customerId

    useEffect(() => {
        async function fetchData() {
            const { data: { result }, error } = await dispatch(getReportFormParams({ reportId: record.id, customerId: customerId }))

            if (!error && result) {
                setState(result)

                if (result.fields.every(item => item.hidden)) {
                    setLoading(true)
                    const data = result.fields.reduce((acc, value) => ({ ...acc, [value.name]: value.defaultValue }), {})

                    handleSubmit(data, null)
                }
            }

            setLoading(false)
        }

        record && (typeof record === 'object') && fetchData();
    }, [record]);

    const handleSubmit = async (newState, ref) => {
        if (onSubmit) onSubmit(newState, ref)
    }

    return (
        !isLoading && (
            <DynamicDialog
                id={record.id}
                title={record.name}
                subtitle={record.summary}
                helpInfo={record.description}
                className={classes.dialogPaper}
                paramsSelector={record => record}
                onSubmit={handleSubmit}
                record={record}
                {...props}
            >
            </DynamicDialog>
        ) ||
        <div></div>
    )
}

export default ReportDynamicDialog