import * as Types from 'constants/ActionTypes'
import { actionRequest } from './index'

export const fetchResources = params => (...args) => {
    actionRequest(...args)({
        type: Types.FETCH_RESOURCES,
        url: '/discovery',
        method: 'get',
        params   
    })
}