import React, { useRef, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import Filter from 'components/core/Filter';
import DateField from 'components/core/DateField';

const DateFilter = ({ showOperator, operator, dataType, ...others }) => {
    const operators = useRef([
        { label: 'equal', value: 'eq' },
        { label: 'not equal', value: 'ne' },
        { label: 'greater than', value: 'gt' },
        { label: 'less than', value: 'lt' },
        { label: 'greater than or equal to', value: 'gte' },
        { label: 'less than or equal to', value: 'lte' },
        // { label: 'between', value: 'between' }    
    ])

    return (
        <Filter
            component={DateField}
            operator={(showOperator && operator) ? operator : "gt"}
            operators={showOperator && operators.current}
            showOperator={showOperator}
            dataType={dataType}
            {...others}
        />
    )
}

export default DateFilter