import { createStore, applyMiddleware, compose } from 'redux'
import { offline } from 'redux-offline'
import offlineConfig from 'redux-offline/lib/defaults'
import axios from 'axios'
import { persistStore, persistReducer } from 'redux-persist'
import Storage from 'db'
import { thunk, logger } from './middlewares'
// import rootReducers from './reducers'

import createReducer from './reducers'

// import { fetchApplication } from 'actions'

const persistConfig = {
    key: 'root',
    whitelist: ['users'],
    storage: Storage
}

const initialState = {}

const reduxOfflineConfig = {
    ...offlineConfig,
    persist: persistStore,
    effect: effect => {
        return axios(effect)
    },
    discard: (error, action, retries) => {
      const { response } = error

      return (response && response.status >= 400) || retries > 10
    }
}

const persistedReducer = persistReducer(persistConfig, createReducer())

// const store = createStore(
//     persistedReducer,
//     initialState,
//     compose(
//         applyMiddleware(
//             thunk, // lets us dispatch() functions
//             logger // neat middleware that logs actions
//         ),
//         offline(reduxOfflineConfig)
//     )
// )

// store.dispatch(fetchApplication(process.env.REACT_APP_NAME))

// export default store

const initializeStore = () => {
    const store = createStore(
        persistedReducer,
        initialState,
        compose(
            compose(
                (process.env.NODE_ENV !== 'production') ? applyMiddleware(
                    thunk, // lets us dispatch() functions
                    logger // neat middleware that logs actions
                ): applyMiddleware(
                    thunk, // lets us dispatch() functions
                ),
                offline(reduxOfflineConfig)
            ),
            offline(reduxOfflineConfig)
        )
    )
  
    // Create an object for any later reducers
    store.asyncReducers = {}
  
    // Creates a convenient method for adding reducers later
    // See withReducer.js for this in use.
    store.injectReducer = (key, reducer) => {
        // Updates the aysncReducers object. This ensures we don't remove any old
        // reducers when adding new ones.
        store.asyncReducers[key] = reducer
        // This is the key part: replaceReducer updates the reducer
        // See rootReducer.createReducer for more details, but it returns a function.
        store.replaceReducer(createReducer(store.asyncReducers))
        return store
    }
    console.log('Store initialized')
    return store
}

export default initializeStore()