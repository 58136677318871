import React, { useState } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import AuthRoute from 'components/AuthRoute'
import ToastMessage from 'components/core/ToastMessage';

import './App.css'

import {  } from 'containers'
import { Login, Main, ForgotPassword, EmailVerify, ResetPassword } from 'screens'
import { cleanStatus } from 'actions/index'

function App({ success, error }) {
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(cleanStatus())
    }

    return (
        <div>
            <Router>
                <Switch>
                    <Route path="/login" component={Login} />
                {/*  <Route path="/register" component={Register} /> */}
                    <Route path="/verify" component={EmailVerify} /> 
                    <Route path="/forgot" component={ForgotPassword} />
                    <Route path="/reset" component={ResetPassword} />
                    <AuthRoute path="/" component={Main} />
                </Switch>
            </Router>
            {(success && success.message) ? (
                <ToastMessage variant="success" message={success.message} onClose={handleClose} autoHideDuration={6000}/>
            ): null}
            {(error && error.message) ? (
                <ToastMessage variant="error" message={error.message} onClose={handleClose} autoHideDuration={null}/>
            ): null}
        </div>
    )
}

const mapStateToProps = state => ({
    success: state.status.success,
    error: state.status.error
})

export default connect(
	mapStateToProps
)(App)
