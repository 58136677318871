import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  Link  } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, TextField, Button, CircularProgress } from '@material-ui/core';

import BrandPaper from 'components/BrandPaper';
import DarkTheme from 'components/themes/dark';
import green from '@material-ui/core/colors/green';
import logo from 'assets/image/logo_color.svg';

import { login } from 'actions';

const useStyles = makeStyles(theme => ({
    logo: {
        width: 290,
        paddingBottom: '20px !important' 
    },
    form: {
        flexShrink: 0,
        width: theme.spacing(41)
    },
    header: {
        textAlign: 'center',
        marginBottom: theme.spacing(4),
        '& > *': {
            padding: theme.spacing(1/2)
        }
    },
    content: {
        flex: '1 0 auto',
    },
    textField: {
        width: theme.spacing(41)
    },
    actions: {
        
    },
    inline: {
        display: 'inline-block',
        width: '100%'
    },
    link: {
        fontSize: '12px',
        display: 'block',
        textDecoration: 'none !important',
        float: 'right',
        marginTop: theme.spacing(2.5)
    },
    buttons: {
        marginTop: theme.spacing(5),
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: theme.spacing(19.5)
    },
    // login: {
    //     marginLeft: theme.spacing(2)
    // },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

const mapState = state => ({
    inProgress: state.auth.inProgress
})

function Login({ history }) {
    const dispatch = useDispatch()
    const { inProgress } = useSelector(mapState)
    const classes = useStyles()
    const [state, setState] = useState() //TODO: remove

    const handleChange = event => {
        const { id, value } = event.target;
        
        setState(prevState => ({ ...prevState, [id]: value }))
    }

    const handleSubmit = async event => {
        const { email, password } = state

        event.preventDefault()
        try {
            const { error } = await dispatch(login(email, password))

            if (!error) history.push('/')
        }
        catch(error) {
            console.log(error)
        }
    }

    return (
        <BrandPaper>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                <div className={classes.header}>
                    {/* <Typography component="h5" variant="h4">
                        HORUS
                    </Typography> */}
                    <img className={classes.logo} src={logo} />
                    <Typography variant="subtitle1" color="textSecondary">
                        Welcome! Please login to your account.
                    </Typography>
                </div>
                <div className={classes.content}>
                    <TextField
                        InputLabelProps={InputLabelProps}
                        autoFocus
                        id="email"
                        label="Email"
                        className={classes.textField}
                        type="email"
                        onChange={handleChange}
                        margin="normal"
                        required={true}
                        //defaultValue={state.email} //TODO: remove
                    />
                    <br/>
                    <TextField
                        InputLabelProps={InputLabelProps}
                        id="password"
                        label="Password"
                        className={classes.textField}
                        type="password"
                        onChange={handleChange}
                        autoComplete="current-password"
                        margin="normal"
                        required={true}
                        //defaultValue={state.password} //TODO: remove
                    />
                </div>
                <div className={classes.actions}>
                    <DarkTheme>
                        <div className={classes.inline}>
                            <Button className={classes.link} component={Link} to="/forgot">Forgot Password</Button>
                        </div>
                        <div className={classes.buttons}>
                            {/* <Button disabled={inProgress} size="small" variant="outlined" color="primary" className={classes.button} component={Link} to="/register">Sign up</Button> */}
                            <Button disabled={inProgress} type="submit" variant="contained" color="primary" className={classes.button}>
                                Login
                                {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>   
                        </div> 
                    </DarkTheme>
                </div>
            </form>
        </BrandPaper>
    )
}

export default Login