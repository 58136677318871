import * as Types from 'constants/ActionTypes';

const orderItems = (state = {
    inProgress: false,
    didInvalidate: false,
    data: [],
}, action) => {
    switch (action.type) {
        case Types.FETCH_ORDER_ITEMS_DATA:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            })
        case Types.FETCH_ORDER_ITEMS_DATA_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.payload,
                lastUpdated: action.receivedAt
            })
        case Types.FETCH_ORDER_ITEMS_DATA_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            })
        default:
            return state
    }
};

export default orderItems