import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField } from '@material-ui/core';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import SearchField from 'components/core/SearchField';
import SelectField from 'components/core/SelectField';
import ImageField from 'components/core/ImageField';

const useStyles = makeStyles(theme => ({
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45%'
    },
    formTitle: {
        paddingBottom: theme.spacing(10)
    },
    textField: {
        width: theme.spacing(41)
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function CustomerProfileForm({ title = 'Profile Information', record, isSubmitting, onSubmit, onError }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = { npi: ['required'], typeId: ['required'], name: ['required'], websiteUrl: ['url'] }
    const [state, errors, handleChange, handleBlur, handleSubmit] = useForm(callback, validations, record)
    const npiRules = {
        onChange: event => {
            const { selection } = event.target
            const names = ['name', 'address', 'billingAddress1', 'billingAddress2', 'billingCity', 'billingState', 'billingCountry', 'billingZip', 'billingPhone', 'billingFax']

            handleChange({ target: { id: 'npiId', value: selection && selection.id }})
            names.forEach(name => {
                handleChange({ target: { id: name, value: selection && selection[name] }})
            })
        }
    }

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    const handleSearchChange = name => event => {
        const { selection } = event.target
        const value = selection && selection[name]

        event.target.value = value
        handleChange(event)

        npiRules['onChange'](event)
    }

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <Typography className={classes.formTitle} variant="h5" color="textSecondary" noWrap>
                {title}
            </Typography>
            <SearchField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                autoFocus
                margin="normal"
                id="npi"
                label="Search Customer"
                defaultValue={state ? state.npiId : ""}
                // defaultValue={npiId}
                fullWidth
                required={true}
                error={!!errors['npi']}
                helperText={!!errors['npi'] && errors['npi']}
                resource={{
                    path: '/npi-institutions',
                    method: 'get'
                }}
                textName="name"
                valueName="id"
                onChange={handleSearchChange('npi')}
                onBlur={handleBlur}
            />
            <TextField
                InputLabelProps={InputLabelProps}
                InputProps={{ readOnly: true }}
                autoComplete="off"
                margin="normal" 
                id="address"
                label="Billing Demographic"
                value={(state && state.address) ? state.address : ""}
                fullWidth
                required={true}
                error={!!errors['address']}
                helperText={!!errors['address'] && errors['address']}
            />
            <SelectField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="typeId"
                label="Customer Type"
                defaultValue={state ? (state.typeId || "") : ""}
                fullWidth
                required={true}
                error={!!errors['typeId']}
                helperText={!!errors['typeId'] && errors['typeId']}
                resource={{
                    path: '/customer-types',
                    method: 'get'
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                //TODO:
                textName="text"
                valueName="value"
            />
            {/* <TextField
                InputLabelProps={InputLabelProps}
                InputProps={{ readOnly: true }}
                autoComplete="off"
                margin="normal" 
                id="name"
                label="Customer Name"
                value={state ? state.name : ""}
                fullWidth
                // onBlur={handleBlur}
                required={true}
                error={!!errors['name']}
                helperText={!!errors['name'] && errors['name']}
            /> */}
            <TextField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="websiteUrl"
                label="Website"
                defaultValue={state ? state.websiteUrl : ""} 
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                required={false}
                error={!!errors['websiteUrl']}
                helperText={!!errors['websiteUrl'] && errors['websiteUrl']}
            />
            <ImageField
                autoComplete="off"
                margin="normal" 
                id="logo"
                label="Choose Logo"
                defaultValue={state ? state.logo : null} 
                fullWidth
                onChange={handleChange}
                // onBlur={handleBlur}
                required={false}
            />
        </form>
    )
}