import React, { createContext, useState } from 'react';

export const AdvancedModeContext = createContext(["false", () => {}])

export const AdvancedModeProvider = ({ children }) => {
    const [state, setState] = useState("false")

    return (
        <AdvancedModeContext.Provider value={[state, setState]}>
            {children}
        </AdvancedModeContext.Provider>
    )
}