import React from 'react';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Icon } from 'styles';
import { colorMap, initial } from 'utils'

export default function IconAvatar({ icon, className, textClassName }) {
    const text = icon ? initial(icon) : ''

    return (
        <Avatar style={{ backgroundColor: colorMap[text]}} className={ className }>
            {(icon) ? <Icon icon={icon} size={16} /> : <Typography className={textClassName}>{ text }</Typography>}
        </Avatar>
    )
}