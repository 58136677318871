import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField } from '@material-ui/core';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import SelectField from 'components/core/SelectField';
import ImageField from 'components/core/ImageField';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginRight: 5
    },
    vSeparator: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flex: {
        flex: 1
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45%'
    },
    formTitle: {
        paddingBottom: theme.spacing(10)
    },
    formSubtitle: {
        width: '100%',
        textAlign: 'center'
    },
    textField: {
        width: theme.spacing(41)
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

export default function CustomerPaymentInfoForm({ title = 'Payment Information', record, isSubmitting, onSubmit, onError }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = { 
        billingAddress1: ['required'], billingAddress2: ['required'], 
        billingCity: ['required'], billingState: ['required'],  
        billingZip: ['required'], billingCountry: ['required'],
        billingPhone: ['required'], billingFax: ['required']
    }
    const [state, errors, handleChange, handleBlur, handleSubmit] = useForm(callback, validations, record)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <Typography className={classes.formTitle} variant="h5" color="textSecondary" noWrap>
                {title}
            </Typography>
            <div className={classes.hbox}>
                <div className={classes.vbox}>
                    <Typography className={classes.formSubtitle} variant="caption" color="textSecondary" noWrap>
                        Bank    {/* // TODO: */}
                    </Typography>
                    <TextField
                        InputLabelProps={InputLabelProps}
                        autoFocus
                        autoComplete="off"
                        margin="normal" 
                        id="billingAddress1" 
                        label="Name"    // TODO: 
                        defaultValue={state && state.billingAddress1} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!errors['billingAddress1']}
                        helperText={!!errors['billingAddress1'] && errors['billingAddress1']}
                    />
                    <TextField
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="billingAddress1" 
                        label="Routing Number"   // TODO: 
                        defaultValue={state && state.billingAddress1} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!errors['billingAddress1']}
                        helperText={!!errors['billingAddress1'] && errors['billingAddress1']}
                    />
                    <TextField
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="billingAddress1" 
                        label="Account Number"  // TODO: 
                        defaultValue={state && state.billingAddress1} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!errors['billingAddress1']}
                        helperText={!!errors['billingAddress1'] && errors['billingAddress1']}
                    />
                </div>
                <div className={classes.vSeparator}>
                    <Typography className={classes.formSubtitle} variant="caption" color="textSecondary" noWrap>
                        or {/* // TODO: */}
                    </Typography>
                </div>
                <div className={classes.vbox}>
                    <Typography className={classes.formSubtitle} variant="caption" color="textSecondary" noWrap>
                        Credit card {/* // TODO: */}
                    </Typography>
                    <TextField
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="billingAddress1" 
                        label="Number"   // TODO: 
                        defaultValue={state && state.billingAddress1} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!errors['billingAddress1']}
                        helperText={!!errors['billingAddress1'] && errors['billingAddress1']}
                    />
                    <div className={classes.hbox}>
                        <TextField
                            className={clsx(classes.flex, classes.marginRight)}
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            margin="normal" 
                            id="billingCity" 
                            label="Expiration Month"     // TODO: 
                            defaultValue={state && state.billingCity} 
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={true}
                            error={!!errors['billingCity']}
                            helperText={!!errors['billingCity'] && errors['billingCity']}
                        />
                        <TextField
                            className={clsx(classes.flex, classes.marginLeft)}
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            margin="normal" 
                            id="billingState" 
                            label="Expiration Year"  // TODO: 
                            defaultValue={state && state.billingState}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={true}
                            error={!!errors['billingState']}
                            helperText={!!errors['billingState'] && errors['billingState']}
                        />
                    </div>
                    <TextField
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="billingAddress1" 
                        label="Security Code"   // TODO: 
                        defaultValue={state && state.billingAddress1} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!errors['billingAddress1']}
                        helperText={!!errors['billingAddress1'] && errors['billingAddress1']}
                    />
                </div>
            </div>
        </form>
    )
}