import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	activity: {
		color: '#4CB091',
		position: 'relative',
		top: 65,
		left: '48%',
		marginTop: -24, //-theme.spacing(1) - 4,
		marginLeft: -theme.spacing(1) - 4,
		zIndex: 9999
	}
}))

const ActivityMask = () => {
	const classes = useStyles()

	return (
		<CircularProgress size={24} className={classes.activity} />
	)
}

export default ActivityMask