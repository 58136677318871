import React, { Fragment, useMemo, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Tabs, Tab } from '@material-ui/core';
import { Switch, Link, Redirect, useRouteMatch, useParams } from 'react-router-dom';

import { AuthManagerContext } from 'contexts/AuthManager';
import { I18nManagerContext } from 'contexts/I18nManager';
import AuthRoute from 'components/AuthRoute';
import CustomAvatar from 'components/core/CustomAvatar';

import { createRequestId, buildUrl } from 'utils'
import { fetchRecord  } from 'actions/index';

import { PatientAlerts, PatientInteractions, PatientPathways, PatientGoals, PatientDevices, PatientEducationalMaterials, PatientOrders } from 'screens'
import { getRoutesWith } from 'screens/index'

const TabPanel = ({ children, value, index, ...others }) => (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...others}
    >
        <Box p={3}>{children}</Box>
    </Typography>
)
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}
  
const autoSrollable = index => ({
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
})
  
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'stretch',
        flexDirection: 'column'
    },
    title: {
        display: 'flex',
        padding: theme.spacing(5) ,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    },
    avatar: {
        marginTop: 2,
        marginRight: 10
    }
}))

let routesOverride = [{
    name: 'PatientAlerts',
    component: PatientAlerts,
    to: '/alerts'
}, {
    name: 'PatientEducationalMaterials',
    component: PatientEducationalMaterials,
    to: '/educational-materials'
}, {
    name: 'PatientPathways',
    component: PatientPathways,
    to: '/pathways'
}, {
    name: 'PatientGoals',
    component: PatientGoals,
    to: '/goals'
}, {
    name: 'PatientDevices',
    component: PatientDevices,
    to: '/devices'
}, {
    name: 'PatientOrders',
    component: PatientOrders,
    to: '/orders'
}, {
    name: 'PatientInteractions',
    component: PatientInteractions,
    to: '/interactions'
}]

const mapState = ({ resource }) => state => {
    const routes = getRoutesWith(routesOverride, state.application.data)
    const id = createRequestId(resource)
    const record = state.record && state.record[id] && state.record[id].data

    return {
        routes, record
    }
}

const extractProp = path => {
    const splitted = path.split('/')

    return splitted[splitted.length - 1]
}

const entityIdProperty = 'patientId'

const Patient = ({ from, resources }) => {
    const resource = {...resources['read']}
    const authManager = useContext(AuthManagerContext)
    const i18nManager = useContext(I18nManagerContext)
    const { path, url } = useRouteMatch()
    const params = useParams()
    const entityId = params && params[entityIdProperty]
    const dispatch = useDispatch()
    const { routes, record } = useSelector(mapState({ resource: Object.assign(resource, { 
        params: Object.assign({ customerId: authManager.customerId }, params)}) 
    }))

    const classes = useStyles()
    const defaultProp = (routes && routes.length > 0) ? extractProp(routes[0].to) : null

    useEffect(() => {
        if (entityId) {
            dispatch(fetchRecord(resource))
        }
    }, [entityId])

    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h6" color="secondary" noWrap>
                <CustomAvatar image={record && record.image} name={record && record.fullName} className={classes.avatar}></CustomAvatar>
                {record && record.fullName}
            </Typography>
            {(params.prop === 'open') ? (
                <Redirect to={buildUrl(path, Object.assign(params, { prop: defaultProp }))}/>
            ) : (
                <Fragment>
                    <Tabs value={url} indicatorColor="primary" textColor="secondary">
                        {routes.map((route, index) => {
                            const prop = extractProp(route.to)
                            const to = buildUrl(path, Object.assign(params, { prop }))

                            return (
                                <Tab key={index} 
                                    label={i18nManager.t(route.title)} 
                                    value={to} component={Link} to={to}
                                />   
                            )
                        })}
                    </Tabs>
                    <Switch>
                        {routes && routes.map(({ to, component, ...others }, index) => {
                            return (component) ? 
                                <AuthRoute exact
                                    key={index} 
                                    path={`${from || ''}${to}`}
                                    component={component}
                                    tabMode={true}
                                    {...others}
                                /> : null
                        })}
                    </Switch>
                </Fragment>
            )}
        </div>
    )
}

export default Patient