import React from 'react';
import { Button } from '@material-ui/core';

export default function TrackingLink({ carrier, href, className, ...other }) {
    switch (carrier) {
        case 'UPS':
            return <Button className={className} href={'https://www.ups.com/track?loc=null&tracknum=' + href} target='_blank' color='primary' {...other}></Button>
		case 'USPS':
			return <Button className={className} href={'https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=' + href} target='_blank' color='primary' {...other}></Button>
        default:
            return ''
    }
}