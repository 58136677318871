import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, useTheme, Tooltip } from '@material-ui/core';

import { AuthManagerContext } from 'contexts/AuthManager';
import { I18nManagerContext } from 'contexts/I18nManager';

import CustomAvatar from 'components/core/CustomAvatar';
import PhoneLink from 'components/core/PhoneLink';
import AddressLink from 'components/core/AddressLink';
import EmailLink from 'components/core/EmailLink';
import HiddenLink from 'components/core/HiddenLink';
import { Icon } from 'styles';
import { Base } from 'components/themes/main';

import { ageFormat, formatCm2Ft } from 'helpers'

const useStyles = makeStyles(theme => ({
    content: {
        display:'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        flexGrow: 1
    },
    widget: {
        width: '100%',
        height: 'auto',
        border: '1px solid #E2E2E3',
        boxShadow: 'none',
        borderRadius: 2,
        marginBottom: theme.spacing(2)
    },
    widgetHeader: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '12px 20px',
        textTransform: 'uppercase'
    },
    widgetActions: {
        padding: '0px 15px 10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    widgetTitle: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: '1.3125rem',
		minWidth: 11
    },
    widgetContent: {
        padding: 20,
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1  
    },
    vboxWidgetContent: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        flexGrow: 1  
    },
    widgetContentText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        // marginLeft: theme.spacing(2)
    },
    avatar: {
        width: 32,
        height:32
    },
    mediumAvatar: {
        width: 60,
        height:60
    },
    alignRight: {
        textAlign: 'right'
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%'
    },
    hboxSpace: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '100%'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        width: '100%',
        marginRight: 5
    },
    link: {
        fontSize: 'inherit',
		padding: '0px 6px 3px 6px',
        textTransform: 'lowercase',
        minWidth: 0
    },
	addressLink: {
        fontSize: 'inherit',
		padding: '2px 6px 0px 6px',
        // textTransform: 'lowercase',
        minWidth: 0,
		verticalAlign: 'top'
    },
    label: {
        display: 'inline-block',
        width: 100,
        textAlign: 'right'
    },
    configButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-center',
        flex: 1,
        marginBottom: theme.spacing(2)
    },
	icon: {
		verticalAlign: 'middle'
	},
	separator: {
		color: 'rgba(0, 0, 0, 0.5)',
    	fontSize: 8,
    	verticalAlign: 'top',
		paddingLeft: 5,
		paddingRight: 5
	}
}))

const PatientProfile = ({ record }) => {
    const classes = useStyles()
	const theme = useTheme()
    const i18nManager = useContext(I18nManagerContext)

    return (
        <Paper className={classes.widget}>
            <div className={classes.widgetHeader}>
                <Typography className={classes.widgetTitle} variant="caption" color="secondary" noWrap>Patient Profile</Typography> 
            </div>
            <div className={classes.vboxWidgetContent}>
					<span className={classes.hbox} style={{ paddingTop: 5 }} >
						<CustomAvatar image={record.logo} name={record.fullName} className={classes.avatar}></CustomAvatar>
						<span className={classes.vbox} style={{ paddingLeft: 16 }} >
							<Typography variant="subtitle2" color="secondary" noWrap>{record.fullName}</Typography> 
							<Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>ID: {record.customerPatientId} {(record.emrId) && <span className={classes.widgetTitle} variant="caption" color="textSecondary"><span className={classes.separator}></span> EMRID: {record.emrId}</span>}</Typography> 
							
						</span>
					</span>
                    <div className={classes.widgetContentText} style={{ paddingTop: 8 }}>
						<span className={classes.hbox} style={ {paddingTop: 1, paddingBottom: 5} } >
							<Typography className={classes.widgetTitle} variant="caption" color="secondary" noWrap>{record.gender === 'M' ? 'Male' : 'Female' } <span className={classes.separator}>●</span> {ageFormat(record.dob)} years <span className={classes.separator}>●</span> {record.heightUnitMeasure === 'ft' ? formatCm2Ft(record.height) : Math.round(record.height) + ' ' + record.heightUnitMeasure} <span className={classes.separator}>●</span> { new Intl.DisplayNames(['en'], {type: 'language'}).of(record.languageId) }</Typography>
						</span>
						<span className={classes.hbox} >
                        	<Typography className={classes.widgetTitle} variant="caption" color="textSecondary" ><Icon className={classes.icon} icon={"phone"} size={12} color={theme.palette.text.secondary}/></Typography> 
                        	<Typography className={classes.widgetTitle} variant="caption" color="secondary" ><PhoneLink className={classes.link} href={record.mobilePhone}>{record.mobilePhone}</PhoneLink></Typography> 
						</span>
						<span className={classes.hbox} >
                       		<Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap><Icon className={classes.icon} icon={"mail"} size={12} color={theme.palette.text.secondary} /></Typography> 
                       		<Typography className={classes.widgetTitle} variant="caption" color="secondary" noWrap><EmailLink className={classes.link} href={record.email}>{record.email}</EmailLink></Typography> 
						</span>
						<span className={classes.hbox}>
								<Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap><Icon className={classes.icon} icon={"home"} size={12} color={theme.palette.text.secondary} /></Typography> 
                       		<Typography className={classes.widgetTitle} variant="caption" color="secondary" noWrap><AddressLink className={classes.addressLink} href={record.address}>{record.address}</AddressLink></Typography> 
							{/* <Typography className={classes.widgetTitle} style={{ paddingBottom: 2, paddingRight: 5 }} variant="caption" color="textSecondary"><Icon className={classes.icon} icon={"home"} size={12} color={theme.palette.text.secondary}  /></Typography> 
							<Typography className={classes.widgetTitle} style={{ paddingTop: 2 }} variant="caption" color="secondary"><AddressLink className={classes.link} href={record.address}>{record.address}</AddressLink></Typography>  */}
						</span>
						<span className={classes.hbox}>
                        	{(record.statusId === 64) && (<Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap><Icon className={classes.icon} icon={"key"} size={12} color={theme.palette.text.secondary} /><Typography className={classes.widgetTitle} variant="caption" color="secondary" noWrap><HiddenLink className={classes.link} text={record.emailCode}></HiddenLink></Typography> </Typography>)}
						</span>
                    </div>
            </div> 
        </Paper>
    )
}

export default PatientProfile