import React, { useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Slide, Card, CardContent, Radio } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

import { I18nManagerContext } from 'contexts/I18nManager';
import { highlightDiff } from 'utils'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 550
    },
    title: {
        display: 'block',
        padding: 0
    },
    subtitle: {
        display: 'block',
        paddingLeft: theme.spacing(1 / 4)
    },
    content: {
        display: 'flex'
    },
    card: {
        flex: 1
    },
    cardContent: {
        display: 'flex'
    },
    cardTitle: {
        paddingTop: 6,
        paddingBottom: 20
    },
    buttons: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'right'
    },
    button: {
        marginLeft: theme.spacing(1) / 2,
        marginRight: theme.spacing(1) / 2,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    },
    highlighted: {
        backgroundColor: 'rgba(0, 177, 172, 0.3)'
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function VerifyAddressDialog({ open, title = 'Verify Address', subtitle = '', record, onSubmit, onCancel }) {
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const [selectedValue, setSelectedValue] = useState(true);

    const handleCancel = () => {
        onCancel()
    }

    const handleSubmit = newState => {
        onSubmit(selectedValue)
    }

    const handleChange = (event) => {
        setSelectedValue(event.target.value === 'true');
    }

    return (
        <Dialog
            classes={{
                paper: classes.dialogPaper
            }}
            open={open}
            onClose={onCancel}
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            keepMounted
            disableBackdropClick
            disableEscapeKeyDown
        >
        {!record.verifiedAddress1 ? (
            <Fragment>
            <DialogTitle id="form-dialog-title">
                {i18nManager.t(title)}
                <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                    Sorry, we couldn't find any address suggestion.
                </Typography>
            </DialogTitle>
            <DialogActions className={classes.buttons}>
                <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
            </DialogActions>
            </Fragment>
        ) : (
            <Fragment>
            <DialogTitle id="form-dialog-title">
                {i18nManager.t(title)}
                <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                    To ensure accurate delivery, consider the changes highlighted below.
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Card className={classes.card} variant="outlined" style={{ marginRight: 5 }}>
                    <CardContent className={classes.cardContent}>
                        <Radio
                            style={{ alignSelf: 'flex-start' }}
                            checked={selectedValue === false}
                            onChange={handleChange}
                            value={false}
                            name="radio-buttons"
                            size="small"
                            color="primary"
                            inputProps={{ 'aria-label': 'Original' }}
                        />
                        <div>
                            <Typography className={classes.cardTitle} color="secondary">
                                Original Address
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {record.address1} {record.address2}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {record.city}, {record.state} {record.zip}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {record.country}
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
                <Card className={classes.card} variant="outlined" style={{ marginLeft: 5 }}>
                    <CardContent className={classes.cardContent}>
                        <Radio
                            style={{ alignSelf: 'flex-start' }}
                            checked={selectedValue === true}
                            onChange={handleChange}
                            value={true}
                            name="radio-buttons"
                            size="small"
                            color="primary"
                            inputProps={{ 'aria-label': 'Suggested' }}
                        />
                        <div>
                            <Typography className={classes.cardTitle} color="secondary">
                                Suggested Address
                            </Typography>
                            <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: highlightDiff(record.verifiedAddress1 + ' ' + record.verifiedAddress2, record.address1 + ' ' + record.address2, classes.highlighted) }} />
                            <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: highlightDiff(record.verifiedCity + ', ' + record.verifiedState + ' ' + record.verifiedZip, record.city + ', ' + record.state + ' ' + record.zip, classes.highlighted) }} />
                            <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: highlightDiff(record.verifiedCountry, record.country, classes.highlighted) }} />
                        </div>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions className={classes.buttons}>
                <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" className={classes.button} onClick={handleSubmit} color="secondary">Use selected address</Button>
            </DialogActions>
            </Fragment>
        )}
        </Dialog>
    )
}

VerifyAddressDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default VerifyAddressDialog