import React, { useRef, useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, CircularProgress } from '@material-ui/core';

import useForm from 'hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { createRequestId } from 'utils'
import { fetchRecord } from 'actions/index';

import SelectField from 'components/core/SelectField';
import { Icon } from 'styles';
import { Base } from 'components/themes/main';
import clsx from 'clsx';

import VerifyAddressDialog from 'components/VerifyAddressDialog'

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    flex: {
        flex: 1
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    },
    message: {
        paddingTop: 6,
        paddingBottom: 7,
        paddingRight: 20,
        textAlign: 'center',
        fontSize: '.9rem',
        fontStyle: 'italic',
        color: 'rgba(0, 0, 0, 0.3)'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    },
    icon: {
        marginTop: 9, 
        marginRight: 5
    }
}))

const verificationResource = { 
    path: '/shipping-carriers/:carrier/verify-address', 
    method: 'get' 
}

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

const mapState = ({ resource }) => state => {
    const id = createRequestId(resource)
    const verifiedAddressRecord = state.record && state.record[id] && state.record[id].data
    const isLoading = state.record && state.record[id] && state.record[id].isLoading

    return {
        verifiedAddressRecord, isLoading
    }
}

export default function ShippingInfoForm({ title = 'Shipping Information', record, isSubmitting, onSubmit, onError, onDirty }) {
    const classes = useStyles()
    const ref = useRef()
    const dispatch = useDispatch()
    const validations = { 
        address1: ['required'],
        city: ['required'], state: ['required'],  
        zip: ['required'], country: ['required']
    }
    const [openDialog, setOpenDialog] = useState(false)
    const [verifiedAddress, setVerifiedAddress] = useState(record.verifiedAddress)
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validations, record)
    const { verifiedAddressRecord, isLoading } =  useSelector(mapState({ resource: Object.assign(verificationResource, { params: { carrier: 'UPS' }} )}))

    const onVerifyAddress = (newState) => {
        async function fetchData() {
            const { customerId, patientId, address1, address2, city, state, zip, country } = newState

            const { data: { result }, error } = await dispatch(fetchRecord(verificationResource, { customerId, patientId, address1, address2, city, state, zip, country }))

            if (!error && result) {
                setOpenDialog(true)
            }
        }
  
        newState && fetchData();
    }

    const onModifyAddress = () => {
        setVerifiedAddress(false)
    }

    function callback() {
        if (verifiedAddress) {
            const { verifiedAddress1, verifiedCity, verifiedState, verifiedZip, verifiedCountry } = verifiedAddressRecord
            Object.assign(state, { address1: verifiedAddress1, city: verifiedCity, state: verifiedState, zip: verifiedZip, country: verifiedCountry }) 
        }

        Object.assign(state, { verifiedAddress })

        if (onSubmit) onSubmit(state)
    }

    const handleCancel = () => {
        setOpenDialog(false)
    }

    const handleSelectAddress = (verified) => {
        setVerifiedAddress(verified)
        setOpenDialog(false)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            {verifiedAddress ? (
                <Fragment> 
                    <div>
                        <Typography variant="body2" color="textSecondary">{verifiedAddressRecord.verifiedAddress1 + ' ' + verifiedAddressRecord.verifiedAddress2}</Typography>
                        <Typography variant="body2" color="textSecondary">{verifiedAddressRecord.verifiedCity + ', ' + verifiedAddressRecord.verifiedState + ' ' + verifiedAddressRecord.verifiedZip}</Typography>
                        <Typography variant="body2" color="textSecondary">{verifiedAddressRecord.verifiedCountry}</Typography>
                    </div>
                    <div style={{ marginTop: 16, display: 'flex', justifyContents: 'center'}}>
                        <Icon className={classes.icon} icon="check-circle" size={16} color={Base.success.color}/>
                        <Typography className={classes.message} variant="caption" noWrap>System verified address</Typography>
                        <Button 
                            size="small" 
                            color='primary' 
                            className={classes.smallButton} 
                            onClick={() => onModifyAddress()}> 
                            Modify
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                        {/* <Button 
                            size="small" 
                            color='primary' 
                            className={classes.smallButton} 
                            disabled={state && state.carrier == '99' || !state}
                            onClick={() => onVerifyAddress(state)}> 
                            Update patient profile
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button> */}
                    </div>
                </Fragment>
            ) : (
                <Fragment> 
                <TextField
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal" 
                    id="address1" 
                    label="Address 1"
                    defaultValue={state && state.address1} 
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    error={!!errors['address1']}
                    helperText={!!errors['address1'] && errors['address1']}
                />
                <TextField
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal" 
                    id="address2" 
                    label="Address 2"
                    defaultValue={state && state.address2} 
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors['address2']}
                    helperText={!!errors['address2'] && errors['address2']}
                />
                <div className={classes.hbox}>
                    <SelectField
                        className={clsx(classes.flex, classes.marginRight)}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="country"
                        label="Country"
                        defaultValue={state ? (state.country || "") : ""}
                        required={true}
                        error={!!errors['country']}
                        helperText={!!errors['country'] && errors['country']}
                        resource={{ path: '/countries' }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="value"
                    />
                    <SelectField
                        className={clsx(classes.flex, classes.marginLeft)}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="state"
                        label="State"
                        defaultValue={state ? (state.state || "") : ""}
                        required={true}
                        error={!!errors['state']}
                        helperText={!!errors['state'] && errors['state']}
                        resource={state.country && { path: '/countries/:countryCode/states', params: { countryCode: state.country } }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="value"
                        disabled={state && state.country ? false : true}
                    />
                </div>
                <div className={classes.hbox}>
                    <SelectField
                        className={clsx(classes.flex, classes.marginRight)}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="city"
                        label="City"
                        defaultValue={state ? (state.city || "") : ""}
                        required={true}
                        error={!!errors['city']}
                        helperText={!!errors['city'] && errors['city']}
                        resource={(state.country && state.state) && { path: '/countries/:countryCode/states/:stateCode/cities', params: { countryCode: state.country, stateCode: state.state } }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="value"
                        disabled={state && state.state ? false : true}
                    />
                    <SelectField
                        className={clsx(classes.flex, classes.marginLeft)}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="zip"
                        label="Zip"
                        defaultValue={state ? (state.zip || "") : ""}
                        required={true}
                        error={!!errors['zip']}
                        helperText={!!errors['zip'] && errors['zip']}
                        resource={(state.country && state.state && state.city) && { path: '/countries/:countryCode/states/:stateCode/cities/:cityCode/zipcodes', params: { countryCode: state.country, stateCode: state.state, cityCode: state.city } }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="value"
                        disabled={state && state.city ? false : true}
                    />
                </div>
                <div style={{ marginTop: 16, display: 'flex', justifyContents: 'center'}}>
                    <Icon className={classes.icon} icon="alert-triangle" size={16} color={Base.warning.color}/>
                    <Typography className={classes.message} variant="caption" noWrap>
                        This address have not been verified
                    </Typography>
                    <Button 
                        size="small" 
                        color='primary' 
                        className={classes.smallButton} 
                        disabled={(state && !(state.address1 && state.city && state.state && state.zip)) || !state}
                        onClick={() => onVerifyAddress(state)}>
                        Verify Address
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                    {(openDialog) && (
                        <VerifyAddressDialog
                            open={openDialog}
                            record={verifiedAddressRecord}
                            onCancel={handleCancel}
                            onSubmit={handleSelectAddress}
                        />
                    )}
                </div>
                </Fragment>
            )}
        </form>
    )
}