import React, { useState, useContext, useEffect, useRef } from 'react';
import {  Link  } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { darken, lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { Button, Chip, CircularProgress, Tooltip, Badge, IconButton } from '@material-ui/core';
import StatusChipField from '../StatusChipField';
import CustomAvatar from '../CustomAvatar';
import IconAvatar from '../IconAvatar';
import CustomMultimedia from '../CustomMultimedia';
import PhoneLink from '../PhoneLink';
import EmailLink from '../EmailLink';
import TrackingLink from '../TrackingLink';
import AlertIconButton from '../AlertIconButton';
import { Icon } from 'styles'; 

import { I18nManagerContext } from 'contexts/I18nManager'
import { dateFormat, ageFormat, timefromNow, currencyFormat, formatFloatHours, formatCm2Ft, secToMin } from 'helpers'
import { buildUrl, uncapitalized, capitalized } from 'utils'

const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 150, 200]
const ROWS_PER_PAGE = 50

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}
  
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function pageItems(array, slice) {
    return slice ? array.slice(slice.begin, slice.end) : array
}

function pageSort(array, sortBy) {
    return sortBy ? stableSort(array, getSorting(sortBy.direction, sortBy.property)) : array   
}

const useColumnStyles = makeStyles(theme => ({
    chip: {
        color: 'white',
        padding: '0px 4px',
        width: 'fit-content',
        alignSelf: 'center',
        backgroundColor: 'gray',
    },
    alertChip: {
        color: 'white'
    },
    noValueChip: {
        backgroundColor: 'transparent'
    },
    linkAvatar: {
        marginRight: 10
    },
    smallButton: {
        padding: 0
    },
    badge: {
        transform: 'scale(.7) translate(55%, 50%)'
    },
    deepSleep: {
        color: 'white',
        backgroundColor: '#027BCE',
        "&&&&:active": {
            backgroundColor: '#027BCE'
        },
        "&&&&:focus": {
            backgroundColor: '#027BCE'
        },
        "&&&&:hover": {
            backgroundColor: '#027BCE',
        }
    },
    lightSleep: {
        color: 'white',
        backgroundColor: '#00A0DF',
        "&&&&:active": {
            backgroundColor: '#00A0DF'
        },
        "&&&&:focus": {
            backgroundColor: '#00A0DF'
        },
        "&&&&:hover": {
            backgroundColor: '#00A0DF',
        }
    },
    awake: {
        color: 'white',
        backgroundColor: '#EF7373',
        "&&&&:active": {
            backgroundColor: '#EF7373'
        },
        "&&&&:focus": {
            backgroundColor: '#EF7373'
        },
        "&&&&:hover": {
            backgroundColor: '#EF7373',
        }
    },
    title: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: '1rem',
        paddingTop: 1.5,
        textAlign: 'center'
    },
    noIcon: {
        padding: '4px !important'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 'fit-content',

        '& .active, & .goal, & .escalated, & .delivered': { 
            color: 'white',
            backgroundColor: '#4CB091',
            "&&&&:active": {
                backgroundColor: '#4CB091'
            },
            "&&&&:focus": {
                backgroundColor: '#4CB091'
            },
            "&&&&:hover": {
                backgroundColor: '#4CB091',
            }
        },

        '& .green, & .read': { 
            color: 'white',
            paddingLeft: 8,
            backgroundColor: '#4CB091',
            "&&&&:active": {
                backgroundColor: '#4CB091'
            },
            "&&&&:focus": {
                backgroundColor: '#4CB091'
            },
            "&&&&:hover": {
                backgroundColor: '#4CB091',
            }
        },

        '& .pending, & .baseline, & .sent, & .opened, & .inTransit': {
            color: 'white',
            backgroundColor: '#FCA861',
            "&&&&:active": {
                backgroundColor: '#FCA861'
            },
            "&&&&:focus": {
                backgroundColor: '#FCA861'
            },
            "&&&&:hover": {
                backgroundColor: '#FCA861',
            }
        },

        '& .yellow': {
            color: 'white',
            paddingLeft: 8,
            backgroundColor: '#FCA861',
            "&&&&:active": {
                backgroundColor: '#FCA861'
            },
            "&&&&:focus": {
                backgroundColor: '#FCA861'
            },
            "&&&&:hover": {
                backgroundColor: '#FCA861',
            }
        },

        '& .critical, & .exception': {
            color: 'white',
            backgroundColor: '#EF7373',
            "&&&&:active": {
                backgroundColor: '#EF7373'
            },
            "&&&&:focus": {
                backgroundColor: '#EF7373'
            },
            "&&&&:hover": {
                backgroundColor: '#EF7373',
            }
        },

        '& .red': {
            color: 'white',
            paddingLeft: 8,
            backgroundColor: '#EF7373',
            "&&&&:active": {
                backgroundColor: '#EF7373'
            },
            "&&&&:focus": {
                backgroundColor: '#EF7373'
            },
            "&&&&:hover": {
                backgroundColor: '#EF7373',
            }
        },

        '& .inactive': {
            color: 'white',
            backgroundColor: '#717B7C',
            "&&&&:active": {
                backgroundColor: '#717B7C'
            },
            "&&&&:focus": {
                backgroundColor: '#717B7C'
            },
            "&&&&:hover": {
                backgroundColor: '#717B7C',
            }
        }
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: 'fit-content',
        alignSelf: 'center',
        "&&&&:hover": {
            opacity: .7
        }
    },
    complexLeft: {
        borderRadius: '16px 0px 0px 16px',
        borderRight: '1px solid white'
    },
    complexRight: {
        borderRadius: '0px 16px 16px 0px'
    },
    link: {
        textTransform: 'lowercase'
    },
    address: {
        whiteSpace: 'nowrap'
	},
	trash: {
        color: 'white',
        backgroundColor: '#EF7373',
        border: '1px solid white',
        "&&&&:active": {
            backgroundColor: '#EF7373'
        },
        "&&&&:focus": {
            backgroundColor: '#EF7373'
        },
        "&&&&:hover": {
            backgroundColor: '#EF7373',
        }
    }
}))

function AvatarColumn({ dataKey, filterable, columnText, row }) {
    return (<CustomAvatar image={row[dataKey]} name={row[columnText]}></CustomAvatar>)
}

function IconAvatarColumn({ dataKey, filterable, columnText, row }) {
    return (<IconAvatar icon={row[dataKey]}></IconAvatar>)
}

function DateTimeColumn({ dataKey, filterable, row, format = 'MMM D, YYYY [at] h:mm A' }) {
    return row[dataKey] ? dateFormat(row[dataKey], format) : ''
}

function DateColumn({ dataKey, filterable, row, format = 'MMM D, YYYY' }) {
    return row[dataKey] ? dateFormat(row[dataKey], format) : ''
}

function TimeFromNowColumn({ dataKey, filterable, row }) {
    return timefromNow(row[dataKey])
}

function CurrencyColumn({ dataKey, filterable, row }) {
    return currencyFormat(row[dataKey])
}

function AgeColumn({ dataKey, filterable, row }) {
    return ageFormat(row[dataKey])
}

function MultimediaColumn({ dataKey, filterable, row }) {
    return <CustomMultimedia content={row} />
}

function ButtonColumn({ type, dataKey, columnImage, columnText, filterable, handleClick, row, ...others }) {
    const classes = useColumnStyles()

	const handleButtonClick = (event) => {
        event.stopPropagation()
        handleClick && handleClick(event, row)
    }
    
    return (
        <Button onClick={handleButtonClick} {...others} size="small" className={classes.smallButton}>
            <CustomAvatar image={row[columnImage]} name={row[columnText]} className={classes.linkAvatar}></CustomAvatar>{row[dataKey]}
        </Button>
    )
}

function TimeChipColumn({ dataKey, filterable, columnText, row }) {
    return (<StatusChipField label={secToMin(row[dataKey])} size="small" />)
}

function AddressColumn({ type, dataKey, filterable, row, ...others }) {
    const classes = useColumnStyles()

    return (<div className={classes.address}>{row[dataKey]}</div>)
}

function ActionColumn({ type, dataKey, filterable, row, label, handleClick, handleMouseEnter, handleMouseLeave, ...others }) {
    const classes = useColumnStyles()

    const handleActionClick = (event) => {
        event.stopPropagation()
        handleClick && handleClick(event, row)
    }
    
	return (
        <div className={classes.hbox}>
            <IconButton size="medium" className={classes[uncapitalized(dataKey)]} style={{ padding: 7 }} onClick={handleActionClick}>
                <Icon icon={dataKey} size={16} className={classes.icon} />
            </IconButton>
        </div>
    )
}

function LinkColumn({ type, dataKey, columnImage, columnText, filterable, to, params, row, ...others }) {
    const classes = useColumnStyles()
	const url = buildUrl(to, params, row)
    
    return (
        <Button component={Link} to={url} {...others} size="small" className={classes.smallButton}>
            <CustomAvatar image={row[columnImage]} name={row[columnText]} className={classes.linkAvatar}></CustomAvatar>{row[dataKey]}
        </Button>
    )
}

function LinkNoImageColumn({ type, dataKey, columnText, filterable, to, params, row, ...others }) {
    const classes = useColumnStyles()
	const url = buildUrl(to, params, row)
    
    return (
        <Button component={Link} to={url} {...others} size="small" className={classes.smallButton}>
            {row[dataKey]}
        </Button>
    )
}

function EmailColumn({ type, dataKey, filterable, row, ...others }) {
    const classes = useColumnStyles()

    return (<EmailLink className={classes.link} href={row[dataKey]} {...others}>{row[dataKey]}</EmailLink>)
}

function PhoneColumn({ type, dataKey, filterable, row, ...others }) {
    const classes = useColumnStyles()

    return (<PhoneLink className={classes.link} href={row[dataKey]} {...others}>{row[dataKey]}</PhoneLink>)
}

function TrackingColumn({ type, dataKey, filterable, row, ...others }) {
    const classes = useColumnStyles()
    const carrier = row['carrier'] || ''

    return (carrier ? 
        <TrackingLink carrier={carrier} href={row[dataKey]} {...others}>{row[dataKey]}</TrackingLink> :
        row[dataKey]
        )
}

function AlertBadgeColumn({ type, dataKey, filterable, row, label, handleClick, handleMouseEnter, handleMouseLeave, ...others }) {
    const classes = useColumnStyles()
    const alertCount = row[dataKey] || 0
    const alertType = row['alertType'] || ''

    const handleAlertClick = (event) => {
        //event.stopPropagation()
        handleClick && handleClick(event, row)
    }
    
    return (
        (alertCount > 0 ?
            <div className={classes.hbox}>
                <AlertIconButton badgeContent={alertCount} category={alertType} onClick={handleAlertClick}></AlertIconButton>
            </div>
            :
            <div></div>
        )
    )
}

function AlertColumn({ type, dataKey, filterable, row, label, handleClick, handleMouseEnter, handleMouseLeave, ...others }) {
    const classes = useColumnStyles()
    
    return (
        <div className={classes.hbox}>
            <AlertIconButton category={row[dataKey]}></AlertIconButton>
        </div>
    )
}

function StatusColumn({ type, dataKey, filterable, row, label, handleClick, ...others }) {
    const classes = useColumnStyles()
    let originalStatus = row[dataKey] 

    const status = (originalStatus && originalStatus.includes('Pending')) ? 'Pending' : originalStatus

    return (
        status && (
        <div className={classes.vbox}>
            <Tooltip key='chip' title={originalStatus}>
            <Chip label={status} 
                className={clsx(classes.chip, uncapitalized(status))}
                //onClick={handleClick}
                {...others}  
            />
            </Tooltip>
        </div>
        )
    )
}

function MeasureColumn({ dataKey, filterable, row }) {
    const measureUnit = row[dataKey + 'UnitMeasure'] || ''

    switch (measureUnit) {
        case 'ft':
            return formatCm2Ft(row[dataKey])
        default:
            return Math.round(row[dataKey]) + ' ' + measureUnit
    }
}

function MeasureChipColumn({ type, dataKey, filterable, row, label, handleClick, formatter, ...others }) {
    const classes = useColumnStyles()
    const value = parseFloat(row[dataKey])
    const measure = (value && formatter) ? Formatter[formatter](value) : value
    const age = row[dataKey + 'AgeDate']
    const tendency = row[dataKey + 'Tendency'] || null
    const measureAge = timefromNow(age)
    const measureTendency = tendency == 'Up' ? 'arrow-up' : (tendency == 'Light Up' ? 'arrow-up-right' : (tendency == 'Down' ? 'arrow-down' : (tendency == 'Light Down' ? 'arrow-down-right' : '')))
    const measurePerformance = row[dataKey + 'Performance']
    // const measureUnit = row[dataKey + 'UnitMeasure']
    const cls = measurePerformance ? uncapitalized(measurePerformance) : ''

    const handleChipClick = (event, row) => {
        event.stopPropagation()
        handleClick && handleClick(event, row)
    }

    return (
        (measure ?
            <div className={classes.vbox}>
                <div className={classes.hbox}>
                    {measureTendency ?
                        (<Chip label={measure} // {measure + ' ' + measureUnit} 
                            className={clsx(classes.chip, cls)}
                            icon={<Icon icon={measureTendency} size={16} className={classes.icon}/>}
                            onClick={event => handleChipClick(event, row)}
                            {...others}  
                        />) :
                        (<Chip label={measure} 
                            className={clsx(classes.chip, cls, classes.noIcon)}
                            onClick={event => handleChipClick(event, row)}
                            {...others}  
                        />) 
                        }
                </div>
                <Typography className={classes.title} variant="caption" color="textSecondary" noWrap>{measureAge}</Typography>
            </div> :
            <div></div>
        )
    )
}

function ComplexMeasureChipColumn({ type, dataKey, filterable, dataKey2, row, label, handleClick, ...others }) {
    const classes = useColumnStyles()
    const measure = row[dataKey]
    const age = row[dataKey + 'AgeDate']
    const tendency = row[dataKey + 'Tendency'] || null
    const measureAge = timefromNow(age)
    const measureTendency = tendency == 'Up' ? 'arrow-up' : (tendency == 'Light Up' ? 'arrow-up-right' : (tendency == 'Down' ? 'arrow-down' : (tendency == 'Light Down' ? 'arrow-down-right' : '')))
    const measurePerformance = row[dataKey + 'Performance']
    const cls = measurePerformance ? uncapitalized(measurePerformance) : ''

    const measure2 = row[dataKey2]
    const age2 = row[dataKey2 + 'AgeDate']
    const tendency2 = row[dataKey2 + 'Tendency'] || null
    const measureAge2 = timefromNow(age2)
    const measureTendency2 = tendency == 'Up' ? 'arrow-up' : (tendency2 == 'Light Up' ? 'arrow-up-right' : (tendency2 == 'Down' ? 'arrow-down' : (tendency2 == 'Light Down' ? 'arrow-down-right' : '')))
    const measurePerformance2= row[dataKey2 + 'Performance']
    const cls2 = measurePerformance2 ? uncapitalized(measurePerformance2) : ''

    const handleChipClick = (event, row) => {
        event.stopPropagation()
        handleClick && handleClick(event, row)
    }
    
    return (
        (measure ?
            <div className={classes.vbox}>
                <div className={classes.hbox}>
                    {measureTendency ?
                        (<Chip label={measure} 
                            className={clsx(classes.chip, classes[cls], classes.complexLeft)}
                            icon={<Icon icon={measureTendency} size={16} className={classes.icon}/>}
                            onClick={event => handleChipClick(event, row)}
                            {...others}  
                        />) :
                        (<Chip label={measure} 
                            className={clsx(classes.chip, classes[cls], classes.noIcon, classes.complexLeft)}
                            onClick={event => handleChipClick(event, row)}
                            {...others}  
                        />) 
                        }
                    {measureTendency2 ?
                        (<Chip label={measure2} 
                            className={clsx(classes.chip, classes[cls2], classes.complexRight)}
                            icon={<Icon icon={measureTendency2} size={16} className={classes.icon}/>}
                            onClick={event => handleChipClick(event, row)}
                            {...others}  
                        />) :
                        (<Chip label={measure2} 
                            className={clsx(classes.chip, classes[cls2], classes.noIcon, classes.complexRight)}
                            onClick={event => handleChipClick(event, row)}
                            {...others}  
                        />) 
                        }
                </div>
                <Typography className={classes.title} variant="caption" color="textSecondary" noWrap>{measureAge}</Typography>
            </div> :
            <div></div>
        )
    )
}

function ThresholdColumn({ type, dataKey, filterable, row, label, handleClick, threshhold, ...others }) {
    const classes = useColumnStyles()
    const value = row[dataKey]
    const thresholdIcon = dataKey.includes('Low') ? 'chevron-left' : dataKey.includes('High') ? 'chevron-right' : ''

    return (
        (value ?
            <div className={classes.vbox}>
                {thresholdIcon ? 
                    (<Chip label={value} 
                        className={clsx(classes.chip, threshhold)}
                        icon={<Icon icon={thresholdIcon} size={16} className={classes.icon}/>}
                        {...others}  
                    />) : (<Chip label={value} className={clsx(classes.chip, threshhold)} {...others} />)
                }
                <Typography className={classes.title} variant="caption" color="textSecondary" noWrap>{row['unitMeasure']}</Typography>
            </div> :
            <div></div>
        )
    )
}

const Formatter = {
    formatFloatHours
}

const ColumnType = {
    DateTimeColumn,
    DateColumn,
    TimeFromNowColumn,
    AgeColumn,
    ActionColumn,
	ButtonColumn,
    LinkColumn,
    LinkNoImageColumn,
    EmailColumn,
    PhoneColumn,
    TrackingColumn,
    TimeChipColumn,
    AddressColumn,
    StatusColumn,
    CurrencyColumn,
    AvatarColumn,
    IconAvatarColumn,
    MeasureColumn,
    MeasureChipColumn,
    ComplexMeasureChipColumn,
    ThresholdColumn,
    AlertColumn,
    AlertBadgeColumn,
    MultimediaColumn
}

const columnStyle = column => {
    let style = {}

    if (column.width) {
        style.width = column.width
    }
    return style
}

function DataTableHead({ classes, columns, order, orderBy, numSelected, rowCount, onSelectAllClick, onRequestSort, allowSelection }) {
    const i18nManager = useContext(I18nManagerContext)
    const createSortHandler = property => event => {
        onRequestSort(event, property)
    }
  
    return (
        <TableHead>
            <TableRow>
                {allowSelection && (
                    <TableCell padding="checkbox" className={classes.head}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                    </TableCell>
                )}
                {columns.map(column => (
                    <TableCell
                        style={columnStyle(column)}
                        className={classes.head}
                        key={column.dataKey}
                        align={column.numeric ? 'right' : 'left'}
                        padding={column.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === column.dataKey ? order : false}
                    >
                    <TableSortLabel
                        active={orderBy === column.dataKey}
                        direction={order}
                        onClick={createSortHandler(column.dataKey)}
                    >
                        {i18nManager.t(column.label)}
                        {orderBy === column.dataKey ? (
                            <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                        ) : null}
                    </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
  
DataTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const ActivityMask = () => {
    const classes = useStyles()

    return (
        <CircularProgress size={24} className={classes.activity} />
    )
}
  
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        height: '100%',
        width: '100%',
        maxHeight: '-webkit-fill-available'
    },
    tableWrapper: {
        flex: 1,
        overflow: 'auto'
    },
    table: {
        overflowY: 'none',
        minWidth: 750,
    },
    tableBody: {
        overflowY: 'scroll'
    }, 
    head: {
        backgroundColor: 'white',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        whiteSpace: 'nowrap',
        fontSize: '.75rem',
        color: '#4B4B4C',
        textTransform: 'uppercase'
    },
    rowSelected: {
        // '&&::before': {
        //     backgroundColor: 'red',
        //     content: '""',
        //     display: 'block',
        //     height: '100%',
        //     position: 'absolute',
        //     top: 0,
        //     width: 2
        // }
        //borderLeft: `2px solid ${theme.palette.secondary.light}`
        boxShadow: `3px 0px 0px 0px ${theme.palette.secondary.light} inset`,
        backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
    },
    footer: {
        backgroundColor: 'white',
        position: 'sticky',
        bottom: 0,
        zIndex: 10
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    activity: {
        color: '#4CB091',
        position: 'sticky',
        top: '50%',
        left: '50%',
        marginTop: -24, //-theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4,
        zIndex: 9999
    }
}))

function DataTable({ 
    context, 
    columns, 
    rows,
	defaultSort = {},
	selected = [], 
    onRowClick, 
    onRowDoubleClick, 
    onSelectionChange,
    onContextMenu,
    onPaginationChange, 
    onSortingChange,
    isLoading, 
    inProgress, 
    isOpen, 
    allowSelection = true,
    compactMode = false,
    pageNumber,
    pageSize,
    remoteQuery = false, 
    totalCount = 0
}) {
    const classes = useStyles()
    const columnClasses = useColumnStyles()
    const [order, setOrder] = useState(defaultSort.direction || 'asc')
    const [orderBy, setOrderBy] = useState(defaultSort.property || '')
    const [selection, setSelection] = useState(selected)
    const [page, setPage] = useState((pageNumber && pageNumber > 0) ? pageNumber - 1 : 0)
    const [rowsPerPage, setRowsPerPage] = useState(pageSize || ROWS_PER_PAGE)
    const [total, setTotal] = useState(0)
    const scrollIntoRef = useRef(null)

    useEffect(() => {
        if (rows.length > 0 && selection.length > 0) {
            let newSelected = []

            for(let i = 0, ln = selection.length; i < ln; i++) {
                const item = rows.find(item => item.id === selection[i].id)

                if (item) {
                    newSelected.push(item)
                }
            }

            if (newSelected.length !== selection.length) {
                setSelection([])
                if (onSelectionChange) onSelectionChange([])
            }
            else {
                setSelection(newSelected)
                if (onSelectionChange) onSelectionChange(newSelected)
            }

            scrollIntoRef.current && scrollIntoRef.current.scrollIntoView({ behavior: "instant", block: "center", inline: "nearest" });
        }
    }, [JSON.stringify(rows)])

    useEffect(() => {
        const newPageNumber = (pageNumber && pageNumber > 0) ? pageNumber - 1 : 0

        if (newPageNumber !== page) {
            setPage(newPageNumber)
        } 
    }, [pageNumber])

    useEffect(() => {
        if (remoteQuery) {
            if (totalCount > 0) setTotal(totalCount)
        }
        else {
            setTotal(rows.length)
        }
    }, [remoteQuery, totalCount, rows.length])
  
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc'
        const newOrder = isDesc ? 'asc' : 'desc'

        setOrder(newOrder)
        setOrderBy(property)
        if (remoteQuery && onSortingChange) onSortingChange({ property, direction: newOrder })
    }
  
    function handleSelectAll(event) {
        let newSelected = []

        if (event.target.checked) {
            // newSelected = rows.map(n => n.id)
            newSelected = [...rows]
        }
        setSelection(newSelected)
        if (onSelectionChange) onSelectionChange(newSelected)
    }
  
    function handleSelect(event, row) {
        const id = row.id
        const selectedIndex = selection.findIndex(item => item.id === id)
        let newSelected = []

        event.stopPropagation()

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selection, {...row})
        } 
        else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selection.slice(1))
        } 
        else if (selectedIndex === selection.length - 1) {
            newSelected = newSelected.concat(selection.slice(0, -1))
        } 
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selection.slice(0, selectedIndex),
                selection.slice(selectedIndex + 1),
            )
        }
        setSelection(newSelected)
        if (onSelectionChange) onSelectionChange(newSelected)
    }

    function handleRowClick(event, row) {
        if (allowSelection) {
            let newSelected = []

            event.stopPropagation()

            newSelected.push({ ...row })
            setSelection(newSelected)

            if (onSelectionChange) onSelectionChange(newSelected)
            if (onRowClick) onRowClick(row)
        }
    }

    function handleRowDoubleClick(event, row) {
        if (onRowDoubleClick) onRowDoubleClick(row)
    }

    function handleContextMenu(event, row) {
        const newSelected = [...selection]

        event.preventDefault()

        newSelected.splice(0, newSelected.length)
        newSelected.push({...row})

        setSelection(newSelected)
        if (onSelectionChange) onSelectionChange(newSelected)
        if (onContextMenu) onContextMenu(event)
    }
  
    function handleChangePage(event, newPage) {
        setPage(newPage)
        if (remoteQuery && onPaginationChange) onPaginationChange({ page: newPage + 1, limit: rowsPerPage })
    }
  
    function handleChangeRowsPerPage(event) {
        const newValue = +event.target.value

        setRowsPerPage(newValue)
        setPage(0)
        if (remoteQuery && onPaginationChange) onPaginationChange({ page: 1, limit: newValue })
    }

    const isSelected = id => (selection.findIndex(item => item.id === id) !== -1)
    const isEmpty = (rows && rows.length === 0)
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

    return (
        <div className={classes.root}>
            {/* <DataTableToolbar numSelected={selection.length} /> */}
            {(isLoading || (inProgress && !isOpen)) && (<ActivityMask/>)}
            <div className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                >
                    <DataTableHead
                        classes={classes}
                        columns={columns}
                        numSelected={selection.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAll}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        allowSelection={allowSelection}
                    />
                    {!isEmpty ? (
                        <TableBody className={classes.tableBody}>
                            {pageItems(
                                //stableSort(rows, getSorting(order, orderBy)), 
                                pageSort(rows, 
                                    !remoteQuery && { property: orderBy, direction: order }
                                ),
                                !remoteQuery && { begin: page * rowsPerPage, end: page * rowsPerPage + rowsPerPage }
                            )
                            .map((row, rowIndex) => {
                                const isItemSelected = isSelected(row.id)
                                const labelId = `table-checkbox-${rowIndex}`    
            
                                return (
                                    <TableRow
                                        //className={(rowSelection && rowSelection.id === row.id) ? classes.rowSelected : ''}
                                        className={(selection && selection.findIndex(item => item.id === row.id) !== -1) ? classes.rowSelected : ''}
                                        hover
                                        onContextMenu={onContextMenu && (event => handleContextMenu(event, row))}
                                        onClick={event => handleRowClick(event, row)}
                                        onDoubleClick={event => handleRowDoubleClick(event, row)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={rowIndex}
                                        selected={isItemSelected}
                                        ref={isItemSelected ? scrollIntoRef : null}
                                    >
                                        {allowSelection && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    onChange={event => handleSelect(event, row)}
                                                    onClick={event => handleSelect(event, row)}
                                                />
                                            </TableCell>
                                        )}
                                        {columns.map((column, columnIndex) => {
                                            const Column = column.type && ColumnType[column.type]

                                            return (
                                                <TableCell
                                                    style={{ cursor: 'pointer' }}
                                                    align={(column.numeric) ? "right" : "left"}
                                                    key={columnIndex}
                                                >
                                                    {
                                                        (Column) ? (<Column row={row} {...column}/>) : 
                                                        (column.render) ? (column.render(column, row, Object.assign(context || {}, {
                                                            classes,
                                                            columnClasses
                                                        }))) : 
                                                        (row[column.dataKey])
                                                    }
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={columns.length + 1} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    ) : null}
                </Table>
            </div>

            {!compactMode && ((!isEmpty || isLoading) && total > 0) && (
                <TablePagination
                    className={classes.footer}
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </div>
    )
}

DataTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
}

export default DataTable