import { connect } from 'react-redux'
import { requestId, buildUrl } from 'utils'

import DataBrowserView from 'components/core/DataBrowserView'

import { fetchDataSource, fetchRecord, createRecord, updateRecord  } from 'actions/index';

const mapStateToProps = (state, props) => {
    const { resources } = props
    const id = resources['list'] ? requestId(buildUrl(
        resources['list'].path, 
        resources['list'].params)
    ) : null

    return {
        data: (state.datasources[id]) ? state.datasources[id].data : [],
        record: state.record && state.record.data
    }
}

const mapDispatchToProps = dispatch => ({
    fetchDataSource: ({ path, method, params }, data) => dispatch(fetchDataSource({ path, method, params }, data)),
    fetchRecord: ({ path, method, params }, data) => dispatch(fetchRecord({ path, method, params }, data)),     
    createRecord: ({ path, method, params }, data) => dispatch(createRecord({ path, method, params }, data)),
    updateRecord: ({ path, method, params }, data) => dispatch(updateRecord({ path, method, params }, data))
})

export const DataBrowserContainerWith = (mapStateToPropsWith, mapDispatchToPropsWith) => component => connect(
    (state, props) => Object.assign(mapStateToProps(state, props), mapStateToPropsWith(state, props)),
    (dispatch, props) => Object.assign(mapDispatchToProps(dispatch, props), mapDispatchToPropsWith(dispatch, props))
)(component)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DataBrowserView)