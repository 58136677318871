import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {  Link  } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, TextField, Button, CircularProgress } from '@material-ui/core';

import BrandPaper from 'components/BrandPaper';
import DarkTheme from 'components/themes/dark';
import green from '@material-ui/core/colors/green';
import logo from 'assets/image/logo_color.svg';

import { resetPassword } from 'actions';

const useStyles = makeStyles(theme => ({
    logo: {
        width: 290,
        paddingBottom: '20px !important' 
    },
    form: {
        flexShrink: 0,
        width: theme.spacing(41)
    },
    header: {
        textAlign: 'center',
        marginBottom: theme.spacing(4),
        '& > *': {
            padding: theme.spacing(1/2)
        }
    },
    content: {
        flex: '1 0 auto',
    },
    textField: {
        width: theme.spacing(41)
    },
    actions: {
        
    },
    inline: {
        display: 'inline-block',
        width: '100%'
    },
    buttons: {
        marginTop: theme.spacing(5)
    },
    button: {
        width: theme.spacing(19.5)
    },
    login: {
        marginLeft: theme.spacing(2)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

const mapState = state => ({
    inProgress: state.auth.inProgress
})

function ResetPassword({ history }) {
    const dispatch = useDispatch()
    const { inProgress } = useSelector(mapState)
    const classes = useStyles()
    const [state, setState] = useState({})

    const handleChange = event => {
        const { id, value } = event.target
        
        setState(prevState => ({ ...prevState, [id]: value }))
    }

    const handleSubmit = async event => {
        const { password, confirmPassword } = state

        event.preventDefault()
        try {
            const email = history.location.state.email

            const { error } = await dispatch(resetPassword(email, password));

            if (!error) history.push('/')
        }
        catch(error) {
            console.log(error)
        }
    }

    return (
        <BrandPaper>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                <div className={classes.header}>
                <img className={classes.logo} src={logo} />
                    <Typography variant="subtitle1" color="textSecondary">
                        Enter a new password to access your account.
                    </Typography>
                </div>
                <div className={classes.content}>
                    <TextField
                        InputLabelProps={InputLabelProps}
                        id="password"
                        label="New Password"
                        className={classes.textField}
                        type="password"
                        onChange={handleChange}
                        margin="normal"
                        required={true}
                    />
                    <TextField
                        InputLabelProps={InputLabelProps}
                        id="confirmPassword"
                        label="Confirm New Password"
                        className={classes.textField}
                        type="password"
                        onChange={handleChange}
                        margin="normal"
                        required={true}
                    />
                </div>
                <div className={classes.actions}>
                    <DarkTheme>
                        <div className={classes.buttons}>
                            <Button disabled={inProgress} size="small" variant="outlined" color="primary" className={classes.button} component={Link} to="/login">Cancel</Button>
                            <Button disabled={inProgress} type="submit" variant="contained" color="primary" className={`${classes.button} ${classes.login}`}>
                                Submit
                                {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>   
                        </div> 
                    </DarkTheme>
                </div>
            </form>
        </BrandPaper>
    )
}

export default ResetPassword