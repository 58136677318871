import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import * as Types from 'constants/ActionTypes'
import { defaultLanguage, requestId, buildUrl } from 'utils'

const CancelToken = axios.CancelToken

const axiosRequests = {}

const CREATE_RECORD_SUCCESS = 'A record was successfully created'

const encodeReplacer = (key, value) => {
    return (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/))
      ? moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss.SSS')
      : value
}
const decodeReplacer = (key, value) => {
    return (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}.\d{3}/))
      ? new Date(value)
      : value
}

const request = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json', 
        'Content-Language': defaultLanguage(),
        'X-API-Key': `apikey ${process.env.REACT_APP_API_KEY}`,
        'X-App-Name': 'admin'
    },
    paramsSerializer: params => {
        return qs.stringify(params, { 
            serializeDate: value => moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss.SSS'),
            arrayFormat: 'indices' 
        })
    },
    transformRequest: [data => JSON.stringify(data, encodeReplacer)],
    transformResponse: [data => JSON.parse(data, decodeReplacer)]
})

request.interceptors.response.use(response => response, error => {
    if (error.response) {
        return Promise.reject(error.response.data.errors ? { message: error.response.data.errors[0].msg } : 
           (typeof error.response.data === 'string') ? { message: error.response.data } : error.response.data) 
    } 
    else if (error.request) {
        return Promise.reject(error.request ? { message: error.request.responseText } : { message: 'Request error' })
    } 
    else {
        return Promise.reject(error)
    }
})

export const actionCreator = getState => ({ type, id, url, headers, method, data }) => {
    const { auth } = (getState) ? getState() : {}

    headers = Object.assign((auth && auth.data) ? { 'Authorization': `Bearer ${auth.data.token}` } : {}, headers || {})
    return {
        type,
        id,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url, headers, method, data
                },
                commit: {
                    type: `${type}_COMMIT`,
                    meta: { id, data }
                },
                rollback: {
                    type: `${type}_ROLLBACK`,
                    meta: { id, data }
                }
            }
        }
    }
}

const actionRequestCreator = (type, id) => ({ type, id })
export const actionSuccessCreator = (type, id, data, success = {
    message: CREATE_RECORD_SUCCESS
}) => ({
    type: `${type}_SUCCESS`, 
    id, 
    payload: data && data.result, 
    data, success,
    receivedAt: Date.now()
})
const actionFailureCreator = (type, id, error) => ({
    type: `${type}_FAILURE`, 
    id, error,
    receivedAt: Date.now()
})
const valueAccesor = getState => id => {
    const { auth } = (getState) ? getState() : {}
    return (auth && auth.data) ? auth && auth.data[id] : null
} 
const withParameter = (name, value) => params => (Object.assign(params || {}, {
    [name]: value(name)
})) 

export const actionRequest = (dispatch, getState) => ({ type, url, method, headers, ...args }) => {
    const { auth } = (getState) ? getState() : {}
    const id = requestId(url)

    dispatch(actionRequestCreator(type, id))
    // console.log('Request id', id)
    if (axiosRequests[id]) {
        axiosRequests[id].cancel()
    }

    const axiosRequest = CancelToken.source()
    axiosRequests[id] = axiosRequest

    const requestPromise = request(Object.assign({ url, method, ...args }, {
        headers: Object.assign((auth && auth.data) ? { 'Authorization': `Bearer ${auth.data.token}` } : {}, headers || {}),
        cancelToken: axiosRequest.token
    }))
    .then(response => {
        const { data } = response

        axiosRequests[id] = null
        delete axiosRequests[id]

        return data.error ? dispatch(actionFailureCreator(type, id, data.error)) : dispatch(actionSuccessCreator(type, id, data))
    }, error => {
        if (axios.isCancel(error)) {
            console.log(`Cancel request`)
        }
        else {
            axiosRequests[id] = null
            delete axiosRequests[id]

            return dispatch(actionFailureCreator(type, id,  {
                message: error.message
            }))
        }
    })

    requestPromise.cancel = axiosRequest.cancel

    return requestPromise
}

export const cleanStatus = () => dispatch => dispatch({ type: Types.CLEAN_APPLICATION_STATUS })

export const fetchSchema = name => dispatch => {
    actionRequest(dispatch)({
        type: Types.FETCH_SCHEMA,
        url: `/schema/${name}`,
        method: 'get'
    })
}

export const fetchDataSource = ({ path, method = 'get', params }, query) => (dispatch, getState) => {
    return actionRequest(dispatch, getState)({
        type: Types.FETCH_DATASOURCE,
        url: buildUrl(path, params),
        method, 
        params: query
    })
}

export const clearDataSource = ({ path, params }) => dispatch => {
    const id = requestId(buildUrl(path, params))
    return dispatch(actionSuccessCreator(Types.CLEAR_DATASOURCE, id))
}

export const fetchRecord = ({ path, method = 'get', params }, query) => (dispatch, getState) => {
    return actionRequest(dispatch, getState)({
        type: Types.FETCH_RECORD,
        url: buildUrl(path, params),
        params: query,
        method   
    })
}

export const createRecord = ({ path, method = 'post', params }, data) => (dispatch, getState) => {
    return actionRequest(dispatch, getState)({
        type: Types.CREATE_RECORD,
        url: buildUrl(path, params),
        method, data
    })
}

export const updateRecord = ({ path, method = 'put', params }, data) => (dispatch, getState) => {
    return actionRequest(dispatch, getState)({
        type: Types.UPDATE_RECORD,
        url: buildUrl(path, params),
        method, data
    })
}

export const deleteRecords = ({ path, method = 'delete', params }, data) => (dispatch, getState) => {
    return actionRequest(dispatch, getState)({
        type: Types.DELETE_RECORDS,
        url: buildUrl(path, params),
        method, data
    })
}


export * from './auth'
export * from './applications'
export * from './stateful'
export * from './resources'
export * from './users'
export * from './measures'
export * from './heights'
export * from './weights'
export * from './patients'
export * from './reports'