import React from 'react';

import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'

const columnsOverride = [{
    type: 'IconAvatarColumn',
    dataKey: 'icon',
    order: 1,
    width: 10
}, {
    type: 'DateColumn',
    dataKey: 'date',
    order: 2,
    format: 'MM/DD/YYYY hh:mm A'
}, {
    dataKey: 'by',
    order: 3
}, {
    dataKey: 'type',
    order: 4
}, {
    dataKey: 'notes',
    order: 5,
	width: 350
}, {
    type: 'TimeChipColumn',
    dataKey: 'duration',
    order: 6
}, {
    dataKey: 'durationMinutes',
    order: 7,
    hidden: true
}]

const actionsOverride = [{
    type: 'delete',
    disabled: selection => (selection.length === 0),
    confirm: true,
    order: 1
}]

const PatientInteractions = ({ actions, columns, resources, ...others }) => {
    const { actionsState, columnsState, dataState, totalCountState, recordState, handleRefresh, handleFetchRecord, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions,
        columns,
        resources
    })

    return (
        <DataBrowser
			//statefulId="patient-interactions"
            actions={actionsState}
            columns={columnsState}
            //entityIdName="alertId"
            searchFieldName={['notes', 'by']}
            defaultSort={{ property: 'date', direction: 'desc' }}
            rows={dataState}
            totalCount={totalCountState}
            record={recordState}
            onRefresh={handleRefresh}
            onFetchRecord={handleFetchRecord}
            onAction={handleAction}
            isLoading={isLoading}
            inProgress={inProgress}
            remoteQuery={true}
            //SideComponent={PatientAlertDetails}
            {...others}
        />
    )
}

export default PatientInteractions