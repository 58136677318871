import React, { Fragment, useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Icon } from 'styles';
import { Base } from 'components/themes/main';
import { IconButton, Popover, Card, CardContent, CardActions, Button, TextField } from '@material-ui/core';

import { I18nManagerContext } from 'contexts/I18nManager'

const useStyles = makeStyles(theme => ({

    input: {
        display: 'flex',
        padding: 0,
        height: 'auto'
    },

    singleValue: {
        flexGrow: 1
    },

    dropdown: {
        color: `${theme.palette.secondary.light} !important`,
        padding: '0px !important'
    },

    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        padding: `${theme.spacing(1/4)}px ${theme.spacing(0)}px`
    },

    card: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        // height: 320,
        textAlign: 'center'
    },
    cardContent: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(),
        justifyContent: 'center',
    },
    cardActions: {
        justifyContent: 'flex-end',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}))

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />
}
  
inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
}

function Control(props) {
    const {
      children,
      innerProps,
      innerRef,
      selectProps: { classes, TextFieldProps },
    } = props;
  
    return (
        <TextField
            InputProps={{
                inputComponent,
                inputProps: {
                className: classes.input,
                ref: innerRef,
                children,
                ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    )
}
  
Control.propTypes = {
    /**
     * Children to render.
     */
    children: PropTypes.node,
    /**
     * The mouse down event and the innerRef to pass down to the controller element.
     */
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
}

function IndicatorSeparator () {
    return null
}

function DropdownIndicator({ selectProps, ...others }) {
    return (
        <components.DropdownIndicator className={selectProps.classes.dropdown} selectProps {...others }>
            <ArrowDropDownIcon/>
        </components.DropdownIndicator>
    )
}

function ValueContainer({ selectProps, children }) {
    return <div className={selectProps.classes.valueContainer}>{children}</div>;
}
  
ValueContainer.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
}

function SingleValue({ children, selectProps, innerProps}) {

    const handleDelete = event => {
        event.preventDefault()
        selectProps.onDeleteOption(event)
    }

    return (
        <Fragment>
            <span className={selectProps.classes.singleValue} {...innerProps}>{children}</span>
            <IconButton size="small" onClick={handleDelete}>
                <Icon icon="trash" size={14} color={Base.iconLight.color}/>
            </IconButton>
        </Fragment>
    )
}
  
SingleValue.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * Props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.any/*.isRequired*/,
    selectProps: PropTypes.object.isRequired,
}

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

function I18nPopover({ inputValue, languages, onCreate, ...others }) {
    const primary = languages.find(language => language.primary)
    const primaryCode  = primary ? primary.code : 'en'
    const classes = useStyles()
    const [key, setKey] = useState(null)
    const [values, setValues] = useState({ [primaryCode]: inputValue })

    const handleKeyChange = event => {
        const { value } = event.target
        setKey(value)   
    }

    const handleChange = event => {
        const { id: code, value } = event.target
        setValues(prevState => {
            const newState = {...prevState}
            newState[code] = value
            return newState
        })
    }

    const handleCreate = event => {
        event.preventDefault()
        event.stopPropagation()
        
        onCreate(values, key)
    }

    return (
        <Popover
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...others}
        >
            <form  autoComplete="off" onSubmit={handleCreate}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <TextField
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            autoFocus
                            margin="dense" 
                            id="key" 
                            label="Key"
                            defaultValue={key}
                            fullWidth
                            onChange={handleKeyChange}
                            required={true}
                        />
                        {languages && languages.map((language, index) => (
                            <TextField
                                key={index}
                                InputLabelProps={InputLabelProps}
                                InputProps={{
                                    readOnly: language.primary
                                }}
                                autoComplete="off"
                                margin="dense" 
                                id={language.code} 
                                label={language.name}
                                defaultValue={(language.primary) ? inputValue : null} 
                                fullWidth
                                onChange={handleChange}
                                required={true}
                            />
                        ))}
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button type="submit" color="secondary" variant="contained">Create</Button>
                    </CardActions>
                </Card> 
            </form>
        </Popover>
    )    
}

I18nPopover.propTypes = {
    inputValue: PropTypes.string.isRequired,
    languages: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCreate: PropTypes.func.isRequired
}

export default function I18nField({ id, label, fullWidth, margin, autoFocus, required, isClearable = false, languages, onChange }) {
    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    }
    const createOption = defaultLanguage => ({ key, values}) => ({
        value: key,
        label: values[defaultLanguage]   
    })
    const asOptions = (locales, defaultLanguage) => locales.map(createOption(defaultLanguage))
    const classes = useStyles()
    const theme = useTheme()
    const rootEl = useRef(null)
    const i18nManager = useContext(I18nManagerContext)
    const [inputValue, setInputValue] = useState(null)
    const [selection, setSelection] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)

    const openPopover = Boolean(anchorEl)
    const isLoading = i18nManager.inProgress
    const options = asOptions(i18nManager.locales, i18nManager.defaultLanguage)

    useEffect(() => {
        if (!isLoading && selection && options.findIndex(option => option.value === selection.value) === -1) {
            handleChange(null)
        }
    }, [options])

    const handleChange = newOption => {
        setSelection(newOption)
        if (onChange) onChange({ id, value: newOption ? newOption.value : null })
    }

    const handleCreateOption = inputValue => {
        setInputValue(inputValue)
        setAnchorEl(rootEl.current)
    }

    const handleDeleteOption = () => {
        const { value: key } = selection

        if (i18nManager.onDelete instanceof Promise || i18nManager.onDelete[Symbol.toStringTag] === 'AsyncFunction') {
            i18nManager.onDelete(key)
            .then(() => {
                handleChange(null)  
            })
        }
        else {
            i18nManager.onDelete(key)
            handleChange(null)  
        }
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const handlePopoverCreate = (values, key) => {
        const newOption = createOption(i18nManager.defaultLanguage)({ key, values })
        
        setAnchorEl(null)
        setInputValue(null)
        if (i18nManager.onCreate instanceof Promise || i18nManager.onCreate[Symbol.toStringTag] === 'AsyncFunction') {
            i18nManager.onCreate(values, key)
            .then(() => {
                handleChange(newOption)  
            }) 
        }
        else {
            i18nManager.onCreate(values, key) 
            handleChange(newOption)   
        }
    }

    return (
        <div ref={rootEl}>
            <CreatableSelect
                classes={classes}
                styles={selectStyles}
                inputId={id}
                TextFieldProps={{
                    label, fullWidth, margin, autoFocus, required,
                    InputLabelProps: {
                        htmlFor: id,
                        shrink: true,
                        required: false //Hide asterix on required field
                    }
                }}
                isClearable={isClearable}
                isDisabled={isLoading}
                isLoading={isLoading}
                placeholder=""
                options={options}
                components={{
                    Control,
                    ValueContainer,
                    SingleValue,
                    IndicatorSeparator,
                    DropdownIndicator
                }}
                value={selection}
                onChange={handleChange}
                onCreateOption={handleCreateOption}
                onDeleteOption={handleDeleteOption}
            />
            {openPopover && (
                <I18nPopover
                    inputValue={inputValue}
                    languages={languages}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    onCreate={handlePopoverCreate}
                />
            )}
        </div>
    )
}