import React, { useRef } from "react";
import Filter from 'components/core/Filter'
import { TextField } from "@material-ui/core";

const TextFilter = ({ showOperator, operator, ...others }) => {
    const operators = useRef([
        { label: 'equal', value: 'eq' },
        { label: 'not equal', value: 'ne' },
        { label: 'contains', value: 'contains' },
        { label: 'starts with', value: 'startsWith' },
        { label: 'ends with', value: 'endsWith' }
    ])

    return (
        <Filter
            component={TextField}
            operator={(showOperator && operator) ? operator : "eq"} //TODO: should be contains
            operators={showOperator && operators.current}
            showOperator={showOperator}
            {...others}
        />
    )
}

export default TextFilter