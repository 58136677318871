import React from 'react';

import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'

const columnsOverride = [{
    type: 'MultimediaColumn',
    label: 'Content',
    dataKey: 'contentType',
    order: 1
}, {
    dataKey: 'title',
    hidden: true
}, {
    dataKey: 'library',
    hidden: true
}, {
    dataKey: 'subject',
    hidden: true
}, /*{
    dataKey: 'topic',
    hidden: true
}, {
    dataKey: 'subtopic',
    hidden: true
}, */{
    type: 'StatusColumn',
    size: 'small',
    dataKey: 'status',
    order: 2
}]

/*
    confirm:    [Config] Boolean/Object with title & text to show confirm modal dialog
*/
const actionsOverride = [{
    type: 'delete',
    disabled: selection => (selection.length === 0),
    confirm: true
}]

/*
    title:      [Config]
    subtitle:   [Config]
    path:       [Config]
    actions:    [Config]
    columns:    [Config]
    resources:  [Config]
    params:     ?
*/
const EducationalMaterials = ({ actions, columns, resources, params, ...others }) => {
    const { actionsState, columnsState, dataState, totalCountState, recordState, handleRefresh, handleFetchRecord, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions, 
        columns, 
        resources
    })

    return (
        <DataBrowser
			//statefulId="educational-materials"
            actions={actionsState}
            columns={columnsState}
            entityIdName="educationalMaterialId"
            searchFieldName={['title']}
            rows={dataState}
            totalCount={totalCountState}
            record={recordState}
            onRefresh={handleRefresh}
            onFetchRecord={handleFetchRecord}
            onAction={handleAction}
            isLoading={isLoading}
            inProgress={inProgress}
            remoteQuery={true}
            {...others}
        />
    )
}

export default EducationalMaterials