import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:'column'
    },
    cover: {
        display: 'flex',
        height: '50%',
        width: '100%',
        // backgroundImage: 'linear-gradient(to right top, #1d3557, #135378, #007396, #0095ae, #00b7bf)'
        backgroundColor: theme.palette.primary.main,
    },
    img: {
        // background: 'url(/images/bg.png) bottom no-repeat;',
        backgroundColor: theme.palette.primary.main,
        width: '50%',
        height: '100%'
    },
    details: {
        display: 'flex',
        height: '50%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        
        minWidth: theme.spacing(41)
    },
    form: {
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        width: 800,
        height: '80%',
        boxShadow: '0px 2px 6px 0px rgba(0,0,0,.16)'
    },
    center: {
        height: '100%',
        display: 'flex',
        minWidth: '328px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loopthy: {
        width: 20,
        height: 20,
        background: 'url(/images/icon.png) center center',
        backgroundSize: 'cover'
    },
    copyRight: {
        position: 'absolute',
        bottom: 0,
        paddingBottom: theme.spacing(3),
        display: 'inline-flex'
    },
    link: {
        color:  theme.palette.text.secondary
    }
}))

function BrandPaper({ children }) {
    const classes = useStyles()

    return  (
        <div className={classes.root}>
            <div className={classes.cover}>
                <div className={classes.img}></div>
            </div>
            <div className={classes.details}></div>
            <div className={classes.form}>
                <div className={classes.center}>
                    {children}
                    <Typography className={classes.copyRight} color="textSecondary">
                        <span className={classes.loopthy}></span>
                        <span>Horus Intellicare Solutions</span>
                        &nbsp;© {(new Date().getFullYear())}. All rights reserved.
                    </Typography>
                </div>
            </div>
        </div>    
    )
}

export default BrandPaper