import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField } from '@material-ui/core';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import SelectField from 'components/core/SelectField';
import ImageField from 'components/core/ImageField';
import PhoneField from 'components/core/PhoneField';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    flex: {
        flex: 1
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

export default function BillingInfoForm({ title = 'Address Information', record, isSubmitting, onSubmit, onError, onDirty }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = { 
        address1: ['required'],
        city: ['required'], state: ['required'],  
        zip: ['required'], country: ['required']
    }
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validations, record)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            {/* <Typography className={classes.formTitle} variant="h5" color="textSecondary" noWrap>
                {title}
            </Typography> */}
            <TextField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                autoFocus
                margin="normal" 
                id="address1" 
                label="Address 1"
                defaultValue={state && state.address1} 
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                error={!!errors['address1']}
                helperText={!!errors['address1'] && errors['address1']}
            />
            <TextField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="address2" 
                label="Address 2"
                defaultValue={state && state.address2} 
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors['address2']}
                helperText={!!errors['address2'] && errors['address2']}
            />
            <div className={classes.hbox}>
                <SelectField
                    className={clsx(classes.flex, classes.marginRight)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal"
                    id="country"
                    label="Country"
                    defaultValue={state ? (state.country || "") : ""}
                    required={true}
                    error={!!errors['country']}
                    helperText={!!errors['country'] && errors['country']}
                    resource={{ path: '/countries' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    textName="text"
                    valueName="value"
                />
                <SelectField
                    className={clsx(classes.flex, classes.marginLeft)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal"
                    id="state"
                    label="State"
                    defaultValue={state ? (state.state || "") : ""}
                    required={true}
                    error={!!errors['state']}
                    helperText={!!errors['state'] && errors['state']}
                    resource={state.country && { path: '/countries/:countryCode/states', params: { countryCode: state.country } }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    textName="text"
                    valueName="value"
                    disabled={state && state.country ? false : true}
                />
            </div>
            <div className={classes.hbox}>
                <SelectField
                    className={clsx(classes.flex, classes.marginRight)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal"
                    id="city"
                    label="City"
                    defaultValue={state ? (state.city || "") : ""}
                    required={true}
                    error={!!errors['city']}
                    helperText={!!errors['city'] && errors['city']}
                    resource={(state.country && state.state) && { path: '/countries/:countryCode/states/:stateCode/cities', params: { countryCode: state.country, stateCode: state.state } }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    textName="text"
                    valueName="value"
                    disabled={state && state.state ? false : true}
                />
                <SelectField
                    className={clsx(classes.flex, classes.marginLeft)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal"
                    id="zip"
                    label="Zip"
                    defaultValue={state ? (state.zip || "") : ""}
                    required={true}
                    error={!!errors['zip']}
                    helperText={!!errors['zip'] && errors['zip']}
                    resource={(state.country && state.state && state.city) && { path: '/countries/:countryCode/states/:stateCode/cities/:cityCode/zipcodes', params: { countryCode: state.country, stateCode: state.state, cityCode: state.city } }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    textName="text"
                    valueName="value"
                    disabled={state && state.city ? false : true}
                />
            </div>
            <div className={classes.hbox}>
                <PhoneField
                    className={clsx(classes.flex, classes.marginRight)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal" 
                    id="phone" 
                    label="Phone"
                    defaultValue={state && state.phone} 
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors['phone']}
                    helperText={!!errors['phone'] && errors['phone']}
                />
                <PhoneField
                    className={clsx(classes.flex, classes.marginLeft)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal" 
                    id="fax" 
                    label="Fax"
                    defaultValue={state && state.fax}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors['fax']}
                    helperText={!!errors['fax'] && errors['fax']}
                />
            </div>
        </form>
    )
}