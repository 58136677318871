import React from 'react';

import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'

import PatientGoalDialog from 'components/PatientGoalDialog'
import { PATIENT_GOAL_STATUS_ACTIVE, PATIENT_GOAL_STATUS_INACTIVE, USER_ADMIN_ROLE } from 'constants/index'

const columnsOverride = [{
    dataKey: 'measure',
    order: 1
}, {
    type: 'ThresholdColumn',
    size: 'small',
    dataKey: 'criticalZoneLow',
    threshhold: 'critical',
    order: 2
}, {
    type: 'ThresholdColumn',
    size: 'small',
    dataKey: 'baselineLow',
    threshhold: 'baseline',
    order: 3
}, {
    type: 'ThresholdColumn',
    size: 'small',
    dataKey: 'goal',
    threshhold: 'goal',
    order: 4
}, {
    type: 'ThresholdColumn',
    size: 'small',
    dataKey: 'baselineHigh',
    threshhold: 'baseline',
    order: 5
}, {
    type: 'ThresholdColumn',
    size: 'small',
    dataKey: 'criticalZoneHigh',
    threshhold: 'critical',
    order: 6
}, {
    dataKey: 'timeInterval',
    order: 7
}, {
    dataKey: 'standardFrequency',
    order: 9
}, {
    type: 'StatusColumn',
    size: 'small',
    dataKey: 'status',
    order: 10
}]

const actionsOverride = [{
    type: 'create',
    component: PatientGoalDialog,
	hidden: (authManager, params) => {
        if (params && params.customerId) {
            return authManager.currentCustomerRoleId !== USER_ADMIN_ROLE
        }
		return authManager.roleId !== USER_ADMIN_ROLE
	},
    order: 1
}, {
    type: 'update',
    disabled: selection => !(selection.length === 1),
	hidden: (authManager, params) => {
        if (params && params.customerId) {
            return authManager.currentCustomerRoleId !== USER_ADMIN_ROLE
        }
		return authManager.roleId !== USER_ADMIN_ROLE
	},
    component: PatientGoalDialog,
    order: 2
}, {
    type: 'activate',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 0 && (selection.findIndex(item => item.statusId === PATIENT_GOAL_STATUS_INACTIVE) == -1)))
    },
    selectionMap: ({ id }) => ({ id }),
    confirm: true,
    order: 3
}, {
    type: 'inactivate',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 0 && (selection.findIndex(item => item.statusId === PATIENT_GOAL_STATUS_ACTIVE) == -1)))
    },
    selectionMap: ({ id }) => ({ id }),
    confirm: true,
    order: 4
}]

const PatientGoals = ({ actions, columns, resources, ...others }) => {
    const { actionsState, columnsState, dataState, recordState, handleRefresh, handleFetchRecord, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions, 
        columns, 
        resources
    })

    return (
        <DataBrowser
			//statefulId="patient-goals"
            actions={actionsState}
            columns={columnsState}
            entityIdName="measureId"
            searchFieldName={['measure']}
            rows={dataState}
            record={recordState}
            onRefresh={handleRefresh}
            onFetchRecord={handleFetchRecord}
            onAction={handleAction}
            isLoading={isLoading}
            inProgress={inProgress}
            {...others}
        />
    )
}

export default PatientGoals