import React from 'react';

import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'
import { USER_ALERT_STATUS_OPENED, USER_ALERT_STATUS_EMAIL_SENT, USER_ALERT_STATUS_ESCALATED } from 'constants/index'

import PatientUpdateAlertDialog from 'components/PatientUpdateAlertDialog'

import { PatientAlertDetails } from 'screens'


const columnsOverride = [{
    type: 'AlertColumn',
    dataKey: 'category',
    order: 0,
    width: 8
}, {
    dataKey: 'ruleName',
    order: 1
}, {
    dataKey: 'frequency',
    order: 2
}, {
    type: 'TimeFromNowColumn',
    dataKey: 'alertDate',
    order: 3
}, {
    type: 'StatusColumn',
    dataKey: 'status',
    size: 'small',
    order: 4
}]

const actionsOverride = [{
    type: 'escalate',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 0 && selection.some(item => [USER_ALERT_STATUS_OPENED, USER_ALERT_STATUS_EMAIL_SENT].indexOf(item.statusId) == -1)))
    },
    component: PatientUpdateAlertDialog,
    order: 3
}, {
    type: 'close',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 0 && selection.some(item => [USER_ALERT_STATUS_OPENED, USER_ALERT_STATUS_EMAIL_SENT, USER_ALERT_STATUS_ESCALATED].indexOf(item.statusId) == -1)))
    },
    component: PatientUpdateAlertDialog,
    order: 3
}]

const PatientAlerts = ({ actions, columns, resources, ...others }) => {
    const { actionsState, columnsState, dataState, totalCountState, recordState, handleRefresh, handleFetchRecord, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions,
        columns,
        resources
    })

    return (
        <DataBrowser
			//statefulId="patient-alerts"
            actions={actionsState}
            columns={columnsState}
            entityIdName="alertId"
            searchFieldName={['ruleName']}
            defaultSort={{ property: 'alertDate', direction: 'desc' }}
            rows={dataState}
            totalCount={totalCountState}
            record={recordState}
            onRefresh={handleRefresh}
            onFetchRecord={handleFetchRecord}
            onAction={handleAction}
            isLoading={isLoading}
            inProgress={inProgress}
            remoteQuery={true}
            SideComponent={PatientAlertDetails}
            {...others}
        />
    )
}

export default PatientAlerts