import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { emphasize, withStyles, makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Chip } from '@material-ui/core';

import { matchPath } from 'react-router';
import { Switch, Link } from 'react-router-dom';
import { createRequestId, buildUrl } from 'utils'
import AuthRoute from 'components/AuthRoute';

import { I18nManagerContext } from 'contexts/I18nManager';

const ChipBreadcrumb = withStyles(theme => ({
    root: {
		cursor: 'pointer',
        backgroundColor: theme.palette.grey[300],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip)

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'stretch',
        flexDirection: 'column'
    },
    breadcrumb: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(3, 5, 0, 5)
    },
}))

const mapState = ({ pathname, routes }) => state => {
    const index = routes.findIndex(route => {
        const match = matchPath(pathname, { path: route.to })
        return (match && match.isExact)
    })
    const route = routes[index]

    if (route) {
        const { params } = matchPath(pathname, { path: route.to })
        const { resources, title } = route

        if (resources && resources['read'] && (typeof title === 'function')) {
            const id = createRequestId(Object.assign({ params }, resources['read']))
            return {
                record: state.record && state.record[id] && state.record[id].data,
                route, index, params
            }
        }
    }
    return { route, index }
}

const StackNavigation = ({ history, routes }) => {
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const { record, route, index, params } = useSelector(mapState({ pathname: history.location.pathname, routes }))
    const deps = useMemo(() => JSON.stringify([route.to, record, params]), [route.to, record, params])
    const [breadcrumbs, setBreadcrumbs] = useState([])
    // const [indexState, setIndexState] = useState(index)
    const [indexState, setIndexState] = useState(-1)

    useEffect(() => {
        if (route/* && record && params*/) {
        // if (route && record && params) {
            setBreadcrumbs(prevState => {
                let newState = [...prevState]

                if (index >= indexState) {
                    const url = buildUrl(route.to, params)

                    if (index === indexState) {
                        let breadcrumb = newState.find(breadcrumb => breadcrumb.route.to === route.to)

                        if (breadcrumb) {
                            Object.assign(breadcrumb, { record, route, url})
                        }
                    }
                    else {
                        newState.push({ record, route, url })
                    }
                }
                else if (index < indexState) {
                    newState.splice(index + 1, (indexState - index))
                }
                return newState
            })
            setIndexState(index)
        }
    }, [deps])

    return (
        <div className={classes.root}>
            {(breadcrumbs.length > 1) && (
                <div className={classes.breadcrumb}>
                    <Breadcrumbs aria-label="breadcrumb">
                        {breadcrumbs.slice(0, breadcrumbs.length - 1).map(({ record, route, url }, index) => (
                            <ChipBreadcrumb
                                key={index}
                                component={Link}
                                label={typeof route.title === 'function' ? route.title(record) : i18nManager.t(route.title)}
                                to={url}
                            />
                        ))}
                    </Breadcrumbs>
                </div>
            )}
            <Switch>
                {routes && routes.map(({ to, component, ...others }, index) => {
                    return (component) ? 
                        <AuthRoute exact 
                            key={index} 
                            path={to} 
                            component={component}
                            {...others}
                        /> : null
                })}
            </Switch>
        </div>
    )
}

export default StackNavigation
