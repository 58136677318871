import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Paper, Typography } from '@material-ui/core';

import DataBrowser from 'components/core/DataBrowser'
import PatientUpdateAlertDialog from 'components/PatientUpdateAlertDialog'

import useDataBrowser from 'hooks/useDataBrowse'
import CustomAvatar from 'components/core/CustomAvatar'

import { AuthManagerContext } from 'contexts/AuthManager'
import { PatientAlerts, PatientAlertDetails } from 'screens'
import { USER_ALERT_STATUS_OPENED, USER_ALERT_STATUS_EMAIL_SENT, USER_ALERT_STATUS_ESCALATED } from 'constants/index'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80vw',
        maxWidth: '100%', //400
        height: '80vh'
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        padding: '24px 44px 0px'
    },
    avatar: {
        marginTop: 2,
        marginLeft: 10
    },
    root: {
        flexGrow: 1,

        boxShadow: 'none',
        border: '1px solid #E2E2E3',
        borderRadius: 2,

        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    tableContainer: {
        display: 'flex',
        // padding: 20,
        flexGrow: 1,
        alignItems: 'stretch',
        paddingTop: 32,
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    tableRoot: {
        flexGrow: 1,
        overflowY: 'scroll',
        boxShadow: 'none',
        border: '1px solid #E2E2E3',
        borderRadius: 2,
    },
    tableContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        flexGrow: 1,
        paddingTop: 0
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0)
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4)
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        justifyContent: 'stretch',
        padding: 0
    },
    title: {
        display: 'flex',
        flexGrow: 1
    },
    patient: {
        display: 'flex',
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
        display: 'block',
        paddingLeft: theme.spacing(1 / 4)
        // },
    }
}))

const columnsOverride = [
    {
        type: 'AlertColumn',
        dataKey: 'category',
        order: 0,
        width: 8
    }, {
        dataKey: 'ruleName',
        order: 1
    }, {
        dataKey: 'frequency',
        order: 2
    }, {
        type: 'TimeFromNowColumn',
        dataKey: 'alertDate',
        order: 3
    }, {
        type: 'StatusColumn',
        dataKey: 'status',
        size: 'small',
        order: 4
    }]

const actionsOverride = [{
    type: 'escalate',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 0 && selection.some(item => [USER_ALERT_STATUS_OPENED, USER_ALERT_STATUS_EMAIL_SENT].indexOf(item.statusId) == -1)))
    },
    component: PatientUpdateAlertDialog,
    order: 3
}, {
    type: 'close',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 0 && selection.some(item => [USER_ALERT_STATUS_OPENED, USER_ALERT_STATUS_EMAIL_SENT, USER_ALERT_STATUS_ESCALATED].indexOf(item.statusId) == -1)))
    },
    component: PatientUpdateAlertDialog,
    order: 3
}]

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function PatientAlertsDialog({ open, record, onClose, actions, columns, resources, ...others }) {
    const authManager = useContext(AuthManagerContext)
    const classes = useStyles()
    const params = useParams()
    const entityId = record && record.id
    const mergedResources = { ...resources }
    const [dirtyState, setDirtyState] = useState(false)

    Object.keys(mergedResources).forEach(name => Object.assign(mergedResources[name], { params: Object.assign({ customerId: authManager.customerId, patientId: entityId }, params) }))
    const { actionsState, columnsState, dataState, totalCountState, recordState, handleRefresh, handleFetchRecord, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions,
        columns,
        resources: mergedResources
    })

    const handleCustomAction = (action, data, actionParams) => {
        setDirtyState(true)
        return handleAction(action, data, actionParams)
    }

    const handleClose = () => {
        if (onClose) onClose(dirtyState)
    }

    return (
        <Dialog
            classes={{
                paper: classes.dialogPaper
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            keepMounted
        >
            <div className={classes.dialogTitle}>
                <Typography className={classes.title} variant="h6" color="secondary" noWrap>
                    Alerts
                </Typography>
                <Typography className={classes.patient} variant="h6" color="secondary" noWrap>
                    {record && record.fullName}
                    <CustomAvatar image={record && record.image} name={record && record.fullName} className={classes.avatar}></CustomAvatar>
                </Typography>
            </div>
            <DialogContent className={classes.content}>
                <div className={classes.tableContainer}>
                    <div className={classes.tableContent}>
                        <Paper className={classes.tableRoot}>
                            <DataBrowser
								statefulId="patient-alert-dialog"
                                title={''}
                                actions={actionsState}
                                columns={columnsState} 
                                entityIdName="alertId"
                                searchFieldName={['ruleName']}
                                defaultFilter={{
                                    statusId: {
                                        chipLabel: "Opened, Email Sent, Escalated",
                                        dataType: "integer",
                                        name: "statusId",
                                        operator: "in",
                                        text: "Opened",
                                        value: [70, 72, 74]
                                    }
                                }}
                                defaultSort={{ property: 'alertDate', direction: 'desc' }}
                                rows={dataState}
                                totalCount={totalCountState}
                                record={recordState}
                                onRefresh={handleRefresh}
                                onFetchRecord={handleFetchRecord}
                                onAction={handleCustomAction}
                                isLoading={isLoading}
                                inProgress={inProgress}
                                tabMode={true}
                                remoteQuery={true}
                                SideComponent={PatientAlertDetails}
                                {...others}
                            />
                        </Paper>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default PatientAlertsDialog