export const CLEAN_APPLICATION_STATUS  = 'CLEAN_APPLICATION_STATUS'

export const REQUEST_LOGIN  = 'REQUEST_LOGIN'
export const REQUEST_LOGIN_SUCCESS  = 'REQUEST_LOGIN_SUCCESS'
export const REQUEST_LOGIN_FAILURE  = 'REQUEST_LOGIN_FAILURE'

export const REQUEST_EMAIL_VERIFY  = 'REQUEST_EMAIL_VERIFY'
export const REQUEST_EMAIL_VERIFY_SUCCESS  = 'REQUEST_EMAIL_VERIFY_SUCCESS'
export const REQUEST_EMAIL_VERIFY_FAILURE  = 'REQUEST_EMAIL_VERIFY_FAILURE'

export const REQUEST_RESET_PWD  = 'REQUEST_RESET_PWD'
export const REQUEST_RESET_PWD_SUCCESS  = 'REQUEST_RESET_PWD_SUCCESS'
export const REQUEST_RESET_PWD_FAILURE  = 'REQUEST_RESET_PWD_FAILURE'

export const REQUEST_FORGOT_PWD  = 'REQUEST_FORGOT_PWD'
export const REQUEST_FORGOT_PWD_SUCCESS  = 'REQUEST_FORGOT_PWD_SUCCESS'
export const REQUEST_FORGOT_PWD_FAILURE  = 'REQUEST_FORGOT_PWD_FAILURE'

export const REQUEST_VIDEO_TOKEN  = 'REQUEST_VIDEO_TOKEN'
export const REQUEST_VIDEO_TOKEN_SUCCESS  = 'REQUEST_VIDEO_TOKEN_SUCCESS'
export const REQUEST_VIDEO_TOKEN_FAILURE  = 'REQUEST_VIDEO_TOKEN_FAILURE'

export const FETCH_BODY_HEIGHT_UNITS  = 'FETCH_BODY_HEIGHT_UNITS'
export const FETCH_BODY_HEIGHT_UNITS_SUCCESS  = 'FETCH_BODY_HEIGHT_UNITS_SUCCESS'
export const FETCH_BODY_HEIGHT_UNITS_FAILURE  = 'FETCH_BODY_HEIGHT_UNITS_FAILURE'

export const FETCH_BODY_WEIGHT_UNITS  = 'FETCH_BODY_WEIGHT_UNITS'
export const FETCH_BODY_WEIGHT_UNITS_SUCCESS  = 'FETCH_BODY_WEIGHT_UNITS_SUCCESS'
export const FETCH_BODY_WEIGHT_UNITS_FAILURE  = 'FETCH_BODY_WEIGHT_UNITS_FAILURE'

export const FETCH_SCHEMA  = 'FETCH_SCHEMA'
export const FETCH_SCHEMA_SUCCESS  = 'FETCH_SCHEMA_SUCCESS'
export const FETCH_SCHEMA_FAILURE  = 'FETCH_SCHEMA_FAILURE'

export const FETCH_DATASOURCE  = 'FETCH_DATASOURCE'
export const FETCH_DATASOURCE_SUCCESS  = 'FETCH_DATASOURCE_SUCCESS'
export const FETCH_DATASOURCE_FAILURE  = 'FETCH_DATASOURCE_FAILURE'

export const CLEAR_DATASOURCE = 'CLEAR_DATASOURCE'
export const CLEAR_DATASOURCE_SUCCESS = 'CLEAR_DATASOURCE_SUCCESS'

export const CLEAR_RECORD = 'CLEAR_RECORD'

export const FETCH_RECORD  = 'FETCH_RECORD'
export const FETCH_RECORD_SUCCESS  = 'FETCH_RECORD_SUCCESS'
export const FETCH_RECORD_FAILURE  = 'FETCH_RECORD_FAILURE'

export const CREATE_RECORD  = 'CREATE_RECORD'
export const CREATE_RECORD_SUCCESS  = 'CREATE_RECORD_SUCCESS'
export const CREATE_RECORD_FAILURE  = 'CREATE_RECORD_FAILURE'

export const UPDATE_RECORD  = 'UPDATE_RECORD'
export const UPDATE_RECORD_SUCCESS  = 'UPDATE_RECORD_SUCCESS'
export const UPDATE_RECORD_FAILURE  = 'UPDATE_RECORD_FAILURE'

export const DELETE_RECORD  = 'DELETE_RECORD'
export const DELETE_RECORD_SUCCESS  = 'DELETE_RECORD_SUCCESS'
export const DELETE_RECORD_FAILURE  = 'DELETE_RECORD_FAILURE'

export const DELETE_RECORDS  = 'DELETE_RECORDS'
export const DELETE_RECORDS_SUCCESS  = 'DELETE_RECORDS_SUCCESS'
export const DELETE_RECORDS_FAILURE  = 'DELETE_RECORDS_FAILURE'

export const FETCH_APPLICATION  = 'FETCH_APPLICATION'
export const FETCH_APPLICATION_SUCCESS  = 'FETCH_APPLICATION_SUCCESS'
export const FETCH_APPLICATION_FAILURE  = 'FETCH_APPLICATION_FAILURE'

export const CREATE_APPLICATION_TABLEVIEW  = 'CREATE_APPLICATION_TABLEVIEW'
export const CREATE_APPLICATION_TABLEVIEW_SUCCESS  = 'CREATE_APPLICATION_TABLEVIEW_SUCCESS'
export const CREATE_APPLICATION_TABLEVIEW_FAILURE  = 'CREATE_APPLICATION_TABLEVIEW_FAILURE'

export const FETCH_APPLICATION_LOCALES  = 'FETCH_APPLICATION_LOCALES'
export const FETCH_APPLICATION_LOCALES_SUCCESS  = 'FETCH_APPLICATION_LOCALES_SUCCESS'
export const FETCH_APPLICATION_LOCALES_FAILURE  = 'FETCH_APPLICATION_LOCALES_FAILURE'
export const CREATE_APPLICATION_LOCALE  = 'CREATE_APPLICATION_LOCALE'
export const CREATE_APPLICATION_LOCALE_SUCCESS  = 'CREATE_APPLICATION_LOCALE_SUCCESS'
export const CREATE_APPLICATION_LOCALE_FAILURE  = 'CREATE_APPLICATION_LOCALE_FAILURE'
export const DELETE_APPLICATION_LOCALE  = 'DELETE_APPLICATION_LOCALE'
export const DELETE_APPLICATION_LOCALE_SUCCESS  = 'DELETE_APPLICATION_LOCALE_SUCCESS'
export const DELETE_APPLICATION_LOCALE_FAILURE  = 'DELETE_APPLICATION_LOCALE_FAILURE'

export const FETCH_APPLICATION_CHARTS  = 'FETCH_APPLICATION_CHARTS'
export const FETCH_APPLICATION_CHARTS_SUCCESS  = 'FETCH_APPLICATION_CHARTS_SUCCESS'
export const FETCH_APPLICATION_CHARTS_FAILURE  = 'FETCH_APPLICATION_CHARTS_FAILURE'
export const CREATE_APPLICATION_CHART  = 'CREATE_APPLICATION_CHART'
export const CREATE_APPLICATION_CHART_SUCCESS  = 'CREATE_APPLICATION_CHART_SUCCESS'
export const CREATE_APPLICATION_CHART_FAILURE  = 'CREATE_APPLICATION_CHART_FAILURE'
export const DELETE_APPLICATION_CHART  = 'DELETE_APPLICATION_CHART'
export const DELETE_APPLICATION_CHART_SUCCESS  = 'DELETE_APPLICATION_CHART_SUCCESS'
export const DELETE_APPLICATION_CHART_FAILURE  = 'DELETE_APPLICATION_CHART_FAILURE'

export const FETCH_RESOURCES  = 'FETCH_RESOURCES'
export const FETCH_RESOURCES_SUCCESS  = 'FETCH_RESOURCES_SUCCESS'
export const FETCH_RESOURCES_FAILURE  = 'FETCH_RESOURCES_FAILURE'

export const FETCH_USERS  = 'FETCH_USERS'
export const FETCH_USERS_COMMIT  = 'FETCH_USERS_COMMIT'
export const FETCH_USERS_ROLLBACK  = 'FETCH_USERS_ROLLBACK'

export const FETCH_USER  = 'FETCH_USER'
export const FETCH_USER_COMMIT  = 'FETCH_USER_COMMIT'
export const FETCH_USER_ROLLBACK  = 'FETCH_USER_ROLLBACK'

export const CREATE_USER  = 'CREATE_USER'
export const CREATE_USER_COMMIT  = 'CREATE_USER_COMMIT'
export const CREATE_USER_ROLLBACK  = 'CREATE_USER_ROLLBACK'

export const UPDATE_USER  = 'UPDATE_USER'
export const UPDATE_USER_COMMIT  = 'UPDATE_USER_COMMIT'
export const UPDATE_USER_ROLLBACK  = 'UPDATE_USER_ROLLBACK'

export const DELETE_USER  = 'DELETE_USER'
export const DELETE_USER_COMMIT  = 'DELETE_USER_COMMIT'
export const DELETE_USER_ROLLBACK  = 'DELETE_USER_ROLLBACK'

export const FETCH_MEASURES_DATA = 'FETCH_MEASURES_DATA'
export const FETCH_MEASURES_DATA_SUCCESS = 'FETCH_MEASURES_DATA_SUCCESS'
export const FETCH_MEASURES_DATA_FAILURE = 'FETCH_MEASURES_DATA_FAILURE'

export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
export const PUSH_NOTIFICATION_SUCCESS  = 'PUSH_NOTIFICATION_SUCCESS'
export const PUSH_NOTIFICATION_FAILURE  = 'PUSH_NOTIFICATION_FAILURE'

export const FETCH_ORDER_ITEMS_DATA = 'FETCH_ORDER_ITEMS_DATA'
export const FETCH_ORDER_ITEMS_DATA_SUCCESS = 'FETCH_ORDER_ITEMS_DATA_SUCCESS'
export const FETCH_ORDER_ITEMS_DATA_FAILURE = 'FETCH_ORDER_ITEMS_DATA_FAILURE'

export const FETCH_REPORT_FORM_PARAMS  = 'FETCH_REPORT_FORM_PARAMS'
export const FETCH_REPORT_FORM_PARAMS_SUCCESS  = 'FETCH_REPORT_FORM_PARAMS_SUCCESS'
export const FETCH_REPORT_FORM_PARAMS_FAILURE  = 'FETCH_REPORT_FORM_PARAMS_FAILURE'

export const UPDATE_STATEFUL = 'UPDATE_STATEFUL'