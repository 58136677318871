import React, { useEffect, Fragment } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getTimeStartDay, getTimeEndDay } from 'helpers'
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import {  formatFloatHours, dateFormat } from 'helpers'

am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    height: '100%',
    width: '100%'
  },
  tableWrapper: {
      flex: 1,
  },
  mask: {
    position: "absolute",
    backgroundColor: 'rgba(255,255,255,.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 9999
  },
  activity: {
      color: '#4CB091',
      position: 'relative',
      top: '50%',
      left: '50%',
      marginTop: -theme.spacing(1) - 4,
      marginLeft: -theme.spacing(1) - 4,
      zIndex: 9999
  }
}))

const Formatter = {
  formatFloatHours
}

const ActivityMask = () => {
  const classes = useStyles()

  return (
    <Fragment>
      <div className={classes.mask}></div>
      <CircularProgress size={24} className={classes.activity} />
      </Fragment>
  )
}

export default function Chart({ dateRange, day, setDay, startDate, endDate, setDateRange, showThreshold, data, goals, inProgress, chartsDef }) {
  const classes = useStyles()
  
  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);

    // Export
    chart.exporting.menu = new am4core.ExportMenu();

    // Increase contrast by taking evey second color
    chart.colors.step = 2;

    // setIsLoading(false)
    chart.data = data

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.dateFormats.setKey("minute", "hh:mm a")
    dateAxis.dateFormats.setKey("hour", "hh:mm a")
    dateAxis.periodChangeDateFormats.setKey("minute", "hh:mm a"); 
    dateAxis.periodChangeDateFormats.setKey("hour", "hh:mm a"); 

    if (dateRange == 'day') {
      // Chart title
      let title = chart.titles.create();
      title.text = dateFormat(startDate, 'MMM D, YYYY');
      
      dateAxis.min = (startDate).getTime()
      dateAxis.max = (endDate).getTime()

      dateAxis.baseInterval = {
        "timeUnit": "minute",
        "count": 5
      };
      dateAxis.tooltipDateFormat = "hh:mm a, d MMMM";
    
      dateAxis.keepSelection = true
      chart.exporting.dateFormat = "yyyy-M-d hh:mm:ss a"
    } else {
      dateAxis.renderer.minGridDistance = 50
    }

    function createThreshold(axis, series, threshold) {

      // chart.events.on("beforevalidated", function(ev) {
      //   const minValue = chart.series.values[0].dataItem.values.valueY.low
      //   const minThreshold = threshold['criticalZoneLow']

      //   const maxValue = chart.series.values[0].dataItem.values.valueY.high
      //   const maxThreshold = threshold['criticalZoneHigh']

      //   axis.min =  minThreshold < minValue ? minThreshold - 10 : minValue - 10
      //   axis.max = maxThreshold > maxValue ? maxThreshold + 10 : maxValue + 10
      // });
      // axis.min = threshold['criticalZoneLow'] - 20;
      axis.min = 0;
      axis.max = threshold['criticalZoneHigh'] + 20;

      // Critical High 
      let seriesCriticalHighRange = axis.createSeriesRange(series);
      seriesCriticalHighRange.value = threshold['criticalZoneHigh'];
      seriesCriticalHighRange.endValue = 1000000;
      seriesCriticalHighRange.contents.stroke = am4core.color("#EF7373");
      seriesCriticalHighRange.contents.fill = seriesCriticalHighRange.contents.stroke;

      let axisCriticalHighRange = axis.axisRanges.create();
      axisCriticalHighRange.value = threshold['criticalZoneHigh']
      axisCriticalHighRange.grid.stroke = am4core.color("#EF7373");
      axisCriticalHighRange.grid.strokeWidth = 2;
      axisCriticalHighRange.grid.strokeOpacity = 1;
      axisCriticalHighRange.label.inside = true;
      axisCriticalHighRange.label.text = 'Critical High: '.concat(threshold['criticalZoneHigh'], ' ', threshold['unitMeasure'])
      axisCriticalHighRange.label.fill = axisCriticalHighRange.grid.stroke;
      axisCriticalHighRange.label.verticalCenter = "bottom";

      // Baseline High 
      let seriesBaselineHighRange = axis.createSeriesRange(series);
      seriesBaselineHighRange.value = threshold['baselineHigh']
      seriesBaselineHighRange.endValue = threshold['criticalZoneHigh']
      seriesBaselineHighRange.contents.stroke = am4core.color("#FCA861");
      seriesBaselineHighRange.contents.fill = seriesBaselineHighRange.contents.stroke;

      let axisBaselineHighRange = axis.axisRanges.create();
      axisBaselineHighRange.value = threshold['baselineHigh'];
      axisBaselineHighRange.grid.stroke = am4core.color("#FCA861");
      axisBaselineHighRange.grid.strokeWidth = 2;
      axisBaselineHighRange.grid.strokeOpacity = 1;
      axisBaselineHighRange.label.inside = true;
      axisBaselineHighRange.label.text = 'Baseline High: '.concat(threshold['baselineHigh'], ' ', threshold['unitMeasure'])  
      axisBaselineHighRange.label.fill = axisBaselineHighRange.grid.stroke;
      axisBaselineHighRange.label.verticalCenter = "bottom";

      // Goal 
      let seriesGoalRange = axis.createSeriesRange(series);
      seriesGoalRange.value = threshold['baselineLow'];
      seriesGoalRange.endValue = threshold['baselineHigh'];
      seriesGoalRange.contents.stroke = am4core.color("#4CB091");
      seriesGoalRange.contents.fill = seriesGoalRange.contents.stroke;

      let axisGoalRange = axis.axisRanges.create();
      axisGoalRange.value = threshold['goal'];
      axisGoalRange.grid.stroke = am4core.color("#4CB091");
      axisGoalRange.grid.strokeWidth = 2;
      axisGoalRange.grid.strokeOpacity = 1;
      axisGoalRange.label.inside = true;
      axisGoalRange.label.text = 'Goal: '.concat(threshold['goal'], ' ', threshold['unitMeasure'])  
      axisGoalRange.label.fill = axisGoalRange.grid.stroke;
      axisGoalRange.label.verticalCenter = "bottom";

      // Baseline Low 
      let seriesBaselineLowRange = axis.createSeriesRange(series);
      seriesBaselineLowRange.value = threshold['criticalZoneLow'];
      seriesBaselineLowRange.endValue = threshold['baselineLow'];
      seriesBaselineLowRange.contents.stroke = am4core.color("#FCA861");
      seriesBaselineLowRange.contents.fill = seriesBaselineLowRange.contents.stroke;

      let axisBaselineLowRange = axis.axisRanges.create();
      axisBaselineLowRange.value = threshold['baselineLow'];
      axisBaselineLowRange.grid.stroke = am4core.color("#FCA861");
      axisBaselineLowRange.grid.strokeWidth = 2;
      axisBaselineLowRange.grid.strokeOpacity = 1;
      axisBaselineLowRange.label.inside = true;
      axisBaselineLowRange.label.text = 'Baseline Low: '.concat(threshold['baselineLow'], ' ', threshold['unitMeasure'])  
      axisBaselineLowRange.label.fill = axisBaselineLowRange.grid.stroke;
      axisBaselineLowRange.label.verticalCenter = "top";

      // Critical Low 
      let seriesCriticalLowRange = axis.createSeriesRange(series);
      // seriesCriticalLowRange.value = 0;
      seriesCriticalLowRange.endValue = threshold['criticalZoneLow']
      seriesCriticalLowRange.contents.stroke = am4core.color("#EF7373");
      seriesCriticalLowRange.contents.fill = seriesCriticalLowRange.contents.stroke;

      let axisCriticalLowRange = axis.axisRanges.create();
      axisCriticalLowRange.value = threshold['criticalZoneLow']
      axisCriticalLowRange.grid.stroke = am4core.color("#EF7373");
      axisCriticalLowRange.grid.strokeWidth = 2;
      axisCriticalLowRange.grid.strokeOpacity = 1;
      axisCriticalLowRange.label.inside = true;
      axisCriticalLowRange.label.text = 'Critical Low: '.concat(threshold['criticalZoneLow'], ' ', threshold['unitMeasure'])  
      axisCriticalLowRange.label.fill = axisCriticalLowRange.grid.stroke;
      axisCriticalLowRange.label.verticalCenter = "top";
    }

    // Create Axis and Series
    function createAxisAndSeries(chartDef, index) {
      let seriesDef = chartDef.series
      let axisDef = chartDef.axis
      const detailSeriesDef = chartDef.detailSeries
      const detailAxisDef = chartDef.detailAxis

      seriesDef = (dateRange == 'day' && detailSeriesDef) ? detailSeriesDef : seriesDef
      axisDef = (dateRange == 'day' && detailAxisDef) ? detailAxisDef : axisDef

      let valueAxis = chart.yAxes.push(new am4charts[axisDef.type]());
      valueAxis.cursorTooltipEnabled = false;

      switch (axisDef.type) {
        case 'DurationAxis':
          valueAxis.baseUnit = "hour"
          valueAxis.durationFormatter.durationFormat = "h 'h' m 'm'"
          break
        case 'ValueAxis': 
          valueAxis.min = axisDef.min 
          valueAxis.max = axisDef.max 
          valueAxis.maxPrecision = axisDef.maxPrecision
          valueAxis.strictMinMax = axisDef.strictMinMax

          if (axisDef.renderTextLabel) {
            valueAxis.renderer.labels.template.adapter.add("text", function(text, target) {
              return (text == 0 || text == 4) ? '' : (text == 1 ? 'Deep' : (text == 2 ? 'Light' : 'Awake'))
            });
          }

          if (axisDef.dateAxis) {
            dateAxis.baseInterval = {
              "timeUnit": axisDef.dateAxis.baseInterval.timeUnit,
              "count": axisDef.dateAxis.baseInterval.count
            }
          }
          
          break
        default:
          break
      }

      let series = []
      let goal = goals && goals.find(goal => goal.measureId == chartDef.id)

      if (seriesDef) {
        seriesDef.forEach((serieDef) => {
          createSerie(serieDef)
        }) 
      } else {
        createSerie(chartDef)
      }

      // Create Serie
      function createSerie(serieDef) {
        const type =  serieDef.type //(dateRange == 'day') ? serieDef.detail.type : serieDef.type

        series = chart.series.push(new am4charts[type]());
        series.dataFields.valueY = serieDef.measure;
        series.dataFields.dateX = "date";
        series.strokeWidth = 2
        series.stroke = am4core.color(serieDef.color)
        series.yAxis = valueAxis
        series.name = serieDef.name;
        series.connect = true; // allow holes in data
        series.tensionX = serieDef.tension || 1;
        series.fill = series.stroke;
        series.fillOpacity = serieDef.opacity || 0;
        series.clustered = false

        valueAxis.renderer.line.stroke = serieDef.colorAxis || series.stroke
        valueAxis.renderer.labels.template.fill = serieDef.colorAxis || series.stroke

        function onDrillDown(event) {
          const dataContext = event.target.dataItem.dataContext

          setDay(dataContext.date)
          setDateRange('day')
        }

        if (serieDef.bullet) {
          let bullet = series.bullets.create(am4charts.CircleBullet);
          bullet.tooltipText = "{name}: [bold]{valueY} ".concat(goal && goal['unitMeasure'] != serieDef.field ? goal['unitMeasure'] : '').concat('[/]')
          bullet.circle.strokeWidth = 2
          bullet.circle.radius = 6

          let bullethover = bullet.states.create("hover");
          bullethover.properties.scale = 1.3;

          function paintBullet(color, target) {
            if (!target.dataItem.values.valueY) {
              return color;
            }
            
            var values = target.dataItem.values;
      
            return values.valueY.value >= goal['criticalZoneHigh']
              ? am4core.color("#EF7373")
              : values.valueY.value >= goal['baselineHigh'] 
              ? am4core.color("#FCA861")
              : values.valueY.value <= goal['criticalZoneLow'] 
              ? am4core.color("#EF7373")
              : values.valueY.value <= goal['baselineLow'] 
              ? am4core.color("#FCA861")
              : am4core.color("#4CB091");
          }

          bullet.adapter.add("fill", goal ? paintBullet : () => {})  
          bullet.adapter.add("stroke", showThreshold ? paintBullet : () => {})

          // On bullet hit
          bullet.events.on("hit", onDrillDown)
        }

        switch (type) {
          case 'ColumnSeries':
            series.stacked = serieDef.stacked
            series.fillOpacity = 1

            valueAxis.renderer.minGridDistance = 30;

            let columnTemplate = series.columns.template;
            columnTemplate.strokeOpacity = 1;

            // On column hit
            columnTemplate.events.on("hit", onDrillDown);

            series.tooltipText = "{name}: [bold]{valueY} ".concat(goal && goal['unitMeasure'] != serieDef.field ? goal['unitMeasure'] : '').concat('[/]')

            if (serieDef.tooltip) {
              series.tooltipText = serieDef.tooltip.format ? "{name}: [bold]{valueY.formatDuration(\"h 'h' m 'm'\")}".concat('[/]') : "{name}[/]"
              // series.bullets.clear()
            }
            
            break;
          default:
            break;
        }
      }
      
      valueAxis.title.text = goal && goal['unitMeasure']
      valueAxis.renderer.line.strokeOpacity = 1
      valueAxis.renderer.line.strokeWidth = 2
      valueAxis.renderer.opposite = index > 0 ? true : false
      valueAxis.renderer.grid.template.disabled = (chartsDef.length > 1)

      if (showThreshold) {
        createThreshold(valueAxis, series, goal)
      }
    }

    chartsDef.forEach((chartDef, index) => {
      createAxisAndSeries(chartDef, index)
    })
    
    // Add legend
    chart.legend = new am4charts.Legend()
    // chart.legend.useDefaultMarker = true   

    // Add Scrollbar
    let scrollbarX = new am4core.Scrollbar()
    chart.scrollbarX = scrollbarX

    let scrollbarY = new am4core.Scrollbar()
    chart.scrollbarY = scrollbarY

    chart.events.on("beforedatavalidated", function(ev) {
      chart.data.sort(function(a, b) {
        return (new Date(a.date)) - (new Date(b.date));
      });
    });


    // chart.scrollbarX = new am4core.Scrollbar();
    // chart.scrollbarX.startGrip.disabled = true;
    // chart.scrollbarX.endGrip.disabled = true;

    // chart.scrollbarY = new am4core.Scrollbar();
    // chart.scrollbarY.startGrip.disabled = true;
    // chart.scrollbarY.endGrip.disabled = true;

    // var indicator;
    // function showIndicator() {
    //   if (indicator) {
    //     indicator = chart.tooltipContainer.createChild(am4core.Container);
    //     indicator.background.fill = am4core.color("#fff");
    //     indicator.background.fillOpacity = 0.8;
    //     indicator.width = am4core.percent(100);
    //     indicator.height = am4core.percent(100);

    //     var indicatorLabel = indicator.createChild(am4core.Label);
    //     indicatorLabel.text = "Loading...";
    //     indicatorLabel.align = "center";
    //     indicatorLabel.valign = "middle";
    //     indicatorLabel.fontSize = 20;

    //     indicator.show();
    //   }
    //   else {
    //     indicator = chart.tooltipContainer.createChild(am4core.Container);
    //     indicator.background.fill = am4core.color("#fff");
    //     indicator.background.fillOpacity = 0.8;
    //     indicator.width = am4core.percent(100);
    //     indicator.height = am4core.percent(100);

    //     var indicatorLabel = indicator.createChild(am4core.Label);
    //     indicatorLabel.text = "Loading...";
    //     indicatorLabel.align = "center";
    //     indicatorLabel.valign = "middle";
    //     indicatorLabel.fontSize = 20;
    //   }
    // }

    // function hideIndicator() {
    //   indicator.hide();
    // }

    // chart.events.on("beforedatavalidated", function(ev) {
    //   // check if there's data
    //   if (ev.target.data.length == 0) {
    //     showIndicator();
    //   }
    //   else if (indicator) {
    //     hideIndicator();
    //   }
    // });

    // Add cursor
    chart.cursor = new am4charts.XYCursor()
    chart.cursor.lineY.disabled = true
    chart.cursor.lineX.disabled = true
    chart.cursor.behavior = /*(dateRange == 'day') ? 'none' :*/ 'zoomXY'
    
    return () => {
      chart.data = []
      chart.dispose()
    }
    
  }, [data])

  return (
    <div className={classes.root}>
      {(!data || inProgress) && (<ActivityMask/>)}
      <div className={classes.tableWrapper} id="chartdiv" style={{ width: "100%", height: "100%" }}></div>
    </div>
  )
}