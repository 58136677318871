import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Slide, Tooltip } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import { I18nManagerContext } from 'contexts/I18nManager';
import FormRenderer from 'components/core/FormRenderer'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 350
    },
    title: {
        display: 'block',
        padding: 0
    },
    subtitle: {
        display: 'block',
        paddingLeft: theme.spacing(1 / 4),
        textWrap: 'wrap'
    },
    content: {
        display: 'flex'
    },
    buttons: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: theme.spacing(19.5),
        marginLeft: theme.spacing(1) / 2,
        marginRight: theme.spacing(1) / 2,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function DynamicDialog({ id, className, open, title = '', subtitle = '', paramsSelector, multiEntry, submitOnEnter, resetOnSubmit, helpInfo, record, onSubmit, onCancel, onStateChange, children }) {
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const [isSubmitting, setSubmitting] = useState(false)
    const forms = useSelector(state => state.application.data.forms)
    const dynamicForm = forms.find(form => form.id === id.toString())
    const fields = dynamicForm.fields
    const [state, setState] = useState((typeof record === 'object') ? record : {})

    let params = useParams()
    params = paramsSelector && Object.assign(params || {}, paramsSelector(record) || {})

    const handleCancel = () => {
        onCancel()
    }

    const handleError = () => {
        setSubmitting(false)
    }

    const handleDone = () => {
        setSubmitting(true)
    }

    const handleSubmit = (newState, ref) => {
        onSubmit(newState, ref)
            .then(() => {
                if (resetOnSubmit) {
                    setSubmitting(false)
                    ref.current && ref.current.reset()
                }
            })
    }

    const onKeyDown = event => {
        if (submitOnEnter && event.keyCode === 13) {
            handleDone()
        }
    }

    useEffect(() => {
        if (record) {
            setState(prevState => Object.assign({ ...record }, { ...prevState }))
        }
    }, [record])

    return (
        <Dialog
            classes={{
                paper: className || classes.dialogPaper
            }}
            open={open}
            onClose={onCancel}
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            keepMounted
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="form-dialog-title">
                {i18nManager.t(title) || title}
                <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                    {i18nManager.t(subtitle) || subtitle}
                </Typography>
                {helpInfo && helpInfo.length > 0 && (
                    <Tooltip placement="right" title={<div dangerouslySetInnerHTML={{ __html: helpInfo }}></div>}>
                        <HelpOutlineOutlinedIcon style={{ position: 'absolute', top: 24, right: 25 }} />
                    </Tooltip>
                )}
            </DialogTitle>
            <DialogContent className={classes.content}>
                <FormRenderer
                    defaults={{
                        autoComplete: "off",
                        margin: "normal",
                        fullWidth: true
                    }}
                    fields={fields}
                    record={state}
                    params={params}
                    resetOnSubmit={resetOnSubmit}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    onError={handleError}
                    onStateChange={onStateChange}
                    onKeyDown={onKeyDown}
                />
                {children}
            </DialogContent>
            <DialogActions className={classes.buttons}>
                <Button variant="contained" className={classes.button} onClick={handleCancel}>{multiEntry ? 'Finish' : 'Cancel'}</Button>
                <Button variant="contained" className={classes.button} onClick={handleDone} color="secondary">Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

DynamicDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default DynamicDialog