
import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, InputAdornment, Card, Button, CardContent, CardActionArea, CircularProgress } from '@material-ui/core';

import useForm from 'hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';

import { createRequestId } from 'utils'
import { fetchRecord } from 'actions/index';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    flex: {
        flex: 1
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        display: 'block',
        paddingLeft: theme.spacing(1/4)
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    },
    preview: {
		width: '100%',
		marginTop: 16
	},
	card: {
		marginTop: theme.spacing(1),
		minWidth: 275,
		minHeight: 276,
		height: 'inherit',
		overflow: 'scroll'
    },
    cardContent: {
        paddingBottom: '16px !important'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const shippingResource = { 
    path: '/shipping-carriers/:carrier/tracking-number', 
    method: 'get' 
}

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

const mapState = ({ resource }) => state => {
    const id = createRequestId(resource)
    const shippingRecord = state.record && state.record[id] && state.record[id].data
    const isLoading = state.record && state.record[id] && state.record[id].isLoading

    return {
        shippingRecord, isLoading
    }
}

export default function TrackingInfoForm({ title = 'Tracking Information', record, isSubmitting, onSubmit, onError, onDirty }) {
    const classes = useStyles()
    const ref = useRef()
    const dispatch = useDispatch()
    const validations = record.carrierId == '99' ? {} : { 
         trackingNumber: ['required'],
         cost: ['required'],
         label: ['required']
    }
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validations, record)
    const { shippingRecord, isLoading } =  useSelector(mapState({ resource: Object.assign(shippingResource, { params: { carrier: record.carrierName }} )}))


    function callback() {
        if (shippingRecord) {
            const { label, trackingNumber, cost, labelFormat } = shippingRecord
            Object.assign(state, { label, trackingNumber, cost, labelFormat }) 
        }

        if (onSubmit) onSubmit(state)
    }

    const handleOpenLabel = () => {
        const win = window.open();
        win.document.write('<iframe src="data:image/gif;base64,' + shippingRecord.label  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }

    const onGetTrackingInfo = () => {
        async function fetchData() {
            const { customerId, patientId, packageLength, packageWidth, packageHeight, packageWeight, address1, address2, city, zip, country, phone } = record
			const cityState = record.state

            const { data: { result }, error } = await dispatch(fetchRecord(shippingResource, { customerId, patientId, packageLength, packageWidth, packageHeight, packageWeight, address1, address2, city, state: cityState, zip, country, phone }))

            if (!error && result) {
                const { label, trackingNumber, cost, labelFormat } = result
            	Object.assign(state, { label, trackingNumber, cost, labelFormat })
            }
        }
  
        record && (typeof record === 'object') && fetchData();
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <div style={{ marginTop: 16, display: 'flex', justifyContents: 'center'}}>
                <Button 
                    size="small" 
                    color='primary' 
                    className={classes.smallButton} 
                    disabled={state && state.carrierId == '99' || !state}
                    onClick={onGetTrackingInfo}>
                    Get Tracking Information
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
            </div>
            <TextField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="trackingNumber" 
                label="Tracking Number"
                value={shippingRecord && shippingRecord.trackingNumber || ''} 
                fullWidth
                readOnly={true}
                disabled={true}
            />
            <TextField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="cost" 
                label="Cost"
                value={shippingRecord && shippingRecord.cost || 0} 
                fullWidth
                readOnly={true}
                disabled={true}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>
                }}
            />
            <div className={classes.preview}>
                <Typography variant="caption" color="textSecondary" noWrap>
                    Label
                </Typography>
                <Card className={classes.card} variant="outlined">
                    {shippingRecord && shippingRecord.label && (
                        <CardActionArea onClick={handleOpenLabel}>
                            <CardContent className={classes.cardContent}>
                            <img
                                src={`data:image/png;base64,${shippingRecord.label}`}
                                alt={shippingRecord.trackingNumber}
                                width='100%'
                                loading="lazy"
                            />
                            </CardContent>
                        </CardActionArea>
                    )}
                </Card>
            </div>
        </form>
    )
}