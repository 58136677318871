import { combineReducers } from 'redux'

import auth from './auth'
import application from './application'
import stateful from './stateful'
import locales from './locales'
import heights from './heights'
import weights from './weights'
import charts from './charts'
import chart from './chart'
import datasources from './datasources'
import record from './record'
import resources from './resources'
import users from './users'
import notification from './notification'
import status from './status'
import orderItems from './orderItems'

// export default combineReducers({
//     application,
//     resources,
//     users
// })

const createReducer = asyncReducers =>
  combineReducers({
        auth,
		stateful,
        application,
        locales,
        heights,
        weights,
        charts,
        chart,
        datasources,
        record,
        resources,
        users,
        notification,
        status,
        orderItems,
        // When reducers are provided to createReducer they'll be plopped on here
        ...asyncReducers
  })

export default createReducer