import * as Types from 'constants/ActionTypes';

const application = (state = {
    inProgress: false,
    didInvalidate: false,
    data: {},
}, action) => {
    switch (action.type) {
        case Types.FETCH_APPLICATION:
        case Types.FETCH_REPORT_FORM_PARAMS:
        case Types.CREATE_APPLICATION_TABLEVIEW:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            })
        case Types.FETCH_APPLICATION_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.payload,
                lastUpdated: action.receivedAt
            })
        case Types.FETCH_REPORT_FORM_PARAMS_SUCCESS:
            if (!(state.data.forms.some(element => element.id == action.payload.id))) {
                state.data.forms.push(action.payload)
            }
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: {...state.data},
                lastUpdated: action.receivedAt
            })
        case Types.CREATE_APPLICATION_TABLEVIEW_SUCCESS:
            state.data.views.push(action.payload)
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: {...state.data},
                lastUpdated: action.receivedAt
            })
        case Types.FETCH_APPLICATION_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            })
        default:
            return state
    }
};

export default application