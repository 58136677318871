import React, { useMemo } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const CheckboxField = ({ dataType, label, name, checked, defaultValue, onChange, ...others }) => {

    const handleChange = event => {
        const { id, checked: value } = event.target
        onChange && onChange({
            target: {
                id, value
            }
        })
    };


    return (
        <FormControlLabel
            control={
                <Checkbox
                    id={name}
                    defaultChecked={defaultValue}
                    onChange={handleChange}
                />
            }
            label={label}
        />
    )
}

export default CheckboxField