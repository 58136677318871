import React, { useRef, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
        display: 'block',
        paddingLeft: theme.spacing(1 / 4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function PatientUpdateAlertForm({ record, isSubmitting, onSubmit, onError }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = {}
    const [state, errors, handleChange, handleBlur, handleSubmit] = useForm(callback, validations, record)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <TextField
                multiline
                rows={4}
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                autoFocus
                margin="normal"
                id="comment"
                label="Comment"
                defaultValue={state && state.comment}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                error={!!errors['comment']}
                helperText={!!errors['comment'] && errors['comment']}
            />
        </form>
    )
}