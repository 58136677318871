import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import SearchField from 'components/core/SearchField';
import PhoneField from 'components/core/PhoneField';

const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function PatientPCPForm({ title = 'Primary Care Provider', record, isSubmitting, onSubmit, onError, onDirty }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = {}
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validations, record)
    const npiRules = {
        onChange: event => {
            const { selection } = event.target

            handleChange({ target: { id: 'pcpId', value: selection && selection.id } })
            handleChange({ target: { id: 'pcpNpi', value: selection && selection.npi } })
            handleChange({ target: { id: 'pcpName', value: selection && selection.name } })
            handleChange({ target: { id: 'pcpAddress', value: selection && selection.billingAddress1 } })
        }
    }

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])

    const handleSearchChange = name => event => {
        const { selection } = event.target
        const value = selection && selection[name]

        event.target.value = value

        npiRules['onChange'](event)
    }

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <SearchField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                autoFocus
                margin="normal"
                id="npi"
                label="Search Health Care Provider" // TODO: i18n
                defaultOption={state ? { id: state.pcpId, npi: state.pcpNpi, name: state.pcpName, billingAddress1: state.pcpAddress } : null}
                fullWidth
                required={true}
                error={!!errors['npi']}
                helperText={!!errors['npi'] && errors['npi']}
                resource={{
                    path: '/npi-practitioners',
                    method: 'get'
                }}
                textName="name"
                descriptionName="billingAddress1"
                valueName="id"
                onChange={handleSearchChange('npi')}
                onBlur={handleBlur}
                params={{ zip: state.zip }}
            />
            <TextField
                InputLabelProps={InputLabelProps}
                InputProps={{ readOnly: true }}
                autoComplete="off"
                margin="normal"
                id="pcpNpi"
                label="NPI"
                value={(state && state.pcpNpi) ? state.pcpNpi : ""}
                fullWidth
                required={true}
                error={!!errors['pcpNpi']}
                helperText={!!errors['pcpNpi'] && errors['pcpNpi']}
                disabled={true}
            />
            <TextField
                InputLabelProps={InputLabelProps}
                InputProps={{ readOnly: true }}
                autoComplete="off"
                margin="normal"
                id="pcpAddress"
                label="Address"
                value={(state && state.pcpAddress) ? state.pcpAddress : ""}
                fullWidth
                required={true}
                error={!!errors['pcpAddress']}
                helperText={!!errors['pcpAddress'] && errors['pcpAddress']}
                disabled={true}
            />
            <TextField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="pcpEmail"
                label="Email"     // TODO: 
                defaultValue={state && state.pcpEmail}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors['pcpEmail']}
                helperText={!!errors['pcpEmail'] && errors['pcpEmail']}
            />
            <PhoneField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="pcpMobilePhone"
                label="Mobile Phone"  // TODO: 
                defaultValue={(state && state.pcpMobilePhone)}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors['pcpMobilePhone']}
                helperText={!!errors['pcpMobilePhone'] && errors['pcpMobilePhone']}
            />
        </form>
    )
}