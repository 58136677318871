import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from 'components/core/Filter';
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";

import { createRequestId } from 'utils';
import { fetchDataSource } from 'actions/index';

const useStyles = makeStyles(theme => ({
    checkbox: {
        display: 'flex',
        flexWrap: 'wrap',
        // alignSelf: 'center'
    }
}))

const CheckboxFilter = ({ showOperator, operator, resource, params = {}, onChange, multiple, value, /*, name,*/ ...others }) => {
    debugger
    const classes = useStyles()
    const checked = value == 1 ? true : false

    const handleChange = event => {
        debugger
        const { id, checked: value } = event.target

        onChange && onChange({
			target: {
				id, value
			}
		})
    }

    return (
        <Filter
            className={classes.checkbox}
            component={Checkbox}
            operator={(showOperator && operator) ? operator : (multiple ? "in" : "eq")}
            //onChange={handleChange}
            checked={checked}

           // checked={checkedState}
           // value={value}
           // name={name}
            {...others}
        >
        </Filter>
    )
}

export default CheckboxFilter