import React from 'react';
import { Button } from '@material-ui/core';
import NumberFormat from 'react-number-format';

export default function PhoneLink({ href, className, ...other }) {
    return (
        href && (<Button className={className} href={'tel:' + href} color='primary' {...other}>
                    <NumberFormat value={href} displayType={'text'} format="(###) ###-####" />
                </Button>)
    )
}