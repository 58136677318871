import * as Types from 'constants/ActionTypes';

const notification = (state = {
    inProgress: false,
    didInvalidate: false,
    data: null,
}, action) => {
    switch (action.type) {
        case Types.PUSH_NOTIFICATION_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: {...action.payload},
                lastUpdated: action.receivedAt
            })
        default:
            return state
    }
};

export default notification