import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { PatientOrderHistory, PatientOrderContent } from 'screens'

const DRAWER_WIDTH = 300//'40%'

const useStyles = makeStyles(theme => ({
    details: {
        display: 'contents',
        alignItems: 'start',
        flexDirection: 'row',
        width: DRAWER_WIDTH,
        marginLeft: theme.spacing(2),
        maxWidth: 300,
        minWidth: 300, 
        minHeight: 800,
        overflowY: 'scroll'
    }
}))

let widgetsOverride = [{
    name: 'PatientOrders',
    title : "orderContent.title",
    component: PatientOrderContent,
}, {
    name: 'PatientOrders',
    title : "orderHistory.title",
    component: PatientOrderHistory,
}]

const getWidgets = (widgets, views) => {
    return widgets.map(widget => {
        const view = (views || []).find(view => view.name === widget.name)
        if (view) {
            widget = Object.assign({...view}, widget)   
        }
        
        return widget
    })
}

const PatientOrderDetails = ({ record }) => {
    const views = useSelector(state => state.application.data.views)
    const [widgets] = useState(getWidgets(widgetsOverride, views))
    const classes = useStyles()

    return (
        <div className={classes.details}>
            {widgets && widgets.map(({ component: Component, ...others }, index) => {
                return (
                    <Component
                        key={index}
                        record={record}
                        {...others}
                    />
                )
            })}
        </div>
    )
}

export default PatientOrderDetails