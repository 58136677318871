import * as Types from 'constants/ActionTypes';

const resources = (state = {
    inProgress: false,
    didInvalidate: false,
    data: null,
}, action) => {
    switch (action.type) {
        case Types.FETCH_RESOURCES:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            })
        case Types.FETCH_RESOURCES_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.payload,
                lastUpdated: action.receivedAt
            })
        case Types.FETCH_RESOURCES_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            })
        default:
            return state
    }
};

export default resources