import React, { useRef, useEffect, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import useForm from 'hooks/useForm';

import SelectField from 'components/core/SelectField';
import PhoneField from 'components/core/PhoneField';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginRight: 5
    },
    vSeparator: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flex: {
        flex: 1
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    formSubtitle: {
        width: '100%',
        textAlign: 'center'
    },
    textField: {
        width: theme.spacing(41)
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function PatientContactForm({ title = 'Contact Information', record, isSubmitting, onSubmit, onError, onDirty }) {
    const classes = useStyles()
	const ref = useRef()
	const fieldsRef = useRef(['firstName', 'lastName', 'email', 'mobilePhone'].reduce((result, name) => {
		result[name] = createRef()
		return result
    }, {}))
  
    const validations = { firstName: ['required'], lastName: ['required'], email: ['required', 'email'], mobilePhone: ['required', 'phone'] }
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validations, record)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        if (Object.keys(errors).length > 0 && onError) {
			onError(errors)
		}
    }, [errors])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])
    
    useEffect(() => {
        if (isSubmitting === true) {
			handleSubmit()
		}
		else if (Object.keys(errors).length > 0) {
			const id = Object.keys(errors)[0]
			const fieldRef = fieldsRef.current && fieldsRef.current[id]

			if (fieldRef && fieldRef.current) {
				fieldRef.current.focus()
				fieldRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
			}
		}
    }, [isSubmitting])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            {/* <Typography className={classes.formTitle} variant="h5" color="textSecondary" noWrap>
                {title}
            </Typography> */}
            <div className={classes.hbox}>
                <div className={classes.vbox}>
                    <SelectField
						// ref={createFieldRef('prefix')}
						// ref={fieldsRef.current['prefix']}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="prefix"
                        label="Prefix"
                        defaultValue={state ? (state.prefix || "") : ""}
                        fullWidth
                        error={!!errors['prefix']}
                        helperText={!!errors['prefix'] && errors['prefix']}
                        options={[{
                            text: 'Mr',
                            value: 'Mr'
                        }, {
                            text: 'Miss',
                            value: 'Miss'
                        }, {
                            text: 'Mrs',
                            value: 'Mrs'
                        }, {
                            text: 'Ms',
                            value: 'Ms'
                        }, {
                            text: 'M',
                            value: 'M'
                        }]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="value"
                    />
                    <div className={classes.hbox}>
                        <TextField
							// ref={createFieldRef('firstName')}
							ref={fieldsRef.current['firstName']}
                            className={clsx(classes.flex, classes.marginRight)}
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            margin="normal" 
                            id="firstName" 
                            label="First Name"  // TODO: 
                            defaultValue={state && state.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={true}
                            error={!!errors['firstName']}
                            helperText={!!errors['firstName'] && errors['firstName']}
                        />
                        <TextField
							// ref={createFieldRef('middelName')}
							ref={fieldsRef.current['middleName']}
                            className={clsx(classes.flex, classes.marginLeft)}
                            InputLabelProps={InputLabelProps}
                            autoComplete="off"
                            margin="normal" 
                            id="middleName" 
                            label="Middle Name"     // TODO: 
                            defaultValue={state && state.middleName} 
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors['middleName']}
                            helperText={!!errors['middleName'] && errors['middleName']}
                        />
                    </div>
                    <TextField
						// ref={createFieldRef('lastName')}
						ref={fieldsRef.current['lastName']}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="lastName" 
                        label="Last Name"  // TODO: 
                        defaultValue={state && state.lastName}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!errors['lastName']}
                        helperText={!!errors['lastName'] && errors['lastName']}
                    />
                    <TextField
						// ref={createFieldRef('suffix')}
						ref={fieldsRef.current['suffix']}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="suffix" 
                        label="Suffix"     // TODO: 
                        defaultValue={state && state.suffix} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors['suffix']}
                        helperText={!!errors['suffix'] && errors['suffix']}
                    />
                    <TextField
						// ref={createFieldRef('email')}
						ref={fieldsRef.current['email']}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="email" 
                        label="Email"     // TODO: 
                        type="email"
                        defaultValue={state && state.email} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!errors['email']}
                        helperText={!!errors['email'] && errors['email']}
                    />
                    <PhoneField
						// ref={createFieldRef('mobilePhone')}
						innerRef={fieldsRef.current['mobilePhone']}
						
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="mobilePhone" 
                        label="Mobile Phone"  // TODO:  
                        defaultValue={state && state.mobilePhone}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!errors['mobilePhone']}
                        helperText={!!errors['mobilePhone'] && errors['mobilePhone']}
                    />
                    {record.id && (
                        <SelectField
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="statusId"
                        label="Status"
                        defaultValue={state ? (state.statusId || "") : ""}
                        required={false}
                        error={!!errors['statusId']}
                        helperText={!!errors['statusId'] && errors['statusId']}
                        resource={{
                            path: '/patient-status',
                            method: 'get'
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="value"
                    />
                    )}
                </div>
            </div>
        </form>
    )
}