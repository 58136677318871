import * as Types from 'constants/ActionTypes'
import { actionRequest } from './index'
import { buildUrl } from 'utils';
import uuid from 'uuid/v4'

const resources = {
    interaction: {
        update: { path: '/customers/:customerId/patients/:patientId/interactions/:interactionId', method: 'put' }
    },
    defaultHcp: {
        get: { path: '/customers/:customerId/patients/:patientId/hcps/default', method: 'get' }
    },
	currentCustomer: {
        get: { path: '/customers/:customerId', method: 'get' }
    },
    orderItems: {
        get: { path: '/customers/:customerId/patients/:patientId/devices/not-in-order', method: 'get' }
    }
}

export const updateInteraction = (params, { duration, notes, objectives }) => (...args) => {
    const { path, method } = resources['interaction']['update']
    
    return actionRequest(...args)({
        type: Types.UPDATE_RECORD,
        url: buildUrl(path, params),
        data: { duration, notes, objectives },
        method   
    })
}

export const getDefaultHCP = (params) => (...args) => {
    const { path, method } = resources['defaultHcp']['get']
    
    return actionRequest(...args)({
        type: Types.FETCH_RECORD,
        url: buildUrl(path, params),
        method   
    })
}

export const getOrderItems = (params) => (dispatch, getState) => {
    const { path, method } = resources['orderItems']['get']

    actionRequest(dispatch, getState)({
        id: uuid(),
        type: Types.FETCH_ORDER_ITEMS_DATA,
        url: buildUrl(path, params),
        method
    })
}

export const getCurrentCustomer = (params) => (...args) => {
    const { path, method } = resources['currentCustomer']['get']
    
    return actionRequest(...args)({
        type: Types.FETCH_RECORD,
        url: buildUrl(path, params),
        method   
    })
}