import React, { Fragment, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, List, ListItem, ListItemText, Divider, CircularProgress } from '@material-ui/core';

import { AuthManagerContext } from 'contexts/AuthManager';
import { I18nManagerContext } from 'contexts/I18nManager';

import StatusChipField from 'components/core/StatusChipField';

import { fetchDataSource } from 'actions/index';
import { createRequestId } from 'utils'
import { dateFormat } from 'helpers'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflow: 'auto',
        position: 'relative',
    },
    inline: {
        display: 'inline',
    },
    divider: {
        marginLeft: 32
    },
    widget: {
        width: '100%',
        border: '1px solid #E2E2E3',
        boxShadow: 'none',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        minHeight: 140,
        width: '100%',
        // marginBottom: theme.spacing(2)
        // maxHeight: '-webkit-fill-available',
    },
    widgetHeader: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: 12,
        textTransform: 'uppercase'
    },
    widgetTitle: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: '1.3125rem'
    },
    widgetComment: {
        display: 'flex',
        fontStyle: 'italic'
    },
    widgetContent: {
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1
    },
    vboxWidgetContent: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        flexGrow: 1
    },
    widgetContentText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        marginLeft: theme.spacing(2),
        alignSelf: 'flex-start'
    },
    activity: {
        color: '#4CB091',
        position: 'relative',
        top: 55,
        left: '48%',
        marginTop: -24, //-theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4,
        zIndex: 9999
    }
}))

const ActivityMask = () => {
    const classes = useStyles()

    return (
        <CircularProgress size={24} className={classes.activity} />
    )
}

const mapStateData = ({ resource }) => state => {
    let isLoading = false
    let data = []

    if (resource) {
        const id = createRequestId(resource)

        isLoading = (state.datasources[id]) ? state.datasources[id].isLoading : false
        data = (state.datasources[id]) ? state.datasources[id].data : []
    }
    return { isLoading, data }
}

const PatientAlertHistory = ({ record, resources, title, ...others }) => {
    const authManager = useContext(AuthManagerContext)
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const params = useParams()
    const dispatch = useDispatch()
    const resource = { ...resources['list'] }
    const entityId = record && record.id
    const patientId = record && record.patientId
    const { isLoading, data } = useSelector(mapStateData({
        resource: Object.assign(resource, {
            params: Object.assign({ customerId: authManager.customerId, patientId: patientId, alertId: entityId }, params)
        })
    }))
    const deps = useMemo(() => JSON.stringify([resource, entityId, record.status]), [resource, entityId, record.status])

    useEffect(() => {
        if (resource) {
            dispatch(fetchDataSource(resource))
        }
    }, [deps])

    return (
        <Paper className={classes.widget}>
            <div className={classes.widgetHeader}>
                <Typography className={classes.widgetTitle} variant="caption" color="secondary" noWrap>{i18nManager.t(title)}</Typography>
            </div>
            {isLoading && (<ActivityMask />)}
            <List className={classes.root}>
                {data && data.map((item, index) => <Fragment key={index}>
                    <ListItem key={`item-${index}`} className={classes.listItem}>
                        <ListItemText
                            className={classes.widgetContentText}
                            primary={
                                <Typography variant="subtitle2" color="secondary" noWrap>{dateFormat(item.statusDate, 'MMM D, YYYY h:mm A')}</Typography>
                            }
                            secondary={
                                <Fragment>
                                    <Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>
                                        {item.createBy}
                                    </Typography>
                                    {item.comment && (
                                        <Typography className={clsx(classes.widgetTitle, classes.widgetComment)} variant="caption" color="textSecondary" noWrap>
                                            &quot;{item.comment}&quot;
                                        </Typography>
                                    )}
                                </Fragment>
                            }
                        />
                        <StatusChipField label={item.status} size="small" />
                    </ListItem>
                    {(index < data.length - 1) && (
                        <Divider variant="inset" component="li" className={classes.divider} />
                    )}
                </Fragment>
                )}
            </List>
        </Paper>
    )
}

export default PatientAlertHistory