import React, { Fragment, useMemo, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Tabs, Tab, Paper, Button } from '@material-ui/core';
import { Switch, Link, Redirect, useRouteMatch, useParams } from 'react-router-dom';

import { AuthManagerContext } from 'contexts/AuthManager';
import { I18nManagerContext } from 'contexts/I18nManager';
import CustomAvatar from 'components/core/CustomAvatar';

import { createRequestId, buildUrl } from 'utils'
import { fetchRecord  } from 'actions';

import { ageFormat, dateFormat } from 'helpers'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    content: {
        display:'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        flexGrow: 1
    },
    widget: {
        width: '100%',
        height: 'auto',
        border: '1px solid #E2E2E3',
        boxShadow: 'none',
        borderRadius: 2,
        marginBottom: theme.spacing(2)
    },
    widgetHeader: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: 14
    },
    widgetTitle: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: '1.3125rem'
    },
    widgetContent: {
        padding: 20,
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1  
    },
    vboxWidgetContent: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        flexGrow: 1  
    },
    widgetContentText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        marginLeft: theme.spacing(2)
    },
    bigAvatar: {
        width: 130,
        height:130
    },
    bigAvatarText: {
        fontSize: '4rem'
    },
    alignRight: {
        textAlign: 'right'
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        width: '100%',
        marginRight: 5
    }
}))

const CustomerProfile = ({ record }) => {
    const classes = useStyles()
    const i18nManager = useContext(I18nManagerContext)

    return (
        <Paper className={classes.widget}>
            <div className={classes.widgetHeader}>
                <Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>Profile</Typography> 
            </div>
            <div className={classes.widgetContent}>
                <CustomAvatar image={record.logo} name={record.name} className={classes.bigAvatar}></CustomAvatar>
                <div className={classes.widgetContentText}>
                    <Typography variant="subtitle2" color="secondary" noWrap>Horus Intellicare Solutions Inc.</Typography> 
                    <Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>11760 SW 40Th St, Suite 741</Typography> 
                    <Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>Miami, FL 33175</Typography> 
                    <Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>US</Typography> 
                    <div className={clsx(classes.widgetContentText, classes.alignRight)}>
                        <Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>Phone : (305) 205-3377</Typography> 
                        <Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>Fax : (305) 205-3377</Typography> 
                        <Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>www.horusintellicaresolutions.com</Typography> 
                    </div>
                </div>
            </div> 
        </Paper>
    )
}

export default CustomerProfile