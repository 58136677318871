import React, { useCallback, useState, useEffect, useRef, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { fetchRecord } from 'actions';
import { createRequestId } from 'utils';
import { Card, CardContent, Typography } from '@material-ui/core';
import { I18nManagerContext } from 'contexts/I18nManager';
import * as Types from 'constants/ActionTypes';
import DynamicDialog from 'components/DynamicDialog';
import ActivityMask from 'components/core/ActivityMask';

const useStyles = makeStyles(theme => ({
	dialogPaper: {
        width: '80%',
        maxWidth: 900
    },
	preview: {
		marginTop: theme.spacing(2),
		height: 361,
		width: '100%',
		paddingLeft: 15,
		marginTop: 29,
		minWidth: 556
	},
	card: {
		marginTop: theme.spacing(1),
		minWidth: 275,
		minHeight: 100,
		height: 'inherit',
		overflow: 'scroll'
	},
	contentText: {
		paddingTop: theme.spacing(1/2),
		paddingBottom: theme.spacing(1/2)
	}
}))

const contentResource = {
	path : '/notification-hcp-template-content',
	method : 'get'
}
const dataSourceId = createRequestId(contentResource)

function PatientNotificationDialog ({ record, ...props}){
	const i18nManager = useContext(I18nManagerContext)
	const classes = useStyles()
	const isLoading = useSelector(state => state.record[dataSourceId] && state.record[dataSourceId].isLoading)
    const contentTemplate = useSelector(state => state.record[dataSourceId] && state.record[dataSourceId].data)
	const dispatch = useDispatch()
	const dispatchPromise = useRef()
	const [state, setState] = useState((typeof record === 'object') ? record : {})

	useEffect(() => {
		if (contentTemplate && contentTemplate.subject) {
			setState(prevState => Object.assign({...prevState}, {
				subject: contentTemplate.subject
			}))
		}
	}, [contentTemplate])

	useEffect(() => () => {
		if (dispatchPromise.current && dispatchPromise.current.cancel) {
			dispatchPromise.current.cancel()
		}
		dispatch({
			type: Types.CLEAR_RECORD,
			id: dataSourceId
		})
	}, [])

	const handleStateChange = useCallback((newState, prevState) => {
		const { customerId, patientId, hcpId, measureId, templateId } = newState
		const { templateId: prevTemplateId, measureId: prevMeasureId } = prevState
		
		if (customerId && patientId && (templateId != prevTemplateId || measureId != prevMeasureId)) {
			dispatchPromise.current = dispatch(fetchRecord(contentResource, { customerId, patientId, hcpId, measureId, templateId }))
		}
	}, [])

	return (
		<DynamicDialog
			id='NotifyHcp'
			className={classes.dialogPaper}
			paramsSelector={({ customerId, patientId }) => ({ customerId, patientId })}
			onStateChange={handleStateChange}
			record={state}
			{...props}
		>
			{isLoading && (<ActivityMask />)}
			<div className={classes.preview}>
				<Typography variant="caption" color="textSecondary" noWrap>
					{i18nManager.t('global.preview')}
				</Typography>
				<Card className={classes.card} variant="outlined">
					{contentTemplate && (
						<CardContent>
							<Typography className={classes.contentText} variant="subtitle1" color="textPrimary">
								{contentTemplate.subject}
							</Typography>
							<div style={{ fontSize: 'smaller' }} dangerouslySetInnerHTML={{__html: contentTemplate.body}}></div>
						</CardContent>
					)}
				</Card>
			</div>
		</DynamicDialog>
	)
}

export default PatientNotificationDialog