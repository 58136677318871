import React, { Fragment, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, List, ListItem, ListItemAvatar, ListItemText, Divider, CircularProgress, Avatar, Card, CardHeader, IconButton, CardContent, Collapse, Tooltip } from '@material-ui/core';

import StatusChipField from 'components/core/StatusChipField';
import { Icon } from 'styles';
import { Base } from 'components/themes/main';

import { AuthManagerContext } from 'contexts/AuthManager';
import { I18nManagerContext } from 'contexts/I18nManager';

import { fetchDataSource } from 'actions';
import { createRequestId, colorMap, initial, capitalized } from 'utils'
import { dateFormat, secToMin, timefromNow, timeCalendar } from 'helpers'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflow: 'auto',
        position: 'relative',
    },
    inline: {
        display: 'inline',
    },
    widget: {
        width: '100%',
        border: '1px solid #E2E2E3',
        boxShadow: 'none',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        //minHeight: 70,
        width: '100%',
        // maxHeight: '-webkit-fill-available',
        marginBottom: theme.spacing(2),
        flex: 'none'
    },
    widgetHeader: {
        // borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '3px 5px 3px 20px',
        textTransform: 'uppercase'
    },
    widgetCollapsibleContent: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        overflowY: 'scroll'
    },
    widgetTitle: {
        fontSize: '0.75rem',
        fontWeight: 500,
        // lineHeight: '1.3125rem',
        fontStyle: 'normal'
    },
    widgetComment: {
        display: 'flex',
        fontStyle: 'italic'
    },
    widgetContent: {
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1
    },
    vboxWidgetContent: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        flexGrow: 1  
    },
    widgetContentText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        marginLeft: 4,
        alignSelf: 'flex-start'
    },
	listItem: {
		alignItems: 'start'
	},
    cardContent: {
        padding: '0 !important'
    },
	avatar: {
		width: 32,
		height: 32
	},
    mediumAvatar: {
        minWidth: 0
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'start',
        width: '100%'
    },
    link: {
        fontSize: 'inherit',
        paddingTop: 0,
        paddingBottom: 3,
        textTransform: 'lowercase'
    },
    activity: {
        color: '#4CB091',
        position: 'relative',
        top: 65,
        left: '48%',
        marginTop: -24, //-theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4,
        zIndex: 9999
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
	divider: {
		marginLeft: 68
	}
}))

const ActivityMask = () => {
    const classes = useStyles()

    return (
        <CircularProgress size={24} className={classes.activity} />
    )
}

const mapStateData = ({ resource }) => state => {
    let isLoading = false
    let data = []

    if (resource) {
        const id = createRequestId(resource)

        isLoading = (state.datasources[id]) ? state.datasources[id].isLoading : false
        data = (state.datasources[id]) ? state.datasources[id].data : []
    }
    return { isLoading, data } 
}

const PatientInteractionsWidget = ({ record, context, resources, title, ...others }) => {
    const authManager = useContext(AuthManagerContext)
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const params = useParams()
    const dispatch = useDispatch()
    const resource = {...resources['latest']}
    const entityId = record && record.id
    const { isLoading, data } = useSelector(mapStateData({ resource: Object.assign(resource, { 
        params: Object.assign({ customerId: authManager.customerId, patientId: entityId}, params)}) 
    }))
    const deps = useMemo(() => JSON.stringify([resource, entityId]), [resource, entityId])

    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (resource) {
            dispatch(fetchDataSource(resource))
        }
    }, [deps])

    useEffect(() => {
        if (context && context.interaction) {
            dispatch(fetchDataSource(resource))
        }
    }, [context])

    return (
        data && (data.length > 0) && (
            <Card className={classes.widget}>
                <CardHeader
                    className={classes.widgetHeader}
                    action={
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                                <Icon icon={'chevron-down'} size={16} color={Base.iconLight.color} />
                        </IconButton>
                    }
                    title={i18nManager.t(title)}
                    titleTypographyProps={{ 
                        className: classes.widgetTitle,
                        variant: "caption",
                        color: "secondary",
                        noWrap: true
                    }}
                />
                <Collapse className={classes.widgetCollapsibleContent} in={expanded} timeout="auto" unmountOnExit>
                    <CardContent className={classes.cardContent}>
                        <List className={classes.root}>
                            {data && data.map((item, index) => <Fragment key={index}>
                                <ListItem key={`item-${index}`} className={classes.listItem}>
                                    {/* <ListItemAvatar className={classes.mediumAvatar}>
                                        <Tooltip key='icon' title={item.type}>
                                            <Avatar className={classes.avatar} style={{ backgroundColor: colorMap[initial(item.icon) || 'A' ]}}>{item.icon ? (<Icon icon={item.icon} size={16} />) : (<Icon icon={"phone"} size={16} />)}</Avatar>
                                        </Tooltip>
                                    </ListItemAvatar> */}
                                    <ListItemText
                                        disableTypography={true}
                                        className={classes.widgetContentText}
                                        primary={
											<Fragment>
												<span className={classes.hbox} style={{ paddingTop: 5 }} >
													<Tooltip key='icon' title={item.type}>
														<Avatar className={classes.avatar} style={{ backgroundColor: colorMap[initial(item.icon) || 'A' ]}}>{item.icon ? (<Icon icon={item.icon} size={16} />) : (<Icon icon={"phone"} size={16} />)}</Avatar>
													</Tooltip>
													<span className={classes.vbox} style={{ paddingLeft: 16, flex: 1 }} >
														{/* <Typography variant="subtitle2" color="secondary" noWrap>{dateFormat(item.date, 'MMM D, YYYY ')}</Typography> */}
														<Typography variant="subtitle2" color="secondary" noWrap>{timeCalendar(item.date)}</Typography>
														<Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>{dateFormat(item.date, 'h:mm A')}</Typography>
													</span>
													<StatusChipField label={secToMin(item.duration)} size="small" />
												</span>
											</Fragment>
                                        }
                                        secondary={
                                            <Fragment>
												<div style={ {paddingTop: 8} } >
													<Typography className={clsx(classes.widgetTitle, classes.widgetComment)} variant="caption" color="textSecondary">
														&quot;{item.notes}&quot; 
													</Typography>
													<Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>
														by {item.by}
													</Typography>
												</div>
                                            </Fragment>
                                        }
                                    />
                                   
                                </ListItem>
                                {(index < data.length - 1) && (
                                    <Divider variant="inset" component="li" className={classes.divider} />
                                )}
                            </Fragment>
                            )}
                        </List>
                    </CardContent>
                </Collapse>
            </Card>
        )
    )
}

export default PatientInteractionsWidget