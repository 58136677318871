import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    chipElement: {
        width: 'fit-content',
        alignSelf: 'center',
        marginLeft: 5,
        opacity: .5,
        marginBottom: 2,
        maxWidth: 160
    },
    chipColumn: {
        color: 'white',
        padding: '0px 4px',
        width: 'fit-content',
        alignSelf: 'center',
        backgroundColor: 'gray',
    }
}))

export default function ChipField({ label, className, handleDelete, hasTooltip = true, hasIcon = true, ...others }) {
    const classes = useStyles()

    return (
        (hasTooltip) ?
            (<Tooltip title={label}>
                <Chip label={label}
                    color="secondary"
                    className={clsx(classes.chipElement, classes[className])}
                    onDelete={handleDelete}
                    {...others}
                />
            </Tooltip>) :
            (<Chip label={label}
                color="secondary"
                className={clsx(classes.chipColumn, classes[className])}
                onDelete={handleDelete}
                {...others}
            />)
    )
}