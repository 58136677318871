import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, TextField, ListItemText, Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';

import { requestId } from 'utils';
import { fetchDataSource, clearDataSource } from 'actions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}))

const SelectTagField = ({ 
    className,
    id,
    label,
    defaultOptions,
    textName,
    descriptionName,
    valueName,
    resource,
    params,
    onChange,
    readOnly = false
}) => {
    const classes = useStyles()
    const dataSourceId = useMemo(() => resource ? requestId(resource.path) : null, [resource])
    const [open, setOpen] = useState(false)
    const [inputMode, setInputMode] = useState('input')
    const [inputValue, setInputValue] = useState('')
    const [defaultOptionsState] = useState(defaultOptions ? [...defaultOptions] : null)
    const dispatch = useDispatch()
    const options = useSelector(state => state.datasources[dataSourceId] ? state.datasources[dataSourceId].data : (defaultOptionsState) ? defaultOptionsState : [])
    const isLoading = useSelector(state => state.datasources[dataSourceId] ? state.datasources[dataSourceId].isLoading : false)
    const [selection, setSelection] = useState([])

    useEffect(() => {
        if (defaultOptionsState) {
            setSelection(defaultOptionsState)
        }
    }, [])

    const fetchData = (searchString, top) => {
        if (resource && params) {
            return dispatch(fetchDataSource(resource, { searchString, top, ...params }))
        }
        return
    }

    const clearData = () => {
        if (resource) {
            return dispatch(clearDataSource(resource))
        }
        return    
    }

    useEffect(() => () => clearData(), [])

    useEffect(() => {
        let dispatchPromise = null
        let timer = null

        if (inputMode === 'reset' && inputValue.length >= 3) {
            timer = setTimeout(() => {
                clearTimeout(timer)
                dispatchPromise = fetchData(inputValue, 100)
            }, 500)
        }
        return () => {
            if (dispatchPromise && dispatchPromise.cancel) {
                dispatchPromise.cancel()
            }
            if (timer) clearTimeout(timer)
        }
    }, [inputValue])

    const handleInputChange = (event, newValue, reason) => {
        if (inputMode !== 'reset') {
            setInputMode('reset')
        }
        if (reason === 'clear'/* || reason === 'reset'*/) clearData()
        setInputValue(newValue)
    }

    const handleChange = (event, option) => {
        const newSelection = option || null

        setInputMode('selection')
        setSelection(newSelection)
        if (onChange) onChange({
            target: { id, value: newSelection && newSelection[valueName], selection: newSelection }
        })
    }

    return (
        <Autocomplete
            multiple
            limitTags={2}
            className={clsx(classes.root, className)}
            id={id}
            style={{ width: '100%' }}
            //size="small"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => {
                return option[valueName] === value[valueName]
            }}
            getOptionLabel={(option) => {
                return option[textName] || ""
            }}
            filterOptions={options => options}
            options={options}
            loading={isLoading}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            value={selection}
            disableClearable={readOnly}
            disableCloseOnSelect
            renderInput={props => (
                <TextField
                    {...props}
                    label={label}
                    InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {props.InputProps.endAdornment}
                            </Fragment>
                        ),
                        readOnly: readOnly
                    }}
                />
            )}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                <ListItemText primary={option[textName]} secondary={option[descriptionName]} />
                
                </React.Fragment>
            )}
            onChange={handleChange}
        />
    )
}

export default SelectTagField