import * as Types from 'constants/ActionTypes';

const auth = (state = {
    inProgress: false,
    didInvalidate: false,
    data: null,
}, action) => {
    switch (action.type) {
        case Types.REQUEST_LOGIN:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            })
        case Types.REQUEST_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: {...action.payload},
                lastUpdated: action.receivedAt
            })
        case Types.REQUEST_LOGIN_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            })
        default:
            return state
    }
};

export default auth