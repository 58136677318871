import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import SelectField from 'components/core/SelectField';
import PhoneField from 'components/core/PhoneField';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    flex: {
        flex: 1
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        display: 'block',
        paddingLeft: theme.spacing(1/4)
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

export default function PatientInfoForm({ title = 'Patient Information', record, isSubmitting, onSubmit, onError, onDirty }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = { 
        notifyTo: ['required']
    }
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validations, record)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <TextField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="fullName" 
                label="Main Patient"
                defaultValue={state && state.fullName} 
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                readOnly={true}
                error={!!errors['fullName']}
                helperText={!!errors['fullName'] && errors['fullName']}
                disabled={true}
            />
            <SelectField
                className={clsx(classes.flex, classes.marginRight)}
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                autoFocus
                margin="normal"
                id="companionPatient1"
                label="Companion Patient 1"
                defaultValue={state ? (state.companionPatient1 || "") : ""}
                required={true}
                error={!!errors['companionPatient1']}
                helperText={!!errors['companionPatient1'] && errors['companionPatient1']}
                resource={{ 
                    path: '/first-companion-patient',
                    method: 'get'
                }}
                params={state && state.customerPatientId ? { customerPatientId: state.customerPatientId } : {} }
                onChange={handleChange}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
            />
            <SelectField
                className={clsx(classes.flex, classes.marginRight)}
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="companionPatient2"
                label="Companion Patient 2"
                defaultValue={state ? (state.companionPatient1 || "") : ""}
                required={true}
                error={!!errors['companionPatient2']}
                helperText={!!errors['companionPatient2'] && errors['companionPatient2']}
                resource={{ 
                    path: '/second-companion-patient',
                    method: 'get'
                }}
                params={state && state.customerPatientId && state.companionPatient1 ? { customerPatientId: state.customerPatientId, companionId: state.companionPatient1 } : null }
                onChange={handleChange}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
            />
            <div className={classes.hbox}>
                <PhoneField
                    className={clsx(classes.flex, classes.marginRight)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal" 
                    id="phone" 
                    label="Mobile Phone"
                    defaultValue={state && state.phone} 
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors['phone']}
                    helperText={!!errors['phone'] && errors['phone']}
                    disabled={true}
                />
                <TextField
                    className={clsx(classes.flex, classes.marginLeft)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal" 
                    id="notifyTo" 
                    label="Notify To"     // TODO: 
                    type="notifyTo"
                    defaultValue={state && state.notifyTo} 
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    error={!!errors['notifyTo']}
                    helperText={!!errors['notifyTo'] && errors['notifyTo']}
                />
            </div>
        </form>
    )
}