import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import {  Link  } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, TextField, Button, CircularProgress } from '@material-ui/core';

import BrandPaper from 'components/BrandPaper';
import DarkTheme from 'components/themes/dark';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles(theme => ({
    form: {
        flexShrink: 0,
        width: theme.spacing(41)
    },
    header: {
        textAlign: 'center',
        marginBottom: theme.spacing(4),
        '& > *': {
            padding: theme.spacing(1/2)
        }
    },
    content: {
        flex: '1 0 auto',
    },
    textField: {
        width: theme.spacing(41)
    },
    actions: {
        
    },
    inline: {
        display: 'inline-block',
        width: '100%'
    },
    buttons: {
        marginTop: theme.spacing(5)
    },
    button: {
        width: theme.spacing(19.5)
    },
    login: {
        marginLeft: theme.spacing(2)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

function ForgotPasswordForm({ inProgress, onSubmit }) {
    const classes = useStyles()
    const [state, setState] = useState({})

    const handleChange = event => {
        const { id, value } = event.target
        
        setState(prevState => ({ ...prevState, [id]: value }))
    }

    const handleSubmit = event => {
        const { email } = state

        event.preventDefault()
        onSubmit(email)
    }

    return (
        <BrandPaper>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                <div className={classes.header}>
                    <Typography component="h5" variant="h4">
                        HORUS
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        Enter your email to receive the information to reset your password.
                    </Typography>
                </div>
                <div className={classes.content}>
                    <TextField
                        InputLabelProps={InputLabelProps}
                        autoFocus
                        id="email"
                        label="Email"
                        className={classes.textField}
                        type="email"
                        onChange={handleChange}
                        margin="normal"
                        required={true}
                    />
                </div>
                <div className={classes.actions}>
                    <DarkTheme>
                        <div className={classes.buttons}>
                            <Button disabled={inProgress} size="small" variant="outlined" color="primary" className={classes.button} component={Link} to="/login">Cancel</Button>
                            <Button disabled={inProgress} type="submit" variant="contained" color="primary" className={`${classes.button} ${classes.login}`}>
                                Send
                                {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>   
                        </div> 
                    </DarkTheme>
                </div>
            </form>
        </BrandPaper>
    )
}

ForgotPasswordForm.propTypes = {
    inProgress: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired
};

export default ForgotPasswordForm