import React, { useEffect } from 'react';

import DataBrowser from 'components/core/DataBrowser'

function DataBrowserView({ statefulId, title, subtitle, columns, data, resource, params, fetchDataSource }) {

    useEffect(() => {
        fetchDataSource(resource, params)
    }, [resource])

    return (
        <DataBrowser
			statefulId={statefulId}
            title={title}
            subtitle={subtitle}
            columns={columns}
            rows={data}
        />
    )
}

export default DataBrowserView