import React, { Fragment, forwardRef, useRef, useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, CircularProgress, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide  } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

import useForm from 'hooks/useForm';
import clsx from 'clsx';
import { Icon } from 'styles';

import { I18nManagerContext } from 'contexts/I18nManager';
import PatientActivateForm from 'components/PatientActivateForm'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 400
    },
    title: {
        display: 'block',
        padding: 0
    },
    subtitle: {
        display: 'block',
        paddingLeft: theme.spacing(1/4)
    },
    content: {
    },
    buttons: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: theme.spacing(19.5),
        marginLeft: theme.spacing(1)/2,
        marginRight: theme.spacing(1)/2,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function PatientActivateDialog({ open, title = 'Activate Patient', subtitle = '', record, onSubmit, onCancel }) {
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const [isSubmitting, setSubmitting] = useState(false)
    const [state, setState] = useState(record || {})
    const params = useParams()

    const handleCancel = () => {
        onCancel()
    }

    const handleError = () => {
        setSubmitting(false)   
    }

    const handleDone = () => {
        setSubmitting(true)
    }

    const handleSubmit = newState => {
        onSubmit(newState)
    }

    return (
        <Dialog 
            classes={{
                paper: classes.dialogPaper
            }}
            open={open} 
            onClose={onCancel} 
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            keepMounted
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="form-dialog-title">
                {/* <Typography className={classes.title} variant="h6" color="secondary" noWrap> */}
                    {i18nManager.t(title)}
                {/* </Typography> */}
                <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                    {i18nManager.t(subtitle)}
                </Typography>  
            </DialogTitle>
            <DialogContent className={classes.content}>
                <PatientActivateForm 
                    record={state}
                    params={params}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    onError={handleError}
                />
            </DialogContent>
            <DialogActions className={classes.buttons}>
                <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" className={classes.button} onClick={handleDone} color="secondary">Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

PatientActivateDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default PatientActivateDialog