import { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

const validators = {
    required: {
        test: value => !!value,
        message: (name, value) => `This field is required`
    },
    max: {
        test: (value, fieldValidator) => fieldValidator ? value <= fieldValidator.value : false,
        message: (name, value, fieldValidator) => `${value} is grather than ${fieldValidator.value}`
    },
    email: {
        test: value => value ? /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value) : true,
        ///^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        message: (name, value) => `This field is invalid`
    },
    phone: {
        test: value => value ? /^(\+?1)?[-.\s]?\(?(\d{3,3})[)-.\s]{0,2}(\d{3,3})[-.\s]?(\d{4,4})\s*$/i.test(value) : true,
        message: (name, value) => `This field is invalid`
    },
    url: {
        test: value => value ? /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(value) : true,
        message: (name, value) => `This field is invalid`
    },
    height: { // TODO:
        test: value => /^\d?(?:\d|1[0-1])$/gm.test(value),
        message: (name, value) => `This field is invalid`
    },
    weight: { // TODO:
        test: value => /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(value),
        message: (name, value) => `This field is invalid`
    },
    minNotes: { // TODO:
        test: value => /^^\s*(?:[\w\W]\s*){10,}$/i.test(value),
        message: (name, value) => `This field should be minimum 10 characters`
    }
}

const validate = validations => values => {
    return Object.keys(validations).reduce((errors, name) => {
        const fieldValidators = validations[name] || []
        let isValid = true

        for(let i = 0, ln = fieldValidators.length; i < ln && isValid; i++) {
            const fieldValidator = fieldValidators[i]
            const validatorId = typeof fieldValidator === 'string' ? fieldValidator : fieldValidator.type
            const validator = validators[validatorId]

            if (validator) {
                isValid = validator.test(values[name], fieldValidator)
                if (!isValid) errors[name] = validator.message(name, values[name], fieldValidator)
            }
        }
        return errors
    }, {})
}

const useForm = (callback, validations, record = {}) => {
    const [values, setValues] = useState(record)
    const [errors, setErrors] = useState({})
    const [isDirty, setIsDirty] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        setIsDirty(!isEqual(record, values))
    }, [values])

    useEffect(() => {
        // setValues({...record})
        if (record) {
			setValues(prevState => Object.assign({...record}, {...prevState}))
		}
    }, [record])

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            setIsSubmitting(false)
            callback()
        }
        else {
            setIsSubmitting(false)
        }
    }, [errors])

    const handleSubmit = event => {
        if (event) {
            event.preventDefault()
        }
        setErrors(validate(validations)(values))
        setIsSubmitting(true)
    }

    const handleChange = event => {
        const { id, name, value, extraValues = {} } = event.target

        // event.persist()
        setValues(values => ({ ...values, ...extraValues, [id || name]: value }))

		errors[id] = null
    }

    const handleBlur = event => {
        //if (/*Object.keys(errors).length > 0 && */!isSubmitting) {
            // setErrors(validate(validations)(values))
            const { id, name } = event.target
            const fieldname = [id || name]

            setErrors(prevErrors => {
                const newErrors = {...prevErrors}
                const validation = validate({
                    [fieldname]: validations[fieldname]
                })({
                    [fieldname]: values[fieldname]
                })

                if (validation[fieldname]) {
                    newErrors[fieldname] = validation[fieldname]
                }
                else {
                    delete newErrors[fieldname]
                }
                return newErrors
            })
        //}
    }

    return [values, errors, handleChange, handleBlur, handleSubmit, isDirty]
}

export default useForm