import React, { Fragment, forwardRef, useRef, useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, CircularProgress, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide  } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

import useForm from 'hooks/useForm';
import clsx from 'clsx';
import { Icon } from 'styles';

import { I18nManagerContext } from 'contexts/I18nManager';
import PatientDeviceForm from 'components/PatientDeviceForm'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 400
    },
    root: {
        flexGrow: 1,
        
        boxShadow: 'none',
        border: '1px solid #E2E2E3',
        borderRadius: 2,

        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(5) ,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0)
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4)
    },
    titleContainer: {
        flex: 1
    },
    title: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            padding: 0
        // },
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    content: {
        // flex: '1 0 auto',
        // overflowY: 'scroll',
        // flexGrow: 1
        // paddingLeft: 75,
        // paddingRight: 75

    },
    step: {
        height: '100%',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        paddingTop: 70
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45%'
    },
    textField: {
        width: theme.spacing(41)
    },
    actions: {
        
    },
    buttons: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: theme.spacing(19.5),
        marginLeft: theme.spacing(1)/2,
        marginRight: theme.spacing(1)/2,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function PatientDeviceDialog({ open, title, subtitle, record, resource, fetchRecord, inProgress, onSubmit, onCancel }) {
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const [isSubmitting, setSubmitting] = useState(false)
    const [state, setState] = useState(record || {})
    // const { params } = match
    const params = useParams()

    useEffect(() => {
        if (params && params.id) {
            fetchRecord(Object.assign(resource, { params }))
        }
    }, [resource])

    // useEffect(() => {
    //     setState({ ...record })
    // }, [record])

    const handleCancel = () => {
        onCancel()
    }

    const handleError = () => {
        setSubmitting(false)   
    }

    const handleDone = () => {
        setSubmitting(true)
    }

    const handleSubmit = newState => {
        onSubmit(newState)
    }

    return (
        <Dialog 
            classes={{
                paper: classes.dialogPaper
            }}
            open={open} 
            onClose={onCancel} 
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            keepMounted
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="form-dialog-title">
                {/* <Typography className={classes.title} variant="h6" color="secondary" noWrap> */}
                    {i18nManager.t(title)}
                {/* </Typography> */}
                <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                    {i18nManager.t(subtitle)}
                </Typography>  
            </DialogTitle>
            <DialogContent className={classes.content}>
                <PatientDeviceForm 
                    record={state}
                    params={params}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    onError={handleError}
                />
            </DialogContent>
            <DialogActions className={classes.buttons}>
                <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" className={classes.button} onClick={handleDone} color="secondary">Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

PatientDeviceDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default PatientDeviceDialog