import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateStateful } from 'actions';

const useStateful = (id, key, initialValue) => {
	const dispatch = useDispatch()
	const statefulId = id || window.location.hash
	const stateful = useSelector(state => statefulId && state.stateful.data[statefulId])
	const [state, setRawState] = useState(() => {
		const value = stateful && stateful[key]

		return value || initialValue
	})

	const setState = (value) => {
		if (statefulId && key) {
			dispatch(updateStateful(statefulId, key, value))
			setRawState(value)
		}
	}

	return [state, setState]
}

export default useStateful