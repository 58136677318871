import React, { useRef, useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, Chip, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import clsx from 'clsx';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import SelectField from 'components/core/SelectField';
import { AuthManagerContext } from 'contexts/AuthManager'

const useStyles = makeStyles(theme => ({
    flex: {
        flex: 1
    },
    container: {
        justifyContent: 'center'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    chip: {
        color: 'white',
        padding: '0px 4px',
        marginBottom: 8,
        width: 100
    },
    critical: {
        backgroundColor: '#EF7373',
        "&&&&:active": {
            backgroundColor: '#EF7373'
        },
        "&&&&:focus": {
            backgroundColor: '#EF7373'
        },
        "&&&&:hover": {
            backgroundColor: 'rgba(239, 115, 115, .5)'
        }
    },
    baseline: {
        backgroundColor: '#FCA861',
        "&&&&:active": {
            backgroundColor: '#FCA861'
        },
        "&&&&:focus": {
            backgroundColor: '#FCA861'
        },
        "&&&&:hover": {
            backgroundColor: 'rgba(252, 168, 97, .5)'
        }
    },
    goal: {
        backgroundColor: '#4CB091',
        "&&&&:active": {
            backgroundColor: '#4CB091'
        },
        "&&&&:focus": {
            backgroundColor: '#4CB091'
        },
        "&&&&:hover": {
            backgroundColor: 'rgba(76, 176, 145, .7)'
        }
    },
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function PatientGoalForm({ title = 'Pathway Information', record, params = {}, isSubmitting, onSubmit, onError }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = { measureId: ['required'], unitMeasureId: ['required'], timeIntervalId: ['required'],
                          standardFrequency: ['required'], /* criticalZoneHigh: ['required'], criticalZoneLow: ['required'], 
                          baselineHigh: ['required'], baselineLow: ['required'],*/ goal: ['required'], notify: ['required'] }
    const [state, errors, handleChange, handleBlur, handleSubmit] = useForm(callback, validations, record)
    const authManager = useContext(AuthManagerContext)
    const [unitMeasure, setUnitMeasure] = useState(state.unitMeasure)
    const [notifyState, setNotifyState] = useState(state.notify)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    const handleSelectChange = event => {
        const { selection } = event.target
        
        setUnitMeasure(selection && selection.text)
        handleChange(event)
    }

    const handleCheckboxChange = name => event => {
        const { checked } = event.target
        setNotifyState(checked)
        event.target.value = checked ? 1 : 0
        handleChange(event)
      };

    // console.log(state)

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <SelectField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="measureId"
                label="Measure"  // TODO: 
                defaultValue={state ? (state.measureId || "") : ""}
                fullWidth
                required={true}
                error={!!errors['measureId']}
                helperText={!!errors['measureId'] && errors['measureId']}
                resource={{ path: '/measures' }}
                onChange={handleChange}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
                disabled={(record && record.measureId) ? true : false }
            />
            <SelectField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="unitMeasureId"
                label="Unit Measure"  // TODO: 
                defaultValue={state ? (state.unitMeasureId || "") : ""}
                fullWidth
                required={true}
                error={!!errors['unitMeasureId']}
                helperText={!!errors['unitMeasureId'] && errors['unitMeasureId']}
                //resource={(authManager.customerId && params.patientId) && { path: '/customers/:customerId/patients/:patientId/pathways', params: { customerId: authManager.customerId, patientId: params.patientId } }}
                resource={{ path: '/unit-measures' }}
                onChange={handleSelectChange}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
            />
            <SelectField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="timeIntervalId"
                label="Time Interval"  // TODO: 
                defaultValue={state ? (state.timeIntervalId || "") : ""}
                fullWidth
                required={true}
                error={!!errors['timeIntervalId']}
                helperText={!!errors['timeIntervalId'] && errors['timeIntervalId']}
                resource={{ path: '/unit-measures?category=TimeInterval' }}
                onChange={handleChange}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
            />
            <TextField
                className={classes.flex}
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="standardFrequency" 
                label="Standard Frequency"
                defaultValue={state && state.standardFrequency} 
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors['standardFrequency']}
                helperText={!!errors['standardFrequency'] && errors['standardFrequency']}
            />
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                    <Chip label='Critical High' 
                        className={clsx(classes.chip, classes['critical'])}
                        //icon={<Icon icon='chevrons-up' size={16} />}
                    />
                </Grid>
                <Grid item style={{width: 10}}></Grid>
                <Grid item className={classes.flex}>
                    <TextField
                        type="number"
                        className={classes.flex}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="criticalZoneHigh" 
                        //label=""
                        defaultValue={state && state.criticalZoneHigh} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors['criticalZoneHigh']}
                        helperText={!!errors['criticalZoneHigh'] && errors['criticalZoneHigh']}
                        InputProps={{
                            endAdornment: unitMeasure && <InputAdornment position="end">{unitMeasure}</InputAdornment>
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                    <Chip label='High' 
                        className={clsx(classes.chip, classes['baseline'])}
                        //icon={<Icon icon='chevrons-up' size={16} />}
                    />
                </Grid>
                <Grid item style={{width: 10}}></Grid>
                <Grid item className={classes.flex}>
                    <TextField
                        type="number"
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="baselineHigh" 
                        //label=""
                        defaultValue={state && state.baselineHigh} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors['baselineHigh']}
                        helperText={!!errors['baselineHigh'] && errors['baselineHigh']}
                        InputProps={{
                            endAdornment: unitMeasure && <InputAdornment position="end">{unitMeasure}</InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end" className={classes.container} >
                <Grid item>
                    <Chip label='Goal' 
                        className={clsx(classes.chip, classes['goal'])}
                        //icon={<Icon icon='chevrons-down' size={16} />}
                    />
                </Grid>
                <Grid item style={{width: 10}}></Grid>
                <Grid item className={classes.flex}>
                    <TextField
                        type="number"
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="goal" 
                        //label=""
                        defaultValue={state && state.goal} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors['goal']}
                        helperText={!!errors['goal'] && errors['goal']}
                        InputProps={{
                            endAdornment: unitMeasure && <InputAdornment position="end">{unitMeasure}</InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                    <Chip label='Low' 
                        className={clsx(classes.chip, classes['baseline'])}
                        //icon={<Icon icon='chevrons-down' size={16} />}
                    />
                </Grid>
                <Grid item style={{width: 10}}></Grid>
                <Grid item className={classes.flex}>
                    <TextField
                        type="number"
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="baselineLow" 
                        //label=""
                        defaultValue={state && state.baselineLow} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors['baselineLow']}
                        helperText={!!errors['baselineLow'] && errors['baselineLow']}
                        InputProps={{
                            endAdornment: unitMeasure && <InputAdornment position="end">{unitMeasure}</InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                    <Chip label='Critical Low' 
                        className={clsx(classes.chip, classes['critical'])}
                        //icon={<Icon icon='chevrons-down' size={16} />}
                    />
                </Grid>
                <Grid item style={{width: 10}}></Grid>
                <Grid item className={classes.flex}>
                    <TextField
                        type="number"
                        className={classes.flex}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal" 
                        id="criticalZoneLow" 
                        //label=""
                        defaultValue={state && state.criticalZoneLow} 
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors['criticalZoneLow']}
                        helperText={!!errors['criticalZoneLow'] && errors['criticalZoneLow']}
                        InputProps={{
                            endAdornment: unitMeasure && <InputAdornment position="end">{unitMeasure}</InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
            <FormControlLabel
                control={
                <Checkbox
                    id="notify" 
                    checked={notifyState}
                    onChange={handleCheckboxChange('notify')}
                    value={ state && state.notify } 
                    color="primary"
                />
                }
                label="Notify critical measure" // TODO: I18N
            />
        </form>
    )
}