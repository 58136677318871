import React, { useRef, useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField } from '@material-ui/core';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import SelectField from 'components/core/SelectField';
import { AuthManagerContext } from 'contexts/AuthManager'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    flex: {
        flex: 1
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function PatientInsuranceForm({ title = 'Pathway Information', record, params = {}, isSubmitting, onSubmit, onError }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = { planName: ['required'], memberID: ['required'], groupNumber: ['required'] }
    const [state, errors, handleChange, handleBlur, handleSubmit] = useForm(callback, validations, record)
    const authManager = useContext(AuthManagerContext)
    const planRules = {
        onChange: event => {
            const { selection } = event.target
            handleChange({ target: { id: 'planName', value: selection && selection.text }})
        }
    }
    const [companyId, setCompanyId] = useState(state.companyId)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    const handlePlanChange = name => event => {
        const { selection } = event.target
        const value = selection && selection[name]

        handleChange(event)

        event.target.value = value

        planRules['onChange'](event)
    }

    const handleCompanyChange = name => event => {
        const { selection } = event.target
        const value = selection && selection[name]

        setCompanyId(value)
    }

    // console.log(state)

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <SelectField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="companyId"
                label="Company"  // TODO: 
                defaultValue={companyId || ""}
                fullWidth
                required={true}
                error={!!errors['companyId']}
                helperText={!!errors['companyId'] && errors['companyId']}
                resource={{ path: '/insurance-companies' }}
                onChange={handleCompanyChange('value')}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
            />
            <SelectField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="planId"
                label="Plan"     // TODO: 
                defaultValue={state ? (state.planId || "") : ""}
                fullWidth
                required={true}
                error={!!errors['planId']}
                helperText={!!errors['planId'] && errors['planId']}
                resource={companyId && { path: '/insurance-companies/:companyId/plans', params: { companyId } }}
                params={companyId}
                onChange={handlePlanChange('text')}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
                disabled={companyId ? false : true}
            />
            <div className={classes.hbox}>
                <TextField
                    className={clsx(classes.flex, classes.marginRight)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal" 
                    id="memberID" 
                    label="Member ID"  // TODO: 
                    defaultValue={state && state.memberID}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    error={!!errors['memberID']}
                    helperText={!!errors['memberID'] && errors['memberID']}
                />
                <TextField
                    className={clsx(classes.flex, classes.marginLeft)}
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal" 
                    id="groupNumber" 
                    label="Group Number"     // TODO: 
                    defaultValue={state && state.groupNumber} 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors['groupNumber']}
                    helperText={!!errors['groupNumber'] && errors['groupNumber']}
                />
            </div>
        </form>
    )
}