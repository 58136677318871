import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';

function inputComponent(props) {
    const { id, inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
            console.log(props)
          onChange({
            target: {
                id,
                value: values.value,
            },
          });
        }}
        //format="#########" 
        placeholder="#########"
        mask={['#', '#', '#', '#', '#', '#', '#', '#', '#']}
        isNumericString
        type='password'
        maxLength='9'
      />
    );
}
  
inputComponent.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

export default function SSNField(props) {
    return (
        <TextField
            InputProps={{
                inputComponent,

            }}
            {...props}
        />
    )
}