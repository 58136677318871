import * as Types from 'constants/ActionTypes'
import { buildUrl } from 'utils';
import uuid from 'uuid/v4'
import { actionRequest } from './index'

const resources = {
    measures: {
        listDataChart: { path: '/customers/:customerId/patients/:patientId/measures/chart', method: 'get' },
        listDetailDataChart: { path: '/customers/:customerId/patients/:patientId/measures/chart/detail', method: 'get' }
    }
}

export const fecthMeasuresData = (params, { measures, startDate, endDate, includeQualifier }) => (dispatch, getState) => {
    const { path, method } = resources['measures']['listDataChart']

    actionRequest(dispatch, getState)({
        id: uuid(),
        type: Types.FETCH_MEASURES_DATA,
        url: buildUrl(path, params),
        params: { measures, startDate, endDate, includeQualifier },
        method
    })

    // actionRequest(...args)({
    //     type: Types.FETCH_MEASURES_DATA,
    //     url: `/applications/${name}`,
    //     method: 'get'   
    // })
}

export const fecthDetailMeasuresData = (params, { measures, startDate, endDate, includeQualifier }) => (dispatch, getState) => {
    const { path, method } = resources['measures']['listDetailDataChart']

    actionRequest(dispatch, getState)({
        id: uuid(),
        type: Types.FETCH_MEASURES_DATA,
        url: buildUrl(path, params),
        params: { measures, startDate, endDate, includeQualifier },
        method
    })
}