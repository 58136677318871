import moment from 'moment'
import util from 'util'
import 'moment-duration-format'

// TODO: Check this later
// moment.relativeTimeThreshold('s', 40);
// moment.relativeTimeThreshold('ss', 3);
// moment.relativeTimeThreshold('m', 40);
// moment.relativeTimeThreshold('h', 20);
// moment.relativeTimeThreshold('d', 25);
// moment.relativeTimeThreshold('w', 1);  // enables weeks
// moment.relativeTimeThreshold('M', 10);


export const dateFormat = (date, format) => moment(date).format(format)
export const ageFormat = (date) => moment().diff(date, 'years')
export const currencyFormat = (value) => util.format('$%s', value.toFixed(2))
export const secToMin = secs => moment.duration(secs, "seconds").format("h [hrs], m [min], s [sec]", {
    trim: "both"
});//moment.utc(secs*1000).format('HH:mm:ss')




export const timeCalendar = (date) => moment(date).calendar({
	sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    lastWeek: 'MMM D, YYYY',
    sameElse: 'MMM D, YYYY'
})

export const timefromNow = (date) => moment(date).fromNow()
export const previousDay = (date) => moment(date).subtract(1, 'days').toDate()
export const getVideoDuration = duration => {
    duration = new Date(duration)
    const time = ((duration.getHours() * 60 * 60 * 1000) + (duration.getMinutes() * 60 * 1000) + (duration.getSeconds() * 1000) + (duration.getMilliseconds()))
    const momentDuration = moment.duration(time).format('h:mm:ss')

    return momentDuration
}

export const getSecondsDuration = (startTime) => {
    const duration = moment.duration(moment(new Date()).diff(startTime));
    const seconds = duration.asSeconds();

    return seconds
}

export const formatCm2Ft = value => {
    const feets = Math.trunc(value)
    const inches = Math.floor(((value % 1) / 0.08333))
    let formatted = `${feets}'`

    if (inches > 0) formatted += `${inches}"`
    return formatted
}

export const startDateRange = (period) => {
    let date

    switch (period) {
        case 'day':
            date = moment().subtract(24, 'hours')
            break;
        case 'week':
            date = moment().subtract(6, 'days')
            break;
        case 'month':
            date = moment().subtract(1, 'month')
            break;
        case 'year':
            date = moment().subtract(1, 'year')
            break;   
    }

    return getTimeStartDate(date);
}

export const getTimeStartDate = (date) => {
    const day = date ? new Date(date) : new Date()
    day.setHours(0, 0, 0, 0)

    return day
}

export const getTimeEndDate = (date) => {
    const day = date ? new Date(date) : new Date()
    day.setHours(23, 59, 59, 999)

    return day
}

export const getSpecificTimeDay = (date, hour) => {
    const day = date ? new Date(date) : new Date()
    day.setHours(hour, 0, 0, 0)

    return day
}

export const formatFloatHours = (floatHours) => {
    const duration = moment.duration(floatHours, 'h')
    const minutes = duration.minutes()

    return minutes == 0 ? duration.format('h [h]') : duration.format('h [h] m [m]') 
}

const formatThresholdValue = (ruleName, value) => (parseFloat(value) && ruleName.includes('Sleep')) ? formatFloatHours(parseFloat(value)) : parseFloat(value)

export const getMeasureRangeText = ({ ruleName, value, criticalZoneHigh, baselineHigh, criticalZoneLow, baselineLow }) => {
    // TODO: I18N 
    const operator = (criticalZoneHigh && value >= criticalZoneHigh) || (baselineHigh && value >= baselineHigh) ? '≥' :
                     (criticalZoneLow && value < criticalZoneLow) || (baselineLow && value < baselineLow) ? '≤' : ''
    const threshold = (criticalZoneHigh && value > criticalZoneHigh) ? util.format('%s critical high', formatThresholdValue(ruleName, criticalZoneHigh)) : 
                            (baselineHigh && value > baselineHigh) ? util.format('%s baseline high', formatThresholdValue(ruleName, baselineHigh)) : 
                            (criticalZoneLow && value < criticalZoneLow) ? util.format('%s critical low', formatThresholdValue(ruleName, criticalZoneLow)) : 
                            (baselineLow && value < baselineLow) ? util.format('%s baseline low', formatThresholdValue(ruleName, baselineLow))  : 'In the goal range'
    
    return util.format('%s %s', operator, threshold)
}