import React, { Fragment, useState, useContext } from 'react';
import { Switch, useRouteMatch, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import { AuthManagerContext } from 'contexts/AuthManager';

import AuthRoute from 'components/AuthRoute'
import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'
import { isSupported } from 'twilio-video';
import { getReportFormParams } from 'actions';

import PatientStepper from 'components/PatientStepper'
import OrderStepper from 'components/OrderStepper'
import PatientChartDialog from 'components/PatientChartDialog'
import PatientTabDialog from 'components/PatientTabDialog'
import PatientActivateDialog from 'components/PatientActivateDialog';
import PatientAlertDialog from 'components/PatientAlertDialog';
import PatientNotificationDialog from 'components/PatientNotificationDialog';
import PatientNotifyHCPDialog from 'components/PatientNotifyHCPDialog';
import PatientVideoChatDialog, { videChatDialogName } from 'components/PatientVideoChatDialog';
import ReportDynamicDialog from 'components/ReportDynamicDialog'

import { USER_STATUS_ACTIVE, USER_ADMIN_ROLE } from 'constants/index'
import { PatientDetails } from 'screens'
import { defaultLanguage, buildUrl, dynamicDialogHelper, dynamicReportDialogHelper } from 'utils'

const columnsOverride = [{
    type: 'AlertBadgeColumn',
    dataKey: 'alertCount',
    order: 0,
    width: 8
}, /*{
    type: 'AvatarColumn',
    dataKey: 'image',
    columnText: 'fullName',
    order: 1,
    width: 10
}, */{
    type: 'LinkNoImageColumn',
    color: 'primary',
    label: 'global.name',
    columnText: 'fullName',
    dataKey: 'fullName',
    order: 1
}, {
    type: 'PhoneColumn',
    dataKey: 'mobilePhone',
    order: 2,
    hidden: true
}, {
    type: 'AddressColumn',
    dataKey: 'address',
    order: 3,
    hidden: true
}, {
    type: 'AgeColumn',
    label: 'global.age',
    dataKey: 'dob',
    order: 4,
    hidden: true
}, {
    dataKey: 'gender',
    order: 5,
    hidden: true
}, {
    dataKey: 'languageId',
    order: 6,
    hidden: true
}, {
    type: 'MeasureColumn',
    dataKey: 'height',
    order: 7,
    hidden: true
},
/*{
    dataKey: 'conditions',
    order: 6,
    hidden: true
},*/
{
    type: 'MeasureChipColumn',
    size: 'small',
    dataKey: 'heartRate',
    order: 7
    // }, {
    //     type: 'ComplexMeasureChipColumn',
    //     size: 'small',
    //     label: 'Blood Pressure',
    //     dataKey: 'systolic',
    //     dataKey2: 'diastolic',
    //     order: 8,
    // }, {
}, {
    type: 'MeasureChipColumn',
    size: 'small',
    dataKey: 'systolic',
    order: 8
}, {
    type: 'MeasureChipColumn',
    size: 'small',
    dataKey: 'diastolic',
    order: 9,
}, {
    type: 'MeasureChipColumn',
    size: 'small',
    dataKey: 'glucose',
    order: 10
}, {
    type: 'MeasureChipColumn',
    size: 'small',
    dataKey: 'weight',
    order: 11
}, {
    type: 'MeasureChipColumn',
    size: 'small',
    dataKey: 'water',
    order: 12
}, {
    type: 'MeasureChipColumn',
    size: 'small',
    dataKey: 'pulseOximetry',
    order: 13
}, {
    type: 'MeasureChipColumn',
    size: 'small',
    dataKey: 'steps',
    order: 14
}, {
    type: 'MeasureChipColumn',
    size: 'small',
    dataKey: 'sleep',
    formatter: 'formatFloatHours',
    order: 15
}, {
    type: 'StatusColumn',
    size: 'small',
    dataKey: 'status',
    order: 20
}, {
    dataKey: 'owner',
    order: 21,
    filterable: {
        params: (authManager) => {
            return {
                customerId: authManager.customerId
            }
        }
    }
}, {
    dataKey: 'interactionMinutes',
    order: 22
}, {
    dataKey: 'daysWithMeasures',
    order: 23
}, {
    dataKey: 'billingDays',
    order: 24,
    hidden: true
}, {
    type: 'DateColumn',
    dataKey: 'nextBillingDate',
    order: 25
}]

const actionsOverride = [{
    type: 'create',
    component: PatientStepper,
    hidden: (authManager, params) => {
        if (params && params.customerId) {
            return authManager.currentCustomerRoleId !== USER_ADMIN_ROLE
        }
        return authManager.roleId !== USER_ADMIN_ROLE
    },
    order: 1
}, {
    type: 'update',
    disabled: selection => !(selection.length === 1),
    component: PatientTabDialog,
    order: 2
}, {
    type: 'activate',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 0 && (selection.findIndex(item => item.statusId === USER_STATUS_ACTIVE) > -1)))
    },
    selectionMap: ({ id, ownerId }) => ({ id, ownerId }),
    component: PatientActivateDialog,
    order: 3
}, {
    type: 'assignOwner',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 0 && (selection.findIndex(item => item.statusId === USER_STATUS_ACTIVE) === -1)))
    },
    component: PatientActivateDialog,
    order: 4
}, {
    type: 'createOrder',
    disabled: selection => !(selection.length === 1),
    component: OrderStepper,
    order: 5,
    fetchRecord: true
}, {
    type: 'fullReport',
    disabled: selection => !(selection.length === 1),
    selectionMap: () => ({ id: 2, name: 'Patient Report' }),
    component: ReportDynamicDialog,
    order: 7
}, {
    type: 'calendarReport',
    disabled: selection => !(selection.length === 1),
    selectionMap: ({customerId, id: patientId}) => ({ id: 4, name: 'Calendar Report', customerId, patientId }),
    component: ReportDynamicDialog, 
    order: 8
}, {
    order: 9,
    type: 'report',
    disabled: selection => !(selection.length === 1),
    component: dynamicDialogHelper({ id: 'EnrollmentDocuments' })
}, {
    type: 'notes',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 1))
    },
    component: dynamicDialogHelper({ id: 'Notes' }),
    actionData: () => ({ date: new Date() }),
    order: 11
}, {
    type: 'notification',
    disabled: selection => {
        return ((selection.length === 0) ||
            (selection.length > 1) ||
            (selection.length > 0 && (selection.findIndex(item => item.statusId === USER_STATUS_ACTIVE) === -1)) ||
            (selection.length > 0 && (selection.findIndex(item => item.usingMobileDevice === false) > -1)))
    },
    selectionMap: ({ customerId, id: patientId }) => ({ customerId, patientId }),
    component: PatientNotificationDialog,
    actionData: () => ({ date: new Date() }),
    order: 12
}, {
    type: 'notifyHcp',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 1) || (selection.length > 0 && (selection.findIndex(item => item.statusId === USER_STATUS_ACTIVE) === -1)))
    },
    selectionMap: ({ customerId, id: patientId, fullName: patientFullName }) => ({ customerId, patientId, patientFullName }),
    component: PatientNotifyHCPDialog,
    actionData: () => ({ date: new Date() }),
    order: 13
}, {
    type: 'phoneCall',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 1))
    },
    selectionMap: ({ mobilePhone }) => ({ phone: mobilePhone }),
    component: dynamicDialogHelper({ id: 'PhoneCall' }),
    actionData: () => ({ date: new Date() }),
    order: 14
}, {
    type: 'videoChat',
    disabled: (selection, action, { dialogContext }) => {
        const { findDialog } = dialogContext
        const component = findDialog(videChatDialogName)

        return (isSupported && (
            (selection.length === 0) ||
            (selection.length > 1) ||
            (selection.length > 0 && (selection.findIndex(item => item.statusId === USER_STATUS_ACTIVE) === -1)) ||
            (selection.length > 0 && (selection.findIndex(item => item.usingMobileDevice === false) > -1)) ||
            (!!component)
        ))
    },
    selectionMap: ({ id, fullName, customerId }) => ({ id, fullName, customerId }),
    component: PatientVideoChatDialog,
    refreshOnClose: true,
    order: 15
}]

const reports = ['welcomeLetter', 'consentReport', "leaseReport"]
const entityIdProperty = 'patientId'

const Patients = ({ actions, columns, resources, ...others }) => {
    const authManager = useContext(AuthManagerContext)
    const dispatch = useDispatch()
    const { path } = useRouteMatch()
    const params = useParams()
    const [openChartDialog, setOpenChartDialog] = useState(false)
    const [openAlertDialog, setOpenAlertDialog] = useState(false)
    const [measure, setMeasure] = useState([])
    const [clickedRow, setClickedRow] = useState()
    const views = useSelector(state => state.application.data.views)
    const alertView = views.find(view => view.name === 'PatientAlerts')

    const { actionsState, columnsState, dataState, totalCountState, recordState, handleRefresh, handleFetchRecord, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride, [{
        dataKey: 'fullName',
        to: `${path}/:${entityIdProperty}/open`, params: Object.assign({ [entityIdProperty]: row => row.id }, params)
    }, {
        dataKey: 'alertCount',
        handleClick: (event, row) => {
            setOpenAlertDialog(true)
            setClickedRow(row)
        }
    }
    ].concat([
        { name: 'steps', id: 1 }, // TODO: Use constants
        { name: 'sleep', id: 3 },
        { name: 'weight', id: 4 },
        { name: 'water', id: 13 },
        { name: 'heartRate', id: 5 },
        { name: 'diastolic', id: 7 },
        { name: 'systolic', id: 8 },
        { name: 'glucose', id: 9 },
        { name: 'pulseOximetry', id: 18 },].map(measure => ({
            dataKey: measure.name,
            handleClick: (event, row) => {
                setOpenChartDialog(true)
                setMeasure(measure.id)
                setClickedRow(row)
            }
        })))
    ], {
        actions,
        columns,
        resources
    })

    const handleReportAction = (action, data, actionParams) => new Promise((resolve, reject) => {
        try {
            const resource = resources[action.type] || resources[action]
            const customerId = params.customerId || authManager.customerId
            const userId = authManager.id
            const patientId = actionParams.patientId
            const url = new URL(`${process.env.REACT_APP_API_URL}${buildUrl(resource.path, { customerId, ...actionParams })}`)

            const dataStr = JSON.stringify(data).replace('@CurrentCustomerID', customerId)
                                            .replace('@CurrentUserID', userId)
                                            .replace('@CurrentPatientID', patientId)
                                            .replace('@CurrentLanguageID', defaultLanguage())
            const dataObj = JSON.parse(dataStr)

            url.searchParams.append('accessToken', authManager.token)
            // url.searchParams.append('contentLanguage', defaultLanguage())
            Object.keys(dataObj).forEach(name => {
                url.searchParams.append(name, dataObj[name])
            })

            window.open(url, action.name)
            resolve({})
        }
        catch (error) {
            reject({ error })
        }
    })
    

    const handleCustomAction = (action, data, actionParams) => {
        async function fetchData(name, reportId) {
            const customerId = params.customerId || authManager.customerId
            const { data: { result }, error } = await dispatch(getReportFormParams({ reportId, customerId }))

            if (!error && result) {
                if (result.fields.every(item => item.hidden)) {
                    const data = result.fields.reduce((acc, value) => ({ ...acc, [value.name]: value.defaultValue }), {})

                    return handleReportAction(name, data, { patientId: actionParams.patientId, reportId })
                }
            }
        }

        const { customerId, patientId, id, name, ...dataParams } = data

        switch (action.type) {
            case 'fullReport':
                return handleReportAction(action, dataParams, { patientId: actionParams.patientId, reportId: 2 })
            case 'calendarReport':
                return handleReportAction(action, dataParams, { patientId: actionParams.patientId, reportId: 4 })
            case 'report':
                return reports.map(name => {
                    if (data[name]) {
                        switch (name) {
                            case 'leaseReport':
                                return fetchData(name, 8)
                            case 'consentReport':
                                return fetchData(name, 5)
                            case 'welcomeLetter':
                                return fetchData(name, 7)
                            default:
                                return handleReportAction(name, {}, { patientId: actionParams.patientId })
                        }
                    }
                })
            default:
                return handleAction(action, data, actionParams)
        }
    }

    const handleAlertClose = (dirty) => {
        dirty && handleRefresh()
        setOpenAlertDialog(false)
        setClickedRow(null)
    }

    const handleChartClose = () => {
        setOpenChartDialog(false)
        setClickedRow(null)
    }

    return (
        <Fragment>
            <Switch>
                <AuthRoute exact path={path}
                    component={DataBrowser}
                    //statefulId="patients"
                    actions={actionsState}
                    columns={columnsState}
                    entityIdName="patientId"
                    searchFieldName={['fullName']}
                    defaultFilter={{
                        statusId: {
                            chipLabel: "Active",
                            dataType: "integer",
                            name: "statusId",
                            operator: "in",
                            text: "Active",
                            value: [61]
                        }
                    }}
                    rows={dataState}
                    totalCount={totalCountState}
                    defaultLimit={100}
                    record={recordState}
                    onRefresh={handleRefresh}
                    onFetchRecord={handleFetchRecord}
                    onAction={handleCustomAction}
                    isLoading={isLoading}
                    inProgress={inProgress}
                    remoteQuery={true}
                    SideComponent={PatientDetails}
                    {...others}
                />
            </Switch>
            {(openChartDialog) && (
                <PatientChartDialog
                    open={openChartDialog}
                    measure={measure}
                    title={"Chart"}
                    patient={clickedRow}
                    onClose={handleChartClose}
                />
            )}
            {(openAlertDialog) && (
                <PatientAlertDialog
                    open={openAlertDialog}
                    record={clickedRow}
                    onClose={handleAlertClose}
                    {...alertView}
                />
            )}
        </Fragment>
    )
}

export default Patients