import React, { useContext } from 'react';
import { Switch, useRouteMatch, useParams } from 'react-router-dom'

import { AuthManagerContext } from 'contexts/AuthManager';
import AuthRoute from 'components/AuthRoute'
import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'
import ReportDynamicDialog from 'components/ReportDynamicDialog'
import { buildUrl } from 'utils'

const columnsOverride = [
    /*{
    type: 'AvatarColumn',
    dataKey: 'thumbnail',
    columnText: 'name',
    order: 1,
    width: 10
}, */{
        dataKey: 'name',
        order: 2
    }, {
        dataKey: 'summary',
        order: 3
    }, {
        type: 'DateTimeColumn',
        dataKey: 'lastExecuted',
        order: 4,
        format: 'MM/DD/YYYY hh:mm A'
    }]

const actionsOverride = [{
    type: 'execute',
    shortcut: true,
    disabled: selection => (selection.length === 0),
    selectionMap: ({ id, name, summary, description }) => ({ id, name, summary, description, customerId: 0, patientId: 0 }),
    component: ReportDynamicDialog,
    order: 1
}]

const Reports = ({ actions, columns, resources, ...others }) => {
    const authManager = useContext(AuthManagerContext)
    const { path } = useRouteMatch()
    const params = useParams()
    const { actionsState, columnsState, dataState, handleRefresh, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions,
        columns,
        resources
    })

    const handleReportAction = (action, data, actionParams) => new Promise((resolve, reject) => {
        try {
            const { id, name, summary, description, customerId: cid, patientId, ...dataParams } = data
            const resource = resources[action.type] || resources[action]
            const customerId = params.customerId || authManager.customerId
            const userId = authManager.id
            const url = new URL(`${process.env.REACT_APP_API_URL}${buildUrl(resource.path, { customerId, reportId: actionParams.id, ...actionParams })}`)

            const dataStr = JSON.stringify(dataParams).replace('@CurrentCustomerID', customerId).replace('@CurrentUserID', userId)
            const dataObj = JSON.parse(dataStr)

            url.searchParams.append('accessToken', authManager.token)
            // url.searchParams.append('contentLanguage', defaultLanguage())
            Object.keys(dataObj).forEach(name => {
                url.searchParams.append(name, dataObj[name])
            })

            window.open(url, action.name)
            resolve({})
        }
        catch (error) {
            reject({ error })
        }
    })

    return (
        <Switch>
            <AuthRoute exact path={path}
                component={DataBrowser}
                actions={actionsState}
                columns={columnsState}
                searchFieldName={['name']}
                rows={dataState}
                onRefresh={handleRefresh}
                onAction={handleReportAction}
                isLoading={isLoading}
                inProgress={inProgress}
                allowSelection={false}
                compactMode={true}
                {...others}
            />
        </Switch>
    )
}

export default Reports