import * as Types from 'constants/ActionTypes';

const status = (state = {
    success: null,
    error: null
}, action) => {
    switch (action.type) {
        case Types.CLEAN_APPLICATION_STATUS:
            return Object.assign({}, state, { success: null, error: null })
        case Types.CREATE_RECORD_SUCCESS:
            return Object.assign({}, state, {
                success: action.success,
                error: null
            });
        case Types.REQUEST_LOGIN_FAILURE:
        case Types.CREATE_RECORD_FAILURE:
            if (action.error.code && action.error.code === 401) {
                return state;
            }
            return Object.assign({}, state, {
                success: null,
                error: action.error
            });
        default:
            return state
    }
};

export default status;