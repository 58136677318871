import * as Types from 'constants/ActionTypes';

const charts = (state = {
    inProgress: false,
    didInvalidate: false,
    data: [],
}, action) => {
    switch (action.type) {
        case Types.FETCH_APPLICATION_CHARTS:
        case Types.CREATE_APPLICATION_CHART:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            })
        case Types.FETCH_APPLICATION_CHARTS_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.payload,
                lastUpdated: action.receivedAt
            })
        case Types.CREATE_APPLICATION_CHART_SUCCESS:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data, action.payload],
                lastUpdated: action.receivedAt
            })
        case Types.DELETE_APPLICATION_CHART_SUCCESS:
            state.data.splice(state.data.findIndex(l => (l.key === action.payload.key)), 1)
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data],
                lastUpdated: action.receivedAt
            })
        case Types.FETCH_APPLICATION_CHARTS_FAILURE:
        case Types.CREATE_APPLICATION_CHART_FAILURE:
        case Types.DELETE_APPLICATION_CHART_FAILURE:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            })
        default:
            return state
    }
};

export default charts