import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { Icon } from 'styles';
import { uncapitalized } from 'utils'

const useStyles = makeStyles(theme => ({
    badge: {
        transform: 'scale(.7) translate(55%, 50%)'
    },
    information: {
        color: 'white',
        backgroundColor: '#4CB091',
        border: '1px solid white',
        "&&&&:active": {
            backgroundColor: '#4CB091'
        },
        "&&&&:focus": {
            backgroundColor: '#4CB091'
        },
        "&&&&:hover": {
            backgroundColor: '#4CB091',
        }
    },
    warning: {
        color: 'white',
        backgroundColor: '#FCA861',
        border: '1px solid white',
        "&&&&:active": {
            backgroundColor: '#FCA861'
        },
        "&&&&:focus": {
            backgroundColor: '#FCA861'
        },
        "&&&&:hover": {
            backgroundColor: '#FCA861',
        }
    },
    critical: {
        color: 'white',
        backgroundColor: '#EF7373',
        border: '1px solid white',
        "&&&&:active": {
            backgroundColor: '#EF7373'
        },
        "&&&&:focus": {
            backgroundColor: '#EF7373'
        },
        "&&&&:hover": {
            backgroundColor: '#EF7373',
        }
    }
}))

export default function AlertIconButton({ badgeContent = 0, category, onClick, ...others }) {
    const classes = useStyles()

    return (
        <Tooltip key='chip' title={category}>
            <Badge badgeContent={badgeContent} overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} classes={{ badge: clsx(classes[uncapitalized(category)], classes.badge) }} onClick={onClick}>
                <IconButton size="medium" aria-label={category} className={classes[uncapitalized(category)]} style={{ padding: 7 }}>
                    <Icon icon={'bell'} size={16} className={classes.icon} />
                </IconButton>
            </Badge>
        </Tooltip>
    )
}