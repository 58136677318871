import { connect } from 'react-redux';
import ResetPasswordForm from 'components/ResetPasswordForm';
import { resendCode, resetPassword } from 'actions';

const mapStateToProps = state => ({
    // inProgress: state.user.inProgress,
    // user: state.user.data,
    // error: state.user.error
});

const mapDispatchToProps = (dispatch, props) => ({
    // onResend: (email) => dispatch(resendCode(email)),
	onSubmit: (email, code, password) => {
        const { history } = props;
        
        // dispatch(resetPassword(email, code, password, history));
    }
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ResetPasswordForm);