import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import useForm from 'hooks/useForm';

import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    textField: {
        width: theme.spacing(41)
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function ChangePasswordForm({ record, isSubmitting, onSubmit, onError }) {
    const classes = useStyles()
    const ref = useRef()
    const validations = { currentPassword: ['required'], newPassword: ['required'], confirmPassword: ['required']  }
    const [state, errors, handleChange, handleBlur, handleSubmit] = useForm(callback, validations, record)

    function callback() {
		if (state.newPassword === state.confirmPassword) {
			errors['confirmPassword'] = null
			if (onSubmit) onSubmit(state, ref)
		} else {
			errors['confirmPassword'] = 'Passwords don\'t match'
		}
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
			<TextField
				InputLabelProps={InputLabelProps}
				id="currentPassword"
				label="Current Password"
				className={classes.textField}
				type="password"
				//value={(state && state.currentPassword) ? state.currentPassword : ""}
				onChange={handleChange}
				autoComplete="current-password"
				margin="normal"
				required={true}
				error={!!errors['currentPassword']}
                helperText={!!errors['currentPassword'] && errors['currentPassword']}
			/>
			<TextField
				InputLabelProps={InputLabelProps}
				id="newPassword"
				label="New Password"
				className={classes.textField}
				type="password"
				//value={(state && state.newPassword) ? state.newPassword : ""}
				onChange={handleChange}
				autoComplete="off"
				margin="normal"
				required={true}
				error={!!errors['newPassword']}
                helperText={!!errors['newPassword'] && errors['newPassword']}
			/>
			<TextField
				InputLabelProps={InputLabelProps}
				id="confirmPassword"
				label="Confirm Password"
				className={classes.textField}
				type="password"
				//value={(state && state.confirmPassword) ? state.confirmPassword : ""}
				onChange={handleChange}
				autoComplete="off"
				margin="normal"
				required={true}
				error={!!errors['confirmPassword']}
                helperText={!!errors['confirmPassword'] && errors['confirmPassword']}
			/>
        </form>
    )
}