import { connect } from 'react-redux';
import ForgotPasswwordForm from 'components/ForgotPasswordForm';
import { forgotPassword } from 'actions';

const mapStateToProps = state => ({
    inProgress: state.auth.inProgress,
    // error: state.user.error
});

const mapDispatchToProps = (dispatch, props) => ({
	onSubmit: email => {
        const { history } = props;
        
        dispatch(forgotPassword(email, history));
    }
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ForgotPasswwordForm);