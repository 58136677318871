import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary:  { main: '#4B4B4C'},
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Lato', 
            'Roboto',
            'Arial'
        ].join(',')
    },
    overrides: {
        MuiButton: {
            contained: {
                boxShadow: 'none'
            },
        } 
      }
})

const DarkTheme = ({ children }) => (<MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>)

export default DarkTheme