import React, { Fragment, useState, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import util from 'util'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import green from '@material-ui/core/colors/green';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { Icon } from 'styles';
import { Base } from 'components/themes/main';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import { Paper, Toolbar, IconButton, Fab, Menu, FormGroup, FormControlLabel, Checkbox,
    Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Tooltip, MenuItem, ListItemIcon } from '@material-ui/core';
import ChipField from '../ChipField';
import throttle from 'lodash/throttle';

import useStateful from 'hooks/useStateful'

import { DialogContext } from 'contexts/Dialog';
import { I18nManagerContext } from 'contexts/I18nManager';
import { AuthManagerContext } from 'contexts/AuthManager';
import AdvancedMode from 'components/core/AdvancedMode';
import DataTable from 'components/core/DataTable';
import NumberFilter from 'components/core/NumberFilter'
import TextFilter from 'components/core/TextFilter'
import SelectFilter from 'components/core/SelectFilter'
import CheckboxFilter from '../CheckboxFilter/CheckboxFilter'
import DateFilter from '../DateFilter/DateFilter';

const INITIAL_PAGE = 1
const PAGE_SIZE = 50
const VIEW_FILTERS_HEIGHT = 80
const VIEW_COLUMNS_HEIGHT = 80
const DRAWER_WIDTH = 300//'40%'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflowY: 'scroll',
        boxShadow: 'none',
        border: '1px solid #E2E2E3',
        borderRadius: 2,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(5) ,
        paddingTop: 20,
        paddingBottom: theme.spacing(0)
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        padding: theme.spacing(5),
        paddingTop: 0
    },
    innerBody: {
        backgroundColor: '#F8F8F8',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4),
        height: '100%'
    },
    titleContainer: {
        flex: 1
    },
    title: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            padding: 0
        // },
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: 0,
        // flex: 1
    },
    // divider: {
    //     marginTop: theme.spacing(2),
    //     marginBottom: theme.spacing(2),
    // },
    configuration: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    contextMenuItemIcon: {
        minWidth: theme.spacing(5)
    },
    search: {
        ...Base.search,
        flex: 'none'
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        height: 31,
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        // [theme.breakpoints.up('md')]: {
        //     width: 200,
        // },
    },
    paddingRight: {
        paddingRight: `${theme.spacing(1)}px !important`
    },
    clearIcon: {
        height: '100%',
        position: 'absolute',
        top: 8,
        right: 0,
        paddingRight: theme.spacing(5),
    },
    filterIcon: {
        height: '100%',
        position: 'absolute',
        top: 8,
        right: 0,
        paddingRight: theme.spacing(1),
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        width: Base.search.width * 2
    },
    filterActions: {
        padding: theme.spacing(3),
    },
    filterChips: {
        alignSelf: 'center', 
        marginBottom: theme.spacing(2)
    },
    button: {
        minWidth: theme.spacing(5),
        marginLeft: theme.spacing(1),
    },
    separator: {
        borderRight: '0.04em solid rgba(0, 0, 0, 0.3)',
        marginRight: '.5em',
        marginLeft: '.5em',
        paddingTop: '.5em',
        paddingBottom: '.5em',
        height: 20
    },
    configButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: 1,
        marginBottom: theme.spacing(2)
    },
    fab: {
        ...Base.fab,
    },
    fabLight: {
        ...Base.fabLight,
    },
    viewColumns: {
        padding: theme.spacing(1, 2)
    },
    content: {
        display:'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        flexGrow: 1,
        height: 1
    },
    details: {
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        width: DRAWER_WIDTH,
        marginLeft: theme.spacing(2),
        maxWidth: 300,
        minWidth: 300,
        overflowY: 'scroll',
        flexGrow: 1,
        height: '100%',
        maxHeight: '-webkit-fill-available'
    },
    bigAvatar: {
        width: 130,
        height:130
    },
    mediumAvatar: {
        width: 60,
        height:60
    },
    alignRight: {
        textAlign: 'right'
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        width: '100%',
        marginRight: 5
    },
    mask: {
        position: "absolute",
        // top: 0,
        // left: 0,
        // bottom: 0,
        // right: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%'
    },
    activity: {
        color: green[500],
        position: 'relative',
        top: 0,//'50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4,
        zIndex: 9999
    },
    groupedActions: {
        marginRight: 0,
        paddingRight: 0
    },
    iconInset: {
        paddingLeft: 3
    }
}))

const ViewColumns = ({ className, selected, options, target, maxHeight, onChange, onClose }) => {
    const open = Boolean(target)
    const [selection, setSelection] = useState(selected)

    const handleChange = value => {
        const newSelection = [...selection]
        const index = newSelection.indexOf(value)
    
        if (index === -1) newSelection.push(value) 
        else newSelection.splice(index, 1)

        setSelection(newSelection)

        if (onChange) onChange(newSelection)
    }
    
    return (
        <Menu
            anchorEl={target}
            keepMounted
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    maxHeight: maxHeight * 4.5
                },
            }}
        >
            <FormGroup className={className}>
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox 
                                checked={selection.indexOf(option.value) > -1}
                                value={option.value}
                                onChange={() => handleChange(option.value)} 
                            />
                        }
                        label={option.text}
                    />
                ))}
            </FormGroup>
        </Menu>
    )
}

ViewColumns.defaultProps = {
    selected: [],
    options: []    
}

ViewColumns.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired
}

const filterOperator = {
    contain: (row, prop, value) => row[prop] && value ? row[prop].toLowerCase().indexOf(value.toLowerCase()) > -1 : true
}

const rowsFilter = (rows, filters) => {
    if (filters) {
        return rows.filter(row => {
            let cond = true

            for(let i = 0, ln = filters.length; i < ln && cond; i++) {
                const { prop, op, value } = filters[i]

                if (filterOperator[op]) {
                    cond = cond && filterOperator[op](row, prop, value)
                }
            }
            return cond   
        })
    }
    return rows
}

const filterMap = {
    string: {
        component: TextFilter,
        chipLabel: ({ value }) => value
    },
    number: {
        component: NumberFilter,
        chipLabel: ({ label, operator, value }) => util.format('%s %s %s', label, operatorMap[operator], value)
    },
    select: {
        component: SelectFilter,
        chipLabel: ({ text }) => text
    },
    checkbox: {
        component: CheckboxFilter,
        chipLabel: ({ text }) => text
    },
    date: {
        component: DateFilter,
        chipLabel: ({ label, operator, value }) => util.format('%s %s %s', label, operatorMap[operator], value)
    }
}

const operatorMap = {
     'eq': '=',
     'ne': '≠',
     'gt': '>',
     'lt': '<',
     'gte': '≥',
     'lte': '≤'
}

const ViewFilters = ({ className, filters, options, open, target, maxHeight, onSubmit, onClose }) => {
    const classes = useStyles()
    const i18nManager = useContext(I18nManagerContext)
    const authManager = useContext(AuthManagerContext)
    const [filtersState, setFiltersState] = useState(filters)

    const handleFilterChange = ({ name, value, ...others }) => {
        setFiltersState(prevState => {
            const newState = {...prevState}

            if (value) {
                const option = options.find(option => option.dataKey === name)
                const filterable = option.filterable
                const chipLabel = filterMap[filterable.type].chipLabel
                const label = i18nManager.t(option.label)
                const fieldname = filterable.dataKey || name

                newState[fieldname] = {
                    name: fieldname, 
                    value,
                    chipLabel: chipLabel({ name, label, value, ...others}),
                    ...others
                }
            }
            else {
                delete newState[name]
            }
            return newState
        })
    }

    const handleSearch = () => {
        if (onSubmit) onSubmit(filtersState)
    }

    return (
        <Menu
            anchorEl={target}
            keepMounted
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    // maxHeight: maxHeight * 4.5
                },
            }}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <DialogContent className={className}>
                {options.sort((a, b) => a.filterable.order < b.filterable.order ? -1 : a.filterable.order > b.filterable.order ? 1 : 0 )
                    .map((option, index) => {
                    const name = option.dataKey
                    const label = option.label
                    const filterable = option.filterable
                    const FilterMap = filterMap[filterable.type] ? filterMap[filterable.type].component : null
                    const fieldname = filterable.dataKey || name
                    const fieldlabel = filterable.label || label

                    return (
                        <FilterMap
                            key={index}
                            name={name}
                            autoFocus={index === 0}
                            label={i18nManager.t(fieldlabel)}
                            fullWidth
                            dataType={filterable.dataType}
                            multiple={filterable.multiple}
                            showOperator={filterable.operator}
                            resource={filterable.resource}
                            params={filterable.params && filterable.params(authManager)}
                            operator={filtersState[fieldname] && filtersState[fieldname].operator}
                            value={filtersState[fieldname] && filtersState[fieldname].value}
                            onChange={handleFilterChange}
                        />
                    )
                })}
            </DialogContent>
            <DialogActions className={classes.filterActions}>
                {/* <Button onClick={onClose} color="primary">
                    {i18nManager.t('global.cancel')}
                </Button> */}
                <Button onClick={handleSearch} variant="contained" color="secondary">
                    {i18nManager.t('global.search')}
                </Button>
            </DialogActions>
        </Menu>
    )
}

ViewFilters.defaultProps = {
    selected: [],
    options: []    
}

ViewFilters.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired
}

const filtersTransform = filters => Object.keys(filters).map(name => ({ name, 
    operator: filters[name].operator, 
    //Transform value based on dataType
    value: filters[name].dataType === 'string' ? `'${filters[name].value}'` : filters[name].value
}))
const sorterTransform = ({ property, direction }) => property ? `${direction === 'asc' ? '+' : '-'}${property}` : null

const ContextMenu = ({ open, position, actions, selection, onAction, onClose }) => {
	const dialogContext = useContext(DialogContext)
	const authManager = useContext(AuthManagerContext)
    const classes = useStyles()
    const params = useParams()

    const handleClick = action => event => {
        onAction && onAction(event, action)
        onClose && onClose()
    }

    return (
        <Menu
            keepMounted
            open={open}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={
                position.y !== null && position.x !== null
                    ? { top: position.y, left: position.x }
                    : undefined
            }
        >
            {actions.map((action, index) => {
				return !(action.hidden && action.hidden(authManager, params)) && !(action.shortcut) && (
					<MenuItem
						key={index}
						disabled={action.disabled && action.disabled(selection, action, { dialogContext })}
						onClick={handleClick(action)}
					>
						<ListItemIcon className={classes.contextMenuItemIcon}>
							<Icon icon={action.icon} size={18} color={Base.iconLight.color}/>
						</ListItemIcon>
						<Typography variant="inherit" noWrap color="textSecondary">
							{action.tooltip}
						</Typography>
					</MenuItem>
				)
			})}
        </Menu>
    )    
}

const ActionMenu = ({ open, position, actions, selection, onAction, onClose }) => {
    const classes = useStyles()
	const dialogContext = useContext(DialogContext)
	const authManager = useContext(AuthManagerContext)
    const params = useParams()

    const handleClick = action => event => {
        onAction && onAction(event, action)
        onClose && onClose()
    }

    return (
        <Menu
            keepMounted
            open={open}
            onClose={onClose}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            {actions.map((action, index) => {
				return !(action.hidden && action.hidden(authManager, params)) && !(action.shortcut) && (
					<MenuItem
						key={index}
						disabled={action.disabled && action.disabled(selection, action, { dialogContext })}
						onClick={handleClick(action)}
					>
						<ListItemIcon className={classes.contextMenuItemIcon}>
							<Icon icon={action.icon} size={18} color={Base.iconLight.color}/>
						</ListItemIcon>
						<Typography variant="inherit" noWrap color="textSecondary">
							{action.tooltip}
						</Typography>
					</MenuItem>
				)
			}
            )}
        </Menu>
    )    
}

const shortcutsColumn = (shortcuts, handleShortcut) => {
    return {
        label: 'Action',
        dataKey: 'action',
        numeric: true,
        render: (column, row, context) => {
            const { classes } = context

            const handleClick = shortcut => event => {
                handleShortcut && handleShortcut(event, shortcut, row)
            }

            return (
                <div className={classes.hbox} style={{paddingRight: 10}}>{
                    shortcuts.map((shortcut, index) => (
                        <IconButton key={index} /*disabled={shortcut.disabled}*/ size="small" onClick={handleClick(shortcut)} style={{ padding: 7, backgroundColor: '#4CB091' }} >
                            <Icon icon={shortcut.icon} size={16} color='white' className={classes.iconInset} />
                        </IconButton>
                    ))
                }</div>
            )
        }
    }
}

/*
    title:          [Prop] String. Main title.
    subtitle:       [Prop] String. Subtitle (description).
    tabMode:        [Prop] Boolean. If DataBrowser render inside a tab panel as a tab. (Actions toolbar looks differents).
    actions:        [Prop] Array of Objects. Actions metadata.
    columns:        [Prop] Array of Objects. Columns metadata.
    entityIdName:   [Prop] Integer. ID property in the data rows. 8-)
    rows:           [Prop] Array of Objects. Data rows.
    record:         [Prop] Object. Fetched record.
    onRefresh:      [Prop] Func. Handle refresh data rows.
    onFetchRecord:  [Prop] Func. Fetch record before current action.
    onAction:       [Prop] Func. Callback function after action executed.
    isLoading:      [Prop] Boolean.
    inProgress:     [Prop] Boolean.
    SideComponent:  [Prop] Component show on the right side when the user click on the row
*/
const DataBrowser = ({
	statefulId,
    title, 
    subtitle, 
    tabMode, 
    compactMode,
    actions, 
    columns, 
    entityIdName = 'id', 
    searchFieldName,
    defaultFilter,
    defaultSort,
    defaultLimit,
    rows, record, onRefresh, onFetchRecord, onAction, isLoading, inProgress, remoteQuery, totalCount, allowSelection, 
    SideComponent 
}) => {
	const isMounted = useRef(false)
	const dialogContext = useContext(DialogContext)
    const i18nManager = useContext(I18nManagerContext)
	const authManager = useContext(AuthManagerContext)
    const classes = useStyles()
    const params = useParams()
    const [openContextMenu, setOpenContextMenu] = useState(false)
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: null, y: null })
    const [openActionTooltip, setOpenActionTooltip] = useState(false)
    const [openActionMenu, setOpenActionMenu] = useState(false)
    const [ActionMenuPosition, setActionMenuPosition] = useState({ x: null, y: null })
    const [searchCriteria, setSearchCriteria] = useStateful(statefulId, 'searchString', '')
    const [openFilters, setOpenFilters] = useState(false)
    const [viewFilterTarget, setViewFilterTarget] = useState(null)
    const [filtersState, setFiltersState] = useStateful(statefulId, 'filters', defaultFilter || {})
	//TODO: can we convert sort to { property, direction } ?
    const [sortState, setSortState] = useStateful(statefulId, 'sort', defaultSort || {})
    const [sideComponentVisible, setSideComponentVisible] = useState(false)
    const [viewColumnTarget, setViewColumnTarget] = useState(null)
    const [columnsState, setColumnsState] = useState(columns)
    // const [rowSelection, setRowSelection] = useState(null)
    const [selection, setSelection] = useStateful(statefulId, 'selection', [])
    const [formState, setFormState] = useState({ open: false })
    const [confirmState, setConfirmState] = useState({ open: false, title: i18nManager.t('confirm.title'), text: i18nManager.t('confirm.text')})
    const [action, setAction] = useState()
    const [recordState, setRecordState] = useState(null)
    const [page, setPage] = useStateful(statefulId, 'page', INITIAL_PAGE)
	const [pageSize, setPageSize] = useStateful(statefulId, 'pageSize', defaultLimit || PAGE_SIZE)
    const [openAction, setOpenAction] = useState(false)
	const [widgetContext, setWidgetContext] = useState()
    const ActionComponent = (action && action.component) ? action.component : Fragment
    const searchPlaceHolder = useMemo(() => {
        const searchColumns = columns.length > 0 ? searchFieldName.map(name => columns.find(column => column.dataKey === name)) : []
        return (searchColumns.length > 0) ? `${i18nManager.t('global.searchBy')} ` + searchColumns.map(column => `${i18nManager.t(column.label)}`).join(', ') : i18nManager.t('global.search')
    }, [columns.length, searchFieldName])
    let doRefresh = useMemo(() => query =>  onRefresh && onRefresh(remoteQuery ? query : null), []) 
    let doSearch = useMemo(() => throttle(criteria => doRefresh({ search: criteria, page }), 500), [])

    const viewFiltersOptions = useMemo(() => columns.filter(column => !!column.filterable), [columnsState.length])
    const viewColumnsSelected = useMemo(() => columnsState.filter(column => !column.hidden).map(column => column.dataKey), [columnsState.length])
    const viewColumnsOptions = useMemo(() => columns.map(column => ({ value: column.dataKey, text: i18nManager.t(column.label) })), [columns.length])

    useEffect(() => {
		isMounted.current = true
		doRefresh({
			search: searchCriteria,
			sort: sorterTransform(sortState),
			filter: filtersTransform(filtersState),
			page, limit: pageSize    
		})
		return () => {
			//Clean-up all asynchronous process when unmount
			isMounted.current = false
		}
	}, [])

	useEffect(() => setSideComponentVisible(selection && selection.length > 0 ? true : false), [selection])

    useEffect(() => {
        const newColumns = [...columns.filter(column => !column.hidden)]
        const shortcuts = actions.filter(action => !!action.shortcut)

        if (shortcuts.length > 0) {
            newColumns.push(shortcutsColumn(shortcuts, handleShortcutClick))
        }
        setColumnsState(newColumns)
    }, [columns, actions])

    useEffect(() => setRecordState(record), [record])

    const handleSearchChange = (event) => {
        const newValue = event.target.value

        setSearchCriteria(newValue)
        if (remoteQuery) {
            setPage(INITIAL_PAGE)
            doSearch(newValue)
        }
    }

    const handleClearSearch = () => {
        const newValue = ''

        setSearchCriteria(newValue) 
        if (remoteQuery) {
            setPage(INITIAL_PAGE)
            doSearch(newValue)   
        }
    }

    const handleFilterChipDelete = name => () => {
        const newFiltersState = {...filtersState}

        delete newFiltersState[name]
        setFiltersState(newFiltersState)

        setPage(INITIAL_PAGE)
        doRefresh({ 
            filter: filtersTransform(newFiltersState),
            page: INITIAL_PAGE 
        }) 
    }

    const handleViewFilters = () => setOpenFilters(true)
    const handleViewFiltersClose = () => setOpenFilters(false)

    const handleViewFiltersSubmit = filters => {
        setFiltersState(filters)
        handleViewFiltersClose()

        setPage(INITIAL_PAGE)
        doRefresh({ 
            filter: filtersTransform(filters),
            page: INITIAL_PAGE 
        })
    }

    const handleRefresh = () => doRefresh()

    const handleSideComponent = (event) => {
        setSideComponentVisible(prevValue => !prevValue)
    }

    const handleViewColumns = (event) => {
        setViewColumnTarget(event.currentTarget)
    }

    const handleViewColumnsClose = () => {
        setViewColumnTarget(null)
    }

    const handleViewColumnsChange = (selection) => {
        const newColumnsState = [...columns].filter(column => selection.indexOf(column.dataKey) !== -1)

        setColumnsState(newColumnsState)
    }

    const handleRowClick = (selection) => {
    //     let newRowSelection = {...selection}

    //     if (rowSelection && rowSelection.id === selection.id) {
    //         setSideComponentVisible(false) 
    //        newRowSelection = null 
    //     }
    //     setSideComponentVisible(newRowSelection ? true : false)
    //     setRowSelection(newRowSelection)
    }

    const handleRowDoubleClick = (args) => {
    //     console.log(args)
    //     setRowSelection(selection ? {...selection} : null)
    }

    const handleContextMenu = event => {
        setContextMenuPosition({
            x: event.clientX,
            y: event.clientY 
        })
        setOpenContextMenu(true)
    }

    const handleContextMenuClose = () => {
        setOpenContextMenu(false)
    }

    const handleContextMenuAction = (event, action) => {
        handleCurrentAction(action)(event)
    }

    const handleActionMenu = event => {
        setActionMenuPosition({
            x: event.clientX + event.currentTarget.offsetTop,
            y: event.clientY 
        })
        setOpenActionTooltip(false)
        setOpenActionMenu(true)
    }

    const handleActionMenuClose = () => {
        setOpenActionMenu(false)
    }

    const handleActionMenuClick = (event, action) => {
        handleCurrentAction(action)(event)
    }

    const handleShortcutClick = async (event, action, row) => {
        const newSelection = [row]

        setSelection(newSelection)
        await runCurrentAction(action, newSelection)
    }

    const handleSelectionChange = (selection) => {
        setSelection([...selection])
    }

    const handlePaginationChange = ({ page, limit }) => {
        setPage(page)
		setPageSize(limit)
        doRefresh({ page, limit })
    }

    const handleSortingChange = (sorter) => {
		setSortState(sorter)
		doRefresh({ sort: sorterTransform(sorter) })
	}

    const handleEditView = () => {
        console.log('handleEditDataTable')
    }

    const handleConfirm = modalReturn => async () => {
        setConfirmState(prevState => Object.assign({...prevState}, {
            open: false
        }))
        if (modalReturn) {
            let { component, selectionMap } = action
            if (component) {
                setFormState(prevState => Object.assign({...prevState}, {
                    open: true
                }))
            }
            else {
                selectionMap = (typeof selectionMap === 'function') ? selectionMap : (item => item.id) 
                //Multiple selection/batch actions
                const { error } = await onAction(action, selection.map(selectionMap))

                if (!error) {
                    setWidgetContext({ ...action.updateAfterSuccess })
                }
            }
        }
    }

    const handleFormClose = () => {
		if (isMounted.current) {
			if (action) {
				let  { refreshOnClose } = action

                refreshOnClose && doRefresh()
                setWidgetContext({ ...action.updateAfterSuccess })
			}
		
			setFormState(prevState => Object.assign({...prevState}, {
				open: false
			}))
			setRecordState(null)
		}
	}

    const handleFormSubmit = async formData => {
        const isBatch = action.batch
        const keepOpened = action.keepOpened
        let params = {}

        if (isBatch) {
            let { selectionMap } = action

            selectionMap = (typeof selectionMap === 'function') ? selectionMap : (({ id }) => ({ id })) 
            formData = selection.map(item => Object.assign(selectionMap(item), formData))
        }
        // else if (action.type === 'update'){
        else if (action.type !== 'create') {
			let  { actionData } = action

			params = selection.length > 0 ? { [entityIdName]: selection[0].id } : {}
			formData = Object.assign(formData, (typeof actionData === 'function') ? actionData() : actionData || {})
        } 

        const { error } = await onAction(action, formData, params)

        if (!error) {
            setWidgetContext({ ...action.updateAfterSuccess })

            if (keepOpened) {
                return true
            } else {
                handleFormClose()
            }   
        } 

        return false

    }

    const handleCurrentAction = currentAction => async event => {
        await runCurrentAction(currentAction, selection)
    }

    const runCurrentAction = async (currentAction, currentSelection) => {
        const { component, confirm } = currentAction
        let { selectionMap } = currentAction

        selectionMap = (typeof selectionMap === 'function') ? selectionMap : (item => item.id) 

        setAction(currentAction)
        if (confirm) {
            const isObject = typeof confirm === 'object'
            setConfirmState(prevState => Object.assign({...prevState}, isObject ? confirm : {}, {
                open: true   
            }))
        }
        else if (component) {
            if (currentSelection && (currentSelection.length > 0)) {
                if ((currentAction.type === 'update' || currentAction.fetchRecord === true) && onFetchRecord) {
                    await onFetchRecord(currentAction, { [entityIdName]: currentSelection[0].id })
                }
                else {
                    setRecordState(selectionMap(currentSelection[0]))
                }
            }
            setFormState(prevState => Object.assign({...prevState}, {
                open: true
            }))

        }
        else {
            //Multiple selection/batch actions
            const { error } = await onAction(currentAction, currentSelection.map(selectionMap))    

            if (!error) {
                setWidgetContext({ ...currentAction.updateAfterSuccess })
            }
        }   
    }

    return (
        <Fragment>
            {!tabMode && (
                <div className={classes.header}>
                    <div className={classes.titleContainer}>
                        <Typography className={classes.title} variant="h6" color="secondary" noWrap>
                            {i18nManager.t(title)}
                        </Typography>
                        {/* <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                            {i18nManager.t(subtitle)}
                        </Typography> */}
                    </div>
                    <AdvancedMode>
                        <Toolbar className={classes.toolbar}>
                            <Fab variant="extended" size="medium" color="secondary" onClick={handleEditView}>
                                Edit
                            </Fab>
                        </Toolbar>
                    </AdvancedMode>
                    <Toolbar className={classes.toolbar}>
                        {actions.map((action, index) => {
							return !(action.hidden && action.hidden(authManager, params)) && !(action.shortcut) && (
								(action.grouped) ? 
									<Tooltip key={index} placement='bottom' title={action.tooltip ? action.tooltip : action.type}>
										<div>
											<Fab
												key={index}
												className={`${classes.fabLight} ${classes.secondary}`}
												size="small"
												disabled={action.disabled && action.disabled(selection, action, { dialogContext })}
												onClick={handleActionMenu}
											>
												<Icon icon={action.icon} size={20} color={Base.iconLight.color}/>
											</Fab>
											{(openActionMenu) && //action.items.map((item, index) => (
										<ActionMenu
											open={openActionMenu}
											position={ActionMenuPosition}
											actions={action.items}
											selection={selection}
											onClose={handleActionMenuClose}
											onAction={handleActionMenuClick}
										/>}
										</div>
									</Tooltip>
									:  
									<Tooltip key={index} placement='bottom' title={action.tooltip ? action.tooltip : action.type}>
										<div>
											<Fab
												key={index}
												className={`${classes.fabLight} ${classes.secondary}`}
												size="small"
												disabled={action.disabled && action.disabled(selection, action, { dialogContext })}
												onClick={handleCurrentAction(action)}
											>
												<Icon icon={action.icon} size={20} color={Base.iconLight.color}/>
											</Fab>
										</div>
									</Tooltip>
							)}
                        )}
                    </Toolbar>
                </div>
            )}
            <div className={ !tabMode ? classes.body : classes.innerBody }>
                {!compactMode && (
                    <div className={classes.configuration}>
                        <Paper className={classes.search}>
                            <div className={classes.searchIcon}>
                                <Icon icon="search" size={20} color={Base.iconLight.color}/>
                            </div>
                            <InputBase
                                ref={setViewFilterTarget}
                                placeholder={searchPlaceHolder}
                                classes={{
                                    root: classes.inputRoot,
                                    // input: clsx(classes.inputInput, (viewFiltersOptions.length === 0) && classes.paddingRight),
                                    input: classes.inputInput
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchCriteria}
                                onChange={handleSearchChange}
                            />
                            {(searchCriteria && searchCriteria.length > 0) && (
                                <div className={(viewFiltersOptions.length === 0) ? clsx(classes.paddingRight, classes.clearIcon) : classes.clearIcon}>
                                    <IconButton size="small" color="secondary" onClick={handleClearSearch}>
                                        <ClearIcon/>
                                    </IconButton>
                                </div>
                            )}
                            {(viewFiltersOptions.length > 0) && (
                                <div className={classes.filterIcon}>
                                    <IconButton size="small" color="secondary" onClick={handleViewFilters}>
                                        <ExpandMoreIcon/>
                                    </IconButton>
                                </div>
                            )}
                        </Paper>
                        <div className={classes.filterChips}>
                            { Object.keys(filtersState).map((name, index) => (<ChipField key={index} label={ filtersState[name].chipLabel } handleDelete={handleFilterChipDelete(name)} size='small'></ChipField>)) }
                        </div>
                        <div className={classes.configButtons}>
                            {tabMode && (
                                <div className={classes.configButtons} style={{marginBottom: 0}}>
                                    {actions.map((action, index) => (
                                        !(action.hidden && action.hidden(authManager, params)) && !(action.shortcut) && (
                                            <Tooltip key={index} title={action.tooltip ? action.tooltip : action.type}>
                                                <div>
                                                    <IconButton 
                                                        key={index}
                                                        aria-label={action.type} 
                                                        disabled={isLoading || inProgress || (action.disabled && action.disabled(selection, action, { dialogContext }))} 
                                                        onClick={handleCurrentAction(action)}
                                                    >
                                                        <Icon icon={action.icon} size={18} color={Base.iconLight.color}/>
                                                    </IconButton> 
                                                </div>
                                            </Tooltip>   
                                            )
                                    ))}
                                    <Typography className={classes.separator}></Typography>  
                                </div>
                            )}
                            <Tooltip title="Refresh"> 
                                <span>
                                    <IconButton aria-label="refresh" disabled={isLoading} onClick={handleRefresh}>
                                        <Icon icon="rotate-cw" size={18} color={Base.iconLight.color}/>
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Columns"> 
                                <IconButton aria-label="columns" onClick={handleViewColumns}>
                                    <Icon icon="columns" size={18} color={Base.iconLight.color}/>
                                </IconButton>
                            </Tooltip>
                            {(SideComponent != null) && (
                                <Tooltip title="Show/Hide Details"> 
                                    <span>
                                        <IconButton aria-label="sidebar" disabled={selection && selection.length === 0} onClick={handleSideComponent}>
                                            <Icon icon="sidebar" size={18} color={Base.iconLight.color}/>
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )}
                <div className={classes.content}>
                    <Paper className={classes.root}>
                        <DataTable
                            isOpen={formState.open}
                            inProgress={inProgress}
                            rowCount={rows.length}
                            rowGetter={({ index }) => rows[index]}
                            rows={rowsFilter(rows, !remoteQuery && 
                                searchFieldName ? [{ prop: searchFieldName, op: 'contain', value: searchCriteria }] : []
                            )}
							defaultSort={sortState}
							selected={selection}
                            totalCount={totalCount}
                            isLoading={isLoading}
                            remoteQuery={remoteQuery}
                            columns={columnsState}
                            //columns={columnsState.filter(column => !column.hidden)}
                            pageNumber={page}
                            pageSize={pageSize}
                            allowSelection={allowSelection}
                            compactMode={compactMode}
                            onRowClick={handleRowClick}
                            onRowDoubleClick={handleRowDoubleClick}
                            onContextMenu={(actions.length > 0) ? handleContextMenu : null}
                            onSelectionChange={handleSelectionChange}
                            onPaginationChange={handlePaginationChange}
                            onSortingChange={handleSortingChange}
                        />
                         {/* {(isLoading || (inProgress && !formState.open)) && (<ActivityMask/>)} */}
                    </Paper>
                    {(sideComponentVisible && selection[0] && SideComponent) && (
                        <div className={classes.details}>
                            <SideComponent
                                record={selection[0]}
                                context={widgetContext}
                            />
                        </div>
                    )}
                </div>
                {(openContextMenu) && (
                    <ContextMenu
                        open={openContextMenu}
                        position={contextMenuPosition}
                        actions={actions}
                        selection={selection}
                        onClose={handleContextMenuClose}
                        onAction={handleContextMenuAction}
                    />
                )}
                {(openFilters) && (
                    <ViewFilters
                        className={classes.filters}
                        filters={filtersState}
                        options={viewFiltersOptions}
                        open={openFilters}
                        target={viewFilterTarget}
                        maxHeight={VIEW_FILTERS_HEIGHT}
                        onSubmit={handleViewFiltersSubmit}
                        onClose={handleViewFiltersClose}
                    />
                )}
                {(viewColumnTarget) && (
                    <ViewColumns
                        className={classes.viewColumns}
                        selected={viewColumnsSelected}
                        options={viewColumnsOptions}
                        target={viewColumnTarget}
                        width={200}
                        maxHeight={VIEW_COLUMNS_HEIGHT}
                        onChange={handleViewColumnsChange}
                        onClose={handleViewColumnsClose}
                    />
                )}
                {(confirmState.open) && (
                    <Dialog
                        open={confirmState.open}
                        onClose={handleConfirm(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{confirmState.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {confirmState.text}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirm(false)} color="primary">
                                {i18nManager.t('No')}
                            </Button>
                            <Button onClick={handleConfirm(true)} color="primary" autoFocus>
                                {i18nManager.t('Yes')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
                {(action && formState && formState.open) && (
                    <ActionComponent
                        open={formState.open}
                        inProgress={inProgress}
                        title={i18nManager.t(action.title || title)}
                        subtitle={i18nManager.t(action.subtitle || subtitle)}
                        fields={[]} //TODO:
                        record={recordState}
                        onSubmit={handleFormSubmit}
                        onCancel={handleFormClose}
                    />
                )}
            </div>
        </Fragment>
    )
}

DataBrowser.defaultProps = {
    actions: [],
    columns: [],
    rows: []
}

DataBrowser.propTypes = {
    title: PropTypes.string.isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ),
    columns: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
    onAction: PropTypes.func.isRequired
}

export default DataBrowser