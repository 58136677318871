import React from 'react';

import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'

const columnsOverride = [{
    type: 'AvatarColumn',
    dataKey: 'largeImage',
    columnText: 'name',
    order: 1,
    width: 10
}, {
    dataKey: 'modelNo',
    order: 2
}, {
    dataKey: 'name',
    order: 3
}, {
    type: 'StatusColumn',
    size: 'small',
    dataKey: 'status',
    order: 8
}]

/*
    confirm:    [Config] Boolean/Object with title & text to show confirm modal dialog
*/
const actionsOverride = [/* TODO: 
    {
    type: 'delete',
    disabled: selection => (selection.length === 0),
    confirm: true
}*/]

/*
    title:      [Config]
    subtitle:   [Config]
    path:       [Config]
    actions:    [Config]
    columns:    [Config]
    resources:  [Config]
    params:     ?
*/
const Devices = ({ actions, columns, resources, params, ...others }) => {
    const { actionsState, columnsState, dataState, handleRefresh, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions, 
        columns, 
        resources
    })

    return (
        <DataBrowser
			//statefulId="devices"
            actions={actionsState}
            columns={columnsState}
            rows={dataState}
            searchFieldName={['name']}
            onRefresh={handleRefresh}
            onAction={handleAction}
            isLoading={isLoading}
            inProgress={inProgress}
            {...others}
        />
    )
}

export default Devices