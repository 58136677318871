export default theme => ({
    appBar: {
        boxShadow: 'none',
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    fab: {
        backgroundColor: theme.palette.secondary.main
    },
    fabLight: {
        backgroundColor: 'rgba(75,75,76, .1)'
    },
    iconLight: {
        color: 'rgba(75,75,76, .8)'
    },
    iconLighter: {
        color: 'rgba(0, 0, 0, 0.3)'
    },
    search: {
        position: 'relative',
        borderRadius: 2,
        height: 47,
        boxShadow: 'none',
        border: '1px solid #E2E2E3',
        backgroundColor: theme.palette.common.white,
        marginLeft: 0,
        width: 300,
        marginBottom: theme.spacing(2),
    },
    badge: {
        color: '#FFF',
        backgroundColor: '#EF7373'
    },
    warning: {
        color: '#FCA861'
    },
    success: {
        color: '#4CB091'
    },
    error: {
        color: '#EF7373'
    },
})