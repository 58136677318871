import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, FormControl, InputLabel, Chip, useTheme, FormControlLabel, Switch, Paper, Tooltip, Typography } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

import { I18nManagerContext } from 'contexts/I18nManager';
import Chart from 'components/core/Chart';
import DataTable from 'components/core/DataTable'
import SelectMultipleField from 'components/core/SelectMultipleField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { capitalized, uncapitalized } from 'utils'
import { AuthManagerContext } from 'contexts/AuthManager'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import ReorderIcon from '@material-ui/icons/Reorder';
import { previousDay, startDateRange, getTimeStartDate, getSpecificTimeDay, getTimeEndDate, formatFloatHours } from 'helpers'

import { fecthMeasuresData, fecthDetailMeasuresData } from 'actions';
import clsx from 'clsx';
import CustomAvatar from 'components/core/CustomAvatar';

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '100%',
        maxWidth: '100%', //400
        height: '80vh'
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        padding: '24px 44px',
        paddingBottom: 15,
    },
    avatar: {
        margin: '2px 5px 0px 10px'
    },
    root: {
        flexGrow: 1,

        boxShadow: 'none',
        border: '1px solid #E2E2E3',
        borderRadius: 2,

        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    tableContainer: {
        display: 'flex',
        padding: 20,
        flexGrow: 1,
        alignItems: 'stretch',
        paddingTop: 32,
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    tableRoot: {
        flexGrow: 1,
        overflowY: 'scroll',
        boxShadow: 'none',
        border: '1px solid #E2E2E3',
        borderRadius: 2,
    },
    tableContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        flexGrow: 1,
        height: 1
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0)
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4)
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 10,
        paddingLeft: 20
        // padding: theme.spacing(5) ,
        // paddingTop: theme.spacing(4),
        // paddingBottom: theme.spacing(0)
    },
    formControl: {
        // flex: 1
        minWidth: 200
    },
    toggleContainer: {
        minWidth: 580,
        paddingRight: 15,
        marginLeft: 'auto'
    },
    titleContainer: {
        flex: 1
    },
    title: {
        display: 'flex',
        flexGrow: 1
    },
    patient: {
        display: 'flex',
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
        display: 'block',
        paddingLeft: theme.spacing(1 / 4)
        // },
    },
    step: {
        height: '100%',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        paddingTop: 70
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45%'
    },
    textField: {
        width: theme.spacing(41)
    },
    actions: {

    },
    buttons: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: theme.spacing(19.5),
        marginLeft: theme.spacing(1) / 2,
        marginRight: theme.spacing(1) / 2,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    },
    chart: {
        position: 'absolute',
        height: '90%',
        top: 76
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const mapState = state => {
    return {
        charts: state.charts.data,
        resources: state.resources.data,
        data: state.chart.data.data,
        inProgress: state.chart.inProgress,
        goals: state.chart.data.goals
    }
}

const measuresMap = {
    heartRate: 'Heart Rate',
    bodyWater: 'Body Water',
    bodyFat: 'Body Fat',
    muscleMass: 'Muscle Mass',
    boneMass: 'Bone Mass',
    pulseOximetry: 'Pulse Oximetry',
    walkingDistance: 'Walking Distance',
    deepSleep: 'Deep',
    lightSleep: 'Light'
}

const columnMap = (dateRange, key) => {
    const column = {}

    switch (key) {
        case 'date':
            return column[key] = {
                type: (dateRange === 'day') ? 'DateTimeColumn' : 'DateColumn'
            }
        case 'awake':
        case 'deepSleep':
        case 'lightSleep':
            return column[key] = {
                render: (column, row, { columnClasses }) => {
                    return (
                        <Chip label={(dateRange === 'day') ? (row[column.dataKey] ? '' : null) : formatFloatHours(row[column.dataKey])}
                            className={row[column.dataKey] ? clsx(columnClasses.chip, columnClasses[key]) : columnClasses.noValueChip}
                        />
                    )
                }
            }
        default:
            return column[key] = {
                render: (column, row, { goals, columnClasses }) => {
                    let goal = goals.find(goal => uncapitalized(goal.measure).replace(/\s/g, '') == key)

                    let cls = goal ? (row[column.dataKey] >= goal['criticalZoneHigh']
                        ? 'critical'
                        : row[column.dataKey] >= goal['baselineHigh']
                            ? 'baseline'
                            : row[column.dataKey] <= goal['criticalZoneLow']
                                ? 'critical'
                                : row[column.dataKey] <= goal['baselineLow']
                                    ? 'baseline'
                                    : 'goal') : ''

                    return (
                        <div className={columnClasses.vbox}>
                            <Chip label={row[column.dataKey]}
                                className={row[column.dataKey] ? clsx(columnClasses.chip, cls) : columnClasses.noValueChip}
                            />
                        </div>
                    )
                }
            }
    }
}

const asColumns = (dateRange, data) => {
    return Object.keys(data[0] || {}).map(key => {
        const column = columnMap(dateRange, key)

        return Object.assign({
            label: measuresMap[key] || capitalized(key),
            dataKey: key
        }, column)
    })
}

const asRows = (data) => {
    const keys = Object.keys(data[0] || {})

    return data.filter(row => {
        let exists = false
        let ln = keys.length
        let i = 0

        while (!exists && i < ln) {
            const key = keys[i]
            exists = (key !== 'date' && row[key])
            i++
        }
        return exists
    })
}

function PatientChartDialog({ open, measure, patient, onClose }) {
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const params = useParams()
    const dispatch = useDispatch()
    const authManager = useContext(AuthManagerContext)
    // const [isSubmitting, setSubmitting] = useState(false)
    // const [state, setState] = useState(record || {})
    const [measures, setMeasures] = React.useState([measure]);
    const { charts, data, goals, inProgress } = useSelector(mapState)
    const [chartsDef, setChartsDef] = React.useState(charts.find(chart => chart.id === measure));

    const [dateRange, setDateRange] = React.useState(chartsDef.dateRange);
    const [showThreshold, setShowThreshold] = React.useState(false);
    const [view, setView] = React.useState('chart');
    const [day, setDay] = React.useState(new Date())
    const [startDate, setStartDate] = React.useState(getTimeStartDate(day))
    const [endDate, setEndDate] = React.useState(getTimeEndDate(day))

    const handleDateRange = (event, newDateRange) => {
        if (!!newDateRange) {
            setDateRange(newDateRange);
        }
    };

    const handleView = (event, newView) => {
        if (!!newView) {
            setView(newView);
        }
    };

    const handleShowThresholdChange = event => {
        setShowThreshold(event.target.checked);
    };

    const handleChange = event => {
        const value = event.target.value

        if (value && value.length > 0) {
            setMeasures(value);
            setShowThreshold(false);
        }
    };

    // TODO:
    // const handleDelete = chipToDelete => () => {
    //     setMeasures(chips => chips.filter(chip => chip.key !== chipToDelete.key));
    // };

    useEffect(() => {
        switch (dateRange) {
            case 'day':
                const newStartDate = (measures[0] == 3) ? previousDay(getSpecificTimeDay(day, 18)) : getTimeStartDate(day)
                const newEndDate = (measures[0] == 3) ? getSpecificTimeDay(day, 18) : getTimeEndDate(day)

                setStartDate(newStartDate)
                setEndDate(newEndDate)

                dispatch(fecthDetailMeasuresData(Object.assign({ customerId: authManager.customerId, patientId: patient.id }, params), { measures, startDate: newStartDate, endDate: newEndDate, includeQualifier: 1 }))
                break;
            default:
                dispatch(fecthMeasuresData(Object.assign({ customerId: authManager.customerId, patientId: patient.id }, params), { measures, startDate: startDateRange(dateRange), endDate: getTimeEndDate(new Date()), includeQualifier: 1 }))
                break;
        }

    }, [patient, measures, dateRange, showThreshold])

    useEffect(() => {
        const chartsDef = []
        for (const measure of measures) {
            chartsDef.push(charts.find(chart => chart.id === measure))
        }

        setChartsDef(chartsDef)
    }, [measures])

    return (
        <Dialog
            classes={{
                paper: classes.dialogPaper
            }}
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            keepMounted
        >
            <div className={classes.dialogTitle}>
                <Typography className={classes.title} variant="h6" color="secondary" noWrap>
                    Measures
                </Typography>
                <Typography className={classes.patient} variant="h6" color="secondary" noWrap>
                    {patient && patient.fullName}
                    <CustomAvatar image={patient && patient.image} name={patient && patient.fullName} className={classes.avatar}></CustomAvatar>
                </Typography>
            </div>
            <DialogContent className={classes.content}>
                <div className={classes.actionContainer}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-chip-label">Measures</InputLabel>
                        <SelectMultipleField
                            autoComplete="off"
                            allowEmpty={false}
                            value={measures}
                            resource={(authManager.customerId && patient.id) && { path: '/customers/:customerId/patients/:patientId/measures', params: Object.assign({ customerId: authManager.customerId, patientId: patient.id }, params) }}
                            onChange={handleChange}
                            renderValue={selected => (
                                <div className={classes.chips}>
                                    {selected && selected.map(item => (
                                        <Chip key={item['value']} label={capitalized(item['text'])} className={classes.chip} /> //TODO: onDelete={handleDelete(measures)}
                                    ))}
                                </div>
                            )}
                            textName="text"
                            valueName="value"
                        />
                    </FormControl>
                    <div className={classes.toggleContainer}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showThreshold}
                                    onChange={handleShowThresholdChange}
                                    value="checkedB"
                                    color="primary"
                                    disabled={measures.length > 1 || view != 'chart'}
                                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                                />
                            }
                            label="Show Thresholds"
                        />
                        <ToggleButtonGroup
                            value={dateRange}
                            exclusive
                            onChange={handleDateRange}
                        >
                            <ToggleButton value="day">
                                <label>day</label>
                            </ToggleButton>
                            <ToggleButton value="week">
                                <label>week</label>
                            </ToggleButton>
                            <ToggleButton value="month">
                                <label>month</label>
                            </ToggleButton>
                            <ToggleButton value="year">
                                <label>year</label>
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup
                            value={view}
                            exclusive
                            onChange={handleView}
                        >
                            <ToggleButton value="chart">
                                <Tooltip key='chart' title='Chart view'>
                                    <ShowChartIcon />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value="grid">
                                <Tooltip key='list' title='List view'>
                                    <ReorderIcon />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                {view == 'chart' ? (
                    <Chart
                        className={classes.chart}
                        data={data}
                        goals={goals}
                        inProgress={inProgress}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        day={day}
                        setDay={setDay}
                        startDate={startDate}
                        endDate={endDate}
                        showThreshold={showThreshold}
                        chartsDef={chartsDef}
                    />
                ) : (
                        <div className={classes.tableContainer}>
                            <div className={classes.tableContent}>
                                <Paper className={classes.tableRoot}>
                                    <DataTable
                                        isOpen={false}
                                        inProgress={inProgress}
                                        rowCount={data.length}
                                        rowGetter={({ index }) => { } /*rows[index]*/}
                                        context={{ goals }}
                                        columns={asColumns(dateRange, data)}
                                        rows={asRows(data)}
                                        allowSelection={false}
                                    //onRowClick={handleRowClick}
                                    //onSelectionChange={handleSelectionChange}
                                    />
                                </Paper>
                            </div>
                        </div>
                    )
                }
            </DialogContent>
        </Dialog>
    )
}

export default PatientChartDialog