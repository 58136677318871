import merge from 'lodash/merge';

import { AuthManagerContext } from 'contexts/AuthManager';
import { useState, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createRequestId, filterByProp, mergeByProp, ascending, descending } from 'utils'

import { fetchDataSource, fetchRecord, createRecord, updateRecord, deleteRecords  } from 'actions/index';

const actionKey = 'type'
const columnKey = 'dataKey'

const mapActions =  {
    // 'get':      fetchRecord,
    'post':     createRecord,
    'put':      updateRecord,
    'delete':   deleteRecords
}

const applyObject = (target, source) => Object.keys(target).reduce((newTarget, key) => {
    newTarget[key] = merge(target[key], source)
    return newTarget
 }, {})

const mapState = ({ resources }) => state => {
    let data = []
    let totalCount = 0
    let isLoading = false
    let inProgress = false

    if (resources['list']) {
        const id = createRequestId(resources['list'])

        data = (state.datasources[id]) ? state.datasources[id].data : []
        totalCount = (state.datasources[id]) ? state.datasources[id].totalCount : 0
        isLoading = ((state.datasources[id]) ? state.datasources[id].isLoading : false) || isLoading
    }
    inProgress = ['create', 'update', 'delete', 'activate', 'inactivate'].reduce((inProgress, type) => { // TODO: 
        if (resources[type]) {
            const id = createRequestId(resources[type])

            inProgress = inProgress || ((state.record[id]) ? state.record[id].inProgress : false)
        }
        return inProgress
    }, inProgress)

    return {
        data, totalCount, isLoading, inProgress
    }
}

/*
    actionsMerge:   [Prop] First array of the array is actionsOverride and it's used to filter actions, the rest of elements just are used to merge
    columnsMerge:   [Prop] First array of the array is columnsOverride and it's used to filter columns, the rest of elements just are used to merge
    props:          [Prop] Properties from parent component
        actions:    [Config] Array of actions of DataBrowser
        columns:    [Config] Array of columns of DataBrowser
        resources:  [Config] Object of CRUD (& more) resources (list, create, read, udpdate, delete ...)

    return:
    
    actionsState:       [Prop]
    columnsState:       [Prop]
    dataState:          [Prop]
    recordState:        [Prop]
    handleFetchRecord:  [Prop] 
    handleAction:       [Prop]  
    isLoading:          [Prop]  
    inProgress:         [Prop] 
*/
const useDataBrowser = (actionsMerge = [], columnsMerge = [], { 
    actions = [],
    columns = [], 
    resources = {}
}) => {
    const authManager = useContext(AuthManagerContext)
    const params = useParams()
    const dispatch = useDispatch()
    const { data: dataState, totalCount: totalCountState, isLoading, inProgress } = useSelector(mapState({ resources: applyObject(resources, { 
        params: Object.assign({ customerId: authManager.customerId }, params)}) 
    }))
    const [columnsState, setColumnsState] = useState([])
    const [actionsState, setActionsState] = useState([])
    const actionsDeps = useMemo(() => JSON.stringify([actions, actionsMerge]), [actions, actionsMerge])
    const columnsDeps = useMemo(() => JSON.stringify([columns, columnsMerge]), [columns, columnsMerge])
    // const [refresh, setRefresh] = useState(true)
    const [recordState, setRecordState] = useState(null)
    const [fetchingRecord, setFetchingRecord] = useState(false)
    const [queryState, setQueryState] = useState(null)
    // const deps = useMemo(() => JSON.stringify([resources['list'], refresh]), [resources['list'], refresh])

    useEffect(() => {
        const [actionsOverride, ...others] = actionsMerge
        const actionsKeys = actionsOverride.map(action => action[actionKey])

        setActionsState(mergeByProp(
            filterByProp(actions, actionsKeys)(actionKey),
            actionsOverride, ...others
        )(actionKey)
        .sort(descending('order')))
    }, [actionsDeps])

    useEffect(() => {
        const [columnsOverride, ...others] = columnsMerge
        const columnsKeys = columnsOverride.map(column => column[columnKey])

        setColumnsState(mergeByProp(
            filterByProp(columns, columnsKeys)(columnKey),
            columnsOverride, ...others
        )(columnKey)
        .sort(ascending('order')))
    }, [columnsDeps])

    useEffect(() => {
        if (resources['list'] && queryState) {
            dispatch(fetchDataSource(resources['list'], queryState))    
        }    
    }, [queryState])

    const handleRefresh = (query = {}) => setQueryState(prevState => Object.assign({...prevState}, query))

    const handleFetchRecord = (action, params) => {
        let resource = resources['read']

        resource = Object.assign(resource, { params: Object.assign(resource.params, params) })

        setFetchingRecord(true)
        setRecordState(null)

        return dispatch(fetchRecord(resource))
        .then(action => {
            const { payload, error } = action

            setFetchingRecord(false)
            if (!error) {
                setRecordState(payload)
                return Promise.resolve(action)
            }
            else {
                return Promise.reject(error)
            }
        })
        .catch(error => {
            setFetchingRecord(false)
            return Promise.reject(error)
        })
    }

    const handleAction = (action, data, actionParams) => {
        const { 'resource': type } = action
        let resource = {...resources[type]}

        if (resource) {
            const method = resource.method && resource.method.toLowerCase()

            if (mapActions[method]) {
                resource = Object.assign(resource, { params: { customerId: authManager.customerId, ...params, ...actionParams }})
                return dispatch(mapActions[method](resource, data))
                .then(action => {
                    const { payload, error } = action

                    if (!error) {
                        const resultsWithFailure = payload.results && payload.results.find(result => !result.success)

                        // setRefresh(true)
                        handleRefresh()
                        if (resultsWithFailure) {
                            return Promise.reject({ error: 'This batch operation return with failure(s) item(s)' })   
                        }
                    }
                    return action
                })
            }
        }
        return Promise.reject({ error: 'No action/resource found' })
    }

    return useMemo(() => 
        ({ actionsState, columnsState, dataState, totalCountState, recordState, handleRefresh, handleFetchRecord, handleAction, isLoading: (isLoading || fetchingRecord), inProgress }),
        [actionsState.length, columnsState.length, (dataState.length > 0 && dataState[0]), totalCountState, recordState, isLoading, fetchingRecord, inProgress]
    )
    // return { actionsState, columnsState, dataState, totalCountState, recordState, handleRefresh, handleFetchRecord, handleAction, isLoading: (isLoading || fetchingRecord), inProgress }
}

export default useDataBrowser