import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Icon } from 'styles';
import { Base } from 'components/themes/main';

const useStyles = makeStyles(theme => ({
	icon: {
    	padding: 6
  	}
}))

const inputComponent = ({ id, inputRef, onChange, ...other }) => {

	const handleValueChange = values => {
		const value = values.value

		onChange && onChange({
			target: {
				id, value
			}
		})
	}

  	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={handleValueChange}
			format="(###) ###-####"
			placeholder="(###) ###-####"
			mask={['#', '#', '#', '#', '#', '#', '#', '#', '#', '#']}
			isNumericString
		/>
  	);
}

inputComponent.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired
};

const PhoneField = ({ innerRef, InputProps, onChange, defaultValue, value, ...props }) => {
	const classes = useStyles()
	const [valueState, setValueState] = useState(defaultValue ? defaultValue : value)
	
	const handleClickCall = () => {
		if (/^(\+?1)?[-.\s]?\(?(\d{3,3})[)-.\s]{0,2}(\d{3,3})[-.\s]?(\d{4,4})\s*$/i.test(valueState)) {
			window.open('tel:' + valueState, '_self')
		}
	}

	const handleChange = (event) => {
		const { value } = event.target
		
		setValueState(value)
		if (onChange) {
			onChange(event)
		}
	}

	return (
		<TextField
			inputRef={innerRef}
			InputProps={Object.assign(InputProps || {}, {
				inputComponent,
				endAdornment:
					<InputAdornment position="end">
						<IconButton
							className={classes.icon}
							onClick={handleClickCall}
							aria-label="make phone call"
							disabled={!(valueState || value)}
						>
							<Icon icon={'phone'} size={16} color={Base.iconLight.color} />
						</IconButton>
					</InputAdornment>
			})}
			onChange={handleChange}
			defaultValue={defaultValue}
			value={value}
			{...props}
		/>
	)
}

export default PhoneField
