import React, { useRef, useEffect, createRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import useForm from 'hooks/useForm';

import SelectField from 'components/core/SelectField';
import PhoneField from 'components/core/PhoneField';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginRight: 5
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function EmergencyContactForm({ record, isSubmitting, onSubmit, onError, onDirty }) {
    const classes = useStyles()
    const ref = useRef()
    const fieldsRef = useRef(['emergencyName', 'emergencyEmail', 'emergencyPhone'].reduce((result, name) => {
        result[name] = createRef()
        return result
    }, {}))
    const [validationsState, setValidationsState] = useState({ emergencyEmail: ['email'] })
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validationsState, record)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        if (Object.keys(errors).length > 0 && onError) {
            onError(errors)
        }
    }, [errors])

    useEffect(() => {
        setValidationsState(prevState => {
            const newState = { ...prevState }

            state.emergencyName ? newState['emergencyPhone'] = ['required', 'phone'] : delete newState['emergencyPhone']
            state.emergencyName ? newState['emergencyRelationshipId'] = ['required'] : delete newState['emergencyRelationshipId']

            return newState
        })
    }, [state.emergencyName])

    useEffect(() => {
        if (isSubmitting === true) {
            handleSubmit()
        }
        else if (Object.keys(errors).length > 0) {
            const id = Object.keys(errors)[0]
            const fieldRef = fieldsRef.current && fieldsRef.current[id]

            if (fieldRef && fieldRef.current) {
                fieldRef.current.focus()
                fieldRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
            }
        }
    }, [isSubmitting])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            <div className={classes.hbox}>
                <div className={classes.vbox}>
                    <TextField
                        ref={fieldsRef.current['emergencyName']}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="emergencyName"
                        label="Full Name"  // TODO: 
                        defaultValue={state && state.emergencyName}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!errors['emergencyName']}
                        helperText={!!errors['emergencyName'] && errors['emergencyName']}
                    />
                    <PhoneField
                        innerRef={fieldsRef.current['emergencyPhone']}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="emergencyPhone"
                        label="Phone"  // TODO:  
                        defaultValue={state && state.emergencyPhone}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors['emergencyPhone']}
                        helperText={!!errors['emergencyPhone'] && errors['emergencyPhone']}
                    />
                    <TextField
                        ref={fieldsRef.current['emergencyEmail']}
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="emergencyEmail"
                        label="Email"     // TODO: 
                        type="email"
                        required={false}
                        defaultValue={state && state.emergencyEmail}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors['emergencyEmail']}
                        helperText={!!errors['emergencyEmail'] && errors['emergencyEmail']}
                    />
                    <SelectField
                        InputLabelProps={InputLabelProps}
                        autoComplete="off"
                        margin="normal"
                        id="emergencyRelationshipId"
                        label="Relationship To Patient"
                        defaultValue={state ? (state.emergencyRelationshipId || "") : ""}
                        required={true}
                        error={!!errors['emergencyRelationshipId']}
                        helperText={!!errors['emergencyRelationshipId'] && errors['emergencyRelationshipId']}
                        resource={{ path: '/relationships' }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        textName="text"
                        valueName="value"
                    />
                </div>
            </div>
        </form>
    )
}