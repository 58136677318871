import * as Types from 'constants/ActionTypes';

const datasources = (state = {}, action) => {
    switch (action.type) {
        case Types.FETCH_DATASOURCE:
            return Object.assign({}, state, {
                [action.id]: {
                    inProgress: true,
                    isLoading: true,
                    didInvalidate: false,
                    data: []
                }
            })
        case Types.FETCH_DATASOURCE_SUCCESS:
            return Object.assign({}, state, {
                [action.id]: {
                    inProgress: false,
                    isLoading: false,
                    didInvalidate: false,
                    data: action.payload,
                    totalCount: action.data.total || action.payload.length,
                    lastUpdated: action.receivedAt
                }
            })
        case Types.CLEAR_DATASOURCE_SUCCESS:
            delete state[action.id]
            return state
            // return Object.assign({}, state, {
            //     [action.id]: {
            //         inProgress: false,
            //         didInvalidate: false,
            //         data: [],
            //         lastUpdated: action.receivedAt
            //     }
            // })
        case Types.FETCH_DATASOURCE_FAILURE:
            return Object.assign({}, state, {
                [action.id]: {
                    inProgress: false,
                    isLoading: false,
                    didInvalidate: false,
                    data: [],
                    lastUpdated: action.receivedAt
                }
            })
        default:
            return state
    }
};

export default datasources