import React, { Fragment, forwardRef, useRef, useState, useContext, useEffect } from 'react';
// import {  Link  } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, CircularProgress, Paper, Stepper, Step, StepLabel, StepConnector, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

import useForm from 'hooks/useForm';
import clsx from 'clsx';
import { Icon } from 'styles';

import { I18nManagerContext } from 'contexts/I18nManager';
import PatientContactForm from 'components/PatientContactForm'
import PatientProfileForm from 'components/PatientProfileForm'
import BillingInfoForm from 'components/BillingInfoForm'
import PatientPCPForm from 'components/PatientPCPForm'
import EmergencyContactForm from 'components/EmergencyContactForm'
import { useParams } from 'react-router-dom';
import { AuthManagerContext } from 'contexts/AuthManager'
import { useDispatch } from 'react-redux';
import { getCurrentCustomer } from 'actions';

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 600
    },

    root: {
        flexGrow: 1,
        
        boxShadow: 'none',
        border: '1px solid #E2E2E3',
        borderRadius: 2,

        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(5) ,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0)
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4)
    },
    titleContainer: {
        flex: 1
    },
    title: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            padding: 0
        // },
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    content: {
        // paddingTop: 8,
        paddingLeft: 75,
        paddingRight: 75
    },
    step: {
        height: '100%',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        // paddingTop: 20
    },
    message: {
        paddingTop: 6,
        paddingBottom: 13,
        textAlign: 'center',
        fontSize: '.9rem',
        fontStyle: 'italic'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45%'
    },
    textField: {
        width: theme.spacing(41)
    },
    actions: {
        
    },
    buttons: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    buttonsCancel: {
        marginTop: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: theme.spacing(19.5),
        marginLeft: theme.spacing(1)/2,
        marginRight: theme.spacing(1)/2,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const QontoConnector = withStyles({
    alternativeLabel: {
      right: 'calc(50% + 30px)',
    },
    active: {
      '& $line': {
        borderColor: '#4CB0AC',
        borderTopWidth: 1
      },
    },
    completed: {
      '& $line': {
        borderColor: '#4CB0AC',
      },
    },
    line: {
      borderColor: '#E9E9F0',
      borderTopWidth: 1,
      borderRadius: 1
    },
  })(StepConnector);

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#4CB0AC',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      fontSize: 15
    },
    active: {
      color: '#4CB0AC'
    },
    circle: {
        border: '1px solid rgba(0, 0, 0, 0.2)',
        color: 'rgba(0, 0, 0, 0.3)',
        borderRadius: 100,
        width: '2.5em',
        height: '2.5em',
        textAlign: 'center',
        lineHeight: '2.4em'
    },
    circleActive: {
        border: '1px solid #4CB0AC',
        backgroundColor: '#4CB0AC',
        color: 'white',
        borderRadius: 100,
        width: '2.5em',
        height: '2.5em',
        textAlign: 'center',
        lineHeight: '2.4em'
    },
    completed: {
        borderRadius: 100,
        backgroundColor: '#4CB0AC',
        width: '2.5em',
        height: '2.5em',
        textAlign: 'center',
        lineHeight: '2.4em'
    },
  });
  
  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed, icon } = props;
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <div className={classes.completed}><Icon icon="check" size={20} /></div> : active ? <div className={classes.circleActive}> { icon } </div> : <div className={classes.circle}> { icon } </div>}
        
      </div>
    );
  }
  
  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
  };

//TODO: localize
const Steps = [
    { label: 'Contact', component: PatientContactForm },
    { label: 'Profile', component: PatientProfileForm },
    { label: 'Address', component: BillingInfoForm },
    { label: 'PCP', component: PatientPCPForm },
    { label: 'Emergency Contact', component: EmergencyContactForm }
]

function getSteps() {
    return Steps.map(step => step.label)
}
  
function StepComponent({ activeStep, ...others }) {
    const Component = Steps[activeStep].component
    return (
        <Component {...others} />
    ) 
}

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function PatientStepper({ open, params = {}, title, subtitle, record, resource, fetchRecord, inProgress, onSubmit, onCancel }) {
    const i18nManager = useContext(I18nManagerContext)
	const authManager = useContext(AuthManagerContext)
	const dispatch = useDispatch()
    const classes = useStyles()
    const [isSubmitting, setSubmitting] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const steps = getSteps()
    const [isDirty, setIsDirty] = useState(false)
    const [isCancelling, setIsCancelling] = useState(false)
    const [state, setState] = useState({country: 'US', heightUnitMeasureId: 6, weightUnitMeasureId: 25, height: 5, weight: 120 })   // TODO: ft, lb

	const parameters = useParams() 
	const customerId = parameters.customerId || authManager.customerId

	// console.log(state)

	useEffect(() => {
        async function fetchData() {
          const { data: { result }, error } = await dispatch(getCurrentCustomer({ customerId: customerId }))
            
          if (!error && result) {
            const { typeId } = result
				setState(Object.assign({ ...state }, { customerTypeId: typeId }))
          }
        }

        fetchData();
    }, [customerId]);


    // const { params } = match

    useEffect(() => {
        if (params && params.id) {
            fetchRecord(Object.assign(resource, { params }))
        }
    }, [resource])

    // useEffect(() => {
    //     setState({ ...record })
    // }, [record])

    const handleCancel = () => {
        if (isDirty) {
            setIsCancelling(true)
        } else {
            onCancel()
        }
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const handleError = () => {
        setSubmitting(false)   
    }

    const handleContinue = event => {
        event.preventDefault()
        setSubmitting(true)
    }

    const handleDirty = isDirty => {
        setIsDirty(isDirty)
    }

    const handleConfirm = () => {
        onCancel()
    }

    const handleDenied = () => {
        setIsCancelling(false)
    }

    const handleSubmit = newState => {
        setSubmitting(false)
        // setState(prevState => Object.assign(prevState, {...newState}))
        setState({...newState})
        if (activeStep < steps.length - 1) {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        }
        else {
            onSubmit(newState)
        }
    }

    return (
        <Dialog 
            classes={{
                paper: classes.dialogPaper
            }}
            open={open} 
            onClose={onCancel} 
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            keepMounted
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="form-dialog-title">
                {/* <Typography className={classes.title} variant="h6" color="secondary" noWrap> */}
                    {i18nManager.t(title)}
                {/* </Typography> */}
                <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                    {i18nManager.t(subtitle)}
                </Typography> 
                <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper> 
            </DialogTitle>
            <DialogContent className={classes.content}>
                {/* <DialogContentText>
                    <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                        {i18nManager.t(subtitle)}
                    </Typography>  
                </DialogContentText> */}
               
                <div className={classes.step}>
                    <StepComponent 
                        activeStep={activeStep} 
                        record={state}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmit}
                        onError={handleError}
                        onDirty={handleDirty}
                    />
                </div>
            </DialogContent>
            {isCancelling ? (
                <Fragment> 
                    <Typography className={classes.message} variant="caption" color="error" noWrap>
                        Are you sure you want to cancel? All changes will be lost.
                    </Typography> 
                    <DialogActions className={classes.buttonsCancel}>
                        <Button variant="contained" className={classes.button} onClick={handleDenied}>No</Button>
                        <Button variant="contained" className={classes.button} onClick={handleConfirm} color="secondary">Yes</Button>
                    </DialogActions> 
                </Fragment>
            ) : (
            <DialogActions className={classes.buttons}>
                <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
                    {(activeStep > 0 && activeStep < steps.length) && (
                        <Button variant="contained" className={classes.button} onClick={handleBack}>Back</Button>
                    )}
                <Button variant="contained" className={classes.button} onClick={handleContinue} color="secondary">
                    {(activeStep < steps.length - 1) ? "Continue" : "Finish"}
                </Button>
            </DialogActions>
            )}
        </Dialog>
        // <Fragment>
        //     <div className={classes.header}>
        //         <div className={classes.titleContainer}>
        //             <Typography className={classes.title} variant="h6" color="secondary" noWrap>
        //                 {i18nManager.t(title)}
        //             </Typography>
        //             <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
        //                 {i18nManager.t(subtitle)}
        //             </Typography>
        //         </div>
        //     </div>
        //     <div className={classes.body}>
        //         <Paper className={classes.root}>
        //             <div className={classes.content}>
        //                 <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
        //                     {steps.map(label => (
        //                         <Step key={label}>
        //                             <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        //                         </Step>
        //                     ))}
        //                 </Stepper>
        //                 <div className={classes.step}>
        //                     <StepComponent 
        //                         activeStep={activeStep} 
        //                         record={state}
        //                         isSubmitting={isSubmitting}
        //                         onSubmit={handleSubmit}
        //                         onError={handleError}
        //                     />
        //                 </div>
        //             </div>
        //             <div className={classes.actions}>
        //                 <div className={classes.buttons}>
        //                     <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
        //                     {(activeStep > 0 && activeStep < steps.length) && (
        //                         <Button variant="contained" className={classes.button} onClick={handleBack}>Back</Button>
        //                     )}
        //                     <Button variant="contained" className={classes.button} onClick={handleContinue} color="secondary">
        //                         {(activeStep < steps.length - 1) ? "Continue" : "Finish"}
        //                     </Button>
        //                 </div> 
        //             </div>
        //         </Paper>   
        //     </div>
        // </Fragment>
    )
}

PatientStepper.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default PatientStepper