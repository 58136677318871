import merge from 'lodash/merge';
import DynamicDialog from '../components/DynamicDialog';

export const colorMap = {
    A: '#CF9893',
    B: '#56638A',
    C: '#BC7C9C',
    D: '#5D576B',
    E: '#3D5A6C',
    F: '#717B7C',
    G: '#5A5353',
    H: '#3E442B',
    I: '#A27E8E',
    J: '#A77464',
    K: '#8693AB',
    L: '#A7A2A9',
    M: '#81A094',
    N: '#91ADC2',
    O: '#9BA0BC',
    P: '#7D7C7A',
    Q: '#4CB091',
    R: '#5B5B5B',
    S: '#C9C19F',
    T: '#6A3937',
    V: '#706563',
    W: '#82204A',
    X: '#231123',
    Y: '#595457',
    Z: '#545E75'
}

export const capitalized = str => (str.length > 0) ? `${str[0].toUpperCase()}${str.substr(1, str.length - 1)}` : str
export const uncapitalized = str => (str.length > 0) ? `${str[0].toLowerCase()}${str.substr(1, str.length - 1)}`.replace(/\s/g,'') : str
export const initial = str => capitalized(str.match(/\b(\w)/g)[0])

export const encodeBase64 = value => {
    value = JSON.stringify(value)
    return (value) ? Buffer.from(value).toString('base64') : value
}

export const decodeBase64 = value => {
    value = value ? Buffer.from(value, 'base64').toString('ascii') : value
    try {
        return JSON.parse(value)
    }
    catch(e) {
        return
    }
}
export const ascending = name => (a, b) => a[name] < b[name] ? -1 : a[name] > b[name] ? 1 : 0
export const descending = name => (a, b) => a[name] < b[name] ? 1 : a[name] > b[name] ? -1 : 0
export const filterByProp = (sources, props) => prop => sources.filter(source => props.indexOf(source[prop]) !== -1)
export const mergeByProp = (target, ...sources) => prop => target.map(targetItem => {
    return sources.reduce((targetItem, source) => {
        const sourceItem = source.find(sourceItem => sourceItem[prop] === targetItem[prop])
        return (sourceItem) ? merge(targetItem, sourceItem) : targetItem
    }, {...targetItem})
})
export const cm2ft = cm => Math.round(cm / 30.48 * 10) / 10
export const ft2cm = ft => Math.round(ft * 30.48 * 10) / 10
export const requestId = value => value.substring(1, value.length)
export const createRequestId = resource => {
    const { path, params } = resource
    const url = buildUrl(path, params)

    return url.substring(1, url.length)
}
export const defaultLanguage = () => navigator.language.split('-')[0]

export const buildUrl = (path, params = {}, context = {}) => {
    const value = name => (typeof params[name] === 'function') ? params[name](context) : params[name]
    return Object.keys(params).reduce((url, name) => url.replace(new RegExp(`:${name}`, 'g'), value(name)), path)
}

export const delayPromise = (promise, ms) => (...args) =>
    new Promise((resolve, reject) => {
        const handler = setTimeout(() => {
            promise(...args)
            .then(response => {
                clearTimeout(handler)
                resolve(response)
            })
            .catch(error => {
                clearTimeout(handler)
                reject(error)
            })
        }, ms)
    })

export const dynamicDialogHelper = ({ id, paramsSelector, multiEntry, submitOnEnter, resetOnSubmit }) => (props) => DynamicDialog({ id, paramsSelector, multiEntry, submitOnEnter, resetOnSubmit, ...props })

export const highlightDiff = (suggested, original, className) => { 
    var text = "";
    var spanOpen = false;
    suggested.split("").forEach(function(value, index){
				if (value != original.charAt(index)){   
            text += !spanOpen ? "<span class=" + className + ">" : "";
            text += value;
            spanOpen = true;
        } else {       
            text += spanOpen ? "</span>" : "";
            text += value;
            spanOpen = false;  
        }	
    });
    return text;
}