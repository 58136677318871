import * as Types from 'constants/ActionTypes';

const stateful = (state = {
    data: {},
}, action) => {
    switch (action.type) {
        case Types.UPDATE_STATEFUL:
            return Object.assign({}, state, {
				data: { ...state.data, [action.payload.id]: {
					...state.data[action.payload.id] || {},
					[action.payload.key]: action.payload.value
				}}
            })
        default:
            return state
    }
};

export default stateful