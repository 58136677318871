import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import base from './base'

const theme = createMuiTheme({
    palette: {
        primary:  { main: '#00B1AC', light: '#5AC5C0', contrastText: '#FFF'},
        secondary:  { main: '#4B4B4C'},
        error: { main: '#EF7373' },
        background: { paper: '#FFF', default: '#F2F2F2'},
        text: { secondary: '#6E7C7C' }
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Lato', 
            'Roboto',
            'Arial'
        ].join(','),
        // h1
        // h2
        // h3
        // h4
        // h5
        h6: {
            fontSize: '1.75rem',
            fontWeight: 400
        },
        subtitle1: {
            fontSize: '0.85rem'
        },
        subtitle2: {
            fontSize: '0.75rem',
			lineHeight: 1.1
        },
        // body1
        // body2
        // button
        caption: {
            fontSize: '1rem'
        }
        // overline
    },
    overrides: {
        MuiButton: {
            contained: {
                boxShadow: 'none'
            },
            root: {
                whiteSpace: 'nowrap'
            }
        },
        MuiIconButton: {
            root: {
                // marginLeft: 5
                '&$disabled': {
                    opacity: .5
                }
            }
        },
        MuiFab: {
            root: {
                boxShadow: 'none',
                margin: 2.5,
                '&$disabled': {
                    opacity: .5
                }
            },
        },
        MuiInput: {
            underline: {
                "&&&&:before": {
                    borderBottom: '1px solid #E9E9F0'
                },
                "&&&&:after": {
                    borderBottom: '1px solid #4CB0AC'
                },
                "&&&&:hover:before": {
                    borderBottom: '1px solid #E9E9F0'
                },
                "&&&&:hover:after": {
                    borderBottom: '1px solid #4CB0AC'
                }
            }
        },
        // MuiInputBase: {
        //     input: {
        //         padding: '12px 0 10.8px'
        //     }
        // },
        MuiSelect: {
            select: {
                "&&&&:focus": {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: '0.8rem',
                position: 'absolute',
                bottom: -21
               // top: 45
            }
        },
        MuiTableRow: {
            root: {
                "&$selected": {
                    'backgroundColor': 'transparent'//rgba(0, 0, 0, 0.02)'
                }
            }
        },
        MuiTableCell: {
            root: {
                padding: '10px 10px'
            }
        },
        // MuiTabs:{
        //     root: {
        //         paddingBottom: 40
        //     }
        // },
        MuiTab:{
            root: {
                fontSize: '1rem !important',
                textTransform: 'none'
            }
        },
        MuiToggleButtonGroup: {
            root: {
                display: 'inline',
                paddingLeft: 10
            }
        },
        MuiStepper: {
            alternativeLabel: {
                paddingTop: 70
            }
        },
        MuiStepLabel: {
            alternativeLabel: {
                paddingRight: 8
            },
            label: {
                paddingRight: '0 !important', 
                color: 'rgba(0, 0, 0, 0.3)',

                "&$active": {
                    color: 'rgba(0, 0, 0, 0.57)',
                },

                "&$completed": {
                    color: 'rgba(0, 0, 0, 0.57)',
                }
            }
        },
        MuiCardHeader: {
            action: {
                marginTop: '0px',
                marginRight: '0px'
            }
        }
        // MuiIconButton: {
        //     disabled: {
        //         opacity: 1
        //     }
        // }
    }
});

export const Base = base(theme)

const MainTheme = ({ children }) => (<MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>)

export default MainTheme