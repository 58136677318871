import * as Types from 'constants/ActionTypes'
import { actionRequest } from './index'

export const fetchApplication = name => (...args) => {
    actionRequest(...args)({
        type: Types.FETCH_APPLICATION,
        url: `/applications/${name}`,
        method: 'get'   
    })
}

export const createApplicationTableView = (name, data) => (...args) => {
    actionRequest(...args)({
        type: Types.CREATE_APPLICATION_TABLEVIEW,
        url: `/applications/${name}/tableviews`,
        method: 'post',
        data  
    })
}

export const fetchApplicationLocales = name => (...args) => {
    actionRequest(...args)({
        type: Types.FETCH_APPLICATION_LOCALES,
        url: `/applications/${name}/locales`,
        method: 'get'
    })
}

export const createApplicationLocale = (name, data) => (...args) => {
    actionRequest(...args)({
        type: Types.CREATE_APPLICATION_LOCALE,
        url: `/applications/${name}/locales`,
        method: 'post',
        data  
    })
}

export const deleteApplicationLocale = (name, key) => (...args) => {
    actionRequest(...args)({
        type: Types.DELETE_APPLICATION_LOCALE,
        url: `/applications/${name}/locales/${key}`,
        method: 'delete'
    })
}

export const fetchApplicationCharts = name => (...args) => {
    actionRequest(...args)({
        type: Types.FETCH_APPLICATION_CHARTS,
        url: `/applications/${name}/charts`,
        method: 'get'
    })
}