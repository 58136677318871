import React, { Fragment, useState, useEffect, useMemo, useCallback, forwardRef, Children, useRef, createContext, useContext, cloneElement, isValidElement } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, TextField, ListItem, ListItemText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { VariableSizeList } from 'react-window';
import clsx from 'clsx';

import { createRequestId, delayPromise } from 'utils';
import { fetchDataSource } from 'actions/index';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}))

const LISTBOX_PADDING = 8 // px

const renderRow = props => {
    const { data, index, style } = props

    return cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    })
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext)

    return <div ref={ref} {...props} {...outerProps} />
})

const useResetCache = data => {
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data])

    return ref
}

const ListboxComponent = forwardRef(({ children, ...other }, ref) => {
    const itemData = Children.toArray(children)
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
    const itemCount = itemData.length
    // const itemSize = smUp ? 36 : 68 //48
    const itemSize = 48
  
    const getChildSize = child => {
        if (isValidElement(child) && child.type === ListSubheader) {
            return itemSize
        }
  
        return itemSize
    }
  
    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
    }
  
    const gridRef = useResetCache(itemCount)
  
    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
            <VariableSizeList
                itemData={itemData}
                height={getHeight() + 2 * LISTBOX_PADDING}
                width="100%"
                ref={gridRef}
                outerElementType={OuterElementType}
                innerElementType="ul"
                itemSize={(index) => getChildSize(itemData[index])}
                overscanCount={5}
                itemCount={itemCount}
            >
                {renderRow}
            </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    )
})
  
ListboxComponent.propTypes = {
    children: PropTypes.node,
}

const SelectField = ({ /*id, valueName, textName, isLoading, defaultValue, options, label, fullWidth, margin, autoFocus, required, isClearable = false, fetchDataSource, resource, params = {}, onChange*/ 
    className,
    disabled,
    id,
    label,
    autoFocus,
    textName,
    valueName,
    defaultValue,
    options = [],
    resource,
    params = {},
    extraValues,
    onChange,
    onKeyDown,
    error, helperText
}) => {
    const classes = useStyles()
    const deps = useMemo(() => JSON.stringify([resource, params]), [resource, params]) 
    const dataSourceId = useMemo(() => resource ? createRequestId(resource) : null, [resource])
    const [visible, setVisible] = useState(false)
    const dispatch = useDispatch()
    const dataState = useSelector(state => state.datasources[dataSourceId] ? state.datasources[dataSourceId].data : options)
    const isLoading = useSelector(state => state.datasources[dataSourceId] ? state.datasources[dataSourceId].isLoading : false)
    const [defaultValueState] = useState(defaultValue)
    const [optionsState, setOptionsState] = useState([])
    const [selection, setSelection] = useState(null)
    let dispatchPromise = null

    const doSelectionChange = newSelection => {
        setSelection(newSelection)
        if (onChange/* && (!newSelection || (newSelection && newSelection[valueName] !== defaultValueState))*/) {
            const target = { id, value: newSelection && newSelection[valueName], selection: newSelection }

            if (extraValues) {
                target.extraValues = extraValues.reduce((values, names) => {
                    if (newSelection) {
                        values[names["submitName"]] = newSelection[names["valueName"]]
                    }
                    
                    return values
                }, {})
            }
            onChange({ target })
        }
    }

    useEffect(() => {
        if (resource && params) {
            dispatchPromise = delayPromise(dispatch(fetchDataSource(resource, params)), 1500)
        }
        return () => {
            if (dispatchPromise && dispatchPromise.cancel) {
                dispatchPromise.cancel()
            }
        }
    }, [deps])

    useEffect(() => {
        let option = null

        setOptionsState(dataState)
        
        if (dataState.length > 0 && defaultValueState) {
            const upperValue = String(defaultValueState).toUpperCase()
            
            option = dataState.find(option => String(option[valueName]).toUpperCase() === upperValue)
        }
        doSelectionChange(option ? {...option} : null)
    }, [deps, dataState.length])

    const handleChange = useCallback((event, option) => {
        doSelectionChange(option ? {...option} : null)
    }, [])    

    return (
        <Autocomplete
            autoHighlight
            autoSelect
            selectOnFocus
            disabled={disabled}
            id={id}
            className={clsx(classes.root, className)}
            open={visible}
            onOpen={() => setVisible(true)}
            onClose={() => setVisible(false)}
            getOptionSelected={(option, value) => {
                return option[valueName] === value[valueName]
            }}
            getOptionLabel={(option) => option[textName] }
            value={selection}
            onChange={handleChange}
            options={optionsState}
            onKeyDown={onKeyDown}
            loading={isLoading}
            ListboxComponent={ListboxComponent}
            renderInput={props => (
                <TextField
                    {...props}
                    label={label}
                    autoFocus={autoFocus}
                    InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {props.InputProps.endAdornment}
                            </Fragment>
                        )
                    }}
                    error={error}
                    helperText={helperText}
                />
            )}
            renderOption={option => (
                <ListItemText primary={option[textName]} />
            )}
        />
    )
}

export default SelectField