import * as Types from 'constants/ActionTypes'
import { actionRequest } from './index'

export const login = (email, password) => (...args) => {
    return actionRequest(...args)({
        type: Types.REQUEST_LOGIN,
        url: '/login',
        method: 'post',
        data: { email, password }   
    })
}

export const emailVerify = (email, emailCode) => (...args) => {
    return actionRequest(...args)({
        type: Types.REQUEST_EMAIL_VERIFY,
        url: '/verify-email',
        method: 'post',
        data: { email, emailCode }   
    })
}

export const resetPassword = (email, password) => (...args) => {
    return actionRequest(...args)({
        type: Types.REQUEST_RESET_PWD,
        url: '/reset-password',
        method: 'post',
        data: { email, password }   
    })
}

export const forgotPassword = email => (...args) => {
    return actionRequest(...args)({
        type: Types.REQUEST_FORGOT_PWD,
        url: '/forgot-pwd',
        method: 'post',
        data: { email }   
    })
}

export const videoToken = (roomName, participants, date) => (...args) => {
    return actionRequest(...args)({
        type: Types.REQUEST_VIDEO_TOKEN,
        url: '/users/video-token',
        method: 'post',
        data: { roomName, participants, date }   
    })
}