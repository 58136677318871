import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, CircularProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import green from '@material-ui/core/colors/green';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import SelectField from 'components/core/SelectField';
import HeightField from 'components/core/HeightField';
import WeightField from 'components/core/WeightField';
import SSNField from 'components/core/SSNField';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginRight: 5
    },
    vSeparator: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flex: {
        flex: 1
    },
    marginRight: {
        marginRight: 5
    },
    marginLeft: {
        marginLeft: 5
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    },
    formSubtitle: {
        width: '100%',
        textAlign: 'center'
    },
    textField: {
        width: theme.spacing(41)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function PatientProfileForm({ title = 'Profile Information', record, isSubmitting, onSubmit, onError, onDirty }) {
    const classes = useStyles()
    const ref = useRef()
    const heightUnits = useSelector(state => state.heights.data)
    const weightUnits = useSelector(state => state.weights.data)
    const validations = { 
		insuranceTypeId: ['required'],
        dob: ['required'], gender: ['required'], height: ['required'/*, 'height'*/],  
        heightUnitMeasureId: ['required'], weight: ['required'],
        weightUnitMeasureId: ['required'], languageId: ['required']
    }
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validations, record)

    function callback() {
        if (onSubmit) onSubmit(state)
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])

    const handleHeightChange = (value, unit) => {
        handleChange({ target: { id: 'height', value: value }})
        handleChange({ target: { id: 'heightUnitMeasureId', value: unit }})
    }

    const handleWeightChange = (value, unit) => {
        handleChange({ target: { id: 'weight', value: value }})
        handleChange({ target: { id: 'weightUnitMeasureId', value: unit }})
    }

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            {/* <Typography className={classes.formTitle} variant="h5" color="textSecondary" noWrap>
                {title}
            </Typography> */}
            <div className={classes.vbox}>
				<div className={classes.hbox}>
					<SelectField
						className={clsx(classes.flex, classes.marginRight)}
						InputLabelProps={InputLabelProps}
						autoComplete="off"
						margin="normal"
						id="insuranceTypeId"
						label="Insurance Type"
						defaultValue={state ? (state.insuranceTypeId || ((state.customerTypeId === 1 || state.customerTypeId === 2) ? 14002 : ((state.customerTypeId === 7 || state.customerTypeId === 8) ? 14000 : 14003))) : ""}
						fullWidth
						required={true}
						error={!!errors['insuranceTypeId']}
						helperText={!!errors['insuranceTypeId'] && errors['insuranceTypeId']}
						resource={{
							path: '/insurance-types',
							method: 'get'
						}}
						onChange={handleChange}
						onBlur={handleBlur}
						textName="text"
						valueName="value"
					/>
					<TextField
						className={clsx(classes.flex, classes.marginLeft)}
						InputLabelProps={InputLabelProps}
						autoComplete="off"
						margin="normal" 
						id="emrId" 
						label="EMR ID"
						defaultValue={state && state.emrId } 
						fullWidth
						onChange={handleChange}
						onBlur={handleBlur}
						error={!!errors['emrId']}
						helperText={!!errors['emrId'] && errors['emrId']}
					/>
				</div>
                {/* <SelectField
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal"
                    id="facilityId"
                    label="Facility"
                    defaultValue={state ? (state.facilityId || "") : ""}
                    fullWidth
                    required={true}
                    error={!!errors['facilityId']}
                    helperText={!!errors['facilityId'] && errors['facilityId']}
                    resource={{ path: '/customers/:id/facilities', params: { id: authManager.customerId } }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    textName="text"
                    valueName="value"
                /> */}
                <SSNField
                    InputLabelProps={InputLabelProps}
                    autoComplete="new-password"
                    margin="normal" 
                    id="personalId" 
                    label="SSN"  // TODO: 
                    defaultValue={state && state.personalId}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    error={!!errors['personalId']}
                    helperText={!!errors['personalId'] && errors['personalId']}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        clearable="clearable"
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="dob"
                        label="Birthday"
                        fullWidth
                        disableFuture
                        error={!!errors['dob']}
                        helperText={!!errors['dob'] && errors['dob']}
                        value={state && state.dob ? state.dob : null}
                        initialFocusedDate={new Date().setFullYear('1960')}
                        onChange={value => handleChange({
                            target: { id: "dob", value }
                        })}
                    />
                </MuiPickersUtilsProvider>
                <SelectField
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal"
                    id="gender"
                    label="Gender"
                    defaultValue={state ? (state.gender || "") : ""}
                    fullWidth
                    required={true}
                    error={!!errors['gender']}
                    helperText={!!errors['gender'] && errors['gender']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    textName="text"
                    valueName="value"
                    options={[{
                            text: 'Male',
                            value: 'M'
                        }, {
                            text: 'Female',
                            value: 'F'
                    }]}
                />
                <HeightField
                    margin="normal" 
                    id="height" 
                    label="Height"
                    defaultValue={state && state.height} 
                    unit={state.heightUnitMeasureId}
                    units={heightUnits}  
                    onValueChange={handleHeightChange} 
                    error={!!errors['height']}
                    helperText={!!errors['height'] && errors['height']}
                />
                <WeightField
                    margin="normal" 
                    id="weight" 
                    label="Weight"
                    defaultValue={state && state.weight} 
                    unit={state.weightUnitMeasureId}
                    units={weightUnits}  
                    onValueChange={handleWeightChange} 
                    readOnlyValue={!!state.id}
                    error={!!errors['weight']}
                    helperText={!!errors['weight'] && errors['weight']}
                />
                <SelectField
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal"
                    id="languageId"
                    label="Language"
                    defaultValue={state ? (state.languageId || "") : ""}
                    fullWidth
                    required={true}
                    error={!!errors['languageId']}
                    helperText={!!errors['languageId'] && errors['languageId']}
                    resource={{
                        path: '/languages',
                        method: 'get'
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    textName="text"
                    valueName="value"
                />
                <SelectField
                    InputLabelProps={InputLabelProps}
                    autoComplete="off"
                    margin="normal"
                    id="ethnicityId"
                    label="Ethnicity"
                    defaultValue={state ? (state.ethnicityId || "") : ""}
                    fullWidth
                    required={true}
                    error={!!errors['ethnicityId']}
                    helperText={!!errors['ethnicityId'] && errors['ethnicityId']}
                    resource={{
                        path: '/ethnicities',
                        method: 'get'
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    textName="text"
                    valueName="value"
                />
            </div>
        </form>
    )
}