import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Switch, useRouteMatch, useParams } from 'react-router-dom'

import { AuthManagerContext } from 'contexts/AuthManager';

import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'

import PatientDeviceDialog from 'components/PatientDeviceDialog'
import { PatientOrderDetails } from 'screens'
import { getReportFormParams } from 'actions';

import { defaultLanguage, buildUrl, dynamicDialogHelper } from 'utils'

const columnsOverride = [{
    type: 'DateColumn',
    dataKey: 'orderDate',
    order: 1,
    format: 'MM/DD/YYYY hh:mm A'
},  {
    type: 'StatusColumn',
    dataKey: 'status',
    size: 'small',
    order: 2
}, {
    dataKey: 'carrier',
    order: 3
}, {
    type: 'TrackingColumn',
    dataKey: 'trackingNumber',
    order: 4
}, {
    type: 'AddressColumn',
    dataKey: 'patientAddress',
    order: 5
}, {
    dataKey: 'totalNumberOfItems',
    order: 6
}]

const actionsOverride = [{
    type: 'report',
    disabled: selection => !(selection.length === 1),
    selectionMap: ({ id: orderId }) => ({ orderId }),
    component: dynamicDialogHelper({ id: 'OrderMaterialsReport'}),
    order: 1
}, {
    type: 'showLabel',
    disabled: selection => (selection.length === 0),
    selectionMap: ({ label }) => ({ label }),
    order: 2
}]

const reports = ['shippingLabel', 'patientInfo', "consentReport", "welcomeLetter", "settingInstructions", "leaseReport"]

const PatientOrders = ({ actions, columns, resources, ...others }) => {
    const authManager = useContext(AuthManagerContext)
    const params = useParams()
    const dispatch = useDispatch()
    const { actionsState, columnsState, dataState, handleRefresh, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions, 
        columns, 
        resources
    })

    const views = useSelector(state => state && state.application.data.views)
    const patientsView = views.find(view => view.name === 'Patients')
    const patientsResources = { ...patientsView.resources }

    const handleReportAction = (name, data, actionParams) => new Promise((resolve, reject) => {        
        try {
            const resource = patientsResources[name]
            const customerId = params.customerId || authManager.customerId
            const userId = authManager.id
            const patientId = actionParams.patientId
            const url = new URL(`${process.env.REACT_APP_API_URL}${buildUrl(resource.path, { customerId, ...actionParams })}`)

            const dataStr = JSON.stringify(data).replace('@CurrentCustomerID', customerId)
                                            .replace('@CurrentUserID', userId)
                                            .replace('@CurrentPatientID', patientId)
                                            .replace('@CurrentLanguageID', defaultLanguage())
            const dataObj = JSON.parse(dataStr)

            url.searchParams.append('accessToken', authManager.token)
            // url.searchParams.append('contentLanguage', defaultLanguage())
            Object.keys(dataObj).forEach(name => {
                url.searchParams.append(name, dataObj[name])
            })

            window.open(url, name)
            resolve({})
        }
        catch (error) {
            reject({ error })
        }
    })

    const handlePrintAction = (action, data, actionParams) => new Promise((resolve, reject) => {
        try {
            data.map(item => {    
                const win = window.open();
                win.document.write('<iframe src="data:image/gif;base64,' + item['label']  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
                // win.document.close();
                // win.focus()
                // win.print();
                // win.close();
            })

            resolve({})   
        }
        catch(error) {
            reject({ error })
        }
    })

    const handleCustomAction = (action, data, actionParams) => {
        async function fetchData(name, reportId) {
            const customerId = params.customerId || authManager.customerId
            const { data: { result }, error } = await dispatch(getReportFormParams({ reportId, customerId }))

            if (!error && result) {
                if (result.fields.every(item => item.hidden)) {
                    const data = result.fields.reduce((acc, value) => ({ ...acc, [value.name]: value.defaultValue }), {})

                    // return handleReportAction(name, data, Object.assign({ patientId: params.patientId }, actionParams))
                    return handleReportAction(name, data, { patientId: params.patientId, reportId })
                }
            }
        }

        switch (action.type) {
            case 'showLabel':
                return handlePrintAction(action, data, actionParams)
            case 'report':
                return reports.map(name => {
                    if (data[name]) {
                        switch (name) {
                            case 'leaseReport':
                                return fetchData(name, 8)
                            case 'consentReport':
                                return fetchData(name, 5)
                            case 'welcomeLetter':
                                return fetchData(name, 7)
                            case 'settingInstructions':
                                return fetchData(name, 6)
                            default:
                                return handleReportAction(name, data, Object.assign({ patientId: params.patientId }, actionParams))
                        }
                    }
                })
            default:
                return handleAction(action, data, actionParams)
        }
    }

    return (
        <DataBrowser
			//statefulId="patient-orders"
            actions={actionsState}
            columns={columnsState}
            rows={dataState}
            searchFieldName={['patientAddress']}
            onRefresh={handleRefresh}
            onAction={handleCustomAction}
            isLoading={isLoading}
            inProgress={inProgress}
            SideComponent={PatientOrderDetails}
            {...others}
        />
    )
}

export default PatientOrders