import React from 'react';

import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'

// import PatientEducationalMaterialDialog from 'components/PatientEducationalMaterialDialog'

const columnsOverride = [{
    type: 'MultimediaColumn',
    label: 'Content',
    dataKey: 'contentType',
    order: 1
}, {
    dataKey: 'title',
    hidden: true
}, {
    dataKey: 'library',
    hidden: true
}, {
    dataKey: 'subject',
    hidden: true
}, /*{
    dataKey: 'topic',
    hidden: true
}, {
    dataKey: 'subtopic',
    hidden: true
}, */{
    type: 'DateColumn',
    dataKey: 'lastTimeRead',
    order: 2
}, {
    type: 'DateColumn',
    dataKey: 'lastTimeSent',
    order: 3
}, {
    dataKey: 'totalRead',
    order: 4
}, {
    dataKey: 'totalSent',
    order: 5
}, {
    type: 'StatusColumn',
    size: 'small',
    dataKey: 'status',
    order: 6
}, {
    dataKey: 'basedOnPathway',
    hidden: true
}]

const actionsOverride = [{
    type: 'send',
    disabled: selection => !(selection.length > 0),
    // disabled: selection => {
    //     return ((selection.length === 0) || (selection.length > 0 && (selection.findIndex(item => item.statusId === null) == -1)))
    // },
    selectionMap: ({ id }) => ({ id }),
    confirm: true,
    order: 1
}]

/*
    title:      [Config]
    subtitle:   [Config]
    path:       [Config]
    actions:    [Config]
    columns:    [Config]
    resources:  [Config]
    params:     ?
*/
const PatientEducationalMaterials = ({ actions, columns, resources, params, ...others }) => {
    const { actionsState, columnsState, dataState, totalCountState, recordState, handleRefresh, handleFetchRecord, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions, 
        columns, 
        resources
    })

    return (
        <DataBrowser
			//statefulId="patient-educational-materials"
            actions={actionsState}
            columns={columnsState}
            entityIdName="educationalMaterialId"
            searchFieldName={['title']}
            defaultFilter={{
                basedOnPathway: {
                    chipLabel: "Based on Pathway",
                    dataType: "boolean",
                    name: "basedOnPathway",
                    operator: "eq",
                    text: "Based on Pathway",
                    value: 1
                }
            }}
            rows={dataState}
            totalCount={totalCountState}
            record={recordState}
            onRefresh={handleRefresh}
            onFetchRecord={handleFetchRecord}
            onAction={handleAction}
            isLoading={isLoading}
            inProgress={inProgress}
            remoteQuery={true}
            {...others}
        />
    )
}

export default PatientEducationalMaterials