import React, { Fragment, forwardRef, useRef, useState, useContext, useEffect } from 'react';
// import {  Link  } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, CircularProgress, Paper, Stepper, Step, StepLabel, StepConnector } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

import useForm from 'hooks/useForm';
import clsx from 'clsx';
import { Icon } from 'styles';

import { I18nManagerContext } from 'contexts/I18nManager';
import CustomerProfileForm from 'components/CustomerProfileForm'
import BillingInfoForm from 'components/BillingInfoForm'
import CustomerPaymentInfoForm from 'components/CustomerPaymentInfoForm'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        
        boxShadow: 'none',
        border: '1px solid #E2E2E3',
        borderRadius: 2,

        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        justifyContent: 'stretch'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(5) ,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0)
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
        padding: theme.spacing(5),
        paddingTop: theme.spacing(4)
    },
    titleContainer: {
        flex: 1
    },
    title: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            padding: 0
        // },
    },
    subtitle: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            paddingLeft: theme.spacing(1/4)
        // },
    },
    content: {
        // flex: '1 0 auto',
        overflowY: 'scroll',
        flexGrow: 1
    },
    step: {
        height: '100%',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        paddingTop: 70
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '45%'
    },
    textField: {
        width: theme.spacing(41)
    },
    actions: {
        
    },
    buttons: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: theme.spacing(19.5),
        marginLeft: theme.spacing(1)/2,
        marginRight: theme.spacing(1)/2,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const QontoConnector = withStyles({
    alternativeLabel: {
      right: 'calc(50% + 30px)'
    },
    active: {
      '& $line': {
        borderColor: '#4CB0AC',
        borderTopWidth: 1
      },
    },
    completed: {
      '& $line': {
        borderColor: '#4CB0AC',
      },
    },
    line: {
      borderColor: '#E9E9F0',
      borderTopWidth: 1,
      borderRadius: 1
    },
  })(StepConnector);

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#4CB0AC',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#4CB0AC',
    },
    circle: {
        border: '1px solid #4CB0AC',
        borderRadius: 100,
        width: '2.5em',
        height: '2.5em',
        textAlign: 'center',
        lineHeight: '2.4em'
    },
    completed: {
        borderRadius: 100,
        backgroundColor: '#4CB0AC',
        width: '2.5em',
        height: '2.5em',
        textAlign: 'center',
        lineHeight: '2.4em'
    },
  });
  
  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed, icon } = props;
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <div className={classes.completed}><Icon icon="check" size={20} /></div>: <div className={classes.circle}> { icon } </div>}
        
      </div>
    );
  }
  
  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
  };

//TODO: localize
const Steps = [
    { label: 'Profile', component: CustomerProfileForm },
    { label: 'Billing', component: BillingInfoForm },
    { label: 'Payment', component: CustomerPaymentInfoForm }
]

function getSteps() {
    return Steps.map(step => step.label)
}
  
function StepComponent({ activeStep, ...others }) {
    const Component = Steps[activeStep].component
    return (
        <Component {...others} />
    ) 
}

const InputLabelProps = { 
    required: false //Hide asterix on required field
}

// function PaymentInfoForm({ title = 'Payment Information', record, isSubmitting, onSubmit }) {
//     const classes = useStyles()
//     const [state, setState] = useState(record)

//     useEffect(() => {
//         (isSubmitting === true && onSubmit) && onSubmit(state)
//     }, [isSubmitting])

//     const handleChange = event => {
//         const { id, value } = event.target;
        
//         setState(prevState => ({ ...prevState, [id]: value }))
//     }

//     const handleSubmit = event => {
//         event.preventDefault()
//         if (onSubmit) onSubmit(state)
//     }

//     return (
//         <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
//             <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
//                 {title}
//             </Typography>
//             <TextField
//                 InputLabelProps={InputLabelProps}
//                 autoComplete="off"
//                 autoFocus
//                 margin="normal" 
//                 id="name" 
//                 label="Name"
//                 defaultValue={state && state.name} 
//                 fullWidth
//                 onChange={handleChange}
//                 required={true}
//             />
//             <TextField
//                 InputLabelProps={InputLabelProps}
//                 autoComplete="off"
//                 margin="normal" 
//                 id="email" 
//                 label="Email"
//                 defaultValue={state && state.email} 
//                 fullWidth
//                 onChange={handleChange}
//                 required={true}
//             />
//         </form>
//     )
// }

function CustomerStepper({ match, title, subtitle, record, resource, fetchRecord, inProgress, onSubmit, onCancel }) {
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const [isSubmitting, setSubmitting] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const steps = getSteps()
    const [state, setState] = useState(record || {})
    const { params } = match

    useEffect(() => {
        if (params && params.id) {
            fetchRecord(Object.assign(resource, { params }))
        }
    }, [resource])

    useEffect(() => {
        setState({ ...record })
    }, [record])

    const handleCancel = () => {
        onCancel()
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const handleError = () => {
        setSubmitting(false)   
    }

    const handleContinue = () => {
        setSubmitting(true)
    }

    const handleSubmit = state => {
        setSubmitting(false)
        setState({...state})
        setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    const handleFinish = event => {
        event.preventDefault()
        onSubmit(state)
    }

    return (
        <Fragment>
            <div className={classes.header}>
                <div className={classes.titleContainer}>
                    <Typography className={classes.title} variant="h6" color="secondary" noWrap>
                        {i18nManager.t(title)}
                    </Typography>
                    <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                        {i18nManager.t(subtitle)}
                    </Typography>
                </div>
            </div>
            <div className={classes.body}>
                <Paper className={classes.root}>
                    <div className={classes.content}>
                        {/* {(activeStep > 0) && ( */}
                            <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                                {steps.map(label => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        {/* )} */}
                        <div className={classes.step}>
                            <StepComponent 
                                activeStep={activeStep} 
                                record={state}
                                isSubmitting={isSubmitting}
                                onSubmit={handleSubmit}
                                onError={handleError}
                            />
                        </div>
                    </div>
                    <div className={classes.actions}>
                        <div className={classes.buttons}>
                            <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
                            {(activeStep > 0 && activeStep < steps.length) && (
                                <Button variant="contained" className={classes.button} onClick={handleBack}>Back</Button>
                            )}
                            {(activeStep < steps.length - 1) && (
                                <Button variant="contained" className={classes.button} onClick={handleContinue} color="secondary">Continue</Button>
                            )}
                            {(activeStep === steps.length - 1) && (
                                <Button variant="contained" className={classes.button} onClick={handleFinish} color="secondary">Finish</Button>
                            )}
                        </div> 
                    </div>
                </Paper>   
            </div>
        </Fragment>
    )
}

CustomerStepper.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default CustomerStepper