// import * as Types from 'constants/ActionTypes'
// import uuid from 'uuid/v4'
// import { actionCreator } from './index'

// export const fetchUsers = () => dispatch => {
//     dispatch(actionCreator({
//         type: Types.FETCH_USERS,
//         url: 'http://localhost:3800/api/users',
//         method: 'GET'
//     }))
// }

// export const createUser = user => dispatch => {
//     const guid = uuid()

//     dispatch(actionCreator({
//         type: Types.CREATE_USER,
//         url: 'http://localhost:3800/api/users',
//         method: 'POST',
//         data: Object.assign(user, { guid })
//     }))
// }

// export const updateUser = user => dispatch => {
//     const guid = uuid()

//     dispatch(actionCreator({
//         type: Types.UPDATE_USER,
//         url: 'http://localhost:3800/api/users',
//         method: 'PUT',
//         data: Object.assign(user, { guid })
//     }))
// }

// export const deleteUser = user => dispatch => {
//     dispatch(actionCreator({
//         type: Types.DELETE_USER,
//         id: uuid(),
//         url: 'http://localhost:3800/api/users',
//         method: 'DELETE',
//         data: user
//     }))
// }

import * as Types from 'constants/ActionTypes'
import { actionRequest } from './index'
import { buildUrl } from 'utils';

const resources = {
    fetchUserByEmail: {
        get: { path: '/customers/:customerId/user', method: 'get' }
    }
}

export const fetchUserByEmail = (params) => (...args) => {
    const { path, method } = resources['fetchUserByEmail']['get']

    return actionRequest(...args)({
        type: Types.FETCH_USER,
        url: buildUrl(path, params),
        method   
    })
}