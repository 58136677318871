import * as Types from 'constants/ActionTypes';

const record = (state = {}, action) => {
    switch (action.type) {
		case Types.CLEAR_RECORD:
			delete state[action.id]
            return state
        case Types.FETCH_RECORD:
            return Object.assign({}, state, {
                [action.id]: {
                    isLoading: true,
                    didInvalidate: false
                }
            })
        case Types.CREATE_RECORD:
        case Types.UPDATE_RECORD:
        case Types.DELETE_RECORD:
        case Types.DELETE_RECORDS:
                return Object.assign({}, state, {
                    [action.id]: {
                        inProgress: true,
                        didInvalidate: false
                    }
                })
        case Types.FETCH_RECORD_SUCCESS:
            return Object.assign({}, state, {
                [action.id]: {
                    isLoading: false,
                    didInvalidate: false,
                    data: action.payload,
                    lastUpdated: action.receivedAt
                }
            })
        case Types.CREATE_RECORD_SUCCESS:
        case Types.UPDATE_RECORD_SUCCESS:
        case Types.DELETE_RECORD_SUCCESS:
        case Types.DELETE_RECORDS_SUCCESS:
                return Object.assign({}, state, {
                    [action.id]: {
                        inProgress: false,
                        didInvalidate: false
                    }
                })
        case Types.FETCH_RECORD_FAILURE:
            return Object.assign({}, state, {
                [action.id]: {
                    isLoading: false,
                    didInvalidate: false,
                    lastUpdated: action.receivedAt
                }
            })
        case Types.CREATE_RECORD_FAILURE:
        case Types.UPDATE_RECORD_FAILURE:
        case Types.DELETE_RECORD_FAILURE:
        case Types.DELETE_RECORDS_FAILURE:
                return Object.assign({}, state, {
                    [action.id]: {
                        inProgress: false,
                        didInvalidate: false
                    }
                })
        default:
            return state
    }
};

export default record

// const records = (state = {}, action) => {
//     switch (action.type) {
//         case Types.FETCH_RECORD:
//             return Object.assign({}, state, {
//                 [action.id]: {
//                     inProgress: true,
//                     didInvalidate: false
//                 }
//             })
//         case Types.FETCH_RECORD_SUCCESS:
//             return Object.assign({}, state, {
//                 [action.id]: {
//                     inProgress: false,
//                     didInvalidate: false,
//                     data: action.payload,
//                     lastUpdated: action.receivedAt
//                 }
//             })
//         case Types.FETCH_RECORD_FAILURE:
//             return Object.assign({}, state, {
//                 [action.id]: {
//                     inProgress: false,
//                     didInvalidate: false,
//                     lastUpdated: action.receivedAt
//                 }
//             })
//         default:
//             return state
//     }
// };

// export default records