import React, { Fragment, useEffect, useRef, useContext, useMemo, useState, useCallback } from 'react'
import { AuthManagerContext } from 'contexts/AuthManager';
import { WebSocketContext } from 'contexts/WebSocket';
import { makeStyles } from '@material-ui/core/styles';
import Video from 'twilio-video';
import { useDispatch } from 'react-redux';
import { videoToken, updateInteraction } from 'actions';
import { Slide, IconButton, Typography, Modal, DialogContent, Tooltip } from '@material-ui/core';
import { Icon } from 'styles';
import { getSecondsDuration } from 'helpers';
import Draggable from 'react-draggable';

import { DialogContext } from 'contexts/Dialog'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
		width: 'auto',
        // maxWidth: 350
	},
	dialog: {
		width: '80%',
        maxWidth: 400
	},
	actionContainer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		padding: 20,
		display: 'flex',
		justifyContent: 'flex-start'
	},
	hangButton: {
		backgroundColor: '#EF7373',
		border: '2px solid #EF7373',
		margin: 10,
        "&&&&:active": {
			backgroundColor: '#EF7373',
			border: '2px solid #EF7373',
        },
        "&&&&:focus": {
			backgroundColor: '#EF7373',
			border: '2px solid #EF7373',
        },
        "&&&&:hover": {
			backgroundColor: 'rgba(239, 115, 115, .8)',
			borderColor: 'rgba(239, 115, 115, .8)',
        }
	},
	callButton: {
        backgroundColor: '#4CB091',
        border: '2px solid #4CB091',
		margin: 10,
        "&&&&:active": {
            backgroundColor: '#4CB091'
        },
        "&&&&:focus": {
            backgroundColor: '#4CB091'
        },
        "&&&&:hover": {
            backgroundColor: '#4CB091'
        }
    },
	otherButton: {
		border: '2px solid white',
		margin: 10
	},
    content: {
		padding: '0px !important',
		display: 'flex',
		position: 'absolute',
		zIndex: 9999,
		// top: '50%',
		// transform: 'translate(-50%, -50%)',
		// left: '50%',
		outline: 'none',
		minHeight: 285,
		minWidth: 288, //340,
		background: 'black',
		borderRadius: 10,
		overflow: 'hidden',
		cursor: 'move'
	},
	fullScreenVideo: {
		display: 'flex',
	},
	floatVideo: {
		// position: 'absolute',
		// transform: 'translate(8%, 64%) scale(0.3)'
		display: 'flex',
		position: 'absolute',
		justifyContent: 'flex-end',
		height: 150,
		bottom: 10,
		right: 10,
		width: '-webkit-fill-available',
	},
	remoteParticipant: {
		minHeight: 150,
		display: 'flex'
	},
	title: {
		position: 'absolute',
		color: 'white',
		padding: 20
	},
	caption: {
		display: 'block'
	}
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const Participant = ({ participant }) => {
	const classes = useStyles()
	const [videoTracks, setVideoTracks] = useState([])
	const [audioTracks, setAudioTracks] = useState([])
	const videoRef = useRef()
	const audioRef = useRef()
  
	const trackpubsToTracks = (trackMap) =>
		Array.from(trackMap.values())
			.map((publication) => publication.track)
			.filter((track) => track !== null)
  
	useEffect(() => {
		setVideoTracks(trackpubsToTracks(participant.videoTracks))
		setAudioTracks(trackpubsToTracks(participant.audioTracks))
  
		const trackSubscribed = (track) => {
			if (track.kind === 'video') {
				setVideoTracks((videoTracks) => [...videoTracks, track])
			} 
			else if (track.kind === 'audio') {
				setAudioTracks((audioTracks) => [...audioTracks, track])
			}
			console.log('Subscribed', track)
		}
  
	  	const trackUnsubscribed = (track) => {
			if (track.kind === 'video') {
				setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track))
			} 
			else if (track.kind === 'audio') {
				setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track))
			}
			console.log('Unsubscribed', track)
	  	}
  
		participant.on('trackSubscribed', trackSubscribed)
		participant.on('trackUnsubscribed', trackUnsubscribed)
  
		return () => {
			setVideoTracks([])
			setAudioTracks([])
			participant.removeAllListeners()
		}
	}, [participant])
  
	useEffect(() => {
		const videoTrack = videoTracks[0]
		if (videoTrack) {
			videoTrack.attach(videoRef.current)
			return () => {
				videoTrack.detach()
			}
		}
	}, [videoTracks])
  
	useEffect(() => {
		const audioTrack = audioTracks[0]
		if (audioTrack) {
			audioTrack.attach(audioRef.current)
			return () => {
				audioTrack.detach()
			}
		}
	}, [audioTracks])
  
	return (
		<Fragment>
			<video className={classes.video} ref={videoRef} autoPlay={true} />
			<audio ref={audioRef} autoPlay={true} />
		</Fragment>
	)
}

const PatientVideoChatDialogContent = React.forwardRef(({ /*open,*/ record, onCancel }, ref) => {
	const authManager = useContext(AuthManagerContext)
	const ws = useContext(WebSocketContext)
	const roomName = useMemo(() => Buffer.from(`${authManager.id}:${record.id}`).toString('base64'), [authManager.id, record.id])
	const dispatch = useDispatch()
	const classes = useStyles()
	const [room, setRoom] = useState(null)
	const [participants, setParticipants] = useState([])
	const [voiceStatus, setVoiceStatus] = useState('Mute')
	const [failedStatus, setFailedStatus] = useState(false)
	const [videoStart, setVideoStart] = useState(null)
	const [interactionState, setInteractionState] = useState(null)
	const [statusMessage, setStatusMessage] = useState('Video Call ...')

	const videoDisconnect = useCallback(async () => {
		if (room && room.localParticipant.state === 'connected') {
			room.localParticipant.tracks.forEach((trackPublication) => {
				trackPublication.track.stop()
			})

			await room.disconnect()
		}
		return
	}, [record, room, videoStart, interactionState])

	const videoConnect = useCallback(async () => {
		if (record.id) {
			const dateVideoStart = new Date()
			const { payload: { token, interaction }, error } = await dispatch(videoToken(roomName, [{patientId: record.id, customerId: record.customerId}], dateVideoStart))
			
			if (!error && token) {
				const newRoom = await Video.connect(token, {
					name: roomName,
					audio: true,
					maxAudioBitrate: 16000, 
					video: { height: 640, frameRate: 24, width: 480 }
				})
				.catch(error => {
					console.error('Failed to join Room:', error.code, error.message);
				}); 

				newRoom.once('disconnected', (room, error) => {
					console.log('disconnected')
					if (error) {
						console.log('You were disconnected from the Room:', error.code, error.message);
					}
				})

				// TODO: Doesn't seem to be working
				window.addEventListener('beforeunload', () => room.disconnect());
				window.addEventListener('unload', () => room.disconnect());
				window.addEventListener('pagehide', () => room.disconnect());

				setRoom(newRoom)
				setVideoStart(dateVideoStart)
				setInteractionState(interaction)

				// unmute on video connect
				newRoom.localParticipant.audioTracks.forEach(audioTrack => {
					audioTrack.track.enable();
				});
				setVoiceStatus("Mute");
			}
		}
	}, [record, roomName])

	const handleToggleVoice = () => { 
		if (voiceStatus === "Mute") {
			room.localParticipant.audioTracks.forEach(audioTrack => {
				audioTrack.track.disable();
			});
			setVoiceStatus("Unmute");
		} else {
			room.localParticipant.audioTracks.forEach(audioTrack => {
				audioTrack.track.enable();
			});
			setVoiceStatus("Mute");
		}
	}

	const videoFailed = useCallback(async (reason) => {
		if (record && room && interactionState) {
			setFailedStatus(true)
			setStatusMessage(reason)

			await Promise.allSettled([
				dispatch(updateInteraction({
					patientId: record.id, 
					customerId: record.customerId, 
					interactionId: interactionState.id
				}, {
					duration: 180, 
					notes: reason, 
					objectives: ''
				})),
				videoDisconnect()
			])
		}
	}, [record, interactionState, videoDisconnect])

	const videoFinished = useCallback(async (reason) => {
		if (record && room && interactionState) {
			setFailedStatus(true)
			setStatusMessage(reason)

			const duration = getSecondsDuration(videoStart)

			await Promise.allSettled([
				dispatch(updateInteraction({
				patientId: record.id, 
				customerId: record.customerId, 
				interactionId: interactionState.id
			}, {
				duration: duration > 180 ? duration : 180, 
				notes: reason, 
				objectives: ''
			})),
			videoDisconnect()
			])

		}
	}, [record, interactionState, videoDisconnect])

	const handleCancel = useCallback(async () => {
		await videoDisconnect()
		if (onCancel) onCancel()
	}, [videoDisconnect, onCancel])

	const handleHangup = useCallback(async () => {
		await videoFinished('Video Call Finished')
		if (onCancel) onCancel()
	}, [videoFinished, onCancel])

	const handleTryAgain = useCallback(() => {
		setFailedStatus(false)
		setStatusMessage('Video Call ...')
		videoConnect()
	}, [videoConnect])

	useEffect(() => {
		videoConnect()
	}, [videoConnect])

	useEffect(() => {

		const handleParticipantConnected = participant => {
			console.log('handleParticipantConnected', participant)
			setParticipants(prevParticipants => [...prevParticipants, participant])
		}
	
		const handleParticipantDisconnected = async (participant) => {
			console.log('handleParticipantDisconnected', participant)
			const nextParticipants = participants.filter(p => p !== participant)
	
			if (room && nextParticipants.length === 0) {
				await videoFinished('Video Call Finished')
				if (onCancel) onCancel()
			}
			else {
				setParticipants(nextParticipants)
			}	
		}

		if (room) {
			room.on('participantConnected', handleParticipantConnected)
			room.on('participantDisconnected', handleParticipantDisconnected)
			room.participants.forEach(handleParticipantConnected)
		}
		return () => {
			if (room) {
				room.removeListener('participantConnected', handleParticipantConnected)
				room.removeListener('participantDisconnected', handleParticipantDisconnected)
			}
		}
	}, [room, videoFinished, onCancel])

	useEffect(() => {
		const handleVideoDeclined = () => {
			videoFailed('Video Call Declined')
		}
		ws.on('videodecline', handleVideoDeclined)
		return () => ws.off('videodecline', handleVideoDeclined)
	}, [ws, videoFailed])

	useEffect(() => {
		let timer
		
		if (record && room && interactionState) {
			timer = setTimeout(async () => {
				if (room && room.localParticipant.state === 'connected' && room.participants.size === 0) {
					videoFailed('Video Call Not Answered') //TODO: localize
				}
			}, 20000)
		}

		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [room, record, interactionState, videoFailed])

	return (
		<DialogContent style={{outline: 0, padding: 0}} ref={ref}>
			<Draggable bounds="body" defaultPosition={{x: 10, y: 10}}>
				<div className={classes.content}>
					{(room && participants.length === 1) && (
						<div className={classes.remoteParticipant}>
							{participants.map(participant => (
								<Participant 
									key={participant.sid} 
									participant={participant} 
								/>
							))}
						</div>
						)}
					{(room && room.localParticipant) && (
						<div className={participants.length === 0 ? classes.fullScreenVideo : classes.floatVideo}>
							{participants.length === 0 && (
								<Typography className={classes.title} variant='h5' > {record.fullName} <Typography className={classes.caption} variant='caption' >{statusMessage}</Typography></Typography>
								
							)}
							<Participant
								key={room.localParticipant.sid}
								participant={room.localParticipant}
							/>
						</div>
					)}
					{(room && room.localParticipant) && (
						failedStatus ? (
							<div className={classes.actionContainer}>
								<Tooltip title='Call Back'>
									<IconButton aria-label="call" className={classes.callButton} onClick={handleTryAgain}>
										<Icon icon={'phone'} size={24} className={classes.icon} />
									</IconButton>
								</Tooltip>
								<Tooltip title='Cancel'>
									<IconButton aria-label="cancel" className={classes.otherButton} onClick={handleCancel}>
										<Icon icon={'x'} size={24} className={classes.icon} />
									</IconButton>
								</Tooltip>
							</div>
						) : (
							<div className={classes.actionContainer}>
								<Tooltip title='End Call'>
									<IconButton aria-label="hang" className={classes.hangButton} onClick={handleHangup}>
										<Icon icon={'phone'} size={24} className={classes.icon} />
									</IconButton>
								</Tooltip>
								<Tooltip title={voiceStatus}>
									<IconButton aria-label={voiceStatus === 'Mute' ? 'mic-off' : 'mic'} className={classes.otherButton} onClick={handleToggleVoice}>
										<Icon icon={voiceStatus === 'Mute' ? 'mic-off' : 'mic'} size={24} className={classes.icon} />
									</IconButton>
								</Tooltip>
							</div>
						)
					)}
				</div>
			</Draggable>
		</DialogContent>
    )
})

export const videChatDialogName = 'patientVideoChat'

const PatientVideoChatDialog = ({ open, record, onCancel }) => {
	const { openDialog, closeDialog } = useContext(DialogContext)

	const handleCancel = useCallback(() => {
		closeDialog(videChatDialogName)
		if (onCancel) onCancel()
	}, [onCancel])

	useEffect(() => {
		if (open === true) {
			openDialog({
				name: videChatDialogName,
				dialog: false,
				alwaysOnTop: true,
				hideBackdrop: true,
				disableEnforceFocus: true,
				disableBackdropClick: true, 
				style: { position: 'fixed', height: 'fit-content', width: 'fit-content' },
				children: (
					<PatientVideoChatDialogContent
						record={record}
						onCancel={handleCancel}	
					/>
				)
			})
		}
	}, [open, record])

	return (<Fragment/>)
}

export default PatientVideoChatDialog