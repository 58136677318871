import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, FormGroup, FormLabel, TextField, MenuItem } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    row: {
        minHeight: 45,
        alignItems: 'center'
    },
    filterLabel: {
        alignSelf: 'center',
        width: '27%'
    },
    filterOperator: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    filterValue: {
        marginLeft: theme.spacing(1),
        flex: 1
    }
}))

const Filter = ({
    name,
    operator,
    value,
    dataType,
    label,
    fullWidth,
    showOperator,
    operators = [],
    onChange,
    component: Component,
    children,
    multiple = false,
    ...others
}) => {
    const classes = useStyles()
    const [operatorState, setOperatorState] = useState(operator)
    const [valueState, setValue] = useState(value || (multiple ? [] : ''))

    const handleOperatorChange = event => {
        const newOperator = event.target.value

        setOperatorState(newOperator)
        if (onChange) onChange({
            name,
            operator: newOperator,
            value: valueState,
            dataType
        })
    }

    const handleValueChange = event => {

        const newValue = event.target.value

        setValue(newValue)
        if (onChange) onChange({
            name,
            operator: operatorState,
            value: newValue,
            dataType
        })
    }

    return (
        <FormControl
            margin="dense"
            fullWidth
        >
            <FormGroup row className={classes.row}>
                <FormLabel className={classes.filterLabel} component="label">{label}</FormLabel>
                {(operators.length > 0) && (
                    <TextField
                        className={classes.filterOperator}
                        select={true}
                        value={operatorState}
                        onChange={handleOperatorChange}
                    >
                        {operators.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
                <Component
                    className={classes.filterValue}
                    multiple={multiple}
                    {...others}
                    value={valueState}
                    onChange={handleValueChange}
                >
                    {children}
                </Component>
            </FormGroup>
        </FormControl>
    )
}

export default Filter