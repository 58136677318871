import React, { createContext } from 'react';

export const AuthManagerContext = createContext(null)

export const AuthManagerProvider = ({ value, children }) => {
    return (
        <AuthManagerContext.Provider value={value}>
            {children}
        </AuthManagerContext.Provider>
    )
}