import React, { Fragment, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Paper, List, ListItem, ListItemAvatar, ListItemText, Divider, Button, CircularProgress, Card, CardHeader, IconButton, CardContent, Collapse, useTheme } from '@material-ui/core';

import CustomAvatar from 'components/core/CustomAvatar';
import PhoneLink from 'components/core/PhoneLink';
import EmailLink from 'components/core/EmailLink';

import { AuthManagerContext } from 'contexts/AuthManager';
import { I18nManagerContext } from 'contexts/I18nManager';

import { fetchDataSource } from 'actions/index';
import { createRequestId } from 'utils'
import { Icon } from 'styles';
import { Base } from 'components/themes/main';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflow: 'auto',
        position: 'relative',
    },
    inline: {
        display: 'inline',
    },
    divider: {
        marginLeft: 68
    },
    widget: {
        width: '100%',
        border: '1px solid #E2E2E3',
        boxShadow: 'none',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        // minHeight: 70,
        width: '100%',
        flex: 'none'
        // maxHeight: '-webkit-fill-available',
        // marginBottom: theme.spacing(2)
    },
    widgetHeader: {
        // borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '3px 5px 3px 20px',
        textTransform: 'uppercase'
    },
    widgetCollapsibleContent: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        overflowY: 'scroll'
    },
    widgetTitle: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: '1.3125rem',
        fontStyle: 'normal'
    },
    widgetComment: {
        display: 'flex',
        fontStyle: 'italic'
    },
    widgetContent: {
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1
    },
    vboxWidgetContent: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        flexGrow: 1  
    },
    widgetContentText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexGrow: 1,
		marginLeft: 4,
        alignSelf: 'flex-start'
    },
    cardContent: {
        padding: '0 !important'
    },
    mediumAvatar: {
        minWidth: 0
    },
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        // justifyContent: 'space-between',
        width: '100%'
    },
    link: {
        fontSize: 'inherit',
        paddingTop: 0,
        paddingBottom: 2,
        textTransform: 'lowercase'
    },
    activity: {
        color: '#4CB091',
        position: 'relative',
        top: 65,
        left: '48%',
        marginTop: -24, //-theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4,
        zIndex: 9999
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
	avatar: {
		width: 32,
		height: 32
	},
	icon: {
		verticalAlign: 'middle',
		marginBottom: 3
	}
}))

const ActivityMask = () => {
    const classes = useStyles()

    return (
        <CircularProgress size={24} className={classes.activity} />
    )
}

const mapStateData = ({ resource }) => state => {
    let isLoading = false
    let data = []

    if (resource) {
        const id = createRequestId(resource)

        isLoading = (state.datasources[id]) ? state.datasources[id].isLoading : false
        data = (state.datasources[id]) ? state.datasources[id].data : []
    }
    return { isLoading, data } 
}

const contactInfo = [
    { name: 'mobilePhone', icon: 'smartphone', label: 'Mobile', component: PhoneLink },
    { name: 'phone', icon: 'phone', label: 'Phone', component: PhoneLink },
    { name: 'fax', icon: 'printer', label: 'Fax', component: PhoneLink },
	{ name: 'email', icon: 'mail', label: 'Email', component: EmailLink }
]

const PatientHCProviders = ({ record, context, resources, title, ...others }) => {
    const authManager = useContext(AuthManagerContext)
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
	const theme = useTheme()
    const params = useParams()
    const dispatch = useDispatch()
    const resource = {...resources['list']}
    const entityId = record && record.id
    const { isLoading, data } = useSelector(mapStateData({ resource: Object.assign(resource, { 
        params: Object.assign({ customerId: authManager.customerId, patientId: entityId}, params)}) 
    }))
    const deps = useMemo(() => JSON.stringify([resource, entityId]), [resource, entityId])

    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (resource) {
            dispatch(fetchDataSource(resource))
        }
    }, [deps])

    useEffect(() => {
        if (context && context.hcp) {
            dispatch(fetchDataSource(resource))
        }
    }, [context])

    return (
        data && (data.length > 0) && (
            <Card className={classes.widget}>
                <CardHeader
                    className={classes.widgetHeader}
                    action={
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                                <Icon icon={'chevron-down'} size={16} color={Base.iconLight.color} />
                        </IconButton>
                    }
                    title={i18nManager.t(title)}
                    titleTypographyProps={{ 
                        className: classes.widgetTitle,
                        variant: "caption",
                        color: "secondary",
                        noWrap: true
                    }}
                />
                <Collapse className={classes.widgetCollapsibleContent} in={expanded} timeout="auto" unmountOnExit>
                    <CardContent className={classes.cardContent}>
                        <List className={classes.root}>
                            {data && data.map((item, index) => (
                                <Fragment key={index}>
                                <ListItem key={`item-${index}`} alignItems="flex-start" className={classes.listItem}>
										{/* <ListItemAvatar className={classes.mediumAvatar}>
											<CustomAvatar className={classes.avatar} image={item.logo} name={item.fullName}></CustomAvatar>
                                    </ListItemAvatar> */}
                                    <ListItemText  
                                        disableTypography={true}
                                        className={classes.widgetContentText}
                                        primary={
											<div className={classes.hbox} style={{ paddingTop: 5 }} >
												<CustomAvatar className={classes.avatar} image={item.logo} name={item.fullName}></CustomAvatar>
												<span className={classes.vbox} style={{ paddingLeft: 16 }} >
													<Typography variant="subtitle2" color="secondary" noWrap>{item.fullName}</Typography> 
													<Typography className={classes.widgetTitle} variant="caption" color="textSecondary" noWrap>{item.hcpRole}</Typography> 
												</span>
											</div>
                                        }
                                        secondary={
                                            <Fragment>
                                                <div style={ {paddingTop: 8} } >
                                                    {contactInfo.map((info, index) => {
                                                        if (item[info.name]) {
                                                            const Link = info.component

                                                            return (
                                                                <Typography key={`item-detail-${index}`} component={'div'} className={classes.widgetTitle} variant="caption" color="textSecondary">
                                                                    <Icon className={classes.icon} icon={info.icon} size={12} color={theme.palette.text.secondary} /><Link className={clsx(classes.widgetTitle, classes.link)} href={item[info.name]}>{item[info.name]}</Link>
                                                                </Typography>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </div>
                                            </Fragment>
                                        }
                                    />
                                </ListItem>
                                {(index < data.length - 1) && (
                                    <Divider variant="inset" component="li" className={classes.divider} />
                                )}
                                </Fragment>
                            ))}
                        </List>
                    </CardContent>
            </Collapse>
            </Card>
        )
    )
}

export default PatientHCProviders