import React from 'react';
import { Switch, useRouteMatch, useParams } from 'react-router-dom'

import AuthRoute from 'components/AuthRoute'
import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'

import CustomerStepper from 'components/CustomerStepper'

// const routes = [{
//     name: 'Customer',
//     component: Customer,
//     to: '/customers/:customerId/:prop'
// }, {
//     name: 'Patient',
//     component: Patient,
//     to: '/customers/:customerId/patients/:patiendId/:prop'
// }]

const columnsOverride = [
    /*{
    type: 'AvatarColumn',
    dataKey: 'logo',
    columnText: 'name',
    order: 1,
    width: 10
},*/ {
    type: 'LinkColumn',
    color: 'primary',
    dataKey: 'name',
    columnImage: 'logo',
    columnText: 'name',
    order: 2
}, {
    dataKey: 'code',
    order: 3
}, {
    dataKey: 'npi',
    order: 4
}, {
    dataKey: 'type',
    order: 5
}, {
    type: 'PhoneColumn',
    color: 'primary',
    dataKey: 'billingPhone',
    order: 6
}, {
    type: 'StatusColumn',
    size: 'small',
    dataKey: 'status',
    order: 7
}]

const actionsOverride = [{
    type: 'create',
    component: CustomerStepper,
    order: 1
}, {
    type: 'update',
    component: CustomerStepper,
    order: 3
}, {
    type: 'delete',
    disabled: selection => (selection.length === 0),
    confirm: true,
    order: 5
}]

const entityIdProperty = 'customerId'

const Customers = ({ actions, columns, resources, ...others }) => {
    const { path } = useRouteMatch()
    const params = useParams()
    const { actionsState, columnsState, dataState, handleRefresh, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride, [{ 
        dataKey: 'name', 
            to: `${path}/:${entityIdProperty}/open`, params: Object.assign({ [entityIdProperty]: row => row.id }, params)
        }, {
            dataKey: 'status', 
            onClick: () => alert('Handle Status Click') 
    }]], {
        actions, 
        columns, 
        resources
    })

    return (
        <Switch>
            <AuthRoute exact path={path} 
                component={DataBrowser}
				//statefulId="customers"
                actions={actionsState}
                columns={columnsState}
                searchFieldName={['name']}
                rows={dataState}
                onRefresh={handleRefresh}
                onAction={handleAction}
                isLoading={isLoading}
                inProgress={inProgress}
                {...others}
            />
            {/* <AuthRoute path={`${path}/:${entityIdProperty}/open`} 
                component={DeepNavigation}
                resource={resources['read']}
                routes={routes}
            /> */}
            {/* <AuthRoute exact path={`${path}/new`} 
                component={CustomerStepper}
                title={title}
                subtitle={formSubtitle}
                fields={[]} //TODO:
                record={{}}
                onSubmit={handleFormSubmit}
                onCancel={handleFormCancel}
            />
            <AuthRoute exact path={`${path}/:${entityIdProperty}/edit`} 
                component={CustomerStepper}
                title={title}
                subtitle={formSubtitle}
                fields={[]} //TODO:
                record={recordState}
                resource={resources['read']}
                fetchRecord={fetchRecord}
                onSubmit={handleFormSubmit}
                onCancel={handleFormCancel}
            /> */}
        </Switch>
    )
}

export default Customers