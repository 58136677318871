import React, { Fragment, useRef, useEffect, useContext, useMemo, useCallback, createRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import { AuthManagerContext } from 'contexts/AuthManager';

import NumberField from 'components/core/NumberField';
import SelectField from 'components/core/SelectField';
import SelectFilter from 'components/core/SelectFilter';
import SelectMultipleField from 'components/core/SelectMultipleField';
import PhoneField from 'components/core/PhoneField'
import { TextField } from '@material-ui/core';
import CheckboxField from 'components/core/CheckboxField';

const fieldMap = {
    'NumberField': NumberField,
    'SelectField': SelectField,
    'SelectFilter': SelectFilter,
    'SelectMultipleField': SelectMultipleField,
    'PhoneField': PhoneField,
    'TextField': TextField,
    'CheckboxField': CheckboxField 
}

const useStyles = makeStyles(theme => ({
    subtitle: {
        display: 'block',
        paddingLeft: theme.spacing(1/4)
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function FormRenderer({
    defaults,
    fields, 
    record, 
    params,
    inProgress, 
    resetOnSubmit,
    isSubmitting,
    onSubmit, 
	onError,
    onStateChange,
    onKeyDown,
    onFocus
}) {
    const authManager = useContext(AuthManagerContext)
	const classes = useStyles()
	const ref = useRef()
    const validations = useMemo(() => {
        return fields.reduce((validations, field) => {
            const name = field.id || field.name
            if (name) {
                validations[name] = (field.required == true) ? ['required'] : []
                if (field.validations && Array.isArray(field.validations)) validations[name] = validations[name].concat(field.validations)
            }
            return validations
        }, {})
	}, [fields])
	const initialState = useMemo(() => {
		const defaultValues = fields.reduce((result, { name, defaultValue }) => {
			if (defaultValue) result[name] = defaultValue
			return result
		}, {})
		return Object.assign(record && (typeof record === 'object') ? record : {}, defaultValues)
    }, [fields, record])
    const [formState, setFormState] = useState(initialState)
    const [state, errors, handleChange, handleBlur, handleSubmit] = useForm(callback, validations, initialState)   
    
    function callback() {
        if (onSubmit) {
            onSubmit(state, ref)
        }
    }
	
	useEffect(() => {
        // onStateChange && onStateChange(state)
        onStateChange && onStateChange(state, formState)
        setFormState(state)
	}, [state])

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
	}, [isSubmitting])
    
	const renderFields = useCallback(() => {
		return (
			<Fragment>
				{fields.map(({ component, id, name, resource, hidden, ...others }, index) => {
					const Field = (component) ? (typeof component === 'string') ? fieldMap[component] : component : null

					if (resource) {
						resource = Object.assign({...resource}, { params })
					}

					return (
                        resetOnSubmit === true ? (
						<Field
							// ref={refs[index]}
							key={index}
							InputLabelProps={InputLabelProps}
							id={id || name}
							name={name}
							defaultValue={state ? (state[name] || "") : ""}
							resource={resource}
							error={!!errors[name]}
							helperText={!!errors[name] && errors[name]}
							onChange={handleChange}
                            onKeyDown={onKeyDown}
							onBlur={handleBlur}
                            onFocus={onFocus}
                            style={{ display: hidden ? 'none' : 'inherit'}}
		
							{...defaults}
							{...others}
						/>) : (
                            <Field
							// ref={refs[index]}
							key={index}
							InputLabelProps={InputLabelProps}
							id={id || name}
							name={name}
                            value={state ? (state[name] || "") : ""}
							resource={resource}
							error={!!errors[name]}
							helperText={!!errors[name] && errors[name]}
							onChange={handleChange}
                            onKeyDown={onKeyDown}
							onBlur={handleBlur}
                            onFocus={onFocus}
                            style={{ display: hidden ? 'none' : 'inherit'}}
		
							{...defaults}
							{...others}
						/>
                        )
					)
				})}
			</Fragment>
		)
	}, [fields, formState, params, errors, handleChange, handleBlur, defaults])

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            {renderFields()}
        </form>
    )
}