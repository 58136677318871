import React, { useRef, useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import useForm from 'hooks/useForm';

import { I18nManagerContext } from 'contexts/I18nManager';
import { AuthManagerContext } from 'contexts/AuthManager'
import SearchField from 'components/core/SearchField';
import SelectTagField from 'components/core/SelectTagField';
import SelectField from 'components/core/SelectField';
import PhoneField from 'components/core/PhoneField';

const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    formTitle: {
        paddingBottom: theme.spacing(5)
    }
}))

const InputLabelProps = {
    required: false //Hide asterix on required field
}

export default function PatientPathwayForm({ title = 'Pathway Information', record, params = {}, isSubmitting, onSubmit, onError, onDirty }) {
    const classes = useStyles()
    const ref = useRef()
    const [validationsState, setValidationsState] = useState({ pathwayId: ['required'] })
    const [state, errors, handleChange, handleBlur, handleSubmit, isDirty] = useForm(callback, validationsState, /*validations,*/ record)
    
    const authManager = useContext(AuthManagerContext)
    const npiRules = {
        onChange: event => {
            const { selection } = event.target

            handleChange({ target: { id: 'hcpId', value: selection && selection.id }})
            handleChange({ target: { id: 'hcpNpi', value: selection && selection.npi }})
            handleChange({ target: { id: 'hcpName', value: selection && selection.name }})
            handleChange({ target: { id: 'hcpAddress', value: selection && selection.billingAddress1 }})

            handleChange({ target: { id: 'hcpMobilePhone', value: null }})
            handleChange({ target: { id: 'hcpEmail', value: null }})
        }
    }

    function callback() {
        if (onSubmit) {
            onSubmit(state)
        }
    }

    useEffect(() => {
        (Object.keys(errors).length > 0 && onError && onError(errors))
    }, [errors])

    useEffect(() => {
        setValidationsState(prevState => {
            const newState = { ...prevState }

            state.hcpId ? newState['hcpRoleId'] = ['required'] : delete newState['hcpRoleId']

            return newState
        })
    }, [state.hcpId])

    useEffect(() => {
        (isSubmitting === true && handleSubmit())
    }, [isSubmitting])

    useEffect(() => {
        (onDirty(isDirty))
    }, [isDirty])
    
    const handleSearchChange = name => event => {
        const { selection } = event.target
        const value = selection && selection[name]

        event.target.value = value

        npiRules['onChange'](event)
    }

    const handleSelectTagChange = name => event => {
        const { selection } = event.target
        const value = selection && selection.map(item => ({ [name]: item[name] }))

        event.target.value = value
        handleChange(event)

    }

    // console.log(state)

    return (
        <form ref={ref} className={classes.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
            {/* <Typography className={classes.formTitle} variant="h5" color="textSecondary" noWrap>
                {title}
            </Typography> */}
            <SelectField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="pathwayId"
                label="Pathway"  // TODO: 
                defaultValue={state ? (state.pathwayId || "") : ""}
                fullWidth
                required={true}
                error={!!errors['pathwayId']}
                helperText={!!errors['pathwayId'] && errors['pathwayId']}
                resource={{
                    path: '/pathways',
                    method: 'get'
                }}
                params={((!record.pathwayId && authManager.customerId && params.patientId) ? {  customerId: params.customerId || authManager.customerId, patientId: params.patientId } : {} )}
                onChange={handleChange}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
                disabled={(record && record.pathwayId) ? true : false }
            />
            <SelectTagField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                autoFocus
                margin="normal"
                id="icdCodes"
                label="Search ICD Codes" // TODO: i18n
                defaultOptions={state ? state.icdCodes : null} // TODO:
                fullWidth
                required={true}
                error={!!errors['icdCodes']}
                helperText={!!errors['icdCodes'] && errors['icdCodes']}
                resource={{
                    path: '/icd-codes',
                    method: 'get'
                }}
                textName="value"
                descriptionName="text"
                valueName="value"
                onChange={handleSelectTagChange('value')}
                onBlur={handleBlur}
                params={((!record.pathwayId && authManager.customerId && params.patientId) ? { customerId: params.customerId || authManager.customerId, patientId: params.patientId, pathwayId: state.pathwayId } : {} )}

            />
            <SearchField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                autoFocus
                margin="normal"
                id="npi"
                label="Search Health Care Provider" // TODO: i18n
                //defaultValue={state ? state.pcpId : ""}
                defaultOption={(state && state.hcpId) ? { id: state.hcpId, npi: state.hcpNpi, name: state.hcpName, billingAddress1: state.hcpAddress } : null}
                fullWidth
                required={true}
                error={!!errors['npi']}
                helperText={!!errors['npi'] && errors['npi']}
                resource={{
                    path: '/npi-practitioners',
                    method: 'get'
                }}
                textName="name"
                descriptionName="billingAddress1"
                valueName="id"
                onChange={handleSearchChange('npi')}
                onBlur={handleBlur}
                params={{ zip: null }}  // TODO: Need to include the patient zip
            />
            <TextField
                InputLabelProps={InputLabelProps}
                InputProps={{ readOnly: true }}
                autoComplete="off"
                margin="normal" 
                id="hcpNpi"
                label="NPI"   // TODO: 
                value={(state && state.hcpNpi) ? state.hcpNpi : ""}
                fullWidth
                required={true}
                error={!!errors['hcpNpi']}
                helperText={!!errors['hcpNpi'] && errors['hcpNpi']}
                disabled={ true }
            />
            <TextField
                InputLabelProps={InputLabelProps}
                InputProps={{ readOnly: true }}
                autoComplete="off"
                margin="normal" 
                id="hcpAddress"
                label="Address"
                value={(state && state.hcpAddress) ? state.hcpAddress : ""}
                fullWidth
                required={true}
                error={!!errors['hcpAddress']}
                helperText={!!errors['hcpAddress'] && errors['hcpAddress']}
                disabled={ true }
            />
            <TextField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="hcpEmail" 
                label="Email"     // TODO: 
                //defaultValue={state && state.hcpEmail} 
                value={(state && state.hcpEmail) ? state.hcpEmail : ""}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors['hcpEmail']}
                helperText={!!errors['hcpEmail'] && errors['hcpEmail']}
            />
            <PhoneField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal" 
                id="hcpMobilePhone" 
                label="Mobile Phone"  // TODO: 
                //defaultValue={(state && state.hcpMobilePhone)}
                value={(state && state.hcpMobilePhone) ? state.hcpMobilePhone : ""}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors['hcpMobilePhone']}
                helperText={!!errors['hcpMobilePhone'] && errors['hcpMobilePhone']}
            />
            <SelectField
                InputLabelProps={InputLabelProps}
                autoComplete="off"
                margin="normal"
                id="hcpRoleId"
                label="Role"     // TODO: 
                defaultValue={state ? (state.hcpRoleId || "") : ""}
                fullWidth
                required={state && state.hcpId ? false : true}
                error={!!errors['hcpRoleId']}
                helperText={!!errors['hcpRoleId'] && errors['hcpRoleId']}
                resource={(state.hcpId) && {
                    path: '/hcp-roles',
                    method: 'get'
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                textName="text"
                valueName="value"
                disabled={state && state.hcpId ? false : true}
            />
        </form>
    )
}