import React from 'react';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { colorMap, initial } from 'utils'

export default function CustomAvatar({ image, name, className, textClassName }) {
    const text = name ? initial(name) : ''
    const avatar = image ? 'data:image/png;base64,' + image : null

    return (
        (avatar) ?
        <Avatar alt={ name } src={ avatar } className={ className }/> :
        <Avatar style={{ backgroundColor: colorMap[text]}} className={ className }>
            <Typography className={textClassName}>{ text }</Typography>
        </Avatar>
    )
}