import React from 'react';

import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'

const columnsOverride = [{
    type: 'AvatarColumn',
    dataKey: 'image',
    columnText: 'fullName',
    order: 1,
    width: 10
}, {
    dataKey: 'fullName',
    order: 2
}, {
    type: 'EmailColumn',
    color: 'primary',
    dataKey: 'email',
    order: 3
}, {
    type: 'PhoneColumn',
    color: 'primary',
    dataKey: 'mobilePhone',
    order: 4
}, {
    dataKey: 'emailCode',
    order: 5
}, {
    dataKey: 'phoneCode',
    order: 6
}, {
    dataKey: 'userRole',
    order: 7
}, {
    type: 'StatusColumn',
    size: 'small',
    dataKey: 'status',
    order: 8
}]

/*
    confirm:    [Config] Boolean/Object with title & text to show confirm modal dialog
*/
const actionsOverride = [{
    type: 'delete',
    disabled: selection => (selection.length === 0),
    confirm: true
}]

/*
    title:      [Config]
    subtitle:   [Config]
    path:       [Config]
    actions:    [Config]
    columns:    [Config]
    resources:  [Config]
    params:     ?
*/
const Users = ({ actions, columns, resources, params, ...others }) => {
    const { actionsState, columnsState, dataState, handleRefresh, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions, 
        columns, 
        resources
    })

    return (
        <DataBrowser
			//statefulId="users"
            actions={actionsState}
            columns={columnsState}
            searchFieldName={['fullName']}
            rows={dataState}
            onRefresh={handleRefresh}
            onAction={handleAction}
            isLoading={isLoading}
            inProgress={inProgress}
            {...others}
        />
    )
}

export default Users