import React, { Fragment, createContext, useState, useRef, useCallback } from 'react';
import { Dialog, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    alwaysOnTop: {
		zIndex: `${Number.MAX_SAFE_INTEGER} !important`
	},
	modal: {}
}))

const DialogContainer = ({ children, open, dialog, alwaysOnTop, onClose, ...others }) => {
	const classes = useStyles()
	const cls = alwaysOnTop ? classes.alwaysOnTop : classes.modal

	return dialog ? (
		<Dialog className={cls} open={open} onClose={onClose} {...others}>
			<Fragment>
				{children}
			</Fragment>
		</Dialog>
	) : (
		<Modal className={cls} open={open} onClose={onClose} {...others}>
			<Fragment>
				{children}
			</Fragment>
		</Modal>
	)
}

export const DialogContext = createContext(null)

export const DialogProvider = ({ children }) => {
	const [dialogs, setDialogs] = useState([])
	const openDialog = (option) => {
		const dialog = { ...option, open: true }

		setDialogs((dialogs) => [...dialogs, dialog])
	}
	const closeDialog = (name) => {
		setDialogs((dialogs) => {
			const index = name ? dialogs.findIndex(d => (d.name === name)) : dialogs.length - 1
			const dialog = dialogs[index]
			// const dialog = dialogs.pop()

			if (!dialog) {
				return dialogs
			}
			if (dialog.onClose) {
				dialog.onClose()
			}
			// return [...dialogs].concat({ ...dialog, open: false })
			dialogs.splice(index, 1)
			return [...dialogs]
		})
	}
	const findDialog = (name) => dialogs.find(dialog => dialog.name === name)

	// const contextValue = useRef({ openDialog, closeDialog, findDialog })

	return (
		<DialogContext.Provider value={{ openDialog, closeDialog, findDialog }}>
			{children}
			{dialogs.map((dialog, i) => {
				return <DialogContainer key={i} {...dialog} />
			})}
		</DialogContext.Provider>
	)
}