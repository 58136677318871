import * as Types from 'constants/ActionTypes'

// const mapCreateUserCommit = action => item => {
//     if (item.guid === action.payload.data.guid) {
//         delete action.payload.data.guid
//         return action.payload.data
//     }
//     return item
// }

// const filterCreateUserRollback = action => item => {
//     // return (!item.guid || 
//     // (item.guid && item.guid !== action.payload.data.guid))
//     console.log(item, item.guid, action.payload)
//     return true
// }

const users = (state = {
    inProgress: false,
    didInvalidate: false,
    data: []
}, action) => {
    switch (action.type) {
        case Types.FETCH_USERS:
            return Object.assign({}, state, {
                inProgress: true,
                didInvalidate: false
            })
        case Types.CREATE_USER:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: [...state.data, action.payload.data],
            })
        case Types.FETCH_USERS_COMMIT:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: action.payload.data
            })
        case Types.CREATE_USER_COMMIT:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: state.data.map(item => {
                    if (item.guid === action.payload.data.guid) {
                        delete action.payload.data.guid
                        return action.payload.data
                    }
                    return item
                })
            })
        case Types.FETCH_USERS_ROLLBACK:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false
            })
        case Types.CREATE_USER_ROLLBACK:
            return Object.assign({}, state, {
                inProgress: false,
                didInvalidate: false,
                data: state.data.filter(item => (!item.guid || 
                    (item.guid && item.guid !== action.meta.data.guid))
                )
            })
        default:
            return state
    }
};

export default users