import React from 'react';

import DataBrowser from 'components/core/DataBrowser'
import useDataBrowser from 'hooks/useDataBrowse'

import PatientPathwayDialog from 'components/PatientPathwayDialog'
import { PATIENT_PATHWAY_STATUS_ACTIVE, PATIENT_PATHWAY_STATUS_INACTIVE, USER_ADMIN_ROLE } from 'constants/index'

const columnsOverride = [{
    dataKey: 'pathway',
    order: 1
}, {
    dataKey: 'fullName',
    order: 3
}, {
    dataKey: 'hcpRole',
    order: 4
}, {
    type: 'EmailColumn',
    color: 'primary',
    dataKey: 'hcpEmail',
    order: 5
}, {
    type: 'PhoneColumn',
    color: 'primary',
    dataKey: 'hcpMobilePhone',
    order: 6
}, {
    type: 'StatusColumn',
    size: 'small',
    dataKey: 'status',
    order: 7
}]

const actionsOverride = [{
    type: 'create',
    component: PatientPathwayDialog,
	hidden: (authManager, params) => {
        if (params && params.customerId) {
            return authManager.currentCustomerRoleId !== USER_ADMIN_ROLE
        }
		return authManager.roleId !== USER_ADMIN_ROLE
	},
    order: 1
}, {
    type: 'update',
    disabled: selection => !(selection.length === 1),
	hidden: (authManager, params) => {
        if (params && params.customerId) {
            return authManager.currentCustomerRoleId !== USER_ADMIN_ROLE
        }
		return authManager.roleId !== USER_ADMIN_ROLE
	},
    component: PatientPathwayDialog,
    order: 2
}, {
    type: 'activate',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 0 && (selection.findIndex(item => item.statusId === PATIENT_PATHWAY_STATUS_INACTIVE) == -1)))
    },
    selectionMap: ({ id }) => ({ id }),
    confirm: true,
    order: 3
}, {
    type: 'inactivate',
    disabled: selection => {
        return ((selection.length === 0) || (selection.length > 0 && (selection.findIndex(item => item.statusId === PATIENT_PATHWAY_STATUS_ACTIVE) == -1)))
    },
    selectionMap: ({ id }) => ({ id }),
    confirm: true,
    order: 4
}]

const PatientPathways = ({ actions, columns, resources, ...others }) => {
    const { actionsState, columnsState, dataState, recordState, handleRefresh, handleFetchRecord, handleAction, isLoading, inProgress } = useDataBrowser([actionsOverride], [columnsOverride], {
        actions, 
        columns, 
        resources
    })

    return (
        <DataBrowser
			//statefulId="patient-pathways"
            actions={actionsState}
            columns={columnsState}
            entityIdName="pathwayId"
            searchFieldName={['fullName']}
            rows={dataState}
            record={recordState}
            onRefresh={handleRefresh}
            onFetchRecord={handleFetchRecord}
            onAction={handleAction}
            isLoading={isLoading}
            inProgress={inProgress}
            {...others}
        />
    )
}

export default PatientPathways