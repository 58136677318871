import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red, green } from '@material-ui/core/colors';
import thumbnail from 'assets/image/thumbnail.jpg';
import { getVideoDuration } from 'helpers'

import { Icon } from 'styles';
import { CardActionArea } from '@material-ui/core';
import { PlayCircleFilled, Link } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: 'none'
  },
  header: {
    padding: '16px 16px 0px'
  },
  actions: {
    padding: '0px 16px 16px'
  },
  media: {
    width: 'auto',
    minWidth: 250,
    maxWidth: 250
  },
  content: {
    width: '100%'
  },
  film: {
    backgroundColor: red[500],
  },
  link: {
    backgroundColor: green[500],
  },
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.3)',
    width: '100%',
    height: '100%',
    transition: '.5s ease',
    color: 'transparent'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '-webkit-xxx-large',
    opacity: .8,
    "&&&&:hover": {
      opacity: 1
    }
  }
}));

export default function CustomMultimedia({ content }) {
  const classes = useStyles();
  const contentType = content.contentType == 'Video' ? 'film' : 'link'

  const handleContentClick = () => {  window.open(content.contentUrl, '_blank') }

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.media} onClick={handleContentClick}>
        <CardMedia
          component="img"
          image={content.thumbnailUrl}
          title={content.title}
          alt={content.title}
          onError={(event) => event.target.src = thumbnail}
          className={classes.thumbnail}
        />
        <div className={classes.overlay}>
          {contentType == 'film' ? (<PlayCircleFilled className={classes.icon} size={100}/>) : (<Link className={classes.icon} size={100}/>)}
        </div> 
      </CardActionArea>
      <div className={classes.content}>
        <CardHeader
          avatar={
            <Avatar className={classes[contentType]}>
              <Icon icon={contentType} size={16} />
            </Avatar>
          }
          title={content.title}
          subheader={
            <React.Fragment>
              <Typography variant="subtitle2" color="textSecondary" component="p">
                {content.subject} · {content.topic} · {content.subtopic} 
              </Typography>
            </React.Fragment>
          }
          className={classes.header}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {content.abstract}
          </Typography>
        </CardContent>
        <CardActions disableSpacing className={classes.actions}>
          <Typography variant="subtitle2" color="textSecondary" component="p">
            {getVideoDuration(content.duration)} · {content.languages} · {content.library}
          </Typography>
        </CardActions>
      </div>
    </Card>
  );
}
