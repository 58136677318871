import React, { Fragment, useState, useEffect, useMemo, forwardRef, Children, useRef, createContext, useContext, cloneElement, isValidElement } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, TextField, ListItem, ListItemText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { VariableSizeList } from 'react-window';
import clsx from 'clsx';
import { throttle } from 'lodash';

import { requestId } from 'utils';
import { fetchDataSource, clearDataSource } from 'actions/index';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}))

// const useStyles = makeStyles(theme => ({
//     input: {
//         display: 'flex',
//         padding: 0,
//         height: 'auto'
//     },
//     singleValue: {
//         flexGrow: 1
//     },
//     dropdown: {
//         color: `${theme.palette.secondary.light} !important`,
//         padding: '0px !important'
//     },
//     valueContainer: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         flex: 1,
//         alignItems: 'center',
//         overflow: 'hidden',
//         padding: `${theme.spacing(1/4)}px ${theme.spacing(0)}px`
//     }
// }))

const LISTBOX_PADDING = 8 // px

const renderRow = props => {
    const { data, index, style } = props

    return cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    })
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext)

    return <div ref={ref} {...props} {...outerProps} />
})

const useResetCache = data => {
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data])

    return ref
}

const ListboxComponent = forwardRef(({ children, ...other }, ref) => {
    const itemData = Children.toArray(children)
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
    const itemCount = itemData.length
    // const itemSize = smUp ? 36 : 68 //48
    const itemSize = 68
  
    const getChildSize = child => {
        if (isValidElement(child) && child.type === ListSubheader) {
            return itemSize
        }
  
        return itemSize
    }
  
    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
    }
  
    const gridRef = useResetCache(itemCount)
  
    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
            <VariableSizeList
                itemData={itemData}
                height={getHeight() + 2 * LISTBOX_PADDING}
                width="100%"
                ref={gridRef}
                outerElementType={OuterElementType}
                innerElementType="ul"
                itemSize={(index) => getChildSize(itemData[index])}
                overscanCount={5}
                itemCount={itemCount}
            >
                {renderRow}
            </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    )
})
  
ListboxComponent.propTypes = {
    children: PropTypes.node,
}

const SearchField = ({ /*id, valueName, textName, isLoading, defaultValue, options, label, fullWidth, margin, autoFocus, required, isClearable = false, fetchDataSource, resource, params = {}, onChange*/ 
    className,
    id,
    label,
    defaultOption,
    textName,
    descriptionName,
    valueName,
    resource,
    params, //= {},
    onChange,
    readOnly = false
}) => {
    const classes = useStyles()
    const dataSourceId = useMemo(() => resource ? requestId(resource.path) : null, [resource])
    const [open, setOpen] = useState(false)
    const [inputMode, setInputMode] = useState('input')
    const [inputValue, setInputValue] = useState('')
    const [defaultOptionState] = useState(defaultOption ? {...defaultOption} : null)
    const dispatch = useDispatch()
    const options = useSelector(state => state.datasources[dataSourceId] ? state.datasources[dataSourceId].data : (defaultOptionState) ? [defaultOptionState] : [])
    const isLoading = useSelector(state => state.datasources[dataSourceId] ? state.datasources[dataSourceId].isLoading : false)
    const [selection, setSelection] = useState(null)

    useEffect(() => {
        if (defaultOptionState) {
            setSelection(defaultOptionState)
        }
    }, [])

    const fetchData = (searchString, top) => {
        if (resource && params) {
            return dispatch(fetchDataSource(resource, { searchString, top, ...params }))
        }
        return
    }

    const clearData = () => {
        if (resource) {
            return dispatch(clearDataSource(resource))
        }
        return    
    }

    useEffect(() => () => clearData(), [])

    useEffect(() => {
        let dispatchPromise = null
        let timer = null

        if (inputMode === 'reset' && inputValue.length >= 3) {
            timer = setTimeout(() => {
                clearTimeout(timer)
                dispatchPromise = fetchData(inputValue, 100)
            }, 500)
        }
        return () => {
            if (dispatchPromise && dispatchPromise.cancel) {
                dispatchPromise.cancel()
            }
            if (timer) clearTimeout(timer)
        }
    }, [inputValue])

    const handleInputChange = (event, newValue, reason) => {
        if (inputMode !== 'reset' && (!selection || (selection && newValue !== selection[textName]))) {
            setInputMode('reset')
        }
        if (reason === 'clear') clearData()
        setInputValue(newValue)
    }

    const handleChange = (event, option) => {
        const newSelection = option ? {...option} : null

        setInputMode('selection')
        setSelection(newSelection)
        if (onChange) onChange({
            target: { id, value: newSelection && newSelection[valueName], selection: newSelection }
        })
    }

    return (
        <Autocomplete
            className={clsx(classes.root, className)}
            id={id}
            style={{ width: '100%' }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option[valueName] === value[valueName]}
            getOptionLabel={(option) => {
                return option[textName] || ""
            }}
            filterOptions={options => options}
            options={options}
            loading={isLoading}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            value={selection}
            ListboxComponent={ListboxComponent}
            disableClearable={readOnly}
            renderInput={props => (
                <TextField
                    {...props}
                    label={label}
                    // variant="outlined"
                    InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {props.InputProps.endAdornment}
                            </Fragment>
                        ),
                        readOnly: readOnly
                    }}
                    // onChange={handleInputChange}
                />
            )}
            renderOption={option => (
                <ListItemText primary={option[textName]} secondary={option[descriptionName]} />
            )}
            onChange={handleChange}
        />
    )
}

export default SearchField


// import React, { Fragment, useState, useEffect } from 'react';
// import { connect } from 'react-redux'
// import PropTypes from 'prop-types';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import Select from 'react-select';
// import { components } from 'react-select'
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import { TextField } from '@material-ui/core';
// import { requestId } from 'utils';
// import { fetchDataSource } from 'actions/index';

// const useStyles = makeStyles(theme => ({
//     input: {
//         display: 'flex',
//         padding: 0,
//         height: 'auto'
//     },
//     singleValue: {
//         flexGrow: 1
//     },
//     dropdown: {
//         color: `${theme.palette.secondary.light} !important`,
//         padding: '0px !important'
//     },
//     valueContainer: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         flex: 1,
//         alignItems: 'center',
//         overflow: 'hidden',
//         padding: `${theme.spacing(1/4)}px ${theme.spacing(0)}px`
//     }
// }))

// function inputComponent({ inputRef, ...props }) {
//     return <div ref={inputRef} {...props} />
// }
  
// inputComponent.propTypes = {
//     inputRef: PropTypes.oneOfType([
//         PropTypes.func,
//         PropTypes.shape({
//             current: PropTypes.any.isRequired,
//         }),
//     ]),
// }

// function Control(props) {
//     const {
//       children,
//       innerProps,
//       innerRef,
//       selectProps: { classes, TextFieldProps },
//     } = props;
  
//     return (
//         <TextField
//             InputProps={{
//                 inputComponent,
//                 inputProps: {
//                 className: classes.input,
//                 ref: innerRef,
//                 children,
//                 ...innerProps,
//                 },
//             }}
//             {...TextFieldProps}
//         />
//     )
// }
  
// Control.propTypes = {
//     /**
//      * Children to render.
//      */
//     children: PropTypes.node,
//     /**
//      * The mouse down event and the innerRef to pass down to the controller element.
//      */
//     innerProps: PropTypes.shape({
//         onMouseDown: PropTypes.func.isRequired,
//     }).isRequired,
//     innerRef: PropTypes.oneOfType([
//         PropTypes.oneOf([null]),
//         PropTypes.func,
//         PropTypes.shape({
//             current: PropTypes.any.isRequired,
//         }),
//     ]).isRequired,
//     selectProps: PropTypes.object.isRequired,
// }

// function IndicatorSeparator () {
//     return null
// }

// function DropdownIndicator({ selectProps, ...others }) {
//     return (
//         <components.DropdownIndicator className={selectProps.classes.dropdown} selectProps {...others }>
//             <ArrowDropDownIcon/>
//         </components.DropdownIndicator>
//     )
// }

// function ValueContainer({ selectProps, children }) {
//     return <div className={selectProps.classes.valueContainer}>{children}</div>;
// }
  
// ValueContainer.propTypes = {
//     /**
//      * The children to be rendered.
//      */
//     children: PropTypes.node,
//     selectProps: PropTypes.object.isRequired,
// }

// function SingleValue({ children, selectProps, innerProps}) {
//     return (
//         <Fragment>
//             <span className={selectProps.classes.singleValue} {...innerProps}>{children}</span>
//         </Fragment>
//     )
// }
  
// SingleValue.propTypes = {
//     /**
//      * The children to be rendered.
//      */
//     children: PropTypes.node,
//     /**
//      * Props passed to the wrapping element for the group.
//      */
//     innerProps: PropTypes.any/*.isRequired*/,
//     selectProps: PropTypes.object.isRequired,
// }

// const createOption = (textName, valueName) => option => ({
//     value: option[valueName],
//     label: option[textName]  
// })

// // const createOption = schemaOption => option => Object.keys(schemaOption).reduce((reduced, key) => {
// //     reduced[key] = option[schemaOption[key]]
// //     return reduced
// // }, {})

// function SearchField({ id, valueName, textName, isLoading, defaultValue, options, label, fullWidth, margin, autoFocus, required, isClearable = false, fetchDataSource, resource, params = {}, onChange }) {
//     const selectStyles = {
//         container: base => ({
//             ...base,
//             width: '100%',
//         }),
//         input: base => ({
//             ...base,
//             color: theme.palette.text.primary,
//             '& input': {
//                 font: 'inherit',
//             },
//         }),
//     }
//     const classes = useStyles()
//     const theme = useTheme()
//     const [inputValue, setInputValue] = useState(null)
//     // const [selection, setSelection] = useState(null)
//     const defaultOption = defaultValue ? options.find(option => option[valueName] == defaultValue) : null
//     const [selection, setSelection] = useState(defaultOption ? createOption(textName, valueName)(defaultOption) : null)

//     useEffect(() => {
//         if (resource && inputValue && inputValue.length > 0) {
//             const timer = setTimeout(() => {
//                 fetchDataSource(resource, Object.assign(params, { searchString: inputValue, top: 100 }))
//             }, 1500)
//             return () => clearTimeout(timer)
//         }   
//     }, [inputValue])

//     const handleInputChange = newInputValue => {
//         if (newInputValue && newInputValue.length > 0) setInputValue(newInputValue)
//     }

//     const handleChange = selected => {
//         const option = options.find(option => option[valueName] == selected.value)
//         const value = selected.value

//         setSelection(selected)
//         if (onChange) onChange({
//             target: { id, value, selection: option }
//         })
//     }

//     return (
//         <Select
//             classes={classes}
//             styles={selectStyles}
//             inputId={id}
//             TextFieldProps={{
//                 label, fullWidth, margin, autoFocus, required,
//                 defaultValue: selection ? selection.value : "",
//                 InputLabelProps: {
//                     htmlFor: id,
//                     required: false //Hide asterix on required field
//                 }
//             }}
//             isClearable={isClearable}
//             isLoading={isLoading}
//             placeholder=""
//             components={{
//                 Control,
//                 ValueContainer,
//                 SingleValue,
//                 IndicatorSeparator,
//                 DropdownIndicator
//             }}
//             value={selection}
//             options={options.map(createOption(textName, valueName))}
//             onInputChange={handleInputChange}
//             onChange={handleChange}
//         />
//     )
// }

// const mapStateToProps = (state, props) => {
//     let { options, resource } = props
//     let isLoading = false

//     options = options || []
//     if (resource) {
//         const id = requestId(resource.path)

//         if (state.datasources[id]) {
//             options = state.datasources[id].data
//             isLoading = state.datasources[id].inProgress
//         }
//     }
//     return { isLoading, options }
// }

// const mapDispatchToProps = dispatch => ({
//     fetchDataSource: ({ path, method }, query) => dispatch(fetchDataSource({ path, method }, query))
// })

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(SearchField)