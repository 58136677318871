import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Select from 'react-select';
import { components } from 'react-select'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Icon, IconSet } from 'styles';
import { Base } from 'components/themes/main';
import { TextField, MenuItem } from '@material-ui/core';

const icons = IconSet()
const ITEM_HEIGHT = 48

const useStyles = makeStyles(theme => ({

    icon: {
        marginRight: theme.spacing(1)
    },

    input: {
        display: 'flex',
        padding: 0,
        height: 'auto'
    },

    menuList: {
        maxHeight: `${ITEM_HEIGHT * 4}px !important`
    },

    dropdown: {
        color: `${theme.palette.secondary.light} !important`,
        padding: '0px !important'
    },

    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        padding: `${theme.spacing(1/4)}px ${theme.spacing(0)}px`
    },

    card: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        // height: 320,
        textAlign: 'center'
    },
    cardContent: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(),
        justifyContent: 'center',
    },
    cardActions: {
        justifyContent: 'flex-end',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}))

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />
}
  
inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
}

function Control(props) {
    const {
      children,
      innerProps,
      innerRef,
      selectProps: { classes, TextFieldProps },
    } = props;
  
    return (
        <TextField
            InputProps={{
                inputComponent,
                inputProps: {
                className: classes.input,
                ref: innerRef,
                children,
                ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    )
}
  
Control.propTypes = {
    /**
     * Children to render.
     */
    children: PropTypes.node,
    /**
     * The mouse down event and the innerRef to pass down to the controller element.
     */
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
}

function IndicatorSeparator () {
    return null
}

function DropdownIndicator({ selectProps, ...others }) {
    return (
        <components.DropdownIndicator className={selectProps.classes.dropdown} selectProps {...others }>
            <ArrowDropDownIcon/>
        </components.DropdownIndicator>
    )
}

function ValueContainer({ selectProps, children }) {
    return <div className={selectProps.classes.valueContainer}>{children}</div>;
}
  
ValueContainer.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
}

function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            <span className={props.selectProps.classes.icon}><Icon  icon={props.value} size={20} color={Base.iconLight.color}/></span> {props.children}
        </MenuItem>
    )
}
  
Option.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.shape({
      id: PropTypes.string.isRequired,
      /*key: PropTypes.string.isRequired,*/
      onClick: PropTypes.func.isRequired,
      onMouseMove: PropTypes.func.isRequired,
      onMouseOver: PropTypes.func.isRequired,
      tabIndex: PropTypes.number.isRequired,
    })/*.isRequired*/,
    /**
     * Inner ref to DOM Node
     */
    innerRef: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.func,
      PropTypes.shape({
        current: PropTypes.any.isRequired,
      }),
    ])/*.isRequired*/,
    /**
     * Whether the option is focused.
     */
    isFocused: PropTypes.bool.isRequired,
    /**
     * Whether the option is selected.
     */
    isSelected: PropTypes.bool.isRequired,
}

function MenuList(props) {
    return (
        <components.MenuList className={props.selectProps.classes.menuList} {...props}>{props.children}</components.MenuList>
    )
}

function SingleValue({ children, selectProps, innerProps}) {
    return (
        <Fragment>
            <span className={selectProps.classes.icon}><Icon  icon={children} size={20} color={Base.iconLight.color}/></span>
            <span className={selectProps.classes.singleValue} {...innerProps}>{children}</span>
        </Fragment>
    )
}
  
SingleValue.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * Props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.any/*.isRequired*/,
    selectProps: PropTypes.object.isRequired,
}

// const InputLabelProps = { 
//     required: false //Hide asterix on required field
// }

export default function IconField({ id, label, fullWidth, margin, autoFocus, required, isClearable = false, onChange }) {
    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    }
    const createOption = name => ({
        value: name,
        label: name   
    })
    const asOptions = names => names.map(createOption)
    const classes = useStyles()
    const theme = useTheme()
    const rootEl = useRef(null)
    const [selection, setSelection] = useState(null)

    const options = asOptions(icons)

    const handleChange = newOption => {
        setSelection(newOption)
        if (onChange) onChange({ id, value: newOption ? newOption.value : null })
    }

    return (
        <div ref={rootEl}>
            <Select
                classes={classes}
                styles={selectStyles}
                inputId={id}
                TextFieldProps={{
                    label, fullWidth, margin, autoFocus, required,
                    InputLabelProps: {
                        htmlFor: id,
                        shrink: true,
                        required: false //Hide asterix on required field
                    }
                }}
                isClearable={isClearable}
                placeholder=""
                options={options}
                components={{
                    Control,
                    ValueContainer,
                    MenuList,
                    Option,
                    SingleValue,
                    IndicatorSeparator,
                    DropdownIndicator
                }}
                value={selection}
                onChange={handleChange}
            />
        </div>
    )
}