import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { uncapitalized } from 'utils'

import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    chip: {
        color: 'white',
        //padding: '0px 4px',
        width: 'fit-content',
        alignSelf: 'center',
        backgroundColor: 'gray'
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 'fit-content',
        '& .opened, & .inTransit': {
            color: 'white',
            backgroundColor: '#FCA861'
          },
        '& .green, & .escalated, & .delivered': {
            color: 'white',
            backgroundColor: '#4CB091'
        },
        '& .exception': {
            color: 'white',
            backgroundColor: '#EF7373'
        }
    }
}))

export default function StatusChipField({ label, color, ...others }) {
    const classes = useStyles()
    const cls = uncapitalized(label)

    return (
        <div className={classes.vbox}>
            <Chip label={label}
                className={clsx(classes.chip, cls, color)}
                {...others}
            />
        </div>
    )
}