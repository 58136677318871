import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
    input: {
        //Instead of display: none to prevent Chrome of required hidden fields
        opacity: 0,
        height: 1,
        width: 1
    },
    label: {
        display: 'inline-flex',
        marginTop: theme.spacing(3)
    },
    button: {
        // marginTop: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    typo: {
        display: "inline-block",
        // position: "absolute",
        marginTop: theme.spacing(1),
        marginRight: 2,
        maxWidth: 270,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    avatar: {
        display: "inline-block",
        // position: "absolute",
        // marginTop: 22
    }
}))

export default function ImageField({ id, label, accept, fullWidth, margin, defaultValue, required, onChange }) {
    const classes = useStyles()

    const handleChange = event => {
        const { id, files } = event.target
        const file = files[0]

        return new Promise((resolve, reject) => {
            if (file) {
                const  reader = new FileReader();
                
                reader.onload = () => {
                    const value = {
                        name: file.name,
                        encoding: "base64",
                        mimeType: file.type,
                        content: reader.result
                    };
                    const event = { target: {id, value}, file };
                    
                    onChange(event);
                    resolve(event);
                }
                reader.onerror = reject;
                reader.readAsDataURL(file);
            }
        })
    }
    return (
        <FormControl
            fullWidth={fullWidth}
            margin={margin}
        >
            <Input
                type="file"
                id={id}
                name={id}
                className={classes.input}
                accept={accept || "*"}
                fullWidth={fullWidth}
                onChange={handleChange}
                required={required}
            />
            <label className={classes.label} htmlFor={id}>
                <Button variant="contained" component="span" className={classes.button}>
                    {label}
                </Button>
                {!defaultValue ? (<span/>) : (/[\/](gif|jpg|jpeg|tiff|png)$/i).test(defaultValue.mimeType) ? (
                    <Avatar src={defaultValue.content} className={classes.avatar}/>
                ) : (
                    <Typography variant="subtitle1" component="span" className={classes.typo}>
                        {defaultValue.name}
                    </Typography>
                )}
            </label>
        </FormControl>
    )
}