import * as Types from 'constants/ActionTypes'
import { actionRequest } from './index'
import { buildUrl } from 'utils';

const resources = {
    reportFormParams: {
        get: { path: '/customers/:customerId/reports/:reportId', method: 'get' }
    }
}

export const getReportFormParams = (params) => (...args) => {
    const { path, method } = resources['reportFormParams']['get']

    return actionRequest(...args)({
        type: Types.FETCH_REPORT_FORM_PARAMS,
        url: buildUrl(path, params),
        method   
    })
}

