import React , { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Select, MenuItem, Input } from "@material-ui/core";
import { createRequestId } from 'utils';
import { fetchDataSource } from 'actions/index';

const mapState = ({ resource, options }) => state => {
    if (resource) {
        const id = createRequestId(resource)
        options = (state.datasources[id]) ? state.datasources[id].data : []
    }

    return { options }    
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function getStyles(name, measures, theme) {
    return {
        fontWeight:
        measures.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}


const SelectMultipleField = ({ id, allowEmpty, valueName, textName, options = [], value = [], resource, params, onChange, renderValue, ...others }) => {
    const deps = useMemo(() => JSON.stringify([resource, params]), [resource, params])
    const dispatch = useDispatch()
    const { options: data } = useSelector(mapState({ resource, options }))
    const [selection, setSelection] = useState([])

    useEffect(() => {
        if (resource) {
            dispatch(fetchDataSource(resource, params))
        }
    }, [deps])

    useEffect(() => {
        setSelection(data.filter(option => value.indexOf(option[valueName]) !== -1))
    }, [data, value])

    const handleChange = event => {
        const { value } = event.target
        const selection = data.filter(option => value.indexOf(option[valueName]) !== -1)

        if (allowEmpty || (!allowEmpty && selection.length > 0)) {
            setSelection(selection)
        } 
        event.target = Object.assign(event.target, { id, selection })
        if (onChange) onChange(event)
    }

    return (
        <Select
            multiple
            id={id}
            input={<Input/>}
            value={selection.map(option => option[valueName])}
            onChange={handleChange}
            renderValue={() => renderValue && renderValue(selection)}
            {...others}
        >
            {data ? data.map((option, index) => (
                <MenuItem key={index} value={option[valueName]}>
                    {option[textName]}
                </MenuItem>
            )) : []}
        </Select>
    )
}

export default SelectMultipleField