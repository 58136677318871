import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Slide, Tabs, Tab, Box } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

import { I18nManagerContext } from 'contexts/I18nManager';

import PatientInsuranceForm from 'components/PatientInsuranceForm'
import BillingInfoForm from 'components/BillingInfoForm'
import AuthContactForm from 'components/AuthContactForm'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 'fit-content'
    },
    title: {
        display: 'block',
        padding: 0
    },
    subtitle: {
        display: 'block',
        paddingLeft: theme.spacing(1 / 4)
    },
    content: {
        paddingLeft: 75,
        paddingRight: 75

    },
    formContainer: {
        paddingTop: 24
    },
    textField: {
        width: theme.spacing(41)
    },
    buttons: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        width: theme.spacing(19.5),
        marginLeft: theme.spacing(1) / 2,
        marginRight: theme.spacing(1) / 2,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -theme.spacing(1) - 4,
        marginLeft: -theme.spacing(1) - 4
    }
}))

const Forms = [
    { label: 'Membership', component: PatientInsuranceForm },
    { label: 'Billing', component: BillingInfoForm },
    { label: 'Contact', component: AuthContactForm }
]

const TabPanel = ({ value, index, className, ...others }) => (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        className={className}

    >
        <Box {...others}></Box>
    </Typography>
)

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

const autoSrollable = index => ({
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
})

const InputLabelProps = {
    required: false //Hide asterix on required field
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function PatientInsuranceTabDialog({ open, title, subtitle, record, onSubmit, onCancel }) {
    const i18nManager = useContext(I18nManagerContext)
    const classes = useStyles()
    const [isSubmitting, setSubmitting] = useState(false)
    const [state, setState] = useState(record || {})
    const [errors, setErrors] = useState({})
    const [value, setValue] = useState(0)
    const [selected, setSelected] = useState(0)
    const params = useParams()

    const handleChange = (event, newValue) => {
        event.preventDefault()

        setSelected(newValue)
        setSubmitting(true)
    }

    const handleCancel = () => {
        onCancel()
    }

    const handleDone = () => {
        setSubmitting(true)
    }

    const handleFormError = errors => {
        setSubmitting(false)
        setErrors(errors)
        setSelected(value)
    }

    const handleFormSubmit = newState => {
        setSubmitting(false)
        setErrors({})
        setState({ ...newState })

        if (value === selected) {
            onSubmit(newState)
        }
        else {
            setValue(selected)
        }
    }

    return (
        <Dialog
            classes={{
                paper: classes.dialogPaper
            }}
            open={open}
            onClose={onCancel}
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            keepMounted
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="form-dialog-title">
                {/* <Typography className={classes.title} variant="h6" color="secondary" noWrap> */}
                {i18nManager.t(title)}
                {/* </Typography> */}
                <Typography className={classes.subtitle} variant="caption" color="textSecondary" noWrap>
                    {i18nManager.t(subtitle)}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="secondary">
                    {Forms.map((form, index) => {
                        return (<Tab key={index}
                            label={form.label}
                            value={index}
                        />)
                    })}
                </Tabs>
                {Forms.map((form, index) => {
                    return (
                        <TabPanel
                            key={index}
                            component={form.component}
                            value={value}
                            index={index}
                            record={state}
                            params={params}
                            isSubmitting={(index === value) ? isSubmitting : false}
                            onSubmit={handleFormSubmit}
                            onError={handleFormError}
                            className={classes.formContainer}
                        />)
                })}
            </DialogContent>
            <DialogActions className={classes.buttons}>
                <Button variant="contained" className={classes.button} onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" className={classes.button} onClick={handleDone} color="secondary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

PatientInsuranceTabDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default PatientInsuranceTabDialog